import { types } from "./actionTypes";
const initialState = {
  addTournament: null,
  tournamentDetail: null,
  updateTournamentDetails: null,
  deleteTournaments: null,
  preSignedUrlLogo: null,
  uploadImages: null,
  tournamentById: null,
  fetchTeams: null,
  activeEvents: null,
  upcomingEvents: null,
  getTaggedVidoes: null,
  tournamentUserStatus: null,
  getTeamsOrPlayersByTournamentIdAndUserId: null,
  getTeamsByTournamentBeforeLogin: null,
};
const tournamentReducer = (state: any = initialState, action: any) => {
  console.log("action",action.payload)
  switch (action.type) {
    case types.ADD_TOURNAMENT_SUCCESS:
      state = {
        ...state,
        addTournament: action.payload,
      };
      return state;
      break;
    case types.FETCH_TOURNAMENTS_SUCCESS:
      state = {
        ...state,
        tournamentDetail: action.payload,
      };
      return state;
      break;
    case types.FETCH_TEAMS_SUCCESS:
      state = {
        ...state,
        fetchTeams: action.payload,
      };
      return state;
      break;
    case types.TOURNAMENT_BY_ID_SUCCESS:
      state = {
        ...state,
        tournamentById: action.payload,
      };
      return state;
      break;
    case types.EDIT_TOURNAMENT_SUCCESS:
      state = {
        ...state,
        updateTournamentDetails: action.payload,
      };
      return state;
      break;
    case types.DELETE_TOURNAMENT_SUCCESS:
      state = {
        ...state,
        deleteTournaments: action.payload,
      };
      return state;
      break;
    case types.PRESIGNED_URL_LOGO_SUCCESS:
      state = {
        ...state,
        preSignedUrlLogo: action.payload,
      };
      return state;
      break;
    case types.UPLOAD_IMAGES_SUCCESS:
      state = {
        ...state,
        uploadImages: action.payload,
      };
      return state;
      break;
    case types.TOTAL_ACTIVE_EVENTS_SUCCESS:
      state = {
        ...state,
        activeEvents: action.payload,
      };
      return state;
    case types.UPCOMING_EVENTS_SUCCESS:
      state = {
        ...state,
        upcomingEvents: action.payload,
      };
      return state;
    case types.GET_ADD_VIDEO_TAGE_SUCCESS:
      state = {
        ...state,
        getTaggedVidoes: action.payload,
      };
      return state;
    case types.TOURNAMENT_USER_STATUS_SUCCESS:
      state = {
        ...state,
        tournamentUserStatus: action.payload,
      };
      return state;
    case types.GET_TEAMS_OR_PLAYERS_BY_TOURNAMNTBYID_AND_USERID_SUCCESS:
      state = {
        ...state,
        getTeamsOrPlayersByTournamentIdAndUserId: action.payload,
      };
      return state;
    case types.GET_TEAMS_BY_TOURNAMENT_BEFORE_LOGIN_SUCCESS:
      state = {
        ...state,
        getTeamsByTournamentBeforeLogin: action.payload,
      };
      return state;

    default:
      return state;
  }
};

export default tournamentReducer;
