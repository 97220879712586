import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Card } from "reactstrap";
import { Grid } from "@mui/material";
import Select from "react-select";
// import { getToday } from 'rsuite-utils/lib/DateUtils';
import { isBefore, isAfter, differenceInCalendarDays } from "date-fns";

// store
import { addMatchDetails, updateMatchDetails } from "../../Store/match/actions";
import { fetchTournament } from "../../Store/tournament/actions";
import { fetchTeams } from "../../Store/tournament/actions";
import notifications from "../../components/Notifications/Notification";
import CloseIcon from "../../components/Icons/CloseIcon";
import validate from "./validate";
import { DatePicker, Stack } from "rsuite";
import moment from "moment";
const AddMatch = (props: any) => {
  let formCreate: any = {
    tournamentId: "",
    winningTeamId: "",
    loosingTeamId: "",
    matchDate: null,
  };
  let formUpdate: any = {
    tournamentId: {
      value: {
        id: props?.editMatchDetails?.tournamentInfo?._id,
        startDate: props?.editMatchDetails?.tournamentInfo?.startDate,
        endDate: props?.editMatchDetails?.tournamentInfo?.endDate,
      },
      label: props?.editMatchDetails?.tournamentInfo?.label,
    },
    winningTeamId: {
      value: props?.editMatchDetails?.winningTeamInfo?._id,
      label: props?.editMatchDetails?.winningTeamInfo?.teamName,
    },
    loosingTeamId: {
      value: props?.editMatchDetails?.lossingTeamInfo?._id,
      label: props?.editMatchDetails?.lossingTeamInfo?.teamName,
    },
    matchDate: new Date(props?.editMatchDetails?.matchDate),
  };
  let formData = props?.editMatchDetails ? formUpdate : formCreate;
  const [form, setForm]: any = useState(formData);
  const [errors, setErrors]: any = useState({});
  const [startDateValue, setStartDateValue]: any = useState();
  const [errorFlag, setErrorFlag]: any = useState(false);
  console.log("props", props?.editMatchDetails);
  useEffect(() => {
    getAllTeamsData();
    console.log("props", props?.editMatchDetails);
  }, []);
  useEffect(() => {
    if (errors) {
      validate(form);
    }
    console.log("error", errors);
  }, [errors && setErrorFlag, form]);

  const getAllTeamsData = () => {
    props.fetchTeams(0, 1000, {}, (res: any) => {});
  };
  const handleOnInputChangeTournament = (e: any) => {
    if (e?.length > 2)
      props.fetchTournament(0, 10, { name: e }, (res: any) => {});
  };

  let tournamentOptionsArry: any = [];

  if (props.getTeamDetails !== undefined) {
    let getTeamDetails = props?.getTeamDetails?.data;
    getTeamDetails?.docs?.map((ele: any, i: any) => {
      tournamentOptionsArry.push({
        value: {
          id: ele?.tournamentsDeatils?._id,
          startDate: ele?.tournamentsDeatils?.startDate,
          endDate: ele?.tournamentsDeatils?.endDate,
        },
        label: ele?.tournamentsDeatils?.label,
      });
      const tournamentOptionsArray = tournamentOptionsArry.filter(
        (v: any, i: any, a: any) =>
          a.findIndex((v2: any) => v2.value?.id === v.value?.id) === i
      );
      console.log("test", tournamentOptionsArray);
      tournamentOptionsArry = tournamentOptionsArray;
    });
  }
  console.log("tournamentOptionsArry",tournamentOptionsArry)
  let teamOptionsArry: any = [];
  if (form?.tournamentId?.label) {
    if (props.getTeamDetails !== undefined) {
      let getTeamDetails = props?.getTeamDetails?.data;
      getTeamDetails?.docs?.map((ele: any, i: any) => {
        if (ele?.tournamentsDeatils?._id === form?.tournamentId?.value?.id) {
          teamOptionsArry.push({
            value:  ele?._id,
            label: ele?.teamName,
          });
        }
      });
    }
  }
  const validations = () => {
    let error: any = "";
    let errorObj: any = {};
    console.log("formData", form);
    if (form?.tournamentId === "") {
      error = "required!";
      errorObj.tournamentId = "Tournament name is Required!";
    }
    if (form.matchDate === null) {
      error = "Required!";
      errorObj.matchDate = " Match Date is Required!";
    }
    if (form.winningTeamId === "") {
      error = "Required!";
      errorObj.winningTeamId = " Winning Team Name is Required!";
    }
    if (form.loosingTeamId === "") {
      error = "Required!";
      errorObj.loosingTeamId = " Loosing Team Name is Required!";
    }
    setErrors(errorObj);
    console.log("error", errors);

    return error;
  };
  const handleSubmit = (e: any) => {
    e.preventDefault();
    let result: any = validations();

    if (result?.length === 0) {
      if (form?.winningTeamId && form?.loosingTeamId && form?.tournamentId) {
        if (props?.editMatchDetails) {
          let payload = {
            matchId: props?.editMatchDetails?._id,
            tournamentId: form?.tournamentId?.value?.id,
            winningTeamId: form?.winningTeamId?.value,
            lossingTeamId: form?.loosingTeamId?.value,
            matchDate: moment(form?.matchDate).toISOString(),
          };
          props?.updateMatchDetails(payload, (res: any) => {
            if (res.success) {
              notifications({
                title: "Success!",
                message: res?.message,
                type: "success",
              });
              props.closeModal();
              props?.getAllMatchData();
            } else {
              notifications({
                title: "Error!",
                message: res?.message,
                type: "danger",
              });
            }
          });
        } else {
          let payload = {
            tournamentId: form?.tournamentId?.value?.id,
            winningTeamId: form?.winningTeamId?.value,
            lossingTeamId: form?.loosingTeamId?.value,
            matchDate: moment(form?.matchDate).toISOString(),
          };
          props?.addMatchDetails(payload, (res: any) => {
            if (res.success) {
              notifications({
                title: "Success!",
                message: res?.message,
                type: "success",
              });
              props.closeModal();
              props?.getAllMatchData();
            } else {
              notifications({
                title: "Error!",
                message: res?.message,
                type: "danger",
              });
            }
          });
        }
      }
    }
  };


  const handleSubmitChange = (type: any) => {
    if (type?.name === "tournamentName") {
      setForm({ ...form, tournamentId: type?.value });
    } else if (type?.name === "winningTeam") {
      setForm({ ...form, winningTeamId: type?.value });
    } else if (type?.name === "loosingTeam") {
      setForm({ ...form, loosingTeamId: type?.value });
    } else if (type?.name === "startDate") {
      setForm({ ...form, matchDate: type?.value });
    }
  };
  useEffect(() => {
    console.log("formData", form);
  }, [form]);

  // teamOptionsArry?.filter((ele:any)=>ele?.value !== form?.winningTeam?.value)
  console.log("ournamentOptionsArry", tournamentOptionsArry);
  return (
    <div className="text-white AddGamePage">
      <div className="">
        <Card className="CardTournament">
          {/* <div>
            <div className="d-flex justify-content-center">
              <h4 className="ps-4 pt-3">
               
              </h4>
            </div>
            <div className="d-flex justify-content-end alignItemPopUpLogin1 pe-4">
              <CloseIcon className="float-end" onClickIcon={props.closeModal} />
            </div>
          </div> */}
          <form
            id="form"
            className="flex flex-col"
            onSubmit={(e: any) => handleSubmit(e)}
          >
            <Grid container>
              <Grid item xs={12} className="p-4">
                <label className="mb-3 text-white">Tournament Name</label>
                <Select
                  {...props}
                  className="Game_selector"
                  classNamePrefix="react-select"
                  placeholder="Select Tournament..."
                  autoFocus={true}
                  isSearchable
                  value={form?.tournamentId}
                  options={tournamentOptionsArry}
                  onChange={(e: any) => {
                    console.log("e value", e);
                    handleSubmitChange({
                      name: "tournamentName",
                      value: e,
                    });
                  }}
                  onInputChange={(e) => handleOnInputChangeTournament(e)}
                />
                {errors.tournamentId && form?.tournamentId === "" ? (
                  <p className="RequiredField">{errors?.tournamentId} </p>
                ) : (
                  ""
                )}
              </Grid>
              <Grid item xs={12} className="p-4">
                <label className="mb-3 text-white">Match Date</label>
                <DatePicker
                  {...props}
                  style={{
                    width: "100%",
                  }}
                  format="yyyy-MM-dd "
                  name="StartDate"
                  id="StartDate"
                  value={form?.matchDate ? form?.matchDate : null}
                  {...props}
                   cleanable={false}
                  disabled={form?.tournamentId?.value?.startDate ? false :true}
                  disabledDate={(date:Date)=>isBefore(date,new Date(moment(
                        form?.tournamentId?.value?.startDate
                      ).format("MM-DD-yyyy"))) || isAfter(date,new Date(moment(
                        form?.tournamentId?.value?.endDate
                      ).format("MM-DD-yyyy")) )
                   
                  }
                  onChange={(newValue: any) => {
                    console.log("newValue", newValue);
                    handleSubmitChange({
                      name: "startDate",
                      value: newValue,
                    });
                  }}
                />
                {errors.matchDate && form?.matchDate === null ? (
                  <p className="RequiredField">{errors?.matchDate} </p>
                ) : (
                  ""
                )}
              </Grid>
              <Grid item xs={12} className="p-4">
                <label className="mb-3 text-white">Winning Team</label>
                <Select
                  {...props}
                  className="Game_selector"
                  classNamePrefix="react-select"
                  placeholder="Select Tournament..."
                  autoFocus={true}
                  isSearchable
                  value={form?.winningTeamId}
                  options={teamOptionsArry}
                  onChange={(e: any) => {
                    console.log("e value", e);
                    handleSubmitChange({
                      name: "winningTeam",
                      value: e,
                    });
                  }}
                  // onInputChange={(e) => handleOnInputChangeGame(e)}
                />
                {errors.winningTeamId && form?.winningTeamId === "" ? (
                  <p className="RequiredField">{errors?.winningTeamId} </p>
                ) : (
                  ""
                )}
              </Grid>
              <Grid item xs={12} className="p-4">
                <label className="mb-3 text-white">Loosing Team</label>
                <Select
                  {...props}
                  className="Game_selector"
                  classNamePrefix="react-select"
                  placeholder="Select Tournament..."
                  autoFocus={true}
                  isSearchable
                  value={form?.loosingTeamId}
                  options={teamOptionsArry?.filter(
                    (ele: any) => ele?.value !== form?.winningTeamId?.value
                  )}
                  onChange={(e: any) => {
                    console.log("e value", e);
                    handleSubmitChange({
                      name: "loosingTeam",
                      value: e,
                    });
                  }}
                  // onInputChange={(e) => handleOnInputChangeGame(e)}
                />
                {errors.loosingTeamId && form?.loosingTeamId === "" ? (
                  <p className="RequiredField">{errors?.loosingTeamId} </p>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>

            <button className=" p-1 w-25 mb-4 TorunamentSubmitButton" type="submit">
              Submit
            </button>
          </form>
        </Card>
      </div>
    </div>
  );
};
const mapStateToProps = (state: any) => {
  console.log("state", state);
  return {
    ...state,
    // getTournamentDetails: state?.tournamentReducer?.tournamentDetail,
    getTeamDetails: state?.tournamentReducer?.fetchTeams,
  };
};
export default connect(mapStateToProps, {
  fetchTeams,
  addMatchDetails,
  updateMatchDetails,
  fetchTournament,
})(AddMatch);
