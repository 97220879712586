import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { SiDiscord } from "react-icons/si";
import { Modal, ModalBody } from "reactstrap";
import { connect } from "react-redux";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import YourwordsCarousal from "../../../components/common/YourwordsCarousal";

import { useLocation, useNavigate } from "react-router-dom";
import { gapi } from "gapi-script";
import jwt from "jwt-decode";

// importing video

import Live from "../../../assets/videos/Live Interactive Session _ Home Web.mov";
import Practice from "../../../assets/videos/Practice Scrim _ Home Web.mov";
import GameTournamentVideo from "../../../assets/videos/Esports Tournaments.mp4";
import Individual from "../../../assets/videos/Individual Analysis.mov";
// importing images

import HeaderImage from "../../../assets/images/1.webp";
import valorent from "../../../assets/images/2.webp";
import bgmi from "../../../assets/images/3.webp";

// importing files
import UserRegistration from "../Registration/UserRegistration";
import ResetPassword from "./ResetPassword";
import UserLogin from "./UserLogin";
import notifications from "../../../components/Notifications/Notification";

// importing from store
import {
  checkSocialRegistration,
  checkSocialLogin,
} from "../../../Store/actions";

const Login = (props: any) => {
  const navigate = useNavigate();
  const hash = useLocation().hash;
  let showLogin = useLocation();
  const [openRegistration, setOpenRegistration] = useState(false);
  const [openLogin, setOpenLogin] = useState(false);
  const [resetPassword, setResetPassword] = useState(false);
  const [showRegistrationPopUp, setShowRegistrationPopup]: any = useState(
    showLogin?.state?.showLoginPage
  );

  const search = useLocation().search;
  const userId = new URLSearchParams(search).get("userId");

  let accessTokenDiscord: any = new URLSearchParams(hash).get("access_token");
  const registrationFlage = localStorage.getItem("Registration");
  const loginFlag = localStorage.getItem("Login");

  useEffect(() => {
    if (registrationFlage) {
      let payload = {
        token: accessTokenDiscord,
        type: "discord",
      };

      props?.checkSocialRegistration(payload, (res: any) => {
        if (res.success) {
          notifications({
            title: "Success!",
            message: res?.message,
            type: "success",
          });
        } else {
          notifications({
            title: "Error!",
            message: res?.message,
            type: "danger",
          });
        }
        // console.log(window.location.hash);
        if (window.location.hash) {
          window.history.replaceState(
            "",
            document.title,
            window.location.pathname
          );
        }
        localStorage.removeItem("Registration");
      });
    }
    if (loginFlag) {
      let payload = {
        token: accessTokenDiscord,
        type: "discord",
      };
      loginWithSocialMedia(payload);
      localStorage.removeItem("Login");
    }
  }, [accessTokenDiscord]);

  useEffect(() => {
    if (registrationFlage || showRegistrationPopUp === "open") {
      setOpenRegistration(true);
    }
    setShowRegistrationPopup("");
    if (userId) {
      setResetPassword(true);
    }
    const initClient = () => {
      gapi.client.init({
        clientId: process.env.CLIENTID,
        scope: "",
      });
    };
    gapi.load("client:auth2", initClient);
  }, []);
  useEffect(() => {}, [showRegistrationPopUp]);
  const loginWithSocialMedia = (payload: any) => {
    props?.checkSocialLogin(payload, (res: any) => {
      if (res.success) {
        let authUser: any = jwt(res?.data?.accessToken);
        let rememberMe: any = localStorage.getItem("rememberMe");
        if (rememberMe === true) {
          localStorage.setItem("authUser", JSON.stringify(authUser));
          localStorage.setItem("accessToken", res.data.accessToken);
          localStorage.setItem("refreshToken", res.data.refreshToken);
        } else {
          sessionStorage.setItem("authUser", JSON.stringify(authUser));
          sessionStorage.setItem("accessToken", res.data.accessToken);
          sessionStorage.setItem("refreshToken", res.data.refreshToken);
        }
        notifications({
          title: "Success!",
          message: res?.message,
          type: "success",
        });
        closeModalLogin();
        if (authUser?.role === "Admin") {
          navigate("/admin-dashboard");
          window.scrollTo(0, 0)

        } else {
          navigate("/user/tournament");
          window.scrollTo(0, 0)

        }
      } else {
        notifications({
          title: "Error!",
          message: res?.message,
          type: "danger",
        });
      }
    });
  };
  // let openResetModelPopup = () => {
  //   setResetPassword(true);
  // };
  let closeResetModal = () => {
    setResetPassword(false);
  };
  let openLoginPopUp = () => {
    setOpenLogin(true);
    setOpenRegistration(false);
  };
  let closeModalLogin = () => {
    setOpenLogin(false);
  };
  let openRegistrationPopUp = () => {
    setOpenRegistration(true);
    setOpenLogin(false);
  };
  let closeModal = () => {
    setOpenRegistration(false);
  };
  return (
    <>
      <Modal
        isOpen={resetPassword}
        toggle={closeResetModal}
        size="lg"
        className="RegistrationModal"
      >
        <ModalBody>
          <ResetPassword {...props} closeResetModal={closeResetModal} />
        </ModalBody>
      </Modal>
      <Modal
        isOpen={openRegistration}
        toggle={closeModal}
        size="lg"
        className="RegistrationModal"
      >
        <ModalBody>
          <UserRegistration
            {...props}
            closeModal={closeModal}
            openLoginPopUp={openLoginPopUp}
          />
        </ModalBody>
      </Modal>
      <Modal
        isOpen={openLogin}
        toggle={closeModalLogin}
        size="lg"
        className="RegistrationModal"
      >
        <ModalBody>
          <UserLogin
            {...props}
            closeModalLogin={closeModalLogin}
            openRegistrationPopUp={openRegistrationPopUp}
          />
        </ModalBody>
      </Modal>
      <div className="page-contentBg LoginPage">
        <div className="container">
          <Grid container className="OGGGDiv">
            <Grid className="imagediv" item xs={12}>
              <div className="d-flex justify-content-center">
                <img
                  className="HeaderImage"
                  src={HeaderImage}
                  alt="BecomePro"
                />
              </div>
              <div className="HeaderImgTextDisplayPos">
                <span className="d-flex justify-content-center spanHeaderImageFontStyles fontBecomeOGGG pt-2">
                  BECOME AN OG WITH GG
                </span>
                <div className="d-flex justify-content-center">
                  <Link
                    to="/category"
                    onClick={() => {
                      window.scrollTo(0, 0);
                    }}
                  >
                    <Button
                      // variant="contained"
                      className="spanHeaderButton fontBecomeProButton"
                    >
                      Become a Pro
                      <svg
                        className="ps-2"
                        width="20"
                        height="15"
                        viewBox="0 0 12 21"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12 10.5L-9.38016e-07 20.4593L-6.73465e-08 0.540707L12 10.5Z"
                          fill="black"
                        />
                      </svg>
                    </Button>
                  </Link>
                </div>
              </div>
            </Grid>
          </Grid>
          <div>
            <Grid container className="ChooseGameDiv">
              <Grid item xs={12}>
                <div className="imageCard m-4 imagebg1">
                  <span className="d-flex justify-content-center paddingCoachTop spanChooseFontStyles fontChooseGame pt-2">
                    CHOOSE YOUR GAME
                  </span>
                  <span className="d-flex justify-content-center spanChoose1TextStyle fontMvpbW pt-2">
                    Be the MVP of your most played game
                  </span>
                  <Grid
                    container
                    style={{
                      color: "#FFF",
                    }}
                    className="mt-68"
                  >
                    <Grid item xs={12} md={6}>
                      <div className="imageValorant">
                        <Link to="/category">
                          <img
                            className="imageShadowValorant"
                            src={valorent}
                            width="100%"
                            height="200px"
                            alt="Valorant"
                          ></img>
                        </Link>
                        <div className="marginDiv">
                          <h5 className="d-flex justify-content-center fontVolorant spanTextExclusiveTextStyle">
                            <Link to="/category">Valorant </Link>
                          </h5>
                          <span className=" d-flex  justify-content-center spanTextStyle fontContentStyle textClrF3F3F3 textAlignStylesV">
                            Outplay your competition with tactical abilities &
                            precise gunplay.
                          </span>
                        </div>
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      style={
                        {
                          // background:`url(${Shadow2})`
                        }
                      }
                    >
                      <div
                        className="imageBgmi"
                        style={
                          {
                            // background: `url(${Shadow2})`,
                          }
                        }
                      >
                        <img
                          className="imageShadowbgmi"
                          src={bgmi}
                          width="100%"
                          height="200px"
                          alt="BGMI"
                        ></img>
                        <div className="marginDiv">
                          <h5 className="d-flex justify-content-center spanTextExclusiveTextStyle fontBGMI">
                            BGMI
                          </h5>
                          <span className="d-flex justify-content-center fontContentStyle textClrF3F3F3 textAlignStylesB">
                            {" "}
                            Defeat your enemy with adaptive teamwork & earn that
                            chicken dinner you deserve
                          </span>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </div>
          <div className="LegendDiv">
            <div className="spanDiv">
              <span className="d-flex justify-content-center spanComingFontStyles fontLegend pt-2">
                DON'T JUST PLAY, BECOME A LEGEND
              </span>
              <span className="d-flex justify-content-center spanTextExclusiveTextStyle fontTookKitMaster pt-2 mb-3">
                Here's your toolkit to Master every Game
              </span>
            </div>
            <Grid container spacing={4} className="">
              <Grid item xs={12} lg={6} className="">
                <Card className="CardStyles">
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <div className="text-white CarosuelVideoStyle1 d-flex justify-content-center ">
                        <video
                          className="video-section"
                          autoPlay
                          playsInline
                          muted
                          loop
                        >
                          <source  src={Live} type="video/mp4" />
                        </video>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <div className="TextPadding">
                        <span className="d-flex textStyles fontContentVideo justify-content-start">
                          Interactive Live Sessions
                        </span>
                        <span className="fontContentText textClrF3F3F3">
                          Upskill your desired game with our professional
                          coaches through structured learning designed for
                          gamers at all levels.
                        </span>
                      </div>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
              <Grid item xs={12} lg={6} className="">
                <Card className="CardStyles">
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <div className="text-white CarosuelVideoStyle1 d-flex justify-content-center ">
                        {/* <h3 className="fontGeomatrixLight">Video</h3> */}
                        <video
                          className="video-section"
                          autoPlay
                          playsInline
                          muted
                          loop
                        >
                          <source  src={Practice} type="video/mp4" />
                        </video>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <div className="TextPadding">
                        <span className="d-flex textStyles fontContentVideo justify-content-start">
                          Practice Scrims
                        </span>
                        <span className="fontContentText textClrF3F3F3">
                          Train with the MVPs (our coaches) where we decode real
                          game scenarios and stake it out with your peers to
                          level up.
                        </span>
                      </div>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
              <Grid item xs={12} lg={6} className="">
                <Card className="CardStyles">
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <div className="text-white CarosuelVideoStyle1 d-flex justify-content-center ">
                        {/* <h3 className="fontLoto">Video</h3> */}
                        <video
                          className="video-section"
                          autoPlay
                          playsInline
                          muted
                          loop
                        >
                          <source src={Individual} type="video/mp4" />
                        </video>
                       
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <div className="TextPadding">
                        <span className="d-flex textStyles fontContentVideo justify-content-start pl-3">
                          Individual Analysis
                        </span>
                        <span className="fontContentText textClrF3F3F3">
                          Have experts analyse and better your gaming skills
                          through individual feedback on all your games. Submit
                          your videos and start your journey of becoming a
                          master.
                        </span>
                      </div>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
              <Grid item xs={12} lg={6} className="">
                <Card className="CardStyles">
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <div className="text-white CarosuelVideoStyle1 d-flex justify-content-center ">
                        <video
                          className="video-section"
                          // width="330px"
                          // height="230px"
                          autoPlay
                          playsInline
                          muted
                          loop

                          // controls
                        >
                          <source src={GameTournamentVideo} type="video/mp4" />
                        </video>
                        {/* GameTournamentVideo */}
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <div className="TextPadding">
                        <span className="d-flex textStyles fontContentVideo justify-content-start">
                          Gaming Tournaments
                        </span>
                        <span className="fontContentText textClrF3F3F3">
                          Use your learning in a competitive space and outwit
                          fellow gamers to make your mark in the game.
                        </span>
                      </div>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            </Grid>
          </div>
          <div>
            <div className="WordsDiv">
              <div className="spanDiv">
                <span className="d-flex justify-content-center spanComingFontStyles fontWord pt-2">
                  THEIR WORDS, NOT OURS!
                </span>
              </div>
              <Grid container spacing={2}>
                <Grid item xs={12} className="p-4">
                  <YourwordsCarousal />
                </Grid>
              </Grid>
            </div>
            <div className="spanDiv">
              <div className="d-flex justify-content-center">
                <a
                  href="https://discord.gg/pDUF4Kt5zW"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button variant="contained" className="joinButton pe-2 me-1">
                    Join Our Community
                    <HiOutlineArrowNarrowRight />
                  </Button>
                </a>
              </div>
            </div>
          </div>
          {/* <div className="getCoachDiv">
            <Grid container >
              <Grid item xs={12}>
                <Card
                  className="imageCardCoaches m-4"
                  style={{
                    // background: `url(${Shadow1})`,
                  }}
                >
                  <span className="d-flex justify-content-center paddingCoachTop spanComingFontStyles fontCoach pt-2">
                    GET COACHED BY THE TOP 1%
                  </span>

                  <Grid container spacing={4}>
                    <Grid item xs={0} md={1}></Grid>
                    <Grid item xs={12} md={5} className="">
                      <img className="imagePrathik" src={prathikMehra} alt="Pratik Mehra"></img>
                      <div className=" namePosition">
                        <span className="d-flex justify-content-center namePrathika">
                          PRATIK MEHRA
                        </span>
                        <span className="d-flex justify-content-center nameGame">
                          BGMI
                        </span>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={5} className="">
                      <img src={Brad} className="imageBrad" alt="Brad"></img>
                      <div className="namePosition1">
                        <h2 className="d-flex justify-content-center  nameBrad">
                          BRAD GIL
                        </h2>
                        <span className="d-flex justify-content-center nameGame">
                          Valorant
                        </span>
                      </div>
                    </Grid>
                    <Grid item xs={0} md={1}></Grid>
                  </Grid>
                </Card>
              </Grid>
            </Grid>
          </div> */}
          <div className="everyDiv">
            <div className="CommingSoonDiv">
              <span className="d-flex justify-content-center spanComingFontStyles fontEvery pt-2">
                GG FOR EVERY OG!
              </span>
              <span className="d-flex justify-content-center spanTextExclusiveTextStyle fontTookKitMaster pt-2">
                With learning modules made for every level to help you master
                your game, real good, real fast!{" "}
              </span>
            </div>

            <Grid container>
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={4}>
                    <Card className="CardStylesGGOG">
                      <div className="widthName1 pt-3 ">
                        <span className="d-flex textStyles fontEveryHeader justify-content-start">
                          Rise through the Ranks-
                        </span>
                        <ul>
                          <li className="fontEveryContent textClrF3F3F3 ">
                            Unlock your true gaming potential
                          </li>
                          <li className="fontEveryContent textClrF3F3F3">
                            Rank up at 5X speed
                          </li>
                        </ul>
                      </div>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Card className="CardStylesGGOG">
                      <div className="widthName1 pt-3 ">
                        <span className="d-flex textStyles fontEveryHeader justify-content-start pl-3">
                          GG's Global Gaming Community
                        </span>
                        <ul>
                          <li className="fontEveryContent textClrF3F3F3">
                            Exclusive entry to the community
                          </li>
                          <li className="fontEveryContent textClrF3F3F3">
                            Interact with GamingGeeks like yourself
                          </li>
                        </ul>
                      </div>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Card className="CardStylesGGOG">
                      <div className="widthName1 pt-3 ">
                        <span className="d-flex textStyles fontEveryHeader justify-content-start pl-3">
                          Join Tier 1 teams
                        </span>
                        <ul>
                          <li className="fontEveryContent textClrF3F3F3">
                            Accelerate your gaming career
                          </li>
                          <li className="fontEveryContent textClrF3F3F3">
                            Scouting opportunities with tier 1 gaming teams
                            across the globe
                          </li>
                        </ul>
                      </div>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
          {/* <div className="CommingSoonDiv">
            <span className="d-flex justify-content-center spanComingFontStyles fontComingSoon pt-2">
              COMING SOON, STAY TUNED!
            </span>
            <span className="d-flex justify-content-center spanTextExclusiveTextStyle fontExclusive pt-2">
              EXCLUSIVE GG GLOBAL TOURNAMENT
            </span>
          </div> */}
          <div className="footerDiv">
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} className=" mt-4 mb-4">
                <Card className="CardStylesFooter">
                  <Row>
                    {/* <Col xs={1}></Col> */}
                    <Col xs={12}>
                      <div className="displayDesktopFooter">
                        <Row className="mt-4">
                          <Col
                            xs={4}
                            className="d-flex justify-content-center text-white "
                          >
                            <a
                              href="https://pro-coaching.gaminggeeks.gg/terms-and-conditions"
                              target="_blank"
                              className="fontFooterLink"
                              rel="noreferrer"
                            >
                              Terms & Conditions
                            </a>
                          </Col>
                          <Col
                            xs={4}
                            className="d-flex justify-content-center text-white "
                          >
                            <a
                              href="https://pro-coaching.gaminggeeks.gg/privacy-policy"
                              target="_blank"
                              className="fontFooterLink"
                              rel="noreferrer"
                            >
                              Privacy Policy
                            </a>
                          </Col>
                          <Col
                            xs={4}
                            className="d-flex justify-content-center text-white "
                          >
                            <a
                              href="https://pro-coaching.gaminggeeks.gg/refund-policy"
                              target="_blank"
                              className="fontFooterLink"
                              rel="noreferrer"
                            >
                              Refund Policy
                            </a>
                          </Col>
                        </Row>
                        <Row className="mt-3">
                          <Col xs={2}></Col>
                          <Col
                            xs={3}
                            className="d-flex justify-content-center ps-4 text-white  "
                          >
                            <span className="fontFooterLink"> FAQs</span>
                          </Col>
                          <Col xs={1}></Col>
                          <Col
                            xs={4}
                            className="d-flex justify-content-center text-white fontFollow"
                          >
                            <span className="fontFooterLink"> How to Join</span>
                          </Col>
                          <Col xs={2}></Col>
                        </Row>
                      </div>
                      <div className="displayMobileFooter">
                        <span className="d-flex justify-content-center text-white fontFooterLink">
                          Terms & conditions
                        </span>
                        <span className="d-flex justify-content-center text-white fontFooterLink">
                          Privacy Policy
                        </span>
                        <span className="d-flex justify-content-center text-white fontFooterLink">
                          Refund Policy
                        </span>
                        <span className="d-flex justify-content-center text-white fontFooterLink">
                          FAQs
                        </span>
                        <span className="d-flex justify-content-center text-white fontFooterLink">
                          How to Join
                        </span>
                      </div>
                      <div className="d-flex justify-content-center">
                        <hr className="w-50 horizentalLine"></hr>
                      </div>
                      <div className="d-flex justify-content-center">
                        <span className="textStyles mt-3 fontFollow">
                          Follow Us
                        </span>
                      </div>
                      <Row className="mt-3 fafaIconsRow">
                        <Col xs={0} md={4}></Col>
                        <Col xs={12} md={4}>
                          <Row>
                            <Col xs={3}>
                              <a
                                href="https://www.facebook.com/gg.gaminggeeks"
                                target="_blank"
                                rel="noreferrer"
                              >
                                <i className="fa faIconsStyles faIconsStyles-Facebook cursor-pointer">
                                  &#xf09a;
                                </i>
                              </a>
                            </Col>
                            <Col xs={3}>
                              <a
                                href="https://www.instagram.com/gaminggeeks.gg/"
                                target="_blank"
                                rel="noreferrer"
                              >
                                <i className="fa fa-instagram faIconsStyles faIconsStyles-instagram"></i>
                              </a>
                            </Col>
                            <Col xs={3}>
                              <a
                                href="https://www.youtube.com/channel/UCdrTr3UrzW11glIdiocaXVw"
                                target="_blank"
                                rel="noreferrer"
                              >
                                <i className="fa fa-youtube-play faIconsStyles faIconsStyles-youtube"></i>
                              </a>
                            </Col>
                            <Col xs={3}>
                              <a
                                href="https://discord.com/invite/pDUF4Kt5zW"
                                target="_blank"
                                rel="noreferrer"
                              >
                                <SiDiscord className="faIconsStyles faIconsStyles-discord" />
                              </a>
                            </Col>
                          </Row>
                        </Col>
                        <Col xs={0} md={4}></Col>
                      </Row>
                      <div className="mt-4 d-flex justify-content-center">
                        <span className="text-white fontEmail">
                          Email - contact@gaminggeeks.gg
                        </span>
                      </div>
                      <div className="mt-3 d-flex justify-content-center">
                        <span className="text-white fontEmail">
                          Address - 143, 10th Cross, 1st Stage, Indiranagar,
                          Bangalore - 560038
                        </span>
                      </div>
                    </Col>
                  </Row>
                </Card>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  // console.log("state", state);
  return {
    ...state,
  };
};
export default connect(mapStateToProps, {
  checkSocialRegistration,
  checkSocialLogin,
})(Login);
