export const types = {
    ADD_MATCH:"ADD_MATCH",
    ADD_MATCH_SUCCESS:"ADD_MATCH_SUCCESS",
    FETCH_MATCHS:"FETCH_MATCHS",
    FETCH_MATCHS_SUCCESS:"FETCH_MATCHS_SUCCESS",
    EDIT_MATCH:"EDIT_MATCH",
    EDIT_MATCH_SUCCESS:"EDIT_MATCH_SUCCESS",
    DELETE_MATCH:"DELETE_MATCH",
    DELETE_MATCH_SUCCESS:"DELETE_MATCH_SUCCESS",
    GET_PROFILE_SCORES_MATCHCHES:"GET_PROFILE_SCORES_MATCHCHES",
    GET_PROFILE_SCORES_MATCHCHES_SUCCESS:"GET_PROFILE_SCORES_MATCHCHES_SUCCESS"
}