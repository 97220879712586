import {types } from "./actionTypes";

// creating action
export const getProducts = (callback:any) =>{
    return {
        type:types.GET_PRODUCTS,
        callback
    }
}

