import React, { useEffect, useState } from "react";
import SideBar from "../../components/verticalMenu/SideBar";
import Loader from "../../components/Loader/Loader";
import {  useLocation } from "react-router-dom";
import { Grid, Pagination, Stack } from "@mui/material";
import { connect } from "react-redux";
import { Row, Col } from "reactstrap";
import Index from "../../components/common";
// store
import { fecthScoresByMatchLeader } from "../../Store/leaderBoard/actions";
var offset = 0;
var limit = 10;
var totCnt = 0;
var totPages = 0;
var toVal = 0;
var fromVal = 0;
var data: any = [];
let HeaderData = {
  columns: [
  
    
    {
      label: "Player Name",
      field: "playerName",
      width: 2,
    },
    {
      label: "Email",
      field: "email",
      width: 4,
    },
    {
      label: "DiscordId",
      field: "discordId",
      width: 2,
    },
    {
      label: "Game rank",
      field: "gameRank",
      width: 2,
    },
    {
      label: "Position",
      field: "position",
      width: 1,
    },
  ],
  // rows: trips,
};
const ViewPerfomance = (props: any) => {
  const [loading, setLoading] = useState(false);
  const [matchFilter, setMatchFilter] = useState("");
  const location = useLocation();
  console.log("location", location);
  useEffect(() => {
    getAllData();
  }, []);

  const getAllData = () => {
    let offset = 0;
    setLoading(true);
    props.fecthScoresByMatchLeader(
      offset,
      limit,
      {
        matchId: location?.state?.matchDetails?._id,
      },
      (res: any) => {
        setLoading(false);
        // if (res.success) {
        //   notifications({
        //     title: "Success!",
        //     message: res?.message,
        //     type: "success",
        //   });
        // } else {
        //   notifications({
        //     title: "Error!",
        //     message: res?.message,
        //     type: "danger",
        //   });
        // }
      }
    );
  };
  let handleChangePage = (event: any, val?: any) => {
    console.log("val", val);
    offset = (val - 1) * 10;
    toVal = offset + 10;
    props.fecthScoresByMatchLeader(
      offset,
      limit,
      {
        matchId: location?.state?.matchDetails?._id,
      },
      (res: any) => {
        setLoading(false);
        // if (res.success) {
        //   notifications({
        //     title: "Success!",
        //     message: res?.message,
        //     type: "success",
        //   });
        // } else {
        //   notifications({
        //     title: "Error!",
        //     message: res?.message,
        //     type: "danger",
        //   });
        // }
      }
    );
  };
  if (props?.getPerfomanceDetails !== undefined) {
    let getPerfomanceDetails = props?.getPerfomanceDetails?.data;
    offset = getPerfomanceDetails?.offset;
    totCnt = getPerfomanceDetails?.length;
    fromVal = totCnt !== 0 ? offset + 1 : fromVal;
    toVal = getPerfomanceDetails?.offset + getPerfomanceDetails?.docs?.length;
    totPages = Math.ceil(totCnt / 10);
    data = [];
    props.getPerfomanceDetails?.data?.docs?.map((ele: any, i: any) => {
      data.push({
        id: ele?._id,
        tournamentName: ele?.matchDetails?.tournamentInfo[0]?.label,
        email: ele?.userInfo[0]?.email,
        playerName: ele?.userInfo[0]?.fullName,
        position: ele?.position,
        discordId:ele?.userInfo[0]?.discordId,
        gameRank:ele?.userInfo[0]?.gameRank
        // action: action(ele),
      });
    });
  }
  return (
    <>
      <div className="pageContent">
        <div className="pageContentChild">
          <div>
            <SideBar></SideBar>
          </div>
          <div className="p-3 w-100">
            <h3 className="pt-3 pb-3">Match Scores</h3>
            {loading ? (
              <>
                <div>
                  <Loader />
                </div>
              </>
            ) : (
              <>
                <Index data={data} headerData={HeaderData} limit={limit} />

                {totCnt ? (
                  <>
                   

                    <div className=" m-2">
                      <Row className="m-4">
                        <Col xs={6} className="text-white">
                          <Stack spacing={2}>
                            <Pagination
                              className="pagination"
                              size="small"
                              shape="rounded"
                              page={offset / 10 + 1}
                              count={totPages}
                              color="primary"
                              // onChange={(e)=>{handleChangePage(e,offset / 10 + 1)}}
                              onChange={(e, val) => {
                                handleChangePage(e, val);
                                console.log("event", e, val);
                              }}
                              defaultPage={1}
                              siblingCount={1}
                            />
                          </Stack>
                        </Col>
                        <Col xs={6}>
                          <span className="text-white">
                            Showing {fromVal} to {toVal} of {totCnt} entries
                          </span>
                        </Col>
                      </Row>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  console.log("state", state);
  return {
    ...state,
    getPerfomanceDetails: state?.leaderBoardReducer?.getScoresByLeade,
  };
};
export default connect(mapStateToProps, {
  fecthScoresByMatchLeader,
})(ViewPerfomance);
