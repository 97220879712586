import {takeEvery, fork , put , all, call} from "redux-saga/effects";
import {types } from "./actionTypes";
import  axios ,{AxiosResponse } from "axios";
import jwt from "jwt-decode";
//medthods
import { postApi,patchApi,getApi } from "../../../helpers/auth_helpers";

// workers
function* workerCreateUser({payload,callback}:any) {
    try{
        const url:string = process.env.REACT_APP_SERVERURL+"/user/registerUser"
        const response:AxiosResponse =  yield call(postApi,url,payload);
        callback(response);
        yield put({
            type:types.REGISTER_USER_SUCCESS,
            payload:response,
        })
    }
    catch(e) {
       console.log(e)
    }
}
function* workerCheckUserActivate({payload,callback}:any) {
    try{
        const url:string = process.env.REACT_APP_SERVERURL+"/user/verifyEmail"
        const response:AxiosResponse =  yield call(postApi,url,payload);
        callback(response);
        yield put({
            type:types.CHECK_USER_ACTIVATE_SUCCESS,
            payload:response,
        })
    }
    catch(e) {
       console.log(e)
    }
}


function* workerCheckLoginUser({payload,callback}:any) {
    try{
        const url:string = process.env.REACT_APP_SERVERURL+"/auth/login"
       
        const response:AxiosResponse =  yield call(postApi,url,payload);   
        console.log("resposnce",response)
        // let authUser = jwt(response?.data?.accessToken)
        callback(response);
        // sessionStorage.setItem("authUser",JSON.stringify(authUser))
        // console.log("authUser",authUser)
        // localStorage.setItem("authUser",JSON.stringify(authUser));
        // localStorage.setItem("accessToken", response.data.accessToken);
        // localStorage.setItem("refreshToken", response.data.refreshToken);
        yield put({
            type:types.CHECK_LOGIN_USER_SUCCESS,
            payload:response,
        })
    }
    catch(e) {
       console.log(e)
    }
}
function* workerSendingForgottPasswordLink({payload,callback}:any) {
    try{
        const url:string = process.env.REACT_APP_SERVERURL+"/user/sendForgetPasswordLink"
       
        const response:AxiosResponse =  yield call(postApi,url,payload);
        callback(response);
        yield put({
            type:types.SENDING_FORGOTPASSWORD_LINK_SUCCESS,
            payload:response,
        })
    }
    catch(e) {
       console.log(e)
    }
}
function* workerPasswordResetToken({payload,callback}:any) {
    try{
        const url:string = process.env.REACT_APP_SERVERURL+"/user/resetPassword"
       
        const response:AxiosResponse =  yield call(postApi,url,payload);
        callback(response);
        yield put({
            type:types.PASSWORD_RESET_TOKEN_SUCCESS,
            payload:response,
        })
    }
    catch(e) {
       console.log(e)
    }
}
function* workerUpdatePassword({payload,callback}:any) {
    try{
        const url:string = process.env.REACT_APP_SERVERURL+"/user/updatePassword"
       
        const response:AxiosResponse =  yield call(patchApi,url,payload);
        callback(response);
        yield put({
            type:types.PASSWORD_RESET_TOKEN_SUCCESS,
            payload:response,
        })
    }
    catch(e) {
       console.log(e)
    }
}
function* workerCheckSocialRegistration({payload,callback}:any) {
    try{
        const url:string = process.env.REACT_APP_SERVERURL+"/user/socialRegistration"
        const response:AxiosResponse =  yield call(postApi,url,payload);
        callback(response);
        yield put({
            type:types.CHECK_SOCIAL_REGISTERING_SUCCESS,
            payload:response,
        })
    }
    catch(e) {
       console.log(e)
    }
}
function* workerCheckSocialLogin({payload,callback}:any) {
    try{
        const url:string = process.env.REACT_APP_SERVERURL+"/auth/socialLogin"
        const response:AxiosResponse =  yield call(postApi,url,payload);
        console.log("resposnce",response)
        callback(response);

        // let authUser = jwt(response?.data?.accessToken)
        // console.log("authUser",authUser)
        // localStorage.setItem("authUser",JSON.stringify(authUser));
        // localStorage.setItem("accessToken", response.data.accessToken);
        // localStorage.setItem("refreshToken", response.data.refreshToken);
        yield put({
            type:types.CHECK_SOCIAL_LOGIN_SUCCESS,
            payload:response,
        })
    }
    catch(e) {
       console.log(e)
    }
}
function* workFetchActiveUsers({ filters,callback }: any) {
 let startDate= filters?.startDate ? filters?.startDate :"";
 let endDate = filters?.endDate ? filters?.endDate :"";
    try {
      const url: string =
        process.env.REACT_APP_SERVERURL +
        "/auth/getTotalActiveUser?startDate=" +
        startDate +
        "&endDate="+
        endDate;
      const response: AxiosResponse = yield call(postApi, url);
      callback(response);
      // console.log("users",response)
      yield put({
        type: types.ACTIVE_USERS_SUCCESS,
        payload: response,
      });
    } catch (e) {
      console.log(e);
    }
  }
// watchers
export function* watchCreateUser(){
    yield takeEvery(types.REGISTER_USER, workerCreateUser)
}
export function* watchCheckUserActivate(){
    yield takeEvery(types.CHECK_USER_ACTIVATE, workerCheckUserActivate)
}
export function* watchCheckLoginUser(){
    yield takeEvery(types.CHECK_LOGIN_USER, workerCheckLoginUser)
}
export function* watchSendingForgottPasswordLink(){
    yield takeEvery(types.SENDING_FORGOTPASSWORD_LINK, workerSendingForgottPasswordLink)
}
export function* watchPasswordResetToken(){
    yield takeEvery(types.PASSWORD_RESET_TOKEN, workerPasswordResetToken)
}
export function* watchUpdatePassword(){
    yield takeEvery(types.UPDATE_PASSWORD, workerUpdatePassword)
}
export function* watchCheckSocialRegistration(){
    yield takeEvery(types.CHECK_SOCIAL_REGISTERING, workerCheckSocialRegistration)
}
export function* watchCheckSocialLogin(){
    yield takeEvery(types.CHECK_SOCIAL_LOGIN, workerCheckSocialLogin)
}
export function* watchFetchActiveUser(){
    yield takeEvery(types.ACTIVE_USERS , workFetchActiveUsers)
}
// export function* watchFetchDiscordDetails(){
//     yield takeEvery(types.GET_DISCORD_DETAILS, workFetchDiscordDetails)
// }
function* authLoginSaga (){
    yield all([
        fork(watchCreateUser),
        fork(watchCheckLoginUser),
        fork(watchCheckUserActivate),
        fork(watchSendingForgottPasswordLink),
        fork(watchPasswordResetToken),
        fork(watchUpdatePassword),
        fork(watchCheckSocialRegistration),
        fork(watchCheckSocialLogin),
        fork(watchFetchActiveUser)
        // fork(watchFetchDiscordDetails)
    ])
}
export default authLoginSaga;