import React, { useEffect, useState } from "react";
import { Grid, Card } from "@mui/material";
import { Pagination, Stack } from "@mui/material";
import { Row, Col } from "reactstrap";
import { connect } from "react-redux";

// store
import { fetchUserTournament } from "../../Store/userTournament/actions";
import { fetchTeams } from "../../Store/tournament/actions";

//components
import SideBar from "../../components/verticalMenu/SideBar";
import Loader from "../../components/Loader/Loader";

// global variables
var offset = 0;
var limit = 10;
var totCnt = 0;
var totPages = 0;
var toVal = 0;
var fromVal = 0;
var userData: any = [];
const UserDashboard = (props: any) => {
  const [loading, setLoading] = useState(false);
  let auth: any =
    localStorage.getItem("authUser") || sessionStorage.getItem("authUser");
  let authUser: any = JSON.parse(auth) || "";
  console.log(authUser, "authUser");
  useEffect(() => {
    getAllUserRegisterTournaments();
    getAllData();
  }, []);
  const getAllData = () => {
    console.log("level1");
    let offset = 0;
    setLoading(true);
    props.fetchTeams(offset, limit, { userId: authUser?._id }, (res: any) => {
      setLoading(false);
    });
  };
  const getAllUserRegisterTournaments = () => {
    setLoading(true);
    let offset = 0;
    props.fetchUserTournament(
      offset,
      limit,
      { userId: authUser?._id },
      (res: any) => {
        setLoading(false);
        setLoading(false);
      }
    );
  };
  let handleChangePage = (event: any, val?: any) => {
    setLoading(true);
    console.log("val", val);
    offset = (val - 1) * 10;
    toVal = offset + 10;
    setLoading(true);
    props.fetchTeams(offset, limit, { userId: authUser?._id }, (res: any) => {
      setLoading(false);
    });
  };

  let HeaderData = {
    columns: [
      {
        label: " Player Name",
        field: "name",
        width: 1,
      },
      {
        label: "Email",
        field: "email",
        width: 1,
      },
      {
        label: "Phone",
        field: "phone",
        width: 2,
      },
      {
        label: "Game Rank",
        field: "gameRank",
        width: 2,
      },
      {
        label: "Discord ID",
        field: "discordId",
        width: 2,
      },
    ],
    // rows: trips,
  };
  if (props?.teamDetails !== undefined) {
    let teamDetails = props?.teamDetails?.data;
    offset = teamDetails?.offset;
    totCnt = teamDetails?.length;
    fromVal = totCnt !== 0 ? offset + 1 : fromVal;
    toVal = teamDetails?.offset + teamDetails?.docs?.length;
    totPages = Math.ceil(totCnt / 10);
    userData = [];
    props.teamDetails?.data?.docs?.map((ele: any, i: any) => {
      userData.push({
        id: ele?._id,
        teamLogo: ele?.teamLogo,
        teamName: ele?.teamName,
        tournamentName: ele?.tournamentsDeatils?.label,
        users: ele?.users,
        // action: action(ele),
      });
    });
    // const dataFilter = data.filter(
    //   (v: any, i: any, a: any) => a.findIndex((v2: any) => v2.id === v.id) === i
    // );
    // data = dataFilter;
  }
  let getUserTournamentsDetails: any;
  let registerTournament: any = [];
  if (props?.getUserTournaments !== undefined) {
    getUserTournamentsDetails = props?.getUserTournaments?.data?.docs;
    getUserTournamentsDetails?.map((tournament: any, i: any) => {
      tournament?.users?.map((user: any, i: any) => {
        if (
          user?.user === authUser?._id &&
          user?.acceptanceStatus === "accept"
        ) {
          registerTournament.push({
            ...tournament,
          });
        }
      });
    });
  }
  console.log("registerTournament", registerTournament);
  // let auth: any = localStorage.getItem("authUser");
  // let authUser: any = JSON.parse(auth) || "";
  return (
    <>
      <div className="pageContent">
        <div className="pageContentChild">
          <div>
            <SideBar></SideBar>
          </div>
          <div className="p-3 w-100">
            {loading ? (
              <>
                <div>
                  <Loader />
                </div>
              </>
            ) : (
              <>
                <h3 className="dashboardHeading">Dashboard</h3>
                <Row>
                  <Col xs="12">
                    <h4 className="ListingHeading">My Tournaments</h4>

                    {registerTournament?.length > 0 ? (
                      registerTournament?.map((tournament: any, i: any) => {
                        return (
                          <>
                            <Row className="mt-3">
                              <Col xs="6">
                                <h5>
                                  Tournament Name :{" "}
                                  {tournament?.tournmentInfo?.label}
                                </h5>
                              </Col>
                              <Col xs={12}>
                                <Row className="mt-3">
                                  {tournament?.users?.map(
                                    (user: any, i: any) => (
                                      <Col xs="12" md={4}>
                                        <Card className="UserCard">
                                          <span> Name : {user?.name} </span>
                                          <br />
                                          <span>
                                            {" "}
                                            Phone : {user?.phoneNumber}{" "}
                                          </span>
                                          <br />
                                          <span> Email : {user?.email} </span>
                                          <br />
                                          <span>
                                            {" "}
                                            Game Rank : {user?.gameRank}{" "}
                                          </span>
                                          <br />
                                          <span>
                                            {" "}
                                            DiscordId : {user?.discordId}{" "}
                                          </span>
                                        </Card>
                                      </Col>
                                    )
                                  )}
                                </Row>
                              </Col>
                            </Row>
                          </>
                        );
                      })
                    ) : (
                      <>
                        <div className="d-flex justify-content-center NoDataDiv">
                          <span>You are not a part of any tournament</span>
                        </div>
                      </>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <h4 className="ListingHeading">My Teams</h4>
                    {totCnt ? (
                      <>
                        <Row className="ms-4 me-4 text-white">
                          {userData?.map((ele: any, i: any) => {
                            console.log("ele", ele);
                            return (
                              <>
                                <Row className="m-3">
                                  <Col xs="3">
                                    Team Logo:
                                    <img
                                      src={ele?.teamLogo}
                                      width="50"
                                      height={50}
                                      alt="TeamLogo"
                                    />
                                  </Col>
                                  <Col xs="3">Team Name: {ele?.teamName}</Col>
                                  <Col xs="3">
                                    Tournament Name: {ele?.tournamentName}
                                  </Col>
                                  {/* <Col xs="3">Action: {ele?.action}</Col> */}
                                  <Col xs="12">
                                    <Row className="HeaderRow">
                                      {HeaderData?.columns?.map(
                                        (headItem: any, index: any) => {
                                          return (
                                            <React.Fragment key={index}>
                                              <Col
                                                className="IndexDatatableNeoCol"
                                                key={index}
                                                style={{
                                                  fontSize: "15px",
                                                  fontWeight: "700",
                                                  lineHeight: "18px",
                                                  fontFamily: "Roboto",
                                                }}
                                              >
                                                {headItem.label}{" "}
                                              </Col>
                                            </React.Fragment>
                                          );
                                        }
                                      )}
                                    </Row>
                                    {ele?.users?.map((ele: any, index: any) => {
                                      return (
                                        <React.Fragment key={index}>
                                          <Row className="ContentRow">
                                            <Col
                                              xs={2}
                                              className="IndexDatatableNeoCol"
                                              style={{
                                                fontSize: "15px",
                                                fontWeight: "700",
                                                lineHeight: "18px",
                                                fontFamily: "Roboto",
                                              }}
                                            >
                                              {ele?.name}
                                            </Col>
                                            <Col
                                              xs={3}
                                              className="IndexDatatableNeoCol"
                                              style={{
                                                fontSize: "15px",
                                                fontWeight: "700",
                                                lineHeight: "18px",
                                                fontFamily: "Roboto",
                                              }}
                                            >
                                              {ele?.email}
                                            </Col>
                                            <Col
                                              xs={2}
                                              className="IndexDatatableNeoCol"
                                              style={{
                                                fontSize: "15px",
                                                fontWeight: "700",
                                                lineHeight: "18px",
                                                fontFamily: "Roboto",
                                              }}
                                            >
                                              {ele?.phoneNumber}
                                            </Col>
                                            <Col
                                              xs={2}
                                              className="IndexDatatableNeoCol"
                                              style={{
                                                fontSize: "15px",
                                                fontWeight: "700",
                                                lineHeight: "18px",
                                                fontFamily: "Roboto",
                                              }}
                                            >
                                              {ele?.gameRank}
                                            </Col>
                                            <Col
                                              xs={2}
                                              className="IndexDatatableNeoCol"
                                              style={{
                                                fontSize: "15px",
                                                fontWeight: "700",
                                                lineHeight: "18px",
                                                fontFamily: "Roboto",
                                              }}
                                            >
                                              {ele?.discordId}
                                            </Col>
                                          </Row>
                                        </React.Fragment>
                                      );
                                    })}
                                  </Col>
                                </Row>
                              </>
                            );
                          })}
                        </Row>
                        <div className=" m-2">
                          <Row className="m-4">
                            <Col xs={6} className="text-white">
                              <Stack spacing={2}>
                                <Pagination
                                  className="pagination"
                                  size="small"
                                  shape="rounded"
                                  page={offset / 10 + 1}
                                  count={totPages}
                                  color="primary"
                                  // onChange={(e)=>{handleChangePage(e,offset / 10 + 1)}}
                                  onChange={(e, val) => {
                                    handleChangePage(e, val);
                                    console.log("event", e, val);
                                  }}
                                  defaultPage={1}
                                  siblingCount={1}
                                />
                              </Stack>
                            </Col>
                            <Col xs={6}>
                              <span className="text-white">
                                Showing {fromVal} to {toVal} of {totCnt} entries
                              </span>
                            </Col>
                          </Row>
                        </div>
                      </>
                    ) : (
                      <div className=" d-flex justify-content-center NoDataDiv">
                        <span>you have not invited by Any Team</span>
                      </div>
                    )}
                  </Col>
                </Row>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  console.log("state", state);
  return {
    ...state,
    teamDetails: state?.tournamentReducer?.fetchTeams,
    getUserTournaments: state?.userTournamentReducer?.tournamentDetail,
  };
};
export default connect(mapStateToProps, {
  fetchTeams,
  fetchUserTournament,
})(UserDashboard);
