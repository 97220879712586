import React from 'react';
import { Store } from 'react-notifications-component';

const notifications = (message:any) => {
    Store.addNotification({
        title: message?.title,
        message: message?.message,
        type: message?.type,
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 1000,
          onScreen: true,
        },
      });
  return null;
}

export default notifications;