import React, { useState, useEffect } from "react";
import { Card, Row, Col } from "reactstrap";
import { Grid } from "@mui/material";
import { connect } from "react-redux";
import { default as ReactSelect } from "react-select";
import { components } from "react-select";
import { useLocation, useNavigate } from "react-router-dom";
// store
import { getUsers } from "../.././Store/user/actions";
import { fetchTournament } from "../../Store/tournament/actions";
import { fetchTeams } from "../../Store/tournament/actions";
import {
  sendingEmail,
  storingEmailsWithSaveToGroup,
  sendingWhatsApp,
} from "../../Store/user/actions";

// components
import Loader from "../../components/Loader/Loader";
import SideBar from "../../components/verticalMenu/SideBar";
import notifications from "../../components/Notifications/Notification";
import CloseIcon from "../../components/Icons/CloseIcon";

//global variables
let usersOptionsData: any = [];
let usersOptionsDataEmails: any = [];
let teamsOptions: any = [];
let tournamentOptionsData: any = [];
let teamUsersOptions: any = [];
let usersOptionsDataPhone: any = [];
let usersOptionsDataPhoneTeam: any = [];
const SendingMail = (props: any) => {
  const navigator = useNavigate();
  const [loading, setLoading] = useState(false);
  const [selecetedUsers, setSelectedUsers]: any = useState([]);
  const [selecetedUsersArray, setSelectedUsersArray]: any = useState([]);
  const [selecetedUsersWhatsApp, setSelectedUsersWhatsApp]: any = useState([]);
  const [selecetedUsersArrayWhatsApp, setSelectedUsersArrayWhatsApp]: any =
    useState([]);
  const [selecetedTeamsWhatsApp, setSelectedTeamsWhatsApp]: any = useState([]);
  const [selecetedTeamsWhatsApp1, setSelectedTeamsWhatsApp1]: any = useState(
    []
  );

  const [selectedPoneNo, setSelectedPoneNo]: any = useState([]);
  const [selecetedTournamentsPhone, setSelectedTournamentsPhone]: any =
    useState([]);

  const [selecetedTournaments, setSelectedTournaments]: any = useState([]);
  const [selecetedTeams, setSelectedTeams]: any = useState([]);
  const [selectedEmails, setSelectedEmails]: any = useState([]);
  const [saveToGroup, setSaveToGroup]: any = useState("no");
  const [sendNotification, setSendNotification]: any = useState("mail");
  const [saveToGroupValue, setSaveToGroupValue]: any = useState("");
  const [errors, setErrors]: any = useState({});
  const [errorsWhatsApp, setErrorsWhatsAPP]: any = useState({});

  const [form, setForm]: any = useState({
    subject: "",
    body: "",
    EmailsData: "",
    // phoneNum: "",
  });
  const [formWhatsApp, setFormWhatsApp]: any = useState({
    text: "",
    phoneNum: "",
    mediaUrl:""
  });
  const [emails, setEmails]: any = useState([]);
  const [emails1, setEmails1]: any = useState([]);
  const [phoneNumbers, setphoneNumbers]: any = useState([]);
  const [phoneNumbers1, setphoneNumbers1]: any = useState([]);
  const [phoneNumbers11, setphoneNumbers11]: any = useState([]);
  const validations = () => {
    let error: any = "";
    let errorObj: any = {};

    if (form?.subject === "") {
      error = "required!";
      errorObj.subject = "Subject is Required!";
    }
    if (form.body === "") {
      error = "Required!";
      errorObj.body = " Body is Required!";
    }
    if (form.EmailsData == "") {
      error = "Required!";
      errorObj.EmailsData = " EmailsData is Required!";
    }
    setErrors(errorObj);
    console.log("error", errors);

    return error;
  };
  const validationsWhatsApp = () => {
    let error: any = "";
    let errorObj: any = {};

    if (formWhatsApp?.text === "") {
      error = "required!";
      errorObj.text = "Content is Required!";
    }

    if (formWhatsApp.phoneNum == "") {
      error = "Required!";
      errorObj.phoneNum = " Phone Number's  Required!";
    }
    setErrorsWhatsAPP(errorObj);
    console.log("error", errors);

    return error;
  };
  console.log("errors",errors,errorsWhatsApp,formWhatsApp)
  // calling api's
  useEffect(() => {
    getAllUsersData();
    getAllTournamentData();
    getAllTeamsData();
  }, []);
  useEffect(() => {
    console.log("emails", emails);
  }, [emails, saveToGroupValue]);
  useEffect(() => {
    console.log("selecetedUsers", selecetedUsers, form);
  }, [
    selecetedUsers,
    selecetedTournaments,
    selectedEmails,
    selecetedTeams,
    form,
    saveToGroup,
  ]);
  let getAllUsersData = () => {
    setLoading(true);
    props.getUsers(0, 1000000, {}, (res: any) => {
      setLoading(false);
    });
  };
  let getAllTournamentData = () => {
    console.log("calling");
    setLoading(true);
    let offset = 0;
    let filters = {};
    props.fetchTournament(offset, 100000, filters, (res: any) => {
      setLoading(false);
    });
  };
  const getAllTeamsData = () => {
    console.log("level1");
    let offset = 0;
    props.fetchTeams(offset, 10000, { tournament: "" }, (res: any) => {});
  };
  const Option = (props: any) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            onMouseDown={(e) => {
              console.log("onMouseDown", e);
            }}
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };

  if (props.userDetails !== undefined) {
    let userDetails = props?.userDetails?.data;
    console.log("userDetails", userDetails);
    usersOptionsDataEmails = [];
    usersOptionsDataPhone = [];
    userDetails?.docs?.map((user: any, i: any) => {
      let name = user?.fulName ? user?.fulName : "N/A";
      let phone = user?.phone ? user?.phone : "N/A";
      usersOptionsDataEmails.push({
        value: user?.email,
        label: user?.email + " ( " + phone + " ) " + name,
      });
      if (user?.phone) {
        usersOptionsDataPhone.push({
          value: user?.phone && user?.phone,
          label: user?.phone && user?.phone,
        });
      }
    });
    console.log("usersOptionsDataEmails", usersOptionsDataEmails);
  }
  if (props?.getTournamentDetails !== undefined) {
    tournamentOptionsData = [];
    props?.getTournamentDetails?.data?.docs?.map((tournament: any, i: any) => {
      tournamentOptionsData.push({
        value: tournament?._id,
        label: tournament?.label,
      });
    });
    console.log("tournamentOptionsData", tournamentOptionsData);
  }
  console.log(" props.teamDetails", props.teamDetails);
  if (props?.teamDetails !== undefined) {
    teamsOptions = [];
    usersOptionsData = [];
    usersOptionsDataPhoneTeam = [];
    props.teamDetails?.data?.docs?.map((ele: any, i: any) => {
      teamsOptions.push({
        value: {
          id: ele?._id,
          tournamentId: ele?.tournamentId,
        },
        label: ele?.teamName,
      });
      ele?.users.map((user: any) => {
        {
          sendNotification === "mail"
            ? usersOptionsData.push({
                value: {
                  email: user?.email,
                  teamId: ele?._id,
                },
                label: user?.email,
              })
            : usersOptionsData.push({
                value: {
                  phone: user?.phoneNumber,
                  teamId: ele?._id,
                },
                label: user?.phoneNumber,
              });
        }
      });
    });
  }
  //Email users dropdown
  const handleChangeUsers = (selecetUsers: any) => {
    setSelectedUsersArray(selecetUsers);
    let selected: any = [];
    selecetUsers.forEach((ele: any) => {
      selected.push(ele?.value);
    });
    setSelectedUsers(selected);
    setEmails(selected);
    setForm({
      ...form,
      EmailsData: {
        ...form.EmailsData,
        ...selected,
      },
    });
  };
  //whats app users dropdown

  const handleChangeUsersPhone = (selecetPhone: any) => {
    setSelectedUsersArrayWhatsApp(selecetPhone);
    let selected: any = [];
    selecetPhone.forEach((ele: any) => {
      selected.push(ele?.value);
    });
    setSelectedUsersWhatsApp(selected);
    setphoneNumbers(selected);
    setFormWhatsApp({
      ...formWhatsApp,
      phoneNum: {
        ...formWhatsApp.phoneNum,
        ...selected,
      },
    });
  };
  //tournaments
  const handleChangeTournamnet = (selectedTournamnets: any) => {
    setSelectedTournaments(selectedTournamnets);
    let result = teamsOptions.filter((ele: any) => {
      return selectedTournamnets.find((data: any) => {
        return data?.value === ele?.value?.tournamentId;
      });
    });
    setSelectedTeams(result);
    // console.log("result", result);
    teamsOptions = result;
  };
  const handleChangeTournamnetPhone = (selectedTournamnets: any) => {
    setSelectedTournamentsPhone(selectedTournamnets);
    let result = teamsOptions.filter((ele: any) => {
      return selectedTournamnets.find((data: any) => {
        return data?.value === ele?.value?.tournamentId;
      });
    });
    setSelectedTeamsWhatsApp(result);
    // console.log("result", result);
    teamsOptions = result;
  };
  // selected teams based tournament
  const handleChangeTeam = (selectedTeams: any) => {
    console.log("selectedTeam", selectedTeams);
    let result = usersOptionsData.filter((ele: any) => {
      return selectedTeams.find((data: any) => {
        return data?.value?.id === ele?.value?.teamId;
      });
    });
    teamUsersOptions = result;
    setSelectedEmails(result);
    console.log("result team", result);
  };
  const handleChangeTeamPhone = (selectedTeams: any) => {
    console.log("selectedTeam", selectedTeams);
    setSelectedTeamsWhatsApp1(selectedTeams);
    let result = usersOptionsData.filter((ele: any) => {
      return selectedTeams.find((data: any) => {
        return data?.value?.id === ele?.value?.teamId;
      });
    });
    teamUsersOptions = result;
    setSelectedPoneNo(result);
    console.log("result team", result);
  };
  // mails or phone based on selected team
  let selectedem: any = [];

  const handleChangeEmails = (selectedEmails: any) => {
    console.log("selectedEmails", selectedEmails);
    selectedEmails.forEach((ele: any) => {
      selectedem.push(ele?.value?.email);
    });
    setEmails1(selectedem);
    setForm({
      ...form,
      EmailsData: {
        ...form.EmailsData,
        ...emails1,
      },
    });
  };
  let selectedem1: any = [];
  const handleChangePhoneNOs = (selectedPhonNum: any) => {
    console.log("selectedPhonNum", selectedPhonNum);
    setphoneNumbers11(selectedPhonNum);
    selectedPhonNum.forEach((ele: any) => {
      selectedem1.push(ele?.value?.phone);
    });
    console.log("selectedem1", selectedem1);
    setphoneNumbers1(selectedem1);
    setFormWhatsApp({
      ...formWhatsApp,
      phoneNum: {
        ...formWhatsApp.phoneNum,
        ...selectedem1,
      },
    });
  };
  // whats app
  const handleChange = (type: any) => {
    if (type.name === "subject") {
      setForm({ ...form, [type.name]: type?.value });
    } else if (type.name === "body") {
      setForm({ ...form, [type.name]: type?.value });
    } 
    else if (type.name === "text") {
      setFormWhatsApp({
        ...form,
        [type.name]: type?.value,
      });
    }
    else if (type.name === "mediaUrl"){
      setFormWhatsApp({
        ...formWhatsApp,
        mediaUrl:type?.value
      })
    }
  };
  console.log("form whats app",formWhatsApp)
  // onsubmit for email and save to group
  const onSubmit = () => {
    let result: any = validations();

    if (result?.length === 0) {
      if (form?.subject && form?.body && (emails || emails1)) {
        if (saveToGroup === "yes") {
          let payload = {
            label: saveToGroupValue,
            emails: [...emails, ...emails1],
            subject: form?.subject,
            body: form?.body,
          };
          console.log("payload", payload);
          setLoading(true)
          props.storingEmailsWithSaveToGroup(payload, (res: any) => {
            if (res.success) {
              notifications({
                title: "Success!",
                message: res?.message,
                type: "success",
              });
              setLoading(false)

            } else {
              notifications({
                title: "Error!",
                message: res?.message,
                type: "danger",
              });
            }
          });
        } else {
          let payload = {
            emails: [...emails, ...selecetedUsers],
            subject: form?.subject,
            body: form?.body,
          };
          console.log("payload", payload);
          setLoading(true)
          props.sendingEmail(payload, (res: any) => {
            if (res.success) {
              notifications({
                title: "Success!",
                message: res?.message,
                type: "success",
              });
              setLoading(false)
            } else {
              notifications({
                title: "Error!",
                message: res?.message,
                type: "danger",
              });
            }
          });
        }
      }
      setSaveToGroupValue("");
      setSelectedUsersArray("");
      selectedEmails("");
    }
  };
  //radio  buttons
  const handleChangeRadio = (e: any) => {
    setSaveToGroup(e.target.value);
  };
  const handleChangeRadioNotification = (e: any) => {
    setSendNotification(e.target.value);
    setEmails1([]);
    setEmails1([]);
    setphoneNumbers1([])
    setSelectedUsersArray([])
    // setSelectedTeamsWhatsApp([])
    // selecetedUsersArrayWhatsApp([])
    setphoneNumbers([])
    setForm({
      subject: "",
      body: "",
      EmailsData: "",
    });
    setFormWhatsApp({
      text: "",
      phoneNum: "",
      mediaUrl:""
    });
  };
  console.log("send Notification", sendNotification);
  return (
    <>
      <div className="pageContent1">
        <div className="pageContentChild text-white">
          <div>
            <SideBar></SideBar>
          </div>
          <div className="p-3 w-100">
            <Row>
              <Col xs={12}>
                <div className="">
                  <span
                    className="d-block"
                    data-toggle="popover"
                    data-trigger="focus"
                    data-content="Please selecet account(s)"
                  >
                    <h4>Send Notification</h4>

                    <Row className="ml-2 d-flex">
                      <Col xs={3} lg={3}>
                        <input
                          className="form-check-input"
                          type="radio"
                          value="mail"
                          checked={sendNotification === "mail"}
                          onChange={handleChangeRadioNotification}
                        />
                        <label className="form-check-label ps-2 fontRoboto154 light__span">
                          Mail
                        </label>
                      </Col>
                      <Col xs={3} lg={3}>
                        <input
                          className="form-check-input"
                          type="radio"
                          checked={sendNotification === "whatsApp"}
                          value="whatsApp"
                          onChange={handleChangeRadioNotification}
                        />
                        <label className="form-check-label ps-2 fontRoboto154 light__span">
                          Whats App
                        </label>
                      </Col>
                    </Row>
                  </span>
                </div>
              </Col>
            </Row>

            <h3 className=" mb-4">
              Send Notification -{" "}
              {sendNotification === "mail" ? "Mail" : "Whats App"}
            </h3>
            {sendNotification === "mail" ? (
              <div>
                <span className="">
                  <button
                    style={{
                      color: "#000",
                    }}
                    className="p-3  "
                    onClick={() => {
                      navigator("/get-saved-mails");
                      window.scrollTo(0, 0)
                    }}
                  >
                    Get Saved Mails
                  </button>
                </span>
              </div>
            ) : (
              ""
            )}
            {
              loading ? 
              <Loader />
              :
              <>
              <Card className="CardMail">
              <div>
                {sendNotification === "mail" && (
                  <>
                    <Row>
                      <Col xs={12} md={4} className="p-4">
                        <div>
                          <span
                            className="d-block"
                            data-toggle="popover"
                            data-trigger="focus"
                            data-content="Please selecet account(s)"
                          >
                            <ReactSelect
                              style={{
                                width: "100%",
                              }}
                              {...props}
                              classNamePrefix="react-select"
                              placeholder={"Select Users Emails..."}
                              options={usersOptionsDataEmails}
                              isMulti
                              closeMenuOnSelect={false}
                              hideSelectedOptions={false}
                              components={{
                                Option,
                              }}
                              onChange={handleChangeUsers}
                              allowSelectAll={true}
                              value={selecetedUsersArray}
                            />
                          </span>
                        </div>
                      </Col>
                      <Col xs={12} md={4} className="p-4">
                        <div>
                          <span
                            className="d-block"
                            data-toggle="popover"
                            data-trigger="focus"
                            data-content="Please selecet account(s)"
                          >
                            <ReactSelect
                              style={{
                                width: "100%",
                              }}
                              classNamePrefix="react-select"
                              {...props}
                              placeholder={"Tournaments..."}
                              options={tournamentOptionsData}
                              isMulti
                              closeMenuOnSelect={false}
                              hideSelectedOptions={false}
                              components={{
                                Option,
                              }}
                              onChange={handleChangeTournamnet}
                              allowSelectAll={true}
                              value={selecetedTournaments}
                            />
                          </span>
                        </div>
                      </Col>
                      <Col xs={12} md={4} className="p-4">
                        <div>
                          <span
                            className="d-block"
                            data-toggle="popover"
                            data-trigger="focus"
                            data-content="Please selecet account(s)"
                          >
                            <ReactSelect
                              style={{
                                width: "100%",
                              }}
                              classNamePrefix="react-select"
                              {...props}
                              placeholder={"Teams..."}
                              options={selecetedTeams}
                              // options={teamsOptions}
                              isMulti
                              closeMenuOnSelect={false}
                              hideSelectedOptions={false}
                              components={{
                                Option,
                              }}
                              onChange={handleChangeTeam}
                              allowSelectAll={true}
                            />
                          </span>
                        </div>
                      </Col>
                      <Col xs={12} md={4} className="p-4">
                        <div>
                          <span
                            className="d-block"
                            data-toggle="popover"
                            data-trigger="focus"
                            data-content="Please selecet account(s)"
                          >
                            <ReactSelect
                              style={{
                                width: "100%",
                              }}
                              {...props}
                              classNamePrefix="react-select"
                              placeholder={"Select Emails..."}
                              options={selectedEmails}
                              isMulti
                              closeMenuOnSelect={false}
                              hideSelectedOptions={false}
                              components={{
                                Option,
                              }}
                              onChange={handleChangeEmails}
                              allowSelectAll={true}
                            />
                          </span>
                        </div>
                      </Col>
                      {sendNotification === "mail" ? (
                        <>
                          <Col xs={12} md={4} className="p-4">
                            <div>
                              <span
                                className="d-block"
                                data-toggle="popover"
                                data-trigger="focus"
                                data-content="Please selecet account(s)"
                              >
                                <h5>Save To Group</h5>
                                <Row className="ml-2">
                                  <Col xs={3} lg={3}>
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      value="yes"
                                      checked={saveToGroup === "yes"}
                                      onChange={handleChangeRadio}
                                    />
                                    <label className="form-check-label fontRoboto154 light__span ms-2">
                                      Yes
                                    </label>
                                  </Col>
                                  <Col xs={3} lg={3}>
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      checked={saveToGroup === "no"}
                                      value="no"
                                      onChange={handleChangeRadio}
                                    />
                                    <label className="form-check-label fontRoboto154 light__span ms-2">
                                      No
                                    </label>
                                  </Col>
                                </Row>
                              </span>
                            </div>
                          </Col>

                          <Col xs={12} lg={4} className="p-4">
                            {saveToGroup === "yes" ? (
                              <input
                                {...props}
                                type="text"
                                placeholder="Saved Group..."
                                name="saveGroup"
                                value={saveToGroupValue}
                                onChange={(e: any) => {
                                  setSaveToGroupValue(e.target.value);
                                }}
                              />
                            ) : (
                              ""
                            )}
                          </Col>
                        </>
                      ) : (
                        ""
                      )}
                    </Row>
                    <Row>
                      <Col xs={12} className="m-3">
                        <h5>
                          To:
                          <span
                            style={{
                              color: "red",
                            }}
                          >
                            *
                          </span>
                        </h5>
                        <div className="selectArea">
                          {emails?.length || emails1?.length ? (
                            <>
                              <span className="emailBox">
                                {emails.map((email: any, i: any) => {
                                  return (
                                    <>
                                      <div className="emailSpan mb-2">
                                        {email}
                                      </div>
                                    </>
                                  );
                                })}
                                {/* </span>
                              <span> */}
                                {emails1.map((email: any, i: any) => {
                                  return (
                                    <>
                                      <div className="emailSpan mb-2">
                                        {email}
                                      </div>
                                    </>
                                  );
                                })}
                              </span>
                            </>
                          ) : (
                            <span>
                              Please select player mails from dropdown...
                            </span>
                          )}
                        </div>
                        {errors.EmailsData && form?.EmailsData == "" ? (
                          <p className="RequiredField">{errors?.EmailsData} </p>
                        ) : (
                          ""
                        )}
                      </Col>
                      <Col xs={12} className="m-3">
                        <h5>
                          Subject:{" "}
                          <span
                            style={{
                              color: "red",
                            }}
                          >
                            *
                          </span>
                        </h5>

                        <div className="subjectInput">
                          <input
                            className=""
                            type="text"
                            placeholder="Subject..."
                            value={form?.subject}
                            onChange={(e: any) => {
                              handleChange({
                                name: "subject",
                                value: e.target.value,
                              });
                            }}
                          />
                        </div>
                        {errors.subject && form?.subject === "" ? (
                          <p className="RequiredField">{errors?.subject} </p>
                        ) : (
                          ""
                        )}
                      </Col>
                      <Col xs={12} className="m-3">
                        <h5 className="mb-2">
                          Body:{" "}
                          <span
                            style={{
                              color: "red",
                            }}
                          >
                            *
                          </span>
                        </h5>
                        <div className="bodyInput">
                          <textarea
                            {...props}
                            className=""
                            placeholder="Body..."
                            value={form?.body}
                            onChange={(e: any) => {
                              handleChange({
                                name: "body",
                                value: e.target.value,
                              });
                            }}
                          />
                          {errors.body && form?.body === "" ? (
                            <p className="RequiredField">{errors?.body} </p>
                          ) : (
                            ""
                          )}
                        </div>
                      </Col>
                      <Col xs={12} className="m-3">
                        <button
                          className="sendButton"
                          onClick={() => {
                            onSubmit();
                          }}
                        >
                          Send
                        </button>
                      </Col>
                    </Row>
                  </>
                )}
                {sendNotification === "whatsApp" ? (
                  <>
                    <Row>
                      <Col xs={12} md={4} className="p-4">
                        <div>
                          <span
                            className="d-block"
                            data-toggle="popover"
                            data-trigger="focus"
                            data-content="Please selecet account(s)"
                          >
                            <ReactSelect
                              style={{
                                width: "100%",
                              }}
                              {...props}
                              classNamePrefix="react-select"
                              placeholder={"Select Users Phone No..."}
                              options={usersOptionsDataPhone}
                              isMulti
                              closeMenuOnSelect={false}
                              hideSelectedOptions={false}
                              components={{
                                Option,
                              }}
                              onChange={handleChangeUsersPhone}
                              allowSelectAll={true}
                              value={selecetedUsersArrayWhatsApp}
                            />
                          </span>
                        </div>
                      </Col>
                      <Col xs={12} md={4} className="p-4">
                        <div>
                          <span
                            className="d-block"
                            data-toggle="popover"
                            data-trigger="focus"
                            data-content="Please selecet account(s)"
                          >
                            <ReactSelect
                              style={{
                                width: "100%",
                              }}
                              classNamePrefix="react-select"
                              {...props}
                              placeholder={"Tournaments..."}
                              options={tournamentOptionsData}
                              isMulti
                              closeMenuOnSelect={false}
                              hideSelectedOptions={false}
                              components={{
                                Option,
                              }}
                              onChange={handleChangeTournamnetPhone}
                              allowSelectAll={true}
                              value={selecetedTournamentsPhone}
                            />
                          </span>
                        </div>
                      </Col>
                      <Col xs={12} md={4} className="p-4">
                        <div>
                          <span
                            className="d-block"
                            data-toggle="popover"
                            data-trigger="focus"
                            data-content="Please selecet account(s)"
                          >
                            <ReactSelect
                              style={{
                                width: "100%",
                              }}
                              classNamePrefix="react-select"
                              {...props}
                              placeholder={"Teams..."}
                              options={selecetedTeamsWhatsApp}
                              // options={teamsOptions}
                              isMulti
                              closeMenuOnSelect={false}
                              hideSelectedOptions={false}
                              components={{
                                Option,
                              }}
                              onChange={handleChangeTeamPhone}
                              allowSelectAll={true}
                              value={selecetedTeamsWhatsApp1}
                            />
                          </span>
                        </div>
                      </Col>
                      <Col xs={12} md={4} className="p-4">
                        <div>
                          <span
                            className="d-block"
                            data-toggle="popover"
                            data-trigger="focus"
                            data-content="Please selecet account(s)"
                          >
                            <ReactSelect
                              style={{
                                width: "100%",
                              }}
                              {...props}
                              classNamePrefix="react-select"
                              placeholder={"Phone No..."}
                              options={selectedPoneNo}
                              isMulti
                              closeMenuOnSelect={false}
                              hideSelectedOptions={false}
                              components={{
                                Option,
                              }}
                              onChange={handleChangePhoneNOs}
                              allowSelectAll={true}
                              value={phoneNumbers11}
                            />
                          </span>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} className="m-3">
                        <h5>
                          To:{" "}
                          <span
                            style={{
                              color: "red",
                            }}
                          >
                            *
                          </span>
                        </h5>
                        <div className="selectArea">
                          {phoneNumbers?.length || phoneNumbers1?.length ? (
                            <>
                              <span className="phoneBox">
                                {phoneNumbers.map((phone: any, i: any) => {
                                  return (
                                    <>
                                      <div className="emailSpan mb-2">
                                        {phone}
                                      </div>
                                    </>
                                  );
                                })}
                                {/* </span>
                            <span> */}
                                {phoneNumbers1.map((phone: any, i: any) => {
                                  return (
                                    <>
                                      <div className="emailSpan mb-2">
                                        {phone}
                                      </div>
                                    </>
                                  );
                                })}
                              </span>
                            </>
                          ) : (
                            <span>
                              Please select player PhoneNumbers from dropdown...
                            </span>
                          )}
                        </div>
                        {errorsWhatsApp.phoneNum &&
                        formWhatsApp?.phoneNum == "" ? (
                          <p className="RequiredField">
                            {errorsWhatsApp?.phoneNum}{" "}
                          </p>
                        ) : (
                          ""
                        )}
                      </Col>

                      <Col xs={12} className="m-3">
                        <h5 className="mb-2">
                          Notification-Message{" "}
                          <span
                            style={{
                              color: "red",
                            }}
                          >
                            *
                          </span>
                        </h5>
                        <div className="bodyInput">
                       
                          <textarea
                            className=""
                            placeholder="Please type here message..."
                            value={formWhatsApp?.text}
                            onChange={(e: any) => {
                              handleChange({
                                name: "text",
                                value: e.target.value,
                              });
                            }}
                          />
                        </div>
                        {errorsWhatsApp.text && formWhatsApp?.text == "" ? (
                          <p className="RequiredField">
                            {errorsWhatsApp?.text}{" "}
                          </p>
                        ) : (
                          ""
                        )}
                      </Col>
                      <Col xs={12}>
                      <h5 className="mb-2">
                          Media URL{" "}
                        
                        </h5>
                        <textarea className="mediaUrl"
                        placeholder="Media url"
                        value={formWhatsApp?.mediaUrl}
                        onChange={(e: any) => {
                          handleChange({
                            name: "mediaUrl",
                            value: e.target.value,
                          });
                        }}
                        />
                      
                      </Col>
                      <Col xs={12} className="m-3">
                        {sendNotification === "whatsApp" ? (
                          <button
                            className="sendButton"
                            onClick={() => {
                              let result: any = validationsWhatsApp();

                              if (result?.length === 0) {
                                setLoading(true)
                                let payload: any = {
                                  phoneNumber: [...phoneNumbers,...phoneNumbers1],
                                  text: formWhatsApp?.text,
                                  mediaUrl:formWhatsApp?.mediaUrl,
                                };
                                console.log("payload", payload);
                                props.sendingWhatsApp(payload, (res: any) => {
                                  if (res.success) {
                                    notifications({
                                      title: "Success!",
                                      message: res?.message,
                                      type: "success",
                                    });
                                    setLoading(false)
                                  } else {
                                    notifications({
                                      title: "Error!",
                                      message: res?.message,
                                      type: "danger",
                                    });
                                  }
                                });
                              }
                            }}
                          >
                            Send
                          </button>
                        ) : (
                          <button
                            className="sendButton"
                            onClick={() => {
                              onSubmit();
                            }}
                          >
                            Send
                          </button>
                        )}
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}
              </div>
            </Card>
              </>
            }

            
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  console.log("state", state);
  return {
    ...state,
    userDetails: state?.userReducer?.usersDetails,
    getTournamentDetails: state?.tournamentReducer?.tournamentDetail,
    teamDetails: state?.tournamentReducer?.fetchTeams,
  };
};
export default connect(mapStateToProps, {
  getUsers,
  fetchTournament,
  fetchTeams,
  sendingEmail,
  sendingWhatsApp,
  storingEmailsWithSaveToGroup,
})(SendingMail);
