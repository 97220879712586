import React, { useState } from "react";
import { Card, Row, Col } from "reactstrap";
import { AiOutlinePlusCircle, AiOutlineMinusCircle } from "react-icons/ai";
const FrequentlyAsked = (props: any) => {
  const [expandIndex, setExpandIndex]: any = useState("");

  const toggle = (index: any, item: any) => {
    setExpandIndex(index);
  };
  return (
    <>
      <Row>
        <Col xs={0} md={1}></Col>
        <Col xs={12} md={10} className="frequentlyParent">
          {props.frequentlyAskedQuestions?.map((ele: any, index: any) => {
            return (
              <React.Fragment key={index}>
                <Card
                  onClick={() => {
                    if (expandIndex === index) setExpandIndex("");
                    else toggle(index, ele);
                  }}
                  className="QuesitionCard"
                >
                  <div className="alignQuestion">
                  <div>
                    <h2>{ele?.question}</h2>
                  </div>
                  {/* <div>
                    <h2>
                      {expandIndex === index ? (
                        <AiOutlineMinusCircle
                          className="float-end mt-2"
                          onClick={() => {
                            setExpandIndex("");
                          }}
                        />
                      ) : (
                        <AiOutlinePlusCircle
                          className="float-end mt-2"
                          onClick={() => toggle(index, ele)}
                        />
                      )}
                    </h2>
                  </div> */}
                  </div>
                </Card>
                {expandIndex === index ? (
                  <div className="QuestionAnswer">{ele?.answer}</div>
                ) : (
                  ""
                )}
              </React.Fragment>
            );
          })}
        </Col>
        <Col xs={0} md={1}></Col>
      </Row>
    </>
  );
};

export default FrequentlyAsked;
