import React from "react";
import { Grid } from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import CloseIcon from "../../components/Icons/CloseIcon";
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const schema = Yup.object().shape({
  // tournamentId: Yup.string().required("Tournament Name is required"),
  name: Yup.string().required("Name is required"),
  // teamName: Yup.string().required("Team name is required"),
  email: Yup.string()
    .required("Email is a required field")
    .email("Invalid email format"),
  phoneNumber: Yup.string()
    .required("Phone number is required")
    .matches(phoneRegExp, "Phone number is not valid"),
  gameRank: Yup.string().required("Game rank is required"),
  discordId: Yup.string().required("DiscordId is required"),
});
const PlayersAddForm = (props: any) => {
  return (
    <>
      {/* {addPlayerDetailsFlag ? ( */}
      <Grid container className="playersAddForm">
        <Grid item xs={12}>
          <div className="d-flex justify-content-end pe-4 pt-3">
            <h4 className="text-white">Players Add Form</h4>
            <CloseIcon onClickIcon={() => props.closeModal()} />
          </div>
          <Formik
            validationSchema={schema}
            initialValues={{
              name: "",
              email: "",
              phoneNumber: "",
              gameRank: "",
              discordId: "",
            }}
            onSubmit={(values) => {
              console.log("Calling");
              if (values) {
                props?.closeModal();
                props?.playersOnsubmitForm({
                  name: values?.name,
                  email: values?.email,
                  phoneNumber: Number(values?.phoneNumber),
                  gameRank: values?.gameRank,
                  discordId: values?.discordId,
                });
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
            }: any) => (
              <form noValidate onSubmit={handleSubmit}>
                <Grid container>
                  <Grid item xs={12} className="p-4">
                    <label className="mb-3 text-white">Player FullName</label>

                    <input
                      type="text"
                      name="name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.fullName}
                      placeholder="Name"
                      className="form-control inp_text text-capitalize"
                    />
                    <p className="error">
                      {errors.name && touched.name && errors.name}
                    </p>
                  </Grid>
                  <Grid item xs={12} className="p-4">
                    <label className="mb-3 text-white">Phone</label>
                    <input
                    {...props}
                      type="text"
                      name="phoneNumber"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.phoneNumber}
                      placeholder="Phone"
                      className="form-control inp_text"
                      id="phone"
                      maxLength="10"
                      pattern="\d{10}"
                    />
                    <p className="error">
                      {errors.phoneNumber &&
                        touched.phoneNumber &&
                        errors.phoneNumber}
                    </p>
                  </Grid>
                  <Grid item xs={12} className="p-4">
                    <label className="mb-3 text-white">Email</label>
                    <input
                      type="email"
                      name="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      placeholder="Enter email"
                      className="form-control inp_text"
                      id="email"
                    />
                    <p className="error">
                      {errors.email && touched.email && errors.email}
                    </p>
                  </Grid>
                  <Grid item xs={12} className="p-4">
                    <label className="mb-3 text-white">Game Rank</label>

                    <input
                      type="text"
                      name="gameRank"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.gameRank}
                      placeholder="Game Rank..."
                      className="form-control inp_text text-capitalize"
                    />
                    <p className="error">
                      {errors.gameRank && touched.gameRank && errors.gameRank}
                    </p>
                  </Grid>
                  <Grid item xs={12} className="p-4">
                    <label className="mb-3 text-white">Discord ID</label>

                    <input
                      type="text"
                      name="discordId"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.discordId}
                      placeholder="Discord Id..."
                      className="form-control inp_text text-capitalize"
                    />
                    <p className="error">
                      {errors.discordId &&
                        touched.discordId &&
                        errors.discordId}
                    </p>
                  </Grid>
                  <Grid item xs={12} className="p-4">
                    <button className="userForm" type="submit">
                      Save
                    </button>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </Grid>
      </Grid>
      {/* ) : (
              ""
            )} */}
    </>
  );
};

export default PlayersAddForm;
