import React, { useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";

//components
import CloseIcon from "../../components/Icons/CloseIcon";
// validations
const schema = Yup.object().shape({
  label: Yup.string().required("Label is required"),
});
let gameDetails: any;
const AddGameOrEdit = (props: any) => {
  const [game, setGame] = useState(null);
  console.log("gameDetails", props?.gameDetails);
  return (
    <>
      <div className="text-white AddGamePage">
        <div className="">
          <Formik
            validationSchema={schema}
            initialValues={{
              label:
                props?.gameDetails !== undefined
                  ? props?.gameDetails?.label
                  : "",
            }}
            onSubmit={(values) => {
              console.log("values", values);
              if (values) {
                props?.gameDetails?.label
                  ? props?.handleSubmitUpdateGame({
                      id: props?.gameDetails ? props?.gameDetails?._id : "",
                      label: values?.label,
                    })
                  : props?.handleSubmitCreateGame(values);
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
            }: any) => (
              <div className="login">
                <div className="">
                  {/* <div className="">
                    <div className="d-flex justify-content-end alignItemPopUpLogin1 ">
                      <CloseIcon
                        className="float-end"
                        onClickIcon={props.closeModal}
                      />
                    </div>
                  </div> */}

                  <form
                    noValidate
                    onSubmit={handleSubmit}
                    className="addGameForm"
                  >
                    <div className="formLoginCard">
                      {/* <span className="spanHeading">
                        
                      </span> */}
                      <label className="mb-2">
                        Game
                      </label>
                      <input
                        type="text"
                        name="label"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.label}
                        placeholder={
                          props?.gameDetails?.label
                            ? "Edit Game"
                            : "Add Game..."
                        }
                        className="form-control inp_text text-capitalize"
                      />
                      <p className="error">
                        {errors.label && touched.label && errors.label}
                      </p>

                      <button
                        className="addGameButton p-2 mt-3"
                        type="submit"
                        // onClick={() => props.closeModal()}
                      >
                        {props?.gameDetails?.label ? "Edit" : " ADD"}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default AddGameOrEdit;
