import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Card, Row, Col } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { SiFacebook } from "react-icons/si";
import { ImGooglePlus3 } from "react-icons/im";
import { SocialIcon } from "react-social-icons";
import { GoogleLogin } from "react-google-login";
import FacebookLogin from "react-facebook-login";
import jwt from "jwt-decode";
import { useGoogleLogin } from "@react-oauth/google";

// importing from store
import {
  checkLoginUser,
  checkSocialLogin,
} from "../../../Store/auth/registration/actions";

//importing component
import Logo from "../../../assets/images/Full Logo.png";

//components
import notifications from "../../../components/Notifications/Notification";
import CloseIcon from "../../../components/Icons/CloseIcon";

// validations
const schema = Yup.object().shape({
  email: Yup.string()
    .required("Email is a required field")
    .email("Invalid email format"),
  password: Yup.string()
    .required("Password is a required field")
    .min(8, "Password is tooweak"),
});

const UserLogin = (props: any) => {
  const navigate = useNavigate();
  const [rememberMe, setRememberMe]: any = useState(false);

  useEffect(() => {
    console.log("rememberMe", rememberMe);
  }, [rememberMe]);

  localStorage.setItem("rememberMe", JSON.stringify(rememberMe ? true : false));
  const loginWithSocialMedia = (payload: any) => {
    props?.checkSocialLogin(payload, (res: any, user: any) => {
      if (res.success) {
        let authUser: any = jwt(res?.data?.accessToken);
        if (rememberMe === true) {
          localStorage.setItem("authUser", JSON.stringify(authUser));
          localStorage.setItem("accessToken", res.data.accessToken);
          localStorage.setItem("refreshToken", res.data.refreshToken);
        } else {
          sessionStorage.setItem("authUser", JSON.stringify(authUser));
          sessionStorage.setItem("accessToken", res.data.accessToken);
          sessionStorage.setItem("refreshToken", res.data.refreshToken);
        }
        notifications({
          title: "Success!",
          message: res?.message,
          type: "success",
        });
        props.closeModalLogin();
        if (user?.role === "Admin") {
          navigate("/admin-dashboard");
          window.scrollTo(0, 0);
        } else {
          navigate("/user/tournament");
          window.scrollTo(0, 0);
        }
      } else {
        notifications({
          title: "Error!",
          message: res?.message,
          type: "danger",
        });
      }
    });
  };
  let responseFacebook = (res: any) => {
    console.log("res facebook", res);
    if (res) {
      let payload = {
        token: res?.accessToken,
        type: "facebook",
      };
      loginWithSocialMedia(payload);
    }
  };

  const onSuccessGoogle = (res: any) => {
    console.log("res Google", res);
    if (res) {
      let payload = {
        token: res?.accessToken,
        type: "google",
      };
      loginWithSocialMedia(payload);
    }
  };
  const loginWithGoogle = useGoogleLogin({
    onSuccess: (res: any) => {
      console.log("respose", res);
      if (res) {
        let payload = {
          token: res?.access_token,
          type: "google",
        };
        loginWithSocialMedia(payload);
      }
    },
  });
  const onFailureGoogle = (err: any) => {
    if (err) {
      notifications({
        title: "Error!",
        message: err?.error,
        type: "danger",
      });
    }
  };
  const handleOnclickDiscord = () => {
    window.open(
      `https://discord.com/api/oauth2/authorize?response_type=token&client_id=${process.env.REACT_APP_DISCORD_CLIENTID}&scope=email&redirect_uri=${process.env.REACT_APP_DISCORD_REDIRECT_URL}`,
      "_self"
    );
  };
  let beforeLogin: any = localStorage.getItem("AcceptBeforeLogin");
  let AcceptBeforeLogin: any = JSON.parse(beforeLogin);
  console.log("AcceptBeforeLogin", AcceptBeforeLogin);
  return (
    <>
      <div className="text-white UserLoginPage">
        <div className="">
          <Formik
            validationSchema={schema}
            initialValues={{ email: "", password: "" }}
            onSubmit={(values) => {
              if (values) {
                props?.checkLoginUser(values, (res: any) => {
                  if (res.success) {
                    let authUser: any = jwt(res?.data?.accessToken);
                    if (rememberMe === true) {
                      localStorage.setItem(
                        "authUser",
                        JSON.stringify(authUser)
                      );
                      localStorage.setItem("accessToken", res.data.accessToken);
                      localStorage.setItem(
                        "refreshToken",
                        res.data.refreshToken
                      );
                    } else {
                      sessionStorage.setItem(
                        "authUser",
                        JSON.stringify(authUser)
                      );
                      sessionStorage.setItem(
                        "accessToken",
                        res.data.accessToken
                      );
                      sessionStorage.setItem(
                        "refreshToken",
                        res.data.refreshToken
                      );
                    }
                    console.log(
                      "AcceptBeforeLogin",
                      AcceptBeforeLogin,
                      AcceptBeforeLogin?.path ===
                        "/tournament-team-registration" &&
                        (authUser?.role === "Free" || authUser?.role === "Paid")
                    );
                    if (
                      AcceptBeforeLogin?.path ===
                        "/tournament-team-registration" &&
                      (authUser?.role === "Free" || authUser?.role === "Paid")
                    ) {
                      navigate("/tournament-team-registration", {
                        state: {
                          tournamentId: AcceptBeforeLogin?.tournamentId,
                        },
                      });
                      window.scrollTo(0, 0);
                    } else if (authUser?.role === "Admin") {
                      navigate("/admin-dashboard");
                      window.scrollTo(0, 0);
                    } else if (
                      authUser?.role === "Free" ||
                      authUser?.role === "Paid"
                    ) {
                      navigate("/user/tournament");
                      window.scrollTo(0, 0);
                    }
                    props.closeModalLogin();
                    notifications({
                      title: "Success!",
                      message: res?.message,
                      type: "success",
                    });
                  } else {
                    notifications({
                      title: "Error!",
                      message: res?.message,
                      type: "danger",
                    });
                  }
                });
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
            }) => (
              <div className="login">
                <div className="form">
                  <div className="">
                    <div className="d-flex justify-content-center">
                      <div className="d-flex justify-content-start">
                        <img
                          className="logoImage imageLogoPosLogin"
                          src={Logo}
                          alt="Logo"
                        />
                      </div>
                      <div className="d-flex justify-content-end alignItemPopUpLogin ">
                        <p
                          onClick={() => {
                            props?.openRegistrationPopUp();
                            props.closeModalLogin();
                          }}
                          style={{
                            lineHeight: "20px",
                          }}
                          className="ps-2 pe-2 pt-1 cursor-pointer fontFuturaMedium207 textStylesLogin"
                        >
                          SignUp?
                        </p>
                        <CloseIcon onClickIcon={props.closeModalLogin} />
                      </div>
                    </div>
                  </div>

                  <form noValidate onSubmit={handleSubmit}>
                    <div className="formLoginCard">
                      <span className="spanHeading">
                        LOGIN WITH GAMINGGEEKS
                      </span>
                      <div className="InputDiv">
                        <input
                          type="email"
                          name="email"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.email}
                          placeholder="Enter email"
                          className="form-control inp_text"
                          id="email"
                        />
                        <p className="error">
                          {errors.email && touched.email && errors.email}
                        </p>
                        <input
                          type="password"
                          name="password"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.password}
                          placeholder="Enter password"
                          className="form-control"
                        />
                        <p className="error">
                          {errors.password &&
                            touched.password &&
                            errors.password}
                        </p>

                        <button
                          className="LoginButton"
                          type="submit"
                          onClick={() => props.closeModalLogin()}
                        >
                          Login
                        </button>
                        <span className="mt-3 ForgetButton">
                          <Link to="/sendForgetPasswordLink" target={"_blank"}>
                            Forgot Password???
                          </Link>
                        </span>
                        <div className="d-flex justify-content-start checkbox">
                          <span>
                            {" "}
                            <input
                              type="checkbox"
                              id="rememberMe"
                              name="rememberMe"
                              value={rememberMe}
                              onChange={() => {
                                setRememberMe(true);
                              }}
                              // checked={rememberMe === true}
                            />{" "}
                          </span>

                          <span>Remember Me</span>
                        </div>
                      </div>
                    </div>
                    {/* <span className="d-flex justify-content-center marginTopOr">
                      <hr className="w-25"></hr>
                      <span className="pl5 pr5 pt5">Or</span>
                      <hr className="w-25"></hr>
                    </span> */}
                    {/* <Card className="iconCard ">
                     
                      <Row className="RowPadding">
                        <Col xs={2}></Col>
                        <Col xs={4} className="ps-2">
                          <FacebookLogin
                            {...props}
                            disableMobileRedirect={true}
                            // autoLoad={false}
                            appId={process.env.REACT_APP_FB_ID}
                            returnScopes={false}
                            fields="name,email,picture"
                            scope="public_profile, email"
                            callback={responseFacebook}
                            icon={
                              <SiFacebook className="faceBookIcon cursor-pointer" />
                            }
                            reAuthenticate={false}

                          />
                        </Col>
                        <Col xs={4} className="GoogleIconButton">
                          <ImGooglePlus3 className="googleIcon"
                          onClick={() => loginWithGoogle()} />
                        </Col>
                        <Col xs={2}></Col>
                      </Row>
                    </Card> */}
                  </form>
                </div>
              </div>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state: any) => {
  return {
    ...state,
  };
};
export default connect(mapStateToProps, {
  checkLoginUser,
  checkSocialLogin,
})(UserLogin);
