import { types } from "./actionTypes";
const initialState = {
 getProductsDetails:null
};
const productsReducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    case types.GET_PRODUCTS_SUCCESS:
      state = {
        ...state,
        getProductsDetails: action.payload,
      };
      return state;
      break;
   
    default:
      return state;
  }
};

export default productsReducer;
