import React, { useRef } from "react";
import { Row, Col } from "reactstrap";
import Slider from "react-slick";


const Carousel = (props: any) => {
  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
  
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
    ],
  };
  return (
    <>
      <div>
        <Slider {...settings}>
          <div className="YourwordCarousal1">
            <Row>
              <Col xs={12}>
                <span>
                  Brother, I got plat 2 now. Thanks for you advice ”<br />
                  -ShaikhTaha
                </span>
              </Col>
            </Row>
          </div>
          <div className="YourwordCarousal2">
            <Row>
              <Col xs={12}>
                <span>
                  “ Thank you so much Gill and Shanks. It was great learning
                  with you. Looking forward to learn more out from you ”<br />
                  -XOID
                </span>
              </Col>
            </Row>
          </div>
          <div className="YourwordCarousal1">
            <Row>
              <Col xs={12}>
                <span>
                  Brother, I got plat 2 now. Thanks for you advice ”<br />
                  -ShaikhTaha
                </span>
              </Col>
            </Row>
          </div>
          <div className="YourwordCarousal2">
            <Row>
              <Col xs={12}>
                <span>
                  “ Thank you so much Gill and Shanks. It was great learning
                  with you. Looking forward to learn more out from you ”<br />
                  -XOID
                </span>
              </Col>
            </Row>
          </div>
        </Slider>
      </div>
    </>
  );
};
export default Carousel;
