import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Card } from "reactstrap";
import Select from "react-select";
import { Grid } from "@mui/material";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import { FiArrowLeft } from "react-icons/fi";
import { useNavigate, useLocation } from "react-router";
import { DatePicker, Stack } from "rsuite";
import "rsuite/dist/rsuite.min.css";

//store
import { fetchGame } from "../../Store/game/actions";
import {
  fetchPresignedUrlLogo,
  uploadImages,
  updateTournament,
  fetchPresignedUrlRules,
  fetchPresignedUrlTumbnail,
  fetchPresignedUrlFixtures,
  fetchPresignedUrlPoolPrice,
  fetchPresignedUrlRulesForDocx,
} from "../../Store/tournament/actions";

// components
import Loader from "../../components/Loader/Loader";
import SideBar from "../../components/verticalMenu/SideBar";
import notifications from "../../components/Notifications/Notification";
//options
var tournamentFormatOptons: any = [
  { value: "Round Robin ", label: "Round Robin" },
  { value: "Knockout", label: "Knockout" },
  { value: "League", label: "League" },
];
let gameOptionsArry: any = [];
const errorsAdd: any = {};

var formUpdate = {
  tournamentId: "",
  gameId: "",
  label: null,
  logo: "",
  format: "",
  startDate: null,
  endDate: null,
  rules: "",
  teamSize: null,
  playerInTeam: null,
  fixture: "",
  tournamentForm: "",
  region: "",
  server: "",
  poolPrize: 0,
  poolPrizeImageURL: "",
};
const EditTournament = (props: any) => {
  const [loading, setLoading] = useState(false);
  const [baseImageLogo, setBaseImageLogo]: any = useState("");
  const [baseImageThumbnail, setBaseImageThumbnail]: any = useState("");
  const [baseImageFixtures, setBaseImageFixtures]: any = useState("");
  const [baseImagePoolPrice, setBaseImagePoolPrice]: any = useState("");

  const [selectedFile, setSelectedFile]: any = useState();
  const [selectedFileThumbnail, setSelectedFileThumbnail]: any = useState();
  const [selectedFileFixtures, setSelectedFileFixtures]: any = useState();
  const [selectedFilePoolPrice, setSelectedFilePoolPrice]: any = useState();

  const [selectedFileRules, setSelectedFileRules]: any = useState();
  const [errors, setErrors]: any = useState("");
  const [form, setForm]: any = useState(formUpdate);
  const fileInputLogo: any = useRef();
  const fileInputThumbnail: any = useRef();
  const fileInputFixtures: any = useRef();
  const fileInputRules: any = useRef();
  const fileInputPoolPrice: any = useRef();

  const navigator = useNavigate();
  const location = useLocation();
  console.log("location", location);
  useEffect(() => {
    getAllGamesData();
    if (location?.state?.mode === "edit") {
      const {
        _id,
        logo,
        format,
        label,
        startDate,
        endDate,
        rules,
        thumbnail,
        playerInTeam,
        teamSize,
        gameInfo,
        fixture,
        tournamentForm,
        region,
        server,
        poolPrize,
        prizePoolImage,
      } = location?.state?.tournament;
      setForm({
        tournamentId: _id,
        game: { value: gameInfo?._id, label: gameInfo?.label },
        logo,
        format,
        label,
        startDate: new Date(startDate),
        endDate: new Date(endDate),
        rules,
        thumbnail,
        playerInTeam,
        teamSize,
        fixture,
        tournamentForm,
        region,
        server,
        poolPrize,
        prizePoolImage,
      });
    }
  }, []);
  console.log("form", form, form?.startDate, form?.endDate);
  useEffect(() => {
    if (selectedFile) {
      S3Upload();
    }
  }, [selectedFile]);
  useEffect(() => {
    if (selectedFileThumbnail) {
      S3UploadThumbnail();
    }
  }, [selectedFileThumbnail]);
  useEffect(() => {
    if (selectedFileRules) {
      S3UploadRules();
    }
  }, [selectedFileRules]);
  useEffect(() => {
    if (selectedFilePoolPrice) {
      S3UploadPoolPrice();
    }
  }, [selectedFilePoolPrice]);
  useEffect(() => {
    if (selectedFileFixtures) {
      S3UploadFixtures();
    }
  }, [selectedFileFixtures]);

  const fileUploadTrigger = () => {
    fileInputLogo.current.click();
  };
  const fileUploadTriggerThumbnail = () => {
    fileInputThumbnail.current.click();
  };
  const fileUploadTriggerFixtures = () => {
    fileInputFixtures.current.click();
  };
  const fileUploadTriggerRules = () => {
    fileInputRules.current.click();
  };
  const fileUploadTriggerPoolPrice = () => {
    fileInputPoolPrice.current.click();
  };
  const S3Upload = async () => {
    props.fetchPresignedUrlLogo((res: any) => {
      if (selectedFile) {
        let softCopy = "";
        softCopy = res.data.split("?")[0];
        setForm({ ...form, logo: softCopy });
        const formData = new FormData();
        formData.append(selectedFile, selectedFile.name);
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", selectedFile.type);
        props.uploadImages(res.data, selectedFile, myHeaders);
      }
    });
  };
  const S3UploadThumbnail = async () => {
    props.fetchPresignedUrlLogo((res: any) => {
      if (selectedFileThumbnail) {
        let softCopy = "";
        softCopy = res.data.split("?")[0];
        setForm({ ...form, thumbnail: softCopy });
        const formData = new FormData();
        formData.append(selectedFileThumbnail, selectedFileThumbnail.name);
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", selectedFileThumbnail.type);
        props.uploadImages(res.data, selectedFileThumbnail, myHeaders);
      }
    });
  };
  const S3UploadFixtures = async () => {
    if (selectedFileFixtures) {
      props.fetchPresignedUrlFixtures(
        { type: selectedFileFixtures.type.split("/")[1] },
        (res: any) => {
          if (selectedFileFixtures) {
            let softCopy = "";
            softCopy = res.data.split("?")[0];
            setForm({ ...form, fixture: softCopy });
            const formData = new FormData();
            formData.append(selectedFileFixtures, selectedFileFixtures.name);
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", selectedFileFixtures.type);
            props.uploadImages(res.data, selectedFileFixtures, myHeaders);
          }
        }
      );
    }
  };
  const S3UploadRules = async () => {
    console.log(
      "selectedFileRules?.type",
      selectedFileRules?.type,
      selectedFileRules?.type === "application/pdf"
    );
    if (selectedFileRules?.type === "application/pdf") {
      props.fetchPresignedUrlRules((res: any) => {
        console.log("res", res?.data);
        if (selectedFileRules) {
          let softCopy = "";
          // console.log("Calling Signed",res)
          softCopy = res.data.split("?")[0];
          console.log("softCopy", softCopy);
          setForm({ ...form, rules: softCopy });
          const formData = new FormData();
          formData.append(selectedFileRules, selectedFileRules.name);
          console.log("Calling");
          // console.log("--------", formData);
          const myHeaders = new Headers();
          myHeaders.append("Content-Type", selectedFileRules.type);
          props.uploadImages(res.data, selectedFileRules, myHeaders);
        }
      });
    } else {
      props.fetchPresignedUrlRulesForDocx((res: any) => {
        console.log("res", res?.data);
        if (selectedFileRules) {
          let softCopy = "";
          // console.log("Calling Signed",res)
          softCopy = res.data.split("?")[0];
          console.log("softCopy", softCopy);
          setForm({ ...form, rules: softCopy });
          const formData = new FormData();
          formData.append(selectedFileRules, selectedFileRules.name);
          console.log("Calling");
          // console.log("--------", formData);
          const myHeaders = new Headers();
          myHeaders.append("Content-Type", selectedFileRules.type);
          console.log("Calling");
          props.uploadImages(res.data, selectedFileRules, myHeaders);
          console.log("Calling");
        }
      });
    }
  };
  const S3UploadPoolPrice = async () => {
    props.fetchPresignedUrlPoolPrice((res: any) => {
      console.log("res", res?.data);
      if (selectedFilePoolPrice) {
        let softCopy = "";
        // console.log("Calling Signed",res)
        softCopy = res.data.split("?")[0];
        console.log("softCopy", softCopy);
        setForm({ ...form, poolPrizeImageURL: softCopy });
        const formData = new FormData();
        formData.append(selectedFilePoolPrice, selectedFilePoolPrice.name);
        // console.log("--------", formData);
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", selectedFilePoolPrice.type);
        props.uploadImages(res.data, selectedFilePoolPrice, myHeaders);
      }
    });
  };
  const fileUpload = (event: any) => {
    console.log("EVENT console", event.target.files[0]);
    let name = event.target.name;
    if (name === "Logo") {
      uploadImage(event);
      setSelectedFile(event.target.files[0]);
    } else if (name === "Rules") {
      setSelectedFileRules(event.target.files[0]);
    } else if (name === "Thumbnail") {
      uploadImageThumbnail(event);
      setSelectedFileThumbnail(event.target.files[0]);
    } else if (name === "Fixture") {
      uploadImageFixture(event);
      setSelectedFileFixtures(event.target.files[0]);
    } else if (name === "poolPrizeImageURL") {
      uploadImagePoolPrice(event);
      setSelectedFilePoolPrice(event.target.files[0]);
      console.log("selectedFile", selectedFilePoolPrice, event.target.files[0]);
    }
  };
  const uploadImage = async (e: any) => {
    const file = e.target.files[0];
    const base64 = await convertBase64(file);
    setBaseImageLogo(base64);
  };
  const uploadImageThumbnail = async (e: any) => {
    const file = e.target.files[0];
    const base64 = await convertBase64(file);
    setBaseImageThumbnail(base64);
  };
  const uploadImageFixture = async (e: any) => {
    const file = e.target.files[0];
    const base64 = await convertBase64(file);
    setBaseImageFixtures(base64);
  };
  const uploadImagePoolPrice = async (e: any) => {
    const file = e.target.files[0];
    const base64 = await convertBase64(file);
    setBaseImagePoolPrice(base64);

    // console.log("base Image", this.state.baseImage);
  };

  const convertBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  const handleSubmitChange = (type: any) => {
    setForm({ ...form, [type.name]: type.value });
  };
  const validate = () => {
    let error: any = "";
    let errorObj: any = {};
    console.log("formData", form);
    if (form?.game === "") {
      error = "required!";
      errorObj.game = "Game name is Required!";
    }
    if (form.label === null) {
      error = "Required!";
      errorObj.label = " Tournament name is Required!";
    }
    if (form.thumbnail === "") {
      error = "Required!";
      errorObj.thumbnail = " Thumbnail is Required!";
    }
    if (form.logo === "") {
      error = "Required!";
      errorObj.logo = " Logo is Required!";
    }
    if (form.format === "") {
      error = "Required!";
      errorObj.format = "Format is Required!";
    }
    if (form.startDate === null) {
      error = "Required!";
      errorObj.startDate = " Start Date is Required!";
    }
    if (form.endDate === null) {
      error = "Required!";
      errorObj.endDate = " End Date is Required!";
    }
    if (form.rules === "") {
      error = " Required!";
      errorObj.rules = " Rules are  Required!";
    }
    if (form.teamSize === null) {
      error = "Required!";
      errorObj.teamSize = " Team Size is Required!";
    }
    if (form.playerInTeam === null) {
      error = "Required!";
      errorObj.playerInTeam = " Players In Team is Required!";
    }
    if (form.tournamentForm === "") {
      error = "Required!";
      errorObj.tournamentForm = " Tournament Form is Required!";
    }
    if (form.region === "") {
      error = "Required!";
      errorObj.region = " Region is Required!";
    }
    if (form.server === "") {
      error = "Required!";
      errorObj.server = " Server name is Required!";
    }
    // if (form.poolPrizeImageURL === "") {
    //   error = " Required!";
    //   errorObj.poolPrizeImageURL = " Pool Prize Image name is Required!";
    // }
    setErrors(errorObj);
    console.log("error", errors);

    return error;
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const result = validate();
    if (result?.length === 0) {
      setLoading(true);
      props?.updateTournament(
        {
          tournamentId: form?.tournamentId,
          gameId: form?.game?.value,
          label: form?.label,
          logo: form?.logo,
          format: form?.format,
          startDate: moment(form?.startDate).toISOString(),
          endDate: moment(form?.endDate).toISOString(),
          rules: form?.rules,
          teamSize: form?.teamSize,
          playerInTeam: form?.playerInTeam,
          thumbnail: form?.thumbnail,
          fixture: form?.fixture,
          tournamentForm: form?.tournamentForm,
          region: form?.region,
          server: form.server,
          poolPrize: Number(form?.poolPrize),
          prizePoolImage: form?.poolPrizeImageURL,
        },
        (res: any) => {
          if (res.success) {
            setLoading(false);
            navigator("/tournaments");
            window.scrollTo(0, 0);
            notifications({
              title: "Success!",
              message: res?.message,
              type: "success",
            });
          } else {
            notifications({
              title: "Error!",
              message: res?.message,
              type: "danger",
            });
          }
        }
      );
    }
  };
  let getAllGamesData = () => {
    let offset = 0;
    props.fetchGame(offset, 10, {}, (res: any) => {});
  };
  gameOptionsArry = [];
  if (props.getGamesDetails !== undefined) {
    let getGamesDetails = props?.getGamesDetails?.data;
    getGamesDetails?.docs?.map((ele: any, i: any) => {
      gameOptionsArry.push({
        label: ele?.label,
        value: ele?._id,
      });
    });
  }

  const handleOnInputChangeGame = (e: any) => {
    if (e.length > 2) {
      props.fetchGame(0, 10, { name: e }, (res: any) => {});
    }
  };
  return (
    <>
      <div className="pageContent">
        <div className="pageContentChild">
          <div>
            <SideBar></SideBar>
          </div>
          <div className="p-3 w-100">
            <div>
              <Link to="/tournaments">
                <button className="m-4">
                  <FiArrowLeft className="cursor-pointer editIcon " />
                </button>
              </Link>
            </div>
            {loading ? (
              <>
                <div>
                  <Loader />
                </div>
              </>
            ) : (
              <>
                <div className="container">
                  <Card className="CardTournament">
                    <form noValidate className="mb-5">
                      <Grid container>
                        <Grid item xs={12} md={6} lg={4} className="p-4">
                          <label className="mb-3 text-white">
                            Game
                            <span
                              style={{
                                color: "red",
                              }}
                            >
                              *
                            </span>
                          </label>
                          <Select
                            {...props}
                            className="Game_selector"
                            classNamePrefix="react-select"
                            placeholder="Select Game..."
                            autoFocus={true}
                            isSearchable
                            name="gameId"
                            id="gameId"
                            // value={form?.game ? gameOptionsArry?.find(
                            //   (ele: any) => ele?.value == form?.value
                            // ) : form?.game}
                            value={form?.game}
                            options={gameOptionsArry}
                            onChange={(e: any) => {
                              handleSubmitChange({
                                name: "game",
                                value: e,
                              });
                            }}
                            onInputChange={(e) => handleOnInputChangeGame(e)}
                          />
                          {errors.game && form?.game?.length <= 0 ? (
                            <p className="RequiredField">{errors?.game} </p>
                          ) : (
                            ""
                          )}
                        </Grid>

                        <Grid item xs={12} md={6} lg={4} className="p-4">
                          <label className="mb-3 text-white">
                            Tournament Name
                            <span
                              style={{
                                color: "red",
                              }}
                            >
                              *
                            </span>
                          </label>
                          <input
                            type="text"
                            id="tournamentName"
                            name="label"
                            value={form?.label}
                            placeholder="Tournament Name..."
                            className="form-control inp_text textTransNone"
                            onChange={(e: any) => {
                              handleSubmitChange({
                                name: "label",
                                value: e.target.value,
                              });
                            }}
                            // onInputChange={()=>{}}
                          />
                          {errors.label && form?.label === null ? (
                            <p className="RequiredField">{errors?.label} </p>
                          ) : (
                            ""
                          )}
                        </Grid>
                        <Grid item xs={12} md={6} lg={4} className="p-4">
                          <label className="mb-3 text-white">
                            Tournament Format
                            <span
                              style={{
                                color: "red",
                              }}
                            >
                              *
                            </span>
                          </label>
                          <Select
                            className="Game_selector"
                            classNamePrefix="react-select"
                            placeholder="Select Tournament Format..."
                            autoFocus={true}
                            isSearchable
                            name="format"
                            value={tournamentFormatOptons?.find(
                              (ele: any) => ele?.value == form?.format
                            )}
                            options={tournamentFormatOptons}
                            onChange={(e: any) => {
                              handleSubmitChange({
                                name: "format",
                                value: e?.value,
                              });
                            }}
                          />
                          {errors.format && form?.format?.length <= 0 ? (
                            <p className="RequiredField">{errors?.format} </p>
                          ) : (
                            ""
                          )}
                        </Grid>
                        <Grid item xs={12} md={6} lg={4} className="p-4">
                          <label className="mb-3 text-white">
                            Max. number of teams
                            <span
                              style={{
                                color: "red",
                              }}
                            >
                              *
                            </span>
                          </label>
                          <input
                            type="number"
                            name="teamSize"
                            // onChange={handleChange}

                            value={form?.teamSize}
                            placeholder="Team Size..."
                            className="form-control inp_text text-capitalize"
                            onChange={(e: any) => {
                              handleSubmitChange({
                                name: "teamSize",
                                value: Number(e.target.value),
                              });
                            }}
                          />
                          {errors.teamSize && !form?.teamSize ? (
                            <p className="RequiredField">{errors?.teamSize} </p>
                          ) : (
                            ""
                          )}
                        </Grid>
                        <Grid item xs={12} md={6} lg={4} className="p-4">
                          <label className="mb-3 text-white">
                            Max. number of players in a team{" "}
                            <span
                              style={{
                                color: "red",
                              }}
                            >
                              *
                            </span>
                          </label>
                          <input
                            type="number"
                            name="playerInTeam"
                            // onChange={handleChange}

                            value={form?.playerInTeam}
                            placeholder="Players In Team..."
                            className="form-control inp_text text-capitalize"
                            onChange={(e: any) => {
                              handleSubmitChange({
                                name: "playerInTeam",
                                value: Number(e.target.value),
                              });
                            }}
                          />
                          {errors.playerInTeam && !form?.playerInTeam ? (
                            <p className="RequiredField">
                              {errors?.playerInTeam}{" "}
                            </p>
                          ) : (
                            ""
                          )}
                        </Grid>
                        <Grid item xs={12} md={6} lg={4} className="p-4">
                          <label className="mb-3 text-white">
                            Start Date
                            <span
                              style={{
                                color: "red",
                              }}
                            >
                              *
                            </span>
                          </label>
                          <div>
                            <DatePicker
                              style={{
                                width: "100%",
                              }}
                              format="yyyy-MM-dd HH:mm"
                              name="startDate"
                              id="startDate"
                              cleanable={false}
                              value={form?.startDate}
                              onChange={(newValue: any) => {
                                handleSubmitChange({
                                  name: "startDate",
                                  value: newValue,
                                });
                              }}
                            />
                            {errors.startDate && !form?.startDate ? (
                              <p className="RequiredField">
                                {errors?.startDate}{" "}
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4} className="p-4">
                          <label className="mb-3 text-white">
                            End Date
                            <span
                              style={{
                                color: "red",
                              }}
                            >
                              *
                            </span>
                          </label>
                          <div>
                            <DatePicker
                              style={{
                                width: "100%",
                              }}
                              format="yyyy-MM-dd HH:mm"
                              name="endDate"
                              id="endDate"
                              cleanable={false}
                              value={form?.endDate}
                              onChange={(newValue: any) => {
                                handleSubmitChange({
                                  name: "endDate",
                                  value: newValue,
                                });
                              }}
                            />
                            {errors.endDate && !form?.endDate ? (
                              <p className="RequiredField">
                                {errors?.endDate}{" "}
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4} className="p-4">
                          <label className="mb-3 text-white">
                            Tournament Form
                            <span
                              style={{
                                color: "red",
                              }}
                            >
                              *
                            </span>
                          </label>
                          <input
                            type="text"
                            id="tournamentForm"
                            name="tournamentForm"
                            value={form?.tournamentForm}
                            placeholder="Tournament Form..."
                            className="form-control inp_text text-capitalize"
                            onChange={(e: any) => {
                              console.log("tournamentForm", e.target.value);
                              handleSubmitChange({
                                name: "tournamentForm",
                                value: e.target.value,
                              });
                            }}
                          />
                          {errors.tournamentForm &&
                          form?.tournamentForm?.length <= 0 ? (
                            <p className="RequiredField">
                              {errors?.tournamentForm}{" "}
                            </p>
                          ) : (
                            ""
                          )}
                        </Grid>
                        <Grid item xs={12} md={6} lg={4} className="p-4">
                          <label className="mb-3 text-white">
                            Region
                            <span
                              style={{
                                color: "red",
                              }}
                            >
                              *
                            </span>
                          </label>
                          <input
                            type="text"
                            id="region"
                            name="region"
                            value={form?.region}
                            placeholder="Region..."
                            className="form-control inp_text text-capitalize"
                            onChange={(e: any) => {
                              console.log("region", e.target.value);
                              handleSubmitChange({
                                name: "region",
                                value: e.target.value,
                              });
                            }}
                          />
                          {errors.region && form?.region?.length <= 0 ? (
                            <p className="RequiredField">{errors?.region} </p>
                          ) : (
                            ""
                          )}
                        </Grid>
                        <Grid item xs={12} md={6} lg={4} className="p-4">
                          <label className="mb-3 text-white">
                            Server
                            <span
                              style={{
                                color: "red",
                              }}
                            >
                              *
                            </span>
                          </label>
                          <input
                            type="text"
                            id="server"
                            name="server"
                            value={form?.server}
                            placeholder="Server..."
                            className="form-control inp_text text-capitalize"
                            onChange={(e: any) => {
                              console.log("server", e.target.value);
                              handleSubmitChange({
                                name: "server",
                                value: e.target.value,
                              });
                            }}
                          />
                          {errors.server && form?.server?.length <= 0 ? (
                            <p className="RequiredField">{errors?.server} </p>
                          ) : (
                            ""
                          )}
                        </Grid>
                        <Grid item xs={12} md={6} lg={4} className="p-4">
                          <label className="mb-3 text-white">Prize Pool</label>
                          <input
                            type="number"
                            id="poolPrize"
                            name="poolPrize"
                            value={form?.poolPrize}
                            placeholder="Prize Pool..."
                            className="form-control inp_text text-capitalize"
                            onChange={(e: any) => {
                              console.log("poolPrize", e.target.value);
                              handleSubmitChange({
                                name: "poolPrize",
                                value: e.target.value,
                              });
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4} className="p-4">
                          <label className="mb-3 text-white">
                            Tournament Logo
                            <span
                              style={{
                                color: "red",
                              }}
                            >
                              *
                            </span>
                          </label>
                          <div>
                            <input
                              type="file"
                              name="Image"
                              multiple={true}
                              style={{ display: "none" }}
                              ref={fileInputLogo}
                              onChange={(e) => {
                                fileUpload({
                                  target: {
                                    name: "Logo",
                                    files: e.target.files,
                                  },
                                });
                              }}
                            />
                            <span
                              style={{
                                cursor: "pointer",
                                color: "#5664D2",
                              }}
                              onClick={() => fileUploadTrigger()}
                            >
                              <svg
                                style={{
                                  marginTop: "10px",
                                  marginLeft: "20px",
                                }}
                                width="51"
                                height="51"
                                viewBox="0 0 51 51"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M50.7061 0H0.293948C0.131605 0 0 1.60235 0 3.57895V47.4211C0 49.3977 0.131605 51 0.293948 51H50.7061C50.8684 51 51 49.3977 51 47.4211V3.57895C51 1.60235 50.8684 0 50.7061 0Z"
                                  fill="#05284E"
                                  fillOpacity="0.59"
                                />
                                <path
                                  d="M50.7062 0.224609H0.294131C0.141934 0.224609 0.0185547 1.72681 0.0185547 3.57987V47.422C0.0185547 49.275 0.141934 50.7772 0.294131 50.7772H50.7062C50.8584 50.7772 50.9818 49.275 50.9818 47.422V3.57987C50.9818 1.72681 50.8584 0.224609 50.7062 0.224609Z"
                                  fill="#05284E"
                                  fillOpacity="0.59"
                                />
                                <path
                                  d="M26 21V31"
                                  stroke="white"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M21 26H31"
                                  stroke="white"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </span>
                            <span className="ms-3">
                              <img
                                src={baseImageLogo ? baseImageLogo : form?.logo}
                                alt="logo"
                                width="100px"
                                height="100px"
                              />
                            </span>
                          </div>
                          {errors.logo && form?.logo?.length <= 0 ? (
                            <p className="RequiredField">{errors?.logo} </p>
                          ) : (
                            ""
                          )}
                        </Grid>
                        <Grid item xs={12} md={6} lg={4} className="p-4">
                          <label className="mb-3 text-white">
                            Tournament Thumbnail
                            <span
                              style={{
                                color: "red",
                              }}
                            >
                              *
                            </span>
                          </label>
                          <div>
                            <input
                              type="file"
                              name="Image"
                              multiple={true}
                              style={{ display: "none" }}
                              ref={fileInputThumbnail}
                              onChange={(e) => {
                                fileUpload({
                                  target: {
                                    name: "Thumbnail",
                                    files: e.target.files,
                                  },
                                });
                              }}
                            />
                            <span
                              style={{
                                cursor: "pointer",
                                color: "#5664D2",
                              }}
                              onClick={() => fileUploadTriggerThumbnail()}
                            >
                              <svg
                                style={{
                                  marginTop: "10px",
                                  marginLeft: "20px",
                                }}
                                width="51"
                                height="51"
                                viewBox="0 0 51 51"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M50.7061 0H0.293948C0.131605 0 0 1.60235 0 3.57895V47.4211C0 49.3977 0.131605 51 0.293948 51H50.7061C50.8684 51 51 49.3977 51 47.4211V3.57895C51 1.60235 50.8684 0 50.7061 0Z"
                                  fill="#05284E"
                                  fillOpacity="0.59"
                                />
                                <path
                                  d="M50.7062 0.224609H0.294131C0.141934 0.224609 0.0185547 1.72681 0.0185547 3.57987V47.422C0.0185547 49.275 0.141934 50.7772 0.294131 50.7772H50.7062C50.8584 50.7772 50.9818 49.275 50.9818 47.422V3.57987C50.9818 1.72681 50.8584 0.224609 50.7062 0.224609Z"
                                  fill="#05284E"
                                  fillOpacity="0.59"
                                />
                                <path
                                  d="M26 21V31"
                                  stroke="white"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M21 26H31"
                                  stroke="white"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </span>
                            <span className="ms-3">
                              <img
                                src={
                                  baseImageThumbnail
                                    ? baseImageThumbnail
                                    : form?.thumbnail
                                }
                                alt="thumbnail"
                                width="100px"
                                height="100px"
                              />
                            </span>
                          </div>
                          {errors.thumbnail && form?.thumbnail?.length <= 0 ? (
                            <p className="RequiredField">
                              {errors?.thumbnail}{" "}
                            </p>
                          ) : (
                            ""
                          )}
                        </Grid>
                        <Grid item xs={12} md={6} lg={4} className="p-4">
                          <label className="mb-3 text-white">
                            Tournament Rule
                            <span
                              style={{
                                color: "red",
                              }}
                            >
                              *
                            </span>
                          </label>
                          <div>
                            <input
                              type="file"
                              name="Image"
                              accept=".doc,.docx,.pdf,"
                              multiple={true}
                              style={{ display: "none" }}
                              ref={fileInputRules}
                              onChange={(e) => {
                                fileUpload({
                                  target: {
                                    name: "Rules",
                                    files: e.target.files,
                                  },
                                });
                              }}
                            />
                            <span
                              style={{
                                cursor: "pointer",
                                color: "#5664D2",
                              }}
                              onClick={() => fileUploadTriggerRules()}
                            >
                              <svg
                                style={{
                                  marginTop: "10px",
                                  marginLeft: "20px",
                                }}
                                width="51"
                                height="51"
                                viewBox="0 0 51 51"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M50.7061 0H0.293948C0.131605 0 0 1.60235 0 3.57895V47.4211C0 49.3977 0.131605 51 0.293948 51H50.7061C50.8684 51 51 49.3977 51 47.4211V3.57895C51 1.60235 50.8684 0 50.7061 0Z"
                                  fill="#05284E"
                                  fillOpacity="0.59"
                                />
                                <path
                                  d="M50.7062 0.224609H0.294131C0.141934 0.224609 0.0185547 1.72681 0.0185547 3.57987V47.422C0.0185547 49.275 0.141934 50.7772 0.294131 50.7772H50.7062C50.8584 50.7772 50.9818 49.275 50.9818 47.422V3.57987C50.9818 1.72681 50.8584 0.224609 50.7062 0.224609Z"
                                  fill="#05284E"
                                  fillOpacity="0.59"
                                />
                                <path
                                  d="M26 21V31"
                                  stroke="white"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M21 26H31"
                                  stroke="white"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </span>
                            <a
                              href={form?.rules}
                              target="_blank"
                              className="cursor-pointer"
                              rel="noreferrer"
                            >
                              {" "}
                              Rules
                              <embed
                                src={form?.rules}
                                width="50px"
                                height="50px"
                              />
                            </a>
                            {selectedFileRules ? (
                              <div
                                className="container"
                                style={{ display: "flex" }}
                              >
                                <div style={{ width: "30%" }}>
                                  <i className="fas fa-file-alt fa-2x m-1"></i>
                                  {selectedFileRules?.name}
                                </div>
                                <div style={{ flexGrow: "1" }}>
                                  <i
                                    className="fas fa-times"
                                    style={{ cursor: "pointer", color: "red" }}
                                  ></i>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                          {errors.rules && form?.rules?.length <= 0 ? (
                            <p className="RequiredField">{errors?.rules} </p>
                          ) : (
                            ""
                          )}
                        </Grid>
                        <Grid item xs={12} md={6} lg={4} className="p-4">
                          <label className="mb-3 text-white">
                            Tournament Fixtures
                            <span
                              style={{
                                color: "red",
                              }}
                            >
                              *
                            </span>
                          </label>
                          <div>
                            <input
                              type="file"
                              name="Image"
                              multiple={true}
                              style={{ display: "none" }}
                              ref={fileInputFixtures}
                              onChange={(e) => {
                                fileUpload({
                                  target: {
                                    name: "Fixture",
                                    files: e.target.files,
                                  },
                                });
                              }}
                            />
                            <span
                              style={{
                                cursor: "pointer",
                                color: "#5664D2",
                              }}
                              onClick={() => fileUploadTriggerFixtures()}
                            >
                              <svg
                                style={{
                                  marginTop: "10px",
                                  marginLeft: "20px",
                                }}
                                width="51"
                                height="51"
                                viewBox="0 0 51 51"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M50.7061 0H0.293948C0.131605 0 0 1.60235 0 3.57895V47.4211C0 49.3977 0.131605 51 0.293948 51H50.7061C50.8684 51 51 49.3977 51 47.4211V3.57895C51 1.60235 50.8684 0 50.7061 0Z"
                                  fill="#05284E"
                                  fillOpacity="0.59"
                                />
                                <path
                                  d="M50.7062 0.224609H0.294131C0.141934 0.224609 0.0185547 1.72681 0.0185547 3.57987V47.422C0.0185547 49.275 0.141934 50.7772 0.294131 50.7772H50.7062C50.8584 50.7772 50.9818 49.275 50.9818 47.422V3.57987C50.9818 1.72681 50.8584 0.224609 50.7062 0.224609Z"
                                  fill="#05284E"
                                  fillOpacity="0.59"
                                />
                                <path
                                  d="M26 21V31"
                                  stroke="white"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M21 26H31"
                                  stroke="white"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </span>
                            <span className="ms-3">
                              <img
                                src={
                                  baseImageFixtures
                                    ? baseImageFixtures
                                    : form?.fixture
                                }
                                alt="fixture"
                                width="100px"
                                height="100px"
                              />
                            </span>
                          </div>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4} className="p-4">
                          <label className="mb-3 text-white">
                            Prize Pool Image
                          </label>
                          <div>
                            <input
                              type="file"
                              name="Image"
                              multiple={true}
                              style={{ display: "none" }}
                              ref={fileInputPoolPrice}
                              onChange={(e) => {
                                console.log(e.target.files);
                                fileUpload({
                                  target: {
                                    name: "poolPrizeImageURL",
                                    files: e.target.files,
                                  },
                                });
                              }}
                            />
                            <span
                              style={{
                                cursor: "pointer",
                                color: "#5664D2",
                              }}
                              onClick={() => fileUploadTriggerPoolPrice()}
                            >
                              <svg
                                style={{
                                  marginTop: "10px",
                                  marginLeft: "20px",
                                }}
                                width="51"
                                height="51"
                                viewBox="0 0 51 51"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M50.7061 0H0.293948C0.131605 0 0 1.60235 0 3.57895V47.4211C0 49.3977 0.131605 51 0.293948 51H50.7061C50.8684 51 51 49.3977 51 47.4211V3.57895C51 1.60235 50.8684 0 50.7061 0Z"
                                  fill="#05284E"
                                  fillOpacity="0.59"
                                />
                                <path
                                  d="M50.7062 0.224609H0.294131C0.141934 0.224609 0.0185547 1.72681 0.0185547 3.57987V47.422C0.0185547 49.275 0.141934 50.7772 0.294131 50.7772H50.7062C50.8584 50.7772 50.9818 49.275 50.9818 47.422V3.57987C50.9818 1.72681 50.8584 0.224609 50.7062 0.224609Z"
                                  fill="#05284E"
                                  fillOpacity="0.59"
                                />
                                <path
                                  d="M26 21V31"
                                  stroke="white"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M21 26H31"
                                  stroke="white"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </span>
                            <span className="ms-3">
                              <img
                                src={
                                  baseImagePoolPrice
                                    ? baseImagePoolPrice
                                    : form?.prizePoolImage
                                }
                                alt="Logo"
                                width="100px"
                                height="100px"
                              />
                            </span>
                          </div>
                        </Grid>
                      </Grid>

                      <button
                        className=" p-1 w-25 TorunamentSubmitButton ms-4 mb-5 mt-4"
                        // type="submit"
                        onClick={(e) => {
                          handleSubmit(e);
                        }}
                      >
                        Submit
                      </button>
                    </form>
                  </Card>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state: any) => {
  // console.log("state", state);
  return {
    ...state,
    getGamesDetails: state?.gameReducer?.gameDetail,
  };
};
export default connect(mapStateToProps, {
  fetchGame,
  fetchPresignedUrlLogo,
  uploadImages,
  updateTournament,
  fetchPresignedUrlRules,
  fetchPresignedUrlTumbnail,
  fetchPresignedUrlFixtures,
  fetchPresignedUrlPoolPrice,
  fetchPresignedUrlRulesForDocx,
})(EditTournament);
