import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Row, Col } from "reactstrap";
import notifications from "../../components/Notifications/Notification";
import {Grid, Pagination, Stack } from "@mui/material";
import { FiEdit3, FiEye } from "react-icons/fi";
import { MdOutlineDelete } from "react-icons/md";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { FiArrowLeft } from "react-icons/fi";

// components
import Index from "../../components/common";
import Loader from "../../components/Loader/Loader";
import SideBar from "../../components/verticalMenu/SideBar";
// store
import { fetchTeams, deleteTeamById } from "../../Store/tournament/actions";
// global
var offset = 0;
var limit = 10;
var totCnt = 0;
var totPages = 0;
var toVal = 0;
var fromVal = 0;
var data: any = [];
const Teams = (props: any) => {
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const tournamentDetails = location
    ? location?.state?.tournament?.label
    : props?.tournamentDetails?.label;
  useEffect(() => {
    getAllData();
  }, []);
  let HeaderData = {
    columns: [
      {
        label: " Player Name",
        field: "name",
        width: 2,
      },
      {
        label: "Email",
        field: "email",
        width: 4,
      },
      {
        label: "Phone",
        field: "phoneNumber",
        width: 2,
      },
      {
        label: "Game Rank",
        field: "gameRank",
        width: 1,
      },
      {
        label: "Discord ID",
        field: "discordId",
        width: 2,
      },
    ],
    // rows: trips,
  };
  const getAllData = () => {
    console.log("level1");
    let offset = 0;
    setLoading(true);
    props.fetchTeams(
      offset,
      limit,
      { tournament: tournamentDetails },
      (res: any) => {
        setLoading(false);
        // if (res.success) {
        //   notifications({
        //     title: "Success!",
        //     message: res?.message,
        //     type: "success",
        //   });
        // } else {
        //   notifications({
        //     title: "Error!",
        //     message: res?.message,
        //     type: "danger",
        //   });
        // }
      }
    );
  };
  let handleChangePage = (event: any, val?: any) => {
    console.log("val", val);
    offset = (val - 1) * 10;
    toVal = offset + 10;
    setLoading(true);
    props.fetchTeams(
      offset,
      limit,
      { tournament: tournamentDetails },
      (res: any) => {
        setLoading(false);
        if (res.success) {
          notifications({
            title: "Success!",
            message: res?.message,
            type: "success",
          });
        } else {
          notifications({
            title: "Error!",
            message: res?.message,
            type: "danger",
          });
        }
      }
    );
  };

  // const handleKeyDown = (event: any) => {
  //   console.log("event", event.target);
  //   if (event.key == "Enter") {
  //     offset = 0;
  //     getAllData();
  //   }
  // };
  let deleteTeam = (ele: any) => {
    props?.deleteTeamById({ id: ele?._id }, (res: any) => {
      if (res.success) {
        getAllData();
        notifications({
          title: "Success!",
          message: res?.message,
          type: "success",
        });
      } else {
        notifications({
          title: "Error!",
          message: res?.message,
          type: "danger",
        });
      }
    });
  };
  // let action = (ele: any) => {
  //   return (
  //     <>
  //       <div>
  //         <span>
  //           <FiEye className="cursor-pointer editIcon" />
  //         </span>
  //         <span>
  //           <FiEdit3 className="cursor-pointer editIcon" />
  //         </span>
  //         <span>
  //           <MdOutlineDelete
  //             onClick={() => deleteTeam(ele)}
  //             className="deleteIcon"
  //           />
  //         </span>
  //       </div>
  //     </>
  //   );
  // };
  if (props?.teamDetails !== undefined) {
    let teamDetails = props?.teamDetails?.data;
    offset = teamDetails?.offset;
    totCnt = teamDetails?.length;
    fromVal = totCnt !== 0 ? offset + 1 : fromVal;
    toVal = teamDetails?.offset + teamDetails?.docs?.length;
    totPages = Math.ceil(totCnt / 10);
    data = [];
    props.teamDetails?.data?.docs?.map((ele: any, i: any) => {
      data.push({
        id: ele?._id,
        teamLogo: ele?.teamLogo,
        teamName: ele?.teamName,
        tournamentName: ele?.tournamentsDeatils?.label,
        users: ele?.users,
        // action: action(ele),
      });
    });
    // const dataFilter = data.filter(
    //   (v: any, i: any, a: any) => a.findIndex((v2: any) => v2.id === v.id) === i
    // );
    // data = dataFilter;
  }
  console.log("data", data);
  return (
    <>
      <div className="pageContent" >
  <div className="pageContentChild">
   <div>
   <SideBar></SideBar>
   </div>
   <div className="p-3 w-100">
   <div>
              <Link to="/tournaments">
                <button className="m-4">
                  <FiArrowLeft className="cursor-pointer editIcon " />
                </button>
              </Link>
            </div>
          <h3 className="text-white">Team Details</h3>
          {loading ? (
            <>
              <div>
                <Loader />
              </div>
            </>
          ) : (
            <>
              {totCnt ? (
                <>
                  <Row className=" me-4">
                    {data?.map((ele: any, i: any) => {
                      console.log("ele", ele);
                      return (
                        <>
                          <Row className="m-3 text-white">
                            <Col xs="3" className="d-flex">
                              <h6>Team Logo:</h6>

                              <img
                                className="ps-2"
                                src={ele?.teamLogo}
                                width="50"
                                height={50}
                                alt="TeamLogo"
                              />
                            </Col>
                            <Col xs="3" className="d-flex text-capitalize">
                              <h6>
                                Team Name:{"  "} {ele?.teamName}
                              </h6>
                            </Col>
                            <Col xs="3" className="d-flex text-capitalize">
                              <h6>
                                Tournament Name:{"  "}
                                {ele?.tournamentName}{" "}
                              </h6>
                            </Col>
                            {/* <Col xs="3">Action: {ele?.action}</Col> */}
                            <Col xs="12">
                              <Index
                                data={ele?.users}
                                headerData={HeaderData}
                                limit={limit}
                                rowHeight={"60px"}
                              />
                            </Col>
                          </Row>
                        </>
                      );
                    })}
                  </Row>
                  <div >
                    <Row >
                      <Col xs={6} className="text-white">
                        <Stack spacing={2}>
                          <Pagination
                            className="pagination"
                            size="small"
                            shape="rounded"
                            page={offset / 10 + 1}
                            count={totPages}
                            color="primary"
                            // onChange={(e)=>{handleChangePage(e,offset / 10 + 1)}}
                            onChange={(e, val) => {
                              handleChangePage(e, val);
                              console.log("event", e, val);
                            }}
                            defaultPage={1}
                            siblingCount={1}
                          />
                        </Stack>
                      </Col>
                      <Col xs={6}>
                        <span className="text-white">
                          Showing {fromVal} to {toVal} of {totCnt} entries
                        </span>
                      </Col>
                    </Row>
                  </div>
                </>
              ) : (
                <div className="NoDataDiv">
                  <span>No data exists</span>
                </div>
              )}
            </>
          )}
     </div>
     </div>
     </div>
  
    </>

  );
};

const mapStateToProps = (state: any) => {
  console.log("state", state);
  return {
    ...state,
    teamDetails: state?.tournamentReducer?.fetchTeams,
  };
};
export default connect(mapStateToProps, {
  fetchTeams,
  deleteTeamById,
})(Teams);
