import React, { useEffect, useRef, useState } from "react";
import { Card, Row, Col, Button } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import Select from "react-select";
// store
import {
  addUserTournament,
  fetchPresignedUrlTeamLogo,
  uploadImagesUserTournament,
} from "../../Store/userTournament/actions";
import notifications from "../../components/Notifications/Notification";
import { gameRankOptions } from "./gameRankOptions";
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const TeamRegister = (props: any) => {
  const navigator = useNavigate();
  const fileInputLogo: any = useRef();
  const [name, setName]: any = useState("");
  const [playerDetails, setPlayerDetails]: any = useState([]);
  const [playerForm, setPlayerForm]: any = useState({
    name: "",
    email: "",
    phoneNumber: "",
    gameRank: "",
    discordId: "",
    iGN: "",
  });
  const [count, setCount]: any = useState(
    playerDetails?.length === 0 ? 1 : playerDetails?.length
  );
  const [nextFlag, setNextFlag]: any = useState(false);
  const [baseImageLogo, setBaseImageLogo]: any = useState("");
  const [selectedFile, setSelectedFile]: any = useState();
  const [teamRegValues, setTeamRegValues]: any = useState({
    teamName: "",
    teamLogo: "",
  });
  const [errors, setErrors]: any = useState({});
  const [errorTeamName, setErrorTeamName]: any = useState();
  const [deleteFlag, setDeleteFlag]: any = useState(false);
  const [deleteData, setDeleteData]: any = useState("");
  const[regiMsg,setRegiMsg]:any=useState("")
  useEffect(() => {
    console.log(
      "playerform",
      playerForm,
      playerDetails,
      deleteFlag,
      deleteData
    );
    if (deleteFlag) {
      const deletePlayer = playerDetails.filter(
        (e: any, index: any) => deleteData?.email !== e?.email
      );

      setPlayerDetails(deletePlayer);
      setDeleteFlag(false);
      setCount(count-1)
      console.log("playerform", playerDetails, deletePlayer);
    }
  }, [playerDetails, deleteFlag, deleteData]);

  console.log("playerform", playerDetails);

  const validate = () => {
    let error = "";
    if (teamRegValues.teamName === "") {
      error = "Team name is required!";
      setErrorTeamName("Team name is required!");
    }

    return error;
  };

  let auth: any =
    localStorage.getItem("authUser") || sessionStorage.getItem("authUser");
  let authUser: any = JSON.parse(auth) || "";
  let registerMsg:any;
  useEffect(()=>{
    if(count === props.tournamentDetails?.playerInTeam){
      playerDetails.map((ele:any)=>{
        if(ele?.email != authUser?.email){
          setRegiMsg("You have to add you details while creating team")

        }else{
          setRegiMsg(" ")

        }
      })
    }
    console.log("Chandrika",regiMsg)

  },[count])
  console.log("Chandrika",regiMsg)

  useEffect(() => {
    console.log("playerform", playerForm, playerDetails);
  }, [teamRegValues, playerForm]);

  useEffect(() => {
    if (selectedFile) {
      S3Upload();
    }
    console.log("selectedFile", selectedFile);
  }, [selectedFile]);
  const handleClick = (type: any) => {
    if (type?.name === "teamRegistration") {
      setName(type?.name);
    } else if (type?.name === "playersRegistration") {
      setName(type?.name);
    } else if (type?.name === "submitForm") {
      setName(type?.name);
    }
  };
  //handle submit updates
  function handleChange(event: any) {
    const { name, value } = event.target;
    setTeamRegValues({ ...teamRegValues, [name]: value });
  }
  const fileUploadTrigger = () => {
    fileInputLogo.current.click();
  };
  const fileUpload = (event: any) => {
    console.log("EVENT console", event.target.files[0]);
    uploadImage(event);
    setSelectedFile(event.target.files[0]);
    //   S3Upload();
    console.log("selectedFile", selectedFile, event.target.files[0]);
  };
  const uploadImage = async (e: any) => {
    const file = e.target.files[0];
    const base64 = await convertBase64(file);
    setBaseImageLogo(base64);
  };

  const convertBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  const S3Upload = async () => {
    props.fetchPresignedUrlTeamLogo((res: any) => {
      console.log("res", res?.data);
      if (selectedFile) {
        let softCopy = "";
        // console.log("Calling Signed",res)
        softCopy = res.data.split("?")[0];
        console.log("softCopy", softCopy);
        setTeamRegValues({ ...teamRegValues, teamLogo: softCopy });
        const formData = new FormData();
        formData.append(selectedFile, selectedFile.name);
        console.log("Calling");
        // console.log("--------", formData);
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", selectedFile.type);
        console.log("Calling");
        props.uploadImagesUserTournament(res.data, selectedFile, myHeaders);
        console.log("Calling");
      }
    });
  };
  const validations = () => {
    let error: any = "";
    let errorObj: any = {};
    console.log("formData", playerForm, Number(playerForm.phoneNumber));
    var filter =
      /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if (playerForm?.name === "") {
      error = "required!";
      errorObj.name = "Name is Required!";
    }
    if (playerDetails?.find((user: any) => user?.email == playerForm?.email)) {
      error = "Already Exist's";
      errorObj.email = " Email already exists";
    } else if (playerForm?.email === "") {
      error = "Required!";
      errorObj.email = "Email is Required!";
    } else if (!filter.test(playerForm?.email)) {
      error = "not valid!";
      errorObj.email = "Email is not valid!";
    }
    if (
      playerDetails.find(
        (user: any) =>
          Number(user.phoneNumber) == Number(playerForm.phoneNumber)
      )
    ) {
      error = "Already exist";
      errorObj.phoneNumber = " Phone number already exists";
    } else if (playerForm.phoneNumber === "") {
      error = "Required!";
      errorObj.phoneNumber = " Phone number is Required!";
    } else if (!phoneRegExp.test(playerForm.phoneNumber)) {
      error = "Not valid";
      errorObj.phoneNumber = " Phone number is not valid";
    }
    if (playerForm.gameRank === "") {
      error = "Required!";
      errorObj.gameRank = " Game Rank is Required!";
    }
    if (playerForm.discordId === "") {
      error = "Required!";
      errorObj.discordId = " DiscordId is Required!";
    }
    if (playerForm.iGN === "") {
      error = "Required!";
      errorObj.iGN = " IGN Number is Required!";
    }
    setErrors(errorObj);
    console.log("error", errors);

    return error;
  };
  const handleChangePlayerForm = (type: any) => {
    setPlayerForm({
      ...playerForm,
      [type.name]: type.value,
    });
  };
  const handleSubmit = (e: any) => {
    e.preventDefault();
    let result: any = validations();

    if (result?.length === 0) {
      setPlayerDetails([
        ...playerDetails,
        {
          name: playerForm?.name,
          email: playerForm?.email,
          phoneNumber: Number(playerForm?.phoneNumber),
          gameRank: playerForm?.gameRank?.label,
          discordId: playerForm?.discordId,
          IGN: playerForm?.iGN,
        },
      ]);
      if (playerDetails?.length <= props.tournamentDetails?.playerInTeam) {
          setCount(count+1)
      }
      setPlayerForm({
        name: "",
        email: "",
        phoneNumber: "",
        gameRank: "",
        discordId: "",
        iGN: "",
      });
    }
  };
  return (
    <>
      <Card className="teamRegistrationCard">
        <div className="d-flex CardHeaderParent">
          <div
            className={
              name === "teamRegistration" ||
              (name !== "teamRegistration" &&
                name !== "playersRegistration" &&
                name !== "submitForm")
                ? " cardHeader cardbackgroungColor"
                : " cardHeader"
            }
            // onClick={() => {
            //   handleClick({
            //     name: "teamRegistration",
            //   });
            // }}
          >
            Team Registration
          </div>
          <div
            className={
              name === "playersRegistration"
                ? " cardHeader cardbackgroungColor"
                : " cardHeader"
            }
            // onClick={() => {
            //   handleClick({
            //     name: "playersRegistration",
            //   });
            // }}
          >
            Player Registration
          </div>
          <div
            className={
              name === "submitForm"
                ? " cardHeader cardbackgroungColor"
                : " cardHeader"
            }
            // onClick={() => {
            //   handleClick({
            //     name: "submitForm",
            //   });
            // }}
          >
            Submit Team
          </div>
          <div className="d-flex justify-content-end">
            <svg
              className="cursor-pointer ms-2 mt-2 teamRegCursor"
              onClick={() => props?.closePopUp()}
              width="24"
              height="24"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13 1L1 13"
                stroke="#FFF"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M1 1L13 13"
                stroke="#FFF"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>
        {name !== "teamRegistration" &&
        name !== "playersRegistration" &&
        name !== "submitForm" ? (
          <>
            <Row>
              <Col xs={1}></Col>
              <Col xs={10} className="TeamRegSubParentDiv">
                <div className="d-flex parentDivTeamReg">
                  <Row className="w-100">
                    <Col xs={12} md={3}>
                      <label className="labelName">
                        Team Name <span>:</span>
                      </label>
                    </Col>
                    <Col xs={12} md={9}>
                      <input
                        type={"text"}
                        id="teamName"
                        name="teamName"
                        placeholder="Team Name"
                        className="form-control inp_text text-capitalize inputStyle"
                        value={teamRegValues?.teamName}
                        onChange={(e) => handleChange(e)}
                      />
                      {errorTeamName && teamRegValues?.teamName?.length <= 0 ? (
                        <p className="RequiredField">{errorTeamName} </p>
                      ) : (
                        ""
                      )}
                    </Col>
                  </Row>
                </div>
                <div className="d-flex parentDivTeamReg">
                  <Row>
                    <Col xs={12} md={3}>
                      <label className="labelName">
                        Team Logo :<div>(Optional)</div>
                      </label>
                    </Col>
                    <Col xs={12} md={9}>
                      <div
                        className="inputStyleLogo"
                        onClick={() => fileUploadTrigger()}
                      >
                        {baseImageLogo ? (
                          <img
                            src={baseImageLogo}
                            alt="logo"
                            width="100%"
                            height="100%"
                          />
                        ) : (
                          ""
                        )}
                      </div>

                      <input
                        type="file"
                        name="Image"
                        multiple={true}
                        style={{ display: "none" }}
                        ref={fileInputLogo}
                        onChange={(e) => {
                          console.log(e.target.files);
                          fileUpload({
                            target: {
                              files: e.target.files,
                            },
                          });
                        }}
                      />
                      <span
                        style={{
                          cursor: "pointer",
                          color: "#5664D2",
                        }}
                        onClick={() => fileUploadTrigger()}
                      >
                        <svg
                          className="AddIcon"
                          width="51"
                          height="51"
                          viewBox="0 0 51 51"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M50.7061 0H0.293948C0.131605 0 0 1.60235 0 3.57895V47.4211C0 49.3977 0.131605 51 0.293948 51H50.7061C50.8684 51 51 49.3977 51 47.4211V3.57895C51 1.60235 50.8684 0 50.7061 0Z"
                            fill="#05284E"
                            fillOpacity="0.59"
                          />
                          <path
                            d="M50.7062 0.224609H0.294131C0.141934 0.224609 0.0185547 1.72681 0.0185547 3.57987V47.422C0.0185547 49.275 0.141934 50.7772 0.294131 50.7772H50.7062C50.8584 50.7772 50.9818 49.275 50.9818 47.422V3.57987C50.9818 1.72681 50.8584 0.224609 50.7062 0.224609Z"
                            fill="#05284E"
                            fillOpacity="0.59"
                          />
                          <path
                            d="M26 21V31"
                            stroke="white"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M21 26H31"
                            stroke="white"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </span>
                    </Col>
                  </Row>
                </div>
                <div className="d-flex justify-content-center">
                  <Button
                    className="registerNowBtn"
                    onClick={() => {
                      const result = validate();
                      console.log("result", result, validate(), result?.length);
                      if (result?.length === 0) {
                        setName("playersRegistration");
                      }
                    }}
                  >
                    Next
                  </Button>
                </div>
              </Col>
              <Col xs={1}></Col>
            </Row>
          </>
        ) : (
          <>
            {name === "teamRegistration" && (
              <>
                <Row>
                  <Col xs={1}></Col>
                  <Col xs={10} className="TeamRegSubParentDiv">
                    <div className="d-flex parentDivTeamReg">
                      <Row className="w-100">
                        <Col xs={12} md={3}>
                          <label className="labelName">
                            Team Name <span>:</span>
                          </label>
                        </Col>
                        <Col xs={12} md={9}>
                          <input
                            type={"text"}
                            id="teamName"
                            name="teamName"
                            placeholder="Team Name"
                            className="form-control inp_text text-capitalize inputStyle"
                            value={teamRegValues?.teamName}
                            onChange={(e) => handleChange(e)}
                          />
                          {errorTeamName &&
                          teamRegValues?.teamName?.length <= 0 ? (
                            <p className="RequiredField">{errorTeamName} </p>
                          ) : (
                            ""
                          )}
                        </Col>
                      </Row>
                    </div>
                    <div className="d-flex parentDivTeamReg">
                      <Row>
                        <Col xs={12} md={3}>
                          <label className="labelName">
                            Team Logo :<div>(Optional)</div>
                          </label>
                        </Col>
                        <Col xs={12} md={9}>
                          <div
                            className="inputStyleLogo"
                            onClick={() => fileUploadTrigger()}
                          >
                            {baseImageLogo ? (
                              <img
                                src={baseImageLogo}
                                alt="logo"
                                width="100%"
                                height="100%"
                              />
                            ) : (
                              ""
                            )}
                          </div>

                          <input
                            type="file"
                            name="Image"
                            multiple={true}
                            style={{ display: "none" }}
                            ref={fileInputLogo}
                            onChange={(e) => {
                              console.log(e.target.files);
                              fileUpload({
                                target: {
                                  files: e.target.files,
                                },
                              });
                            }}
                          />
                          <span
                            style={{
                              cursor: "pointer",
                              color: "#5664D2",
                            }}
                            onClick={() => fileUploadTrigger()}
                          >
                            <svg
                              className="AddIcon"
                              width="51"
                              height="51"
                              viewBox="0 0 51 51"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M50.7061 0H0.293948C0.131605 0 0 1.60235 0 3.57895V47.4211C0 49.3977 0.131605 51 0.293948 51H50.7061C50.8684 51 51 49.3977 51 47.4211V3.57895C51 1.60235 50.8684 0 50.7061 0Z"
                                fill="#05284E"
                                fillOpacity="0.59"
                              />
                              <path
                                d="M50.7062 0.224609H0.294131C0.141934 0.224609 0.0185547 1.72681 0.0185547 3.57987V47.422C0.0185547 49.275 0.141934 50.7772 0.294131 50.7772H50.7062C50.8584 50.7772 50.9818 49.275 50.9818 47.422V3.57987C50.9818 1.72681 50.8584 0.224609 50.7062 0.224609Z"
                                fill="#05284E"
                                fillOpacity="0.59"
                              />
                              <path
                                d="M26 21V31"
                                stroke="white"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M21 26H31"
                                stroke="white"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </span>
                        </Col>
                      </Row>
                    </div>
                    <div className="d-flex justify-content-center">
                      <Button
                        className="registerNowBtn"
                        onClick={() => {
                          const result = validate();
                          console.log(
                            "result",
                            result,
                            validate(),
                            result?.length
                          );
                          if (result?.length === 0) {
                            setName("playersRegistration");
                          }
                        }}
                      >
                        Next
                      </Button>
                    </div>
                  </Col>
                  <Col xs={1}></Col>
                </Row>
              </>
            )}
            {name === "playersRegistration" && (
              <>
                <Row>
                  <Col xs={1}></Col>
                  <Col xs={10} className="TeamRegSubParentDiv">
                    <div className="mt-3 text-white NoteText">
                      <span className="me-2" >Note:</span>
                      Your are the Captain.But, You have to add your details also
                      </div>
                      <div className="mt-3 text-white NoteText1">
                      {
                        regiMsg?.length >0 ?
                        <>
                        <span className="me-2" >Warning:</span>
                        {regiMsg}</>:""
                      }
                      </div>
                    <div>
                      
                      <h2 className="text-white mt-3">
                        {" "}
                        Player {count} Details
                      </h2>
                    </div>
                    <div className="RequiredField">
                      {playerDetails?.length >=
                      props.tournamentDetails?.playerInTeam
                        ? "Number of players are exceeded in team"
                        : ""}
                        {
                          registerMsg?.length > 0 ?
                          registerMsg :""
                        }
                    </div>
                    <form noValidate onSubmit={handleSubmit}>
                      <div className="d-flex parentDiv">
                        <Row className="w-100">
                          <Col xs={12} md={2}>
                            <label className="labelName">Name :</label>
                          </Col>
                          <Col xs={12} md={10}>
                            <input
                              type="text"
                              name="name"
                              onChange={(e: any) => {
                                handleChangePlayerForm({
                                  name: "name",
                                  value: e.target.value,
                                });
                              }}
                              value={playerForm.name}
                              placeholder="Name"
                              className="form-control inp_text text-capitalize inputStyle"
                            />
                            {errors.name && playerForm?.name === "" ? (
                              <p className="RequiredField">{errors?.name} </p>
                            ) : (
                              ""
                            )}
                          </Col>
                        </Row>
                      </div>
                      <div className="d-flex parentDiv">
                        <Row className="w-100">
                          <Col xs={12} md={2}>
                            <label className="labelName">Phone:</label>
                          </Col>
                          <Col xs={12} md={10}>
                            <input
                              {...props}
                              type="tel"
                              id="phoneVal"
                              maxLength="10"
                              name="phoneNumber"
                              onChange={(e: any) => {
                                handleChangePlayerForm({
                                  name: "phoneNumber",
                                  value: e.target.value,
                                });
                              }}
                              value={playerForm.phoneNumber}
                              placeholder="Phone"
                              className="form-control inp_text inputStyle"
                              pattern="\d{10}"
                            />
                            {errors.phoneNumber &&
                            (playerForm?.phoneNumber === "" ||
                              playerForm.phoneNumber?.length) ? (
                              <p className="RequiredField">
                                {errors?.phoneNumber}{" "}
                              </p>
                            ) : (
                              ""
                            )}
                          </Col>
                        </Row>
                      </div>{" "}
                      <div className="d-flex parentDiv">
                        <Row className="w-100">
                          <Col xs={12} md={2}>
                            <label className="labelName">Email:</label>
                          </Col>
                          <Col xs={12} md={10}>
                            <input
                              type="emai"
                              name="email"
                              onChange={(e: any) => {
                                handleChangePlayerForm({
                                  name: "email",
                                  value: e.target.value,
                                });
                              }}
                              value={playerForm.email}
                              placeholder="Enter email"
                              className="form-control inp_text inputStyle text-lowercase"
                              id="email"
                            />
                            {errors.email &&
                            (playerForm?.email === "" ||
                              playerForm?.email.length) ? (
                              <p className="RequiredField">{errors?.email} </p>
                            ) : (
                              ""
                            )}
                          </Col>
                        </Row>
                      </div>
                      <div className="d-flex parentDiv">
                        <Row className="w-100">
                          <Col xs={12} md={2}>
                            <label className="labelName">IGN:</label>
                          </Col>
                          <Col xs={12} md={10}>
                            <input
                              type="text"
                              name="iGN"
                              onChange={(e: any) => {
                                handleChangePlayerForm({
                                  name: "iGN",
                                  value: e.target.value,
                                });
                              }}
                              value={playerForm.iGN}
                              placeholder="IGN..."
                              className="form-control inp_text text-capitalize inputStyle"
                            />
                            {errors.iGN && playerForm?.iGN === "" ? (
                              <p className="RequiredField">{errors?.iGN} </p>
                            ) : (
                              ""
                            )}
                          </Col>
                        </Row>
                      </div>
                      <div className="d-flex parentDiv">
                        <Row className="w-100">
                          <Col xs={12} md={2}>
                            <label className="labelName">Rank:</label>
                          </Col>
                          <Col xs={12} md={10}>
                            <Select
                              {...props}
                              className="GameRank_selector text-white"
                              classNamePrefix="react-select1"
                              placeholder="Game Rank"
                              autoFocus={true}
                              isSearchable
                              name="gameRank"
                              id="gameRank"
                              value={playerForm.gameRank}
                              options={gameRankOptions}
                              onChange={(e: any) => {
                                handleChangePlayerForm({
                                  name: "gameRank",
                                  value: e,
                                });
                              }}
                            />

                            {errors.gameRank && playerForm?.gameRank === "" ? (
                              <p className="RequiredField">
                                {errors?.gameRank}{" "}
                              </p>
                            ) : (
                              ""
                            )}
                          </Col>
                        </Row>
                      </div>
                      <div className="d-flex parentDiv">
                        <Row className="w-100">
                          <Col xs={12} md={2}>
                            <label className="labelName">DiscordID:</label>
                          </Col>
                          <Col xs={12} md={10}>
                            <input
                              type="text"
                              name="discordId"
                              onChange={(e: any) => {
                                handleChangePlayerForm({
                                  name: "discordId",
                                  value: e.target.value,
                                });
                              }}
                              value={playerForm.discordId}
                              placeholder="Discord Id..."
                              className="form-control inp_text text-capitalize inputStyle"
                            />
                            {errors.discordId &&
                            playerForm?.discordId === "" ? (
                              <p className="RequiredField">
                                {errors?.discordId}{" "}
                              </p>
                            ) : (
                              ""
                            )}
                          </Col>
                        </Row>
                      </div>
                      <div className="d-flex justify-content-center">
                        <button 
                        disabled={props.tournamentDetails?.playerInTeam=== playerDetails?.length}
                        className="addBtn m-2" 
                        type="submit">
                          Add
                        </button>
                      </div>
                    </form>

                    <div className="d-flex justify-content-center">
                      {nextFlag && playerDetails?.length <= 0 ? (
                        <p className="RequiredField">
                          ***Please Add atleast one player details***
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="BackNextBtn">
                      <button
                        className="addBtn m-2"
                        onClick={() => {
                          handleClick({
                            name: "teamRegistration",
                          });
                        }}
                      >
                        Back
                      </button>
                      <button
                        className="addBtn m-2"
                        onClick={() => {
                          setNextFlag(true);
                          if (playerDetails?.length > 0) {
                            handleClick({
                              name: "submitForm",
                            });
                          }
                        }}
                      >
                        Next
                      </button>
                    </div>
                  </Col>
                  <Col xs={1}></Col>
                </Row>
              </>
            )}
            {name === "submitForm" && (
              <>
              <div className="confirm">
                <div className="d-flex justify-content-center text-center text-white mt-5 ">
                  <h1>
                    These Players will be sent an invite <br />
                    to join {teamRegValues?.teamName}
                  </h1>
                </div>
                <div
                  className={
                    playerDetails?.length > 5
                      ? "d-grid GridContentStyle GridContentStyle1 justify-content-center text-white mt-5 mb-5"
                      : "d-grid justify-content-center text-white mt-5 mb-5"
                  }
                >
                  {playerDetails?.length > 0
                    ? playerDetails.map((ele: any, i: any) => {
                        return (
                          <React.Fragment key={1}>
                            <h1>
                              {i + 1}. {""} {ele?.name}
                         
                              <svg
                                {...props}
                                className="cursor-pointer ms-2"
                                onClick={(e: any) => {
                                  setDeleteData(ele);
                                  setDeleteFlag(true);
                                }}
                                width="24"
                                height="24"
                                viewBox="0 0 14 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M13 1L1 13"
                                  stroke="red"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M1 1L13 13"
                                  stroke="red"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </h1>
                          </React.Fragment>
                        );
                      })
                    : ""}
                </div>
                <div className="d-flex justify-content-center mt-5 mb-4">
                  <button
                    className="addBtn me-2"
                    onClick={() => {
                      handleClick({
                        name: "playersRegistration",
                      });
                    }}
                  >
                    Back
                  </button>

                  <button
                    className="addBtn"
                    onClick={() => {
                      let payload: any = {
                        tournamentId: props?.tournamentId,
                        teamLogo: teamRegValues?.teamLogo,
                        teamName: teamRegValues?.teamName,
                        users: [...playerDetails],
                      };
                      console.log("payload", payload);
                      if (
                        teamRegValues?.teamName &&
                        playerDetails &&
                        props?.tournamentId
                      ) {
                        props?.addUserTournament(payload, (res: any) => {
                          if (res.success) {
                            // navigator("/teams")
                            notifications({
                              title: "Success!",
                              message: res?.message,
                              type: "success",
                            });
                            navigator("/tournament-registration-completed");
                            window.scrollTo(0, 0)

                          } else {
                            notifications({
                              title: "Error!",
                              message: res?.message,
                              type: "danger",
                            });
                          }
                        });
                      }
                    }}
                  >
                    Confirm
                  </button>
                </div>
                </div>
              </>
            )}
          </>
        )}
      </Card>
    </>
  );
};
const mapStateToProps = (state: any) => {
  console.log("state", state);
  return {
    ...state,
  };
};
export default connect(mapStateToProps, {
  addUserTournament,
  uploadImagesUserTournament,
  fetchPresignedUrlTeamLogo,
})(TeamRegister);
