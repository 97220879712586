import { types } from "./actionTypes";
const initialState = {
  addMatch: null,
  matchDetail: null,
  updateDetails: null,
  deleteMatchs: null,
  getProfileScoresFromMatch:null
};
const matchReducer = (state: any = initialState, action: any) => {
  // console.log("action",action.payload)
  switch (action.type) {
    case types.ADD_MATCH_SUCCESS:
      state = {
        ...state,
        addMatch: action.payload,
      };
      return state;
    case types.FETCH_MATCHS_SUCCESS:
      state = {
        ...state,
        matchDetail: action.payload,
      };
      return state;
    case types.EDIT_MATCH_SUCCESS:
      state = {
        ...state,
        updateDetails: action.payload,
      };
      return state;
    case types.DELETE_MATCH_SUCCESS:
      state = {
        ...state,
        deleteMatchs: action.payload,
      };
      return state;
      case types.GET_PROFILE_SCORES_MATCHCHES_SUCCESS:
      state = {
        ...state,
        getProfileScoresFromMatch: action.payload,
      };
      return state;
    default:
      return state;
  }
};

export default matchReducer;
