import React, { Component } from "react";
import Slider from "react-slick";
import { Card, Grid } from "@mui/material";
import { Row, Col, Container } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import Assest1 from "../../assets/images/Asset1.png";
import "./TournamentTheme.scss";
import Loader from "../../components/Loader/Loader";

const Carousel = (props: any) => {
  const navigator = useNavigate();
  let upcomingEventData = props.upcomingEventData?.length;
  const settings = {
    // dots: true,
    infinite: upcomingEventData > 3 ? true : false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: upcomingEventData > 3 ? true : false,
          dots: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 580,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  let auth: any =
    localStorage.getItem("authUser") || sessionStorage.getItem("authUser");
  let authUser: any = JSON.parse(auth) || "";
  return (
    <>
      {props.loading ? (
        <>
          <Loader />
        </>
      ) : (
        <>
          <div className="OnGoingSlider ">
            {props.upcomingEventData?.length ? (
              <>
                <Slider {...settings}>
                  {props.upcomingEventData.map((tournament: any, i: any) => {
                    // console.log("tournament", tournament);
                    return (
                      <React.Fragment key={i}>
                        <Card className="TournamentsCarousal">
                          <div className="row">
                            <div className="col-xl-12">
                              <img
                                className="carosualImage"
                                src={tournament?.logo}
                                alt="TournamentLogo"

                              />
                            </div>
                            <div className="CarousalContent">
                              <div className="row">
                                <h4 className="col-xl-12 TeamHeading text-capitalize">
                                  {tournament?.label}
                                </h4>
                                {/* <h4 className="col-xl-12 VsHeading">Vs</h4> */}
                                <h4 className="col-xl-12 TeamHeading">
                                  {tournament?.startDate}
                                </h4>
                                <div className="col-xl-3"></div>
                                <div className="col-xl-6">
                                  <button
                                    type="button"
                                    className=" text-capitalize btn btn-danger mt-3 text-uppercase mb-4 ResgisterButton"
                                    style={{ width: "100%" }}
                                    onClick={() => {
                                      // if (authUser) {
                                        navigator(
                                          "/tournament-team-registration",
                                          {
                                            state: {
                                              tournamentId: tournament?._id,
                                            },
                                          }
                                        );
                                        window.scrollTo(0, 0)

                                      // } else {
                                      //   props.openLoginPopUp();
                                      // }
                                    }}
                                  >
                                    register
                                  </button>
                                </div>
                                <div className="col-xl-3"></div>
                              </div>
                            </div>
                          </div>
                        </Card>
                      </React.Fragment>
                    );
                  })}
                </Slider>
              </>
            ) : (
              <>
                <div className="d-flex justify-content-center">
                  <h3 className="p-5 noTournamentDiv">
                    No Upcoming Tournaments
                  </h3>
                </div>
              </>
            )}
          </div>
          {/* <div className="OnGoingSlider mobileDisplay">
            {props.upcomingEventData?.length ? (
              <>
                {props.upcomingEventData.map((tournament: any, i: any) => {
                  // console.log("tournament", tournament);
                  return (
                    <React.Fragment key={i}>
                      <Row>
                        <Col xs={1} sm={2}></Col>
                        <Col xs={10} sm={8} className="p-4">
                          <Card className="TournamentsCarousal">
                            <Row>
                              <Col xs={12} >
                                <img
                                  className="carosualImage"
                                  src={tournament?.logo}
                                  alt="TournamentLogo"
                                  
                                />
                                <div className="CarousalContent">
                                  <div className="row">
                                    <h4 className="col-xl-12 TeamHeading text-capitalize">
                                      {tournament?.label}
                                    </h4>
                                    <h4 className="col-xl-12 TeamHeading">
                                      {tournament?.startDate}
                                    </h4>
                                    <div className="col-xl-8">
                                      <button
                                        type="button"
                                        className=" text-capitalize btn btn-danger mt-3 text-uppercase mb-4 ResgisterButton"
                                        style={{ width: "100%" }}
                                        onClick={() => {
                                          // if (authUser) {
                                            navigator(
                                              "/tournament-team-registration",
                                              {
                                                state: {
                                                  tournamentId: tournament?._id,
                                                },
                                              }
                                            );
                                          // } else {
                                          //   props.openLoginPopUp();
                                          // }
                                        }}
                                      >
                                        register
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </Col>
                              <Col xs={1} sm={2}></Col>
                            </Row>
                          </Card>
                        </Col>
                      </Row>
                    </React.Fragment>
                  );
                })}
              </>
            ) : (
              <>
                <div className="d-flex justify-content-center">
                  <h3 className="p-5 noTournamentDiv">
                    No Upcoming Tournaments
                  </h3>
                </div>
              </>
            )}
          </div> */}
        </>
      )}
    </>
  );
};
export default Carousel;
