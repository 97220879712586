import { useState } from "react";
import SideBar from "../../components/verticalMenu/SideBar";
import { Row, Col, Button } from "reactstrap";
import { useLocation, Link } from "react-router-dom";
import { FiArrowLeft } from "react-icons/fi";
import Select from "react-select";
import { connect } from "react-redux";
// store
import { updateUserProfile } from "../../Store/user/actions";
// components
import notifications from "../../components/Notifications/Notification";
const gameRankOptions: any = [
  {
    value: "Iron",
    label: "Iron",
  },
  {
    value: "Bronze",
    label: "Bronze",
  },
  {
    value: "Silver",
    label: "Silver",
  },
  {
    value: "Gold",
    label: "Gold",
  },
  {
    value: "Platinum",
    label: "Platinum",
  },
  {
    value: "Diamond",
    label: "Diamond",
  },
  {
    value: "Ascendant",
    label: "Ascendant",
  },
  {
    value: "Immortal",
    label: "Immortal",
  },
  {
    value: "Radiant",
    label: "Radiant",
  },
];

const UserProfile = (props: any) => {
  const location = useLocation();

  // console.log("Location",location)
 
  let userDetails: any = location?.state?.userDetails;
  const [form, setForm]: any = useState({
    fullName: userDetails?.fullName,
    email: userDetails?.email,
    phone: userDetails?.phone,
    role: userDetails?.role,
    IGN: userDetails?.IGN,
    discordId: userDetails?.discordId,
    gameRank: userDetails?.gameRank
      ? { value: userDetails?.gameRank, label: userDetails?.gameRank }
      : "",
  });
  const handleSubmit = () => {
    let payload: any = {
      userId: userDetails?._id,
      fullName: form?.fullName,
      email: form?.email,
      phone: Number(form?.phone),
      role: form?.role,
      IGN: form?.IGN,
      discordId: form?.discordId,
      gameRank: form?.gameRank?.value,
    };
    console.log("Payload", payload);
    props?.updateUserProfile(payload, (res: any) => {
      if (res.success) {
        notifications({
          title: "Success!",
          message: res?.message,
          type: "success",
        });
      } else {
        notifications({
          title: "Error!",
          message: res?.message,
          type: "danger",
        });
      }
    });
  };
  const handleSubmitChange = (type: any) => {
    console.log("type", type);
    if (type?.name === "IGN") {
      setForm({ ...form, IGN: type?.value });
    } else if (type?.name === "discordId") {
      setForm({ ...form, discordId: type?.value });
    } else if (type?.name === "gameRank") {
      setForm({ ...form, gameRank: type?.value });
    } else if (type?.name === "phone") {
      setForm({ ...form, phone: type?.value });
    }
  };
  console.log("form", form);
  return (
    <>
      
      <div className="pageContent">
        <div className="pageContentChild">
          <div>
            <SideBar></SideBar>
          </div>
          <div className="p-3 w-100">
          { location?.state?.userDetails ? (
              <div>
                <Link to={"/users"}>
                  <button className="m-4">
                    <FiArrowLeft className="cursor-pointer editIcon " />
                  </button>
                </Link>
              </div>
            ) : (
              ""
            )}
            <h3>User Profile</h3>
            <Row className="mt-3">
              <Col xs="10">
                <Row>
                  <Col xs={12} md={6} className="p-3">
                    <label className="mb-3 text-white">Name</label>
                    <input
                      className="text-white"
                      type="string"
                      name="name"
                      value={form?.fullName}
                      readOnly
                    />
                  </Col>
                  <Col xs={12} md={6} className="p-3">
                    <label className="mb-3 text-white">Email</label>
                    <input
                      className="text-white"
                      type="string"
                      name="email"
                      value={userDetails?.email}
                      readOnly
                    />
                  </Col>

                  <Col xs={12} md={6} className="p-3">
                    <label className="mb-3 text-white">Role</label>
                    <input
                      className="text-white"
                      type="string"
                      name="role"
                      value={form?.role}
                      readOnly
                    />
                  </Col>
                  <Col xs={12} md={6} className="p-3">
                    <label className="mb-3 text-white">Phone Number</label>
                    <input
                      className="text-white"
                      type="number"
                      name="phoneNumber"
                      placeholder="Phone No..."
                      value={form?.phone}
                      onChange={(e: any) => {
                        // console.log("e test", e);
                        handleSubmitChange({
                          name: "phone",
                          value: e.target.value,
                        });
                      }}
                    />
                  </Col>
                  <Col xs={12} md={6} className="p-3">
                    <label className="mb-3 text-white">IGN</label>
                    <input
                      className="text-white"
                      type="string"
                      placeholder="IGN..."
                      name="IGN"
                      value={form?.IGN ? form?.IGN : ""}
                      onChange={(e: any) => {
                        // console.log("e test", e);
                        handleSubmitChange({
                          name: "IGN",
                          value: e.target.value,
                        });
                      }}
                    />
                  </Col>
                  <Col xs={12} md={6} className="p-3">
                    <label className="mb-3 text-white">Game Rank</label>

                    <Select
                      className="Game_selector"
                      classNamePrefix="react-select"
                      placeholder="Game Rank..."
                      autoFocus={true}
                      // onBlur={handleBlur}
                      isSearchable
                      name="gameRank"
                      id="gameRank"
                      value={form?.gameRank || "N/A"}
                      options={gameRankOptions}
                      onChange={(e: any) => {
                        // console.log("e test", e);
                        handleSubmitChange({
                          name: "gameRank",
                          value: e,
                        });
                      }}
                    />
                  </Col>
                  <Col xs={12} md={6} className="p-3">
                    <label className="mb-3 text-white">DiscordId</label>
                    <input
                      className="text-white"
                      type="string"
                      name="role"
                      placeholder="DiscordId..."
                      value={form?.discordId ? form?.discordId : ""}
                      onChange={(e: any) => {
                        // console.log("e test", e);
                        handleSubmitChange({
                          name: "discordId",
                          value: e.target.value,
                        });
                      }}
                    />
                  </Col>
                  <Col xs={12} md={6} className="p-3">
                    <Button
                      className="ps-5 pe-5  mt-5 w-50"
                      onClick={() => handleSubmit()}
                    >
                      Save
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Col xs="1"></Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  console.log("state", state);
  return {
    ...state,
  };
};
export default connect(mapStateToProps, {
  updateUserProfile,
})(UserProfile);
