import { types } from "./actionTypes";
const initialState = {
 createOrderDetails:null,
 verifyOrderDetails:null
};
const razarPayReducer = (state: any = initialState, action: any) => {
  // console.log("action",action.payload)
  switch (action.type) {
    case types.CREATE_ORDER_SUCCESS:
      state = {
        ...state,
        createOrderDetails: action.payload,
      };
      return state;
      case types.VERIFY_ORDER_SUCCESS:
        state = {
          ...state,
          verifyOrderDetails: action.payload,
        };
        return state;
     
    default:
      return state;
  }

};

export default razarPayReducer;
