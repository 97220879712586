import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Row, Col, Button, Input } from "reactstrap";
import notifications from "../../components/Notifications/Notification";
import { Card, Grid, Pagination, Stack } from "@mui/material";
import Select from "react-select";
import SideBar from "../../components/verticalMenu/SideBar";
import Loader from "../../components/Loader/Loader";
// store
import { fetchTournament } from "../../Store/tournament/actions";
import { tournamentUserStatus } from "../../Store/user/actions";
import { fetchInvitedPlayers } from "../../Store/userTournament/actions";
// global
var offset = 0;
var limit = 10;
var totCnt = 0;
var totPages = 0;
var toVal = 0;
var fromVal = 0;
var data: any = [];
let touranmentOptions: any = [];
const InvitedPlayers = (props: any) => {
  const [teamNameFilter, setTeamNameFilter]: any = useState("");
  const [tournamentNameFilter, setTournamentNameFilter]: any = useState("");
  const [resetFlag, setResetFlag]: any = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getAllData();
    getAllTournamentData();
  }, []);
  useEffect(() => {
    if (tournamentNameFilter || (!teamNameFilter && resetFlag)) {
      getAllData();
    }
  }, [tournamentNameFilter, resetFlag]);
  let HeaderData = {
    columns: [
      {
        label: " Player Name",
        field: "name",
        width: 1,
      },
      {
        label: "Email",
        field: "email",
        width: 1,
      },
      {
        label: "Phone",
        field: "phone",
        width: 2,
      },
      {
        label: "Game Rank",
        field: "gameRank",
        width: 2,
      },
      {
        label: "Discord ID",
        field: "discordId",
        width: 2,
      },
      {
        label: "Status",
        field: "status",
        width: 2,
      },
    ],
    // rows: trips,
  };
  const getAllData = () => {
    let offset = 0;
    setLoading(true)
    props.fetchInvitedPlayers(
      offset,
      limit,
      { teamName: teamNameFilter, tournamentId: tournamentNameFilter?.value },
      (res: any) => {
        setLoading(false)
        if (res.success) {
          notifications({
            title: "Success!",
            message: res?.message,
            type: "success",
          });
        } else {
          notifications({
            title: "Error!",
            message: res?.message,
            type: "danger",
          });
        }
      }
    );
  };
  let getAllTournamentData = () => {
    props.fetchTournament(0, 10, {}, (res: any) => {});
  };
  let handleChangePage = (event: any, val?: any) => {
    console.log("val", val);
    offset = (val - 1) * 10;
    toVal = offset + 10;
    setLoading(true)
    props.fetchInvitedPlayers(
      offset,
      limit,
      { teamName: teamNameFilter, tournamentId: tournamentNameFilter?.value },
      (res: any) => {
        setLoading(false)
        if (res.success) {
          notifications({
            title: "Success!",
            message: res?.message,
            type: "success",
          });
        } else {
          notifications({
            title: "Error!",
            message: res?.message,
            type: "danger",
          });
        }
      }
    );
  };
  const handleChangeName = (type: any) => {
    console.log("type", type);
    setTeamNameFilter(type?.value);
  };
  const handleKeyDown = (event: any) => {
    console.log("event", event.target);
    if (event.key == "Enter") {
      offset = 0;
      getAllData();
    }
  };
  const handleOnInputChangeGame = (e: any) => {
    if (e.length > 2) {
      props.fetchTournament(0, 10, { name: e }, (res: any) => {});
    }
  };
  const handleReset = () => {
    setTeamNameFilter("");
    setTournamentNameFilter("");
    setResetFlag(true);
    if (teamNameFilter) {
      offset = 0;
      props.fetchInvitedPlayers(0, limit, {}, (res: any) => {});
    }
  };
  const handleSubmitChange = (type: any) => {
    if (type?.name == "tournamentId") {
      setTournamentNameFilter(type?.value);
    }
  };
  const userStatusAccept = (ele: any) => {
    props.tournamentUserStatus(
      {
        tournamentId: ele?.tournamentId,
        teamName: ele?.teamName,
        status: "accept",
      },
      (res: any) => {
        if (res.success) {
          notifications({
            title: "Success!",
            message: res?.message,
            type: "success",
          });
          getAllData();
        } else {
          notifications({
            title: "Error!",
            message: res?.message,
            type: "danger",
          });
        }
      }
    );
  };
  const userStatusReject = (ele: any) => {
    props.tournamentUserStatus(
      {
        tournamentId: ele?.tournamentId,
        teamName: ele?.teamName,
        status: "reject",
      },
      (res: any) => {
        if (res.success) {
          notifications({
            title: "Success!",
            message: res?.message,
            type: "success",
          });
          getAllData();
        } else {
          notifications({
            title: "Error!",
            message: res?.message,
            type: "danger",
          });
        }
      }
    );
  };
  let auth: any = localStorage.getItem("authUser") || sessionStorage.getItem("authUser");
  let authUser: any = JSON.parse(auth) || "";
  const action = (ele: any) => {
    return (
      <>
        {ele?.users?.map((user: any, i: any) => {
          if (user?.user === authUser?._id) {
            console.log("user", user);
            return (
              <React.Fragment key={i}>
                {user?.acceptanceStatus === "pending" ? (
                  <div>
                    <div className="UserButtons">
                      <button
                        className=" ps-4 pe-4"
                        style={{
                          backgroundColor: "blue",
                          color: "#FFF",
                        }}
                        onClick={() => {
                          userStatusAccept(ele);
                        }}
                      >
                        Accept
                      </button>
                      <button
                        className="ps-4 pe-4"
                        style={{
                          backgroundColor: "red",
                          color: "#FFF",
                        }}
                        onClick={() => {
                          userStatusReject(ele);
                        }}
                      >
                        Reject
                      </button>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </React.Fragment>
            );
          }
        })}
      </>
    );
  };
  if (
    props?.InvitedPlayerDetails !== undefined ||
    props?.InvitedPlayerDetails !== null
  ) {
    let InvitedPlayerDetails = props?.InvitedPlayerDetails?.data;
    offset = InvitedPlayerDetails?.offset;
    totCnt = InvitedPlayerDetails?.length;
    fromVal = totCnt !== 0 ? offset + 1 : fromVal;
    toVal = InvitedPlayerDetails?.offset + InvitedPlayerDetails?.docs?.length;
    totPages = Math.ceil(totCnt / 10);
    data = [];
    props.InvitedPlayerDetails?.data?.docs?.map((ele: any, i: any) => {
      data.push({
        id: ele?._id,
        teamLogo: ele?.teamLogo,
        teamName: ele?.teamName,
        users: ele?.users,
        action: action(ele),
      });
    });
  }
  touranmentOptions = [];
  if (props.tournamentDetails !== undefined) {
    let tournamentDetails = props?.tournamentDetails?.data;
    tournamentDetails?.docs?.map((ele: any, i: any) => {
      touranmentOptions.push({
        value: ele?._id,
        label: ele?.label,
      });
    });
  }
  console.log("data", data);
  return (
    <div
      style={{
        minHeight: "100vh",
        backgroundColor: "#000",
      }}
    >
      <Grid container spacing={4}>
        <Grid item xs={12} sm={3} md={2}>
          <SideBar></SideBar>
        </Grid>
        <Grid item xs={12} sm={9} md={10} className="mt-3">
        {loading ? (
              <>
                <div>
                  <Loader />
                </div>
              </>):<>
              <Row className="ms-4">
            <Col xs={2}>
              <Input
                className="form-control"
                type="text"
                name="tournamentName"
                placeholder="Search By Team Name..."
                aria-label="Search"
                value={teamNameFilter}
                onKeyDown={(event: any) => handleKeyDown(event)}
                onChange={(e) => {
                  console.log("e", e.target.value);
                  handleChangeName({
                    value: e.target.value,
                  });
                }}
              />
            </Col>
            <Col xs="4">
              <Select
                className="Game_selector"
                classNamePrefix="react-select"
                placeholder="Select Tournament..."
                autoFocus={true}
                isSearchable
                name="tournamentId"
                value={tournamentNameFilter}
                options={touranmentOptions}
                onChange={(e: any) => {
                  console.log("e test", e);
                  handleSubmitChange({
                    name: "tournamentId",
                    value: e,
                  });
                }}
                onInputChange={(e) => handleOnInputChangeGame(e)}
              />
            </Col>
            <Col xs={1}>
              {" "}
              <button
                onClick={() => handleReset()}
                style={{
                  color: "black",
                  background: "Green",
                  height: "40px",
                  width: "100%",
                  padding: "10px 20px",
                }}
              >
                Reset
              </button>
            </Col>
          </Row>
          {totCnt ? (
            <>
            <h3>Invitation</h3>
              <Row className="ms-4 me-4">
                {data?.map((ele: any, i: any) => {
                  console.log("ele", ele);
                  return (
                    <>
                      <Row className="m-3">
                        <Col xs="4">
                          Team Logo:
                          <img src={ele?.teamLogo} width="50" height={50} alt="Team Logo" />
                        </Col>
                        <Col xs="">Team Name: {ele?.teamName}</Col>
                        <Col xs="5 ">Action: {ele?.action}</Col>
                        <Col xs="12">
                          <Row className="HeaderRow">
                            {HeaderData?.columns?.map((headItem, index) => {
                              return (
                                <Col
                                  className="IndexDatatableNeoCol"
                                  key={index}
                                  style={{
                                    fontSize: "15px",
                                    fontWeight: "700",
                                    lineHeight: "18px",
                                    fontFamily: "Roboto",
                                  }}
                                >
                                  {headItem.label}{" "}
                                </Col>
                              );
                            })}
                          </Row>
                            {ele?.users?.map((ele: any, index: any) => {
                              return (
                                <React.Fragment key={index}>
                                  <Row className="ContentRow">
                                  <Col
                                    xs={2}
                                    className="IndexDatatableNeoCol"
                                    style={{
                                      fontSize: "15px",
                                      fontWeight: "700",
                                      lineHeight: "18px",
                                      fontFamily: "Roboto",
                                    }}
                                  >
                                    {ele?.name}
                                  </Col>
                                  <Col
                                    xs={3}
                                    className="IndexDatatableNeoCol"
                                    style={{
                                      fontSize: "15px",
                                      fontWeight: "700",
                                      lineHeight: "18px",
                                      fontFamily: "Roboto",
                                    }}
                                  >
                                    {ele?.email}
                                  </Col>
                                  <Col
                                    xs={2}
                                    className="IndexDatatableNeoCol"
                                    style={{
                                      fontSize: "15px",
                                      fontWeight: "700",
                                      lineHeight: "18px",
                                      fontFamily: "Roboto",
                                    }}
                                  >
                                    {ele?.phoneNumber}
                                  </Col>
                                  <Col
                                    xs={2}
                                    className="IndexDatatableNeoCol"
                                    style={{
                                      fontSize: "15px",
                                      fontWeight: "700",
                                      lineHeight: "18px",
                                      fontFamily: "Roboto",
                                    }}
                                  >
                                    {ele?.gameRank}
                                  </Col>
                                  <Col
                                    xs={2}
                                    className="IndexDatatableNeoCol"
                                    style={{
                                      fontSize: "15px",
                                      fontWeight: "700",
                                      lineHeight: "18px",
                                      fontFamily: "Roboto",
                                    }}
                                  >
                                    {ele?.discordId}
                                  </Col>
                                  <Col
                                    xs={1}
                                    className="IndexDatatableNeoCol"
                                    style={{
                                      fontSize: "15px",
                                      fontWeight: "700",
                                      lineHeight: "18px",
                                      fontFamily: "Roboto",
                                    }}
                                  >
                                    {ele?.acceptanceStatus}
                                  </Col>
                                  </Row>
                                </React.Fragment>
                              );
                            })}
                        </Col>
                      </Row>
                    </>
                  );
                })}
              </Row>
              <div className=" m-2">
                <Row className="m-4">
                  <Col xs={6} className="text-white">
                    <Stack spacing={2}>
                      <Pagination
                        className="pagination"
                        size="small"
                        shape="rounded"
                        page={offset / 10 + 1}
                        count={totPages}
                        color="primary"
                        // onChange={(e)=>{handleChangePage(e,offset / 10 + 1)}}
                        onChange={(e, val) => {
                          handleChangePage(e, val);
                          console.log("event", e, val);
                        }}
                        defaultPage={1}
                        siblingCount={1}
                      />
                    </Stack>
                  </Col>
                  <Col xs={6}>
                    <span className="text-white">
                      Showing {fromVal} to {toVal} of {totCnt} entries
                    </span>
                  </Col>
                </Row>
              </div>
            </>
          ) : (
            <div className="NoDataDiv">
              <span>No data exists</span>
            </div>
          )}
              </>}
        
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  console.log("state", state);
  return {
    ...state,
    tournamentDetails: state?.tournamentReducer?.tournamentDetail,
    InvitedPlayerDetails: state?.userTournamentReducer?.invitedPlayers,
  };
};
export default connect(mapStateToProps, {
  fetchInvitedPlayers,
  fetchTournament,
  tournamentUserStatus,
})(InvitedPlayers);
