import React from "react";
import { Row, Col, CardTitle, Card } from "reactstrap";

let dataSet: any = [];
let processedData: any = [];
const index = (props: any) => {
  let headerData = props.headerData;
  // useEffect(()=>{

  // })
  const binder = (dataItem: any, index: any) => {
    let dataSetItem: any = [];
    let headers = headerData?.columns;
    Object.keys(dataItem).map((key, index) => {
      if (dataSet?.length <= 10 || props?.limit > 0)
        if (headers)
          headers.forEach((headItem: any, headIndex: any) => {
            if (headItem.field === key) {
              dataSetItem[headIndex] = dataItem[key];
            }
          });
      return true;
    });
    dataSet[index] = dataSetItem;
    processedData = dataSet;
    // setProcessedData(dataSet)
  };
  var formatter = (data: any) => {
    dataSet = [];
    data.map((dataItem: any, index: any) => {
      binder(dataItem, index);
      return true;
    });
  };
  let columnWidthArray: any = [];
  headerData?.columns.forEach((item: any) => {
    columnWidthArray.push(item.width);
  });
  if (props.data?.length>0) {
    console.log("propsData", props.data);
    formatter(props.data);
  }
  return (
    <>
    <div className="IndexDataTableNeoParent">
    <div className="scroollDataTableNeo">
      <div style={{ marginTop: "8px" }}>
        <CardTitle className="CardDateTable">
          <Row
            style={{ alignItems: "center" }}
            className={"IndexDatatableNeoRow "}
            // className="IndexDatatableNeoRow"
          >
            {headerData?.columns?.map((headItem: any, index: any) => {
              return (
                <Col
                  className="IndexDatatableNeoCol HeaderRow"
                  key={index}
                  style={{
                    flex: columnWidthArray[index],
                    // color: `${dataHeaderTextClr}`,
                    fontSize: "15px",
                    fontWeight: "700",
                    lineHeight: "18px",
                    fontFamily: "Roboto",
                  }}
                >
                  {headItem.label}{" "}
                </Col>
              );
            })}
          </Row>
        </CardTitle>
      </div>
      <div style={{ marginTop: "22px" }}>
        {(props.data?.length>0 &&processedData?.length > 0) ? (
          processedData.map((Item: any, index: any) => {
            console.log("processData",Item)
            return (
              <Card
                className="CardDateTable"
                style={{
                  height: "fitContent",
                  textAlign: "center",
                  justifyContent: "center",
                  padding: "1px 4px",
                  boxShadow: "0px 4px 20px -12px",
                  width: "100%",
                  backgroundColor: "#000",
                }}
                key={index}
              >
                <Row
                  className={"IndexDatatableNeoRow ContentRow"}
                  style={{
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  {Item.map((dataItem: any, index: any) => {
                    return (
                      <>
                        <Col
                          className="IndexDatatableNeoCol"
                          key={index}
                          style={{
                            cursor: "pointer",
                            flex: columnWidthArray[index],
                            fontSize: "13px",
                            fontWeight: "400",
                            flexDirection: "row",
                            flexWrap: "nowrap",
                            flexGrow: "0px !important",
                          }}
                        >
                          {dataItem}
                        </Col>
                      </>
                    );
                  })}
                </Row>
              </Card>
            );
          })
        ) : (
          <Card className="noListingPage">
            <div className="fontRoboto15 light__span">
              No Matching Records Found
            </div>
          </Card>
        )}
       
      </div>
      </div>
      </div>
    </>
  );
};

export default index;
