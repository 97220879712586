import {types } from "./actionTypes";

// creating action
export const fecthScoresByLeader=(offset:any,limit:any,filters:any,callback:any)=>{
    return {
        type:types.GET_SCORES_BY_LEADER,
        offset,
        limit,
        filters,
        callback
    }
}
export const fecthScoresByMatchLeader=(offset:any,limit:any,filters:any,callback:any)=>{
    return {
        type:types.GET_SCORES_BY_MATCH_LEADER,
        offset,
        limit,
        filters,
        callback
    }
}
export const fecthScoresByTournamentLeader=(offset:any,limit:any,filters:any,callback:any)=>{
    return {
        type:types.GET_SCORES_BY_TOURNAMENT_LEADER,
        offset,
        limit,
        filters,
        callback
    }
}
export const getTournamentWisePlayerAndTeamLeaderBoard=(filters:any,callback:any)=>{
    return {
        type:types.GET_TOURNAMNET_WISE_PLAYER_AND_TEAM_LEADERBOARD,
        filters,
        callback
    }
}
export const getOverAllLeaderboardScores=(offset:any,limit:any,callback:any)=>{
    return {
        type:types.GET_OVERALL_LEADERBOARD_SCORES,
        offset,
        limit,
        callback
    }
}


