import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import { Pagination, Stack } from "@mui/material";
import { connect } from "react-redux";
import { Modal, ModalBody,ModalHeader } from "reactstrap";
import { useNavigate, useLocation } from "react-router-dom";
import Select from "react-select";
import moment from "moment";
import Index from "../../components/common/index";
import ActionVerticalButton from "../../assets/images/more-vertical.png";

//compoents
import { HeaderData } from "./HeaderData";
import AddMatch from "./AddMatch";
import Loader from "../../components/Loader/Loader";
import SideBar from "../../components/verticalMenu/SideBar";
import notifications from "../../components/Notifications/Notification";

//store
import { fetchTournament } from "../../Store/tournament/actions";
import {
  fetchMatchDetails,
  deleteMatchDetails,
} from "../../Store/match/actions";

//global variables
var offset = 0;
var limit = 10;
var totCnt = 0;
var totPages = 0;
var toVal = 0;
var fromVal = 0;
var data: any = [];
var tournamentOptionsData: any = [];

const MatchDetails = (props: any) => {
  const location = useLocation();
  console.log("location", location);
  const [openAddMatch, setAddMatch]: any = useState(false);
  const [editMatchDetails, setEditMatchDetails]: any = useState();
  const [loading, setLoading] = useState(false);
  const [tournamentId, setTournamentId]: any = useState(location?.state?.mode ?{
    value: location?.state?.tournament?._id,
    label: location?.state?.tournament?.label,
  }:"");
  const [tournamentFilter, setTournamentFilter]: any = useState(
    location?.state?.tournament?._id
  );
  const [resetFlag, setResetFlag]: any = useState(false);
  const navigator = useNavigate();
  useEffect(() => {
    getAllMatchDetails();
    getAllTournamentData();
  }, []);
  useEffect(() => {
    console.log("tournamentId", tournamentFilter);
    if (tournamentId || (!tournamentId && resetFlag)) {
      getAllMatchDetails();
    }
  }, [resetFlag, tournamentFilter]);
  const handleReset = () => {
    setTournamentId("");
    setTournamentFilter(" ");
    setResetFlag(true);
  };

  const getAllMatchDetails = () => {
    let offset = 0;
    setLoading(true);
    props.fetchMatchDetails(
      offset,
      limit,
      {
        tournamentId: tournamentFilter,
      },
      (res: any) => {
        setLoading(false);
      }
    );
  };
  let getAllTournamentData = () => {
    console.log("calling");
    setLoading(true);
    let offset = 0;
    let filters = {
      //   name: tournamentNameFilter,
      //   gameId: gameIdFilter?.value,
      //   startDate: startDateValue,
      //   endDate:endDateValue
    };
    props.fetchTournament(offset, 100000, filters, (res: any) => {
      setLoading(false);
    });
  };
  const openMatchModel = () => {
    setAddMatch(true);

  };
  const closeAddMatchModel = () => {
    setAddMatch(false);
    setEditMatchDetails("")
  };
  let action = (ele: any) => {
    return (
      <>
        <div className="dropdown">
          <img
            style={{ margin: "-4px" }}
            src={ActionVerticalButton}
            alt="actions"
          />
          <div className="dropdown-content">
            {ele?.scoreAdded === true ? (
              <div className="action-button">
                <button
                  onClick={() => {
                    navigator("/view-perfomance", {
                      state: {
                        matchDetails: ele,
                      },
                    });
                    window.scrollTo(0, 0)
                  }}
                >
                  View Perfomance
                </button>
              </div>
            ) : (
              <div className="action-button">
                <button
                  onClick={() => {
                    navigator("/add-user-perfomance", {
                      state: {
                        matchDetails: ele,
                      },
                    });
                    window.scrollTo(0, 0)
                  }}
                >
                  Add Perfomance
                </button>
              </div>
            )}
            <div
              className="action-button"
              onClick={() => {
                navigator(`/view-match-details`, {
                  state: {
                    matchDetails: ele,
                  },
                });
                window.scrollTo(0, 0)
              }}
            >
              View Details
            </div>
            <div
              className="action-button"
              onClick={() => {
                setEditMatchDetails(ele);
                openMatchModel();
              }}
            >
              Edit Details
            </div>
            <div
              className="action-button"
              onClick={() => {
                setLoading(true);
                props?.deleteMatchDetails({ matchId: ele?._id }, (res: any) => {
                  setLoading(false);
                  if (res.success) {
                    notifications({
                      title: "Success!",
                      message: res?.message,
                      type: "success",
                    });
                    getAllMatchDetails();
                  } else {
                    notifications({
                      title: "Error!",
                      message: res?.message,
                      type: "danger",
                    });
                  }
                });
              }}
            >
              Delete
            </div>
          </div>
        </div>
      </>
    );
  };
  let handleChangePage = (event: any, val?: any) => {
    console.log("val", val);
    offset = (val - 1) * 10;
    toVal = offset + 10;
    setLoading(true);
    props.fetchMatchDetails(
      offset,
      limit,
      {
        tournamentId: tournamentFilter,
      },
      (res: any) => {
        setLoading(false);
        if (res.success) {
          notifications({
            title: "Success!",
            message: res?.message,
            type: "success",
          });
        } else {
          notifications({
            title: "Error!",
            message: res?.message,
            type: "danger",
          });
        }
      }
    );
  };
  const handleOnInputChangeTournament = (e: any) => {
    if (e?.length > 2)
      props.fetchTournament(0, 10, { name: e }, (res: any) => {});
  };
  const handleSubmitChange = (type: any) => {
    if (type?.name === "tournamentName") {
      setTournamentFilter(type?.value?.value);
      setTournamentId(type?.value);
    }
  };
  if (props?.getTournamentDetails !== undefined) {
    tournamentOptionsData = [];
    props?.getTournamentDetails?.data?.docs?.map((tournament: any, i: any) => {
      tournamentOptionsData.push({
        value: tournament?._id,
        label: tournament?.label,
      });
    });
    console.log("tournamentOptionsData", tournamentOptionsData);
  }
  if (props?.getFetchMatchDetails !== undefined) {
    let getFetchMatchDetails = props?.getFetchMatchDetails?.data;
    offset = getFetchMatchDetails?.offset;
    totCnt = getFetchMatchDetails?.length;
    fromVal = totCnt !== 0 ? offset + 1 : fromVal;
    toVal = getFetchMatchDetails?.offset + getFetchMatchDetails?.docs?.length;
    totPages = Math.ceil(totCnt / 10);
    data = [];
    props?.getFetchMatchDetails?.data?.docs?.map((ele: any, i: any) => {
      data.push({
        tournamentLogo: (
          <a
            href={ele?.tournamentInfo?.logo}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="mb-2"
              src={ele?.tournamentInfo?.logo}
              width="30px"
              height="30px"
              alt=""
            />
            <div>View Logo</div>
          </a>
        ),
        tournamentName: ele?.tournamentInfo?.label,
        winningTeam: ele?.winningTeamInfo?.teamName,
        loosingTeam: ele?.lossingTeamInfo?.teamName,
        matchDate: ele?.matchDate
          ? moment(new Date(ele?.matchDate)).format("YYYY-MM-DD HH:mm")
          : "N/A",
        action: action(ele),
      });
    });
    console.log("data", data);
  }
  console.log("data", data);

  return (
    <>
     <Modal
       isOpen={openAddMatch}
       toggle={openMatchModel}
        size="lg"
        className="WhatsAppBulkModal"
      >
        <ModalHeader>
          <div className="Heading">
         
            <h3> {editMatchDetails ? "EDIT MATCH" : " ADD MATCH"}</h3>
            <svg
              onClick={() => closeAddMatchModel()}
              width="20"
              height="20"
              viewBox="0 0 9 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8 1L1 8"
                stroke="#C59C6C"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M1 1L8 8"
                stroke="#C59C6C"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </ModalHeader>
        <ModalBody>
        <AddMatch
            {...props}
            closeModal={closeAddMatchModel}
            getAllMatchData={getAllMatchDetails}
            editMatchDetails={editMatchDetails}
            // handleSubmitUpdateGame={handleSubmitUpdateGame}
            // handleSubmitCreateGame={handleSubmitCreateGame}
          />
        </ModalBody>
      </Modal>
      
      <div className="pageContent">
        <div className="pageContentChild">
          <div>
            <SideBar></SideBar>
          </div>
          <div className="p-3 w-100">
            <Row>
              <Col xs={12}>
                <button
                  className="float-end me-4 pe-4 cursor-pointer p-2"
                  onClick={() => {
                    openMatchModel();
                  }}
                >
                  Add Match
                </button>
              </Col>
            </Row>
            <Row>
              <Col xs={8} md={6}>
                <Select
                  {...props}
                  className="Game_selector"
                  classNamePrefix="react-select"
                  placeholder="Select Tournament..."
                  autoFocus={true}
                  isSearchable
                  value={tournamentId}
                  options={tournamentOptionsData}
                  onChange={(e: any) => {
                    console.log("e value", e);
                    handleSubmitChange({
                      name: "tournamentName",
                      value: e,
                    });
                  }}
                  onInputChange={(e) => handleOnInputChangeTournament(e)}
                />
              </Col>
              <Col xs={4} md={3}>
                {" "}
                <button
                  onClick={() => handleReset()}
                  style={{
                    color: "black",
                    background: "Green",
                    height: "40px",
                    width: "100%",
                    padding: "10px 20px",
                  }}
                >
                  Reset
                </button>
              </Col>
            </Row>
            {loading ? (
              <>
                <div>
                  <Loader />
                </div>
              </>
            ) : (
              <>
                <h3 className="mt-4 mb-4">Match Details</h3>
                <Index
                  data={data}
                  headerData={HeaderData}
                  limit={limit}
                  rowHeight={"60px"}
                />
                {totCnt ? (
                  <>
                    <div className="mt-4">
                      <Row>
                        <Col xs={6} className="text-white">
                          <Stack spacing={2}>
                            <Pagination
                              className="pagination"
                              size="small"
                              shape="rounded"
                              page={offset / 10 + 1}
                              count={totPages}
                              color="primary"
                              // onChange={(e)=>{handleChangePage(e,offset / 10 + 1)}}
                              onChange={(e, val) => {
                                handleChangePage(e, val);
                                console.log("event", e, val);
                              }}
                              defaultPage={1}
                              siblingCount={1}
                            />
                          </Stack>
                        </Col>
                        <Col xs={6}>
                          <span className="text-white">
                            Showing {fromVal} to {toVal} of {totCnt} entries
                          </span>
                        </Col>
                      </Row>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state: any) => {
  console.log("state", state);
  return {
    ...state,
    getFetchMatchDetails: state.matchReducer.matchDetail,
    getTournamentDetails: state?.tournamentReducer?.tournamentDetail,
  };
};
export default connect(mapStateToProps, {
  fetchMatchDetails,
  deleteMatchDetails,
  fetchTournament,
})(MatchDetails);
