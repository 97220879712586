import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import { types } from "./actionTypes";
import { AxiosResponse } from "axios";

//medthods
import {
  postApi,
  putApi,
  getApi,
  deleteApi,
} from "../.././helpers/auth_helpers";

// workers

function* workFetchScoresByLeader({ offset, limit, filters, callback }: any) {
  let userId = filters?.userId ? filters?.userId : "";
  try {
    const url: string =
      process.env.REACT_APP_SERVERURL +
      "/leaderboard/leader?offset=" +
      offset +
      "&limit=" +
      limit +
      "&userId=" +
      userId;
    const response: AxiosResponse = yield call(getApi, url);
    callback(response);
    // console.log("users",response)
    yield put({
      type: types.GET_SCORES_BY_LEADER_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workFetchScoresByMatchLeader({
  offset,
  limit,
  filters,
  callback,
}: any) {
  let userId = filters?.userId ? filters?.userId : "";
  let matchId = filters?.matchId ? filters?.matchId : "";
  let tournamentId = filters?.tournamentId ? filters?.tournamentId : "";

  try {
    const url: string =
      process.env.REACT_APP_SERVERURL +
      "/leaderboard/matchLeader?offset=" +
      offset +
      "&limit=" +
      limit +
      "&userId=" +
      userId +
      "&matchId=" +
      matchId +
      "&tournamentId=" +
      tournamentId;
    const response: AxiosResponse = yield call(getApi, url);
    callback(response);
    // console.log("users",response)
    yield put({
      type: types.GET_SCORES_BY_LEADER_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workFetchScoresByTournamentLeader({
  offset,
  limit,
  filters,
  callback,
}: any) {
  let userId = filters?.userId ? filters?.userId : "";
  let tournamentId = filters?.tournamentId ? filters?.tournamentId : "";
  try {
    const url: string =
      process.env.REACT_APP_SERVERURL +
      "/leaderboard/tournamentLeader?offset=" +
      offset +
      "&limit=" +
      limit +
      "&userId=" +
      userId +
      "&tournamentId=" +
      tournamentId;
    const response: AxiosResponse = yield call(getApi, url);
    callback(response);
    // console.log("users",response)
    yield put({
      type: types.GET_SCORES_BY_TOURNAMENT_LEADER_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workgetTournamentWisePlayerAndTeamLeaderBoard({
  filters,
  callback,
}: any) {
  let tournamentId = filters?.tournamentId ? filters?.tournamentId : "";
  let offset = filters?.offset ? filters?.offset : 0;
  let limit = filters?.limit ? filters?.limit : 10;
  try {
    const url: string =
      process.env.REACT_APP_SERVERURL +
      "/leaderboard/getTournamentWisePlayerAndTeamLeaderBoard?offset=" +
      offset +
      "&limit=" +
      limit +
      "&tournamentId=" +
      tournamentId;
    const response: AxiosResponse = yield call(getApi, url);
    callback(response);
    // console.log("users",response)
    yield put({
      type: types.GET_TOURNAMNET_WISE_PLAYER_AND_TEAM_LEADERBOARD_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workOverAllLeaderboardScores({
  offset,
  limit,
  callback,
}: any) {
  
  try {
    const url: string =
      process.env.REACT_APP_SERVERURL +
      "/leaderboard/getOverAllLeaderBoard?offset=" +
      offset +
      "&limit=" +
      limit ;
    const response: AxiosResponse = yield call(getApi, url);
    callback(response);
    // console.log("users",response)
    yield put({
      type: types.GET_OVERALL_LEADERBOARD_SCORES_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
// watchers
export function* watchScoresByLeader() {
  yield takeEvery(types.GET_SCORES_BY_LEADER, workFetchScoresByLeader);
}
export function* watchScoresByMatchLeader() {
  yield takeEvery(
    types.GET_SCORES_BY_MATCH_LEADER,
    workFetchScoresByMatchLeader
  );
}
export function* watchScoresByTournamentLeader() {
  yield takeEvery(
    types.GET_SCORES_BY_TOURNAMENT_LEADER,
    workFetchScoresByTournamentLeader
  );
}
export function* watchgetTournamentWisePlayerAndTeamLeaderBoard() {
  yield takeEvery(
    types.GET_TOURNAMNET_WISE_PLAYER_AND_TEAM_LEADERBOARD,
    workgetTournamentWisePlayerAndTeamLeaderBoard
  );
}
export function* watchOverAllLeaderboardScores() {
  yield takeEvery(
    types.GET_OVERALL_LEADERBOARD_SCORES,
    workOverAllLeaderboardScores)
}
function* leaderBoardSaga() {
  yield all([
    fork(watchScoresByLeader),
    fork(watchScoresByMatchLeader),
    fork(watchScoresByTournamentLeader),
    fork(watchgetTournamentWisePlayerAndTeamLeaderBoard),
    fork(watchOverAllLeaderboardScores)
  ]);
}
export default leaderBoardSaga;
