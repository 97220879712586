import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import { types } from "./actionTypes";
import  { AxiosResponse } from "axios";

//medthods
import {
  postApi,
  putApi,
  getApi,
  deleteApi,
} from "../.././helpers/auth_helpers";

// workers
function* workCreateWhatsAppMedia({ payload, callback }: any) {
  try {
    const url: string = process.env.REACT_APP_SERVERURL + "/whatsapp-media/create";
    const response: AxiosResponse = yield call(postApi, url, payload);
    callback(response);
    // console.log("users",response)
    yield put({
      type: types.CREATE_WHATSAPP_MEDIA_DETAILS_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workGetWhatsAppMedia({ offset, limit, filters, callback }: any) {
  try {
    const url: string =
      process.env.REACT_APP_SERVERURL +
      "/whatsapp-media/media?offset=" +
      offset +
      "&limit=" +
      limit +
      "&sort=-1";
    const response: AxiosResponse = yield call(getApi, url);
    callback(response);
    // console.log("users",response)
    yield put({
      type: types.GET_WHATSAPP_MEDIA_DETAILS_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workFetchPresignedUrlForMedia({ payload,callback }: any) {
  try {
    const url: string =
      process.env.REACT_APP_SERVERURL + "/upload/whatsapp/media";
    const response: AxiosResponse = yield call(postApi, url,payload);
    callback(response);
    yield put({
      type: types.GET_WHATSAPP_MEDIA_PRESIGNED_URL_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
// watchers
export function* watchCreateWhatsAppMedia() {
  yield takeEvery(types.CREATE_WHATSAPP_MEDIA_DETAILS, workCreateWhatsAppMedia);
}
export function* watchGetWhatsAppMedia() {
  yield takeEvery(types.GET_WHATSAPP_MEDIA_DETAILS, workGetWhatsAppMedia);
}
export function* watchFetchPresignedUrlForMedia() {
  yield takeEvery(types.GET_WHATSAPP_MEDIA_PRESIGNED_URL, workFetchPresignedUrlForMedia);
}

function* whatsAppMediaSaga() {
  yield all([
    fork(watchCreateWhatsAppMedia),
    fork(watchGetWhatsAppMedia),
    fork(watchFetchPresignedUrlForMedia)
    
  ]);
}
export default whatsAppMediaSaga;
