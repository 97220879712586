export const types = {
    ADD_USER_TOURNAMENT:"ADD_USER_TOURNAMENT",
    ADD_USER_TOURNAMENT_SUCCESS:"ADD_USER_TOURNAMENT_SUCCESS",
    FETCH_USER_TOURNAMENTS:"FETCH_USER_TOURNAMENTS",
    FETCH_USER_TOURNAMENTS_SUCCESS:"FETCH_USER_TOURNAMENTS_SUCCESS",
    EDIT_USER_TOURNAMENT:"EDIT_USER_TOURNAMENT",
    EDIT_USER_TOURNAMENT_SUCCESS:"EDIT_USER_TOURNAMENT_SUCCESS",
    DELETE_USER_TOURNAMENT:"DELETE_USER_TOURNAMENT",
    DELETE_USER_TOURNAMENT_SUCCESS:"DELETE_USER_TOURNAMENT_SUCCESS",
    PRESIGNED_URL_TEAM_LOGO:"PRESIGNED_URL_TEAM_LOGO",
    PRESIGNED_URL_TEAM_LOGO_SUCCESS:"PRESIGNED_URL_TEAM_LOGO_SUCCESS",
    UPLOAD_IMAGES_TEAM_LOGO:"UPLOAD_IMAGES_TEAM_LOGO",
    UPLOAD_IMAGES_TEAM_LOGO_SUCCESS:"UPLOAD_IMAGES_TEAM_LOGO_SUCCESS",
    FETCH_INVITED_PLAYER:"FETCH_INVITED_PLAYER",
    FETCH_INVITED_PLAYER_SUCCESS:"FETCH_INVITED_PLAYER_SUCCESS"
}