import { types } from "./actionTypes";
const initialState = {
  addMatchPerfomance: null,
  matchPerfomanceDetail: null,
  updateDetails: null,
  deleteMatchPerfomances: null,
};
const matchPerfomanceReducer = (state: any = initialState, action: any) => {
  // console.log("action",action.payload)
  switch (action.type) {
    case types.ADD_MATCH_PERFOMANCE_SUCCESS:
      state = {
        ...state,
        addMatchPerfomance: action.payload,
      };
      return state;
      break;
    case types.FETCH_MATCH_PERFOMANCES_SUCCESS:
      state = {
        ...state,
        matchPerfomanceDetail: action.payload,
      };
      return state;
      break;
    case types.EDIT_MATCH_PERFOMANCE_SUCCESS:
      state = {
        ...state,
        updateDetails: action.payload,
      };
      return state;
      break;
    case types.DELETE_MATCH_PERFOMANCE_SUCCESS:
      state = {
        ...state,
        deleteMatchPerfomances: action.payload,
      };
      return state;
      break;
    default:
      return state;
  }
};

export default matchPerfomanceReducer;
