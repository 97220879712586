import React, { useEffect, useState } from "react";
import { Grid, Card } from "@mui/material";
import { Row, Col } from "reactstrap";
import { connect } from "react-redux";
//components
import Loader from "../../components/Loader/Loader";
import SideBar from "../../components/verticalMenu/SideBar";
//store
import { fetchUserTournament } from "../../Store/userTournament/actions";

const MyTournaments = (props: any) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getAllUserRegisterTournaments();
  }, []);
  let auth: any = localStorage.getItem("authUser") || sessionStorage.getItem("authUser");
  let authUser: any = JSON.parse(auth) || "";
  const getAllUserRegisterTournaments = () => {
    setLoading(true);
    let offset = 0;
    props.fetchUserTournament(
      offset,
      10000,
      { userId: authUser?._id },
      (res: any) => {
        setLoading(false);
       
      }
    );
  };
  let registerTournament: any = [];
  if (props?.getUserTournaments !== undefined) {
    let getUserTournamentsDetails: any = props?.getUserTournaments?.data?.docs;
    getUserTournamentsDetails?.map((tournament: any, i: any) => {
      tournament?.users?.map((user: any, i: any) => {
        if (
          user?.user === authUser?._id &&
          user?.acceptanceStatus === "accept"
        ) {
          registerTournament.push({
            ...tournament,
          });
        }
      });
    });
  }
  console.log("registerTournament", registerTournament);
  return (
    <>
    <div className="pageContent" >
  <div className="pageContentChild">
   <div>
   <SideBar></SideBar>
   </div>
   <div className="p-3 w-100">
   {loading ? (
              <>
                <div>
                  <Loader />
                </div>
              </>
            ) : (
              <>
                <Row>
                  <Col xs="12">
                    <h4>My Tournaments</h4>
                    <Row className="mt-3">
                      {props?.getUserTournaments?.data?.docs.map(
                        (tournament: any, i: any) => {
                          return (
                            <>
                              <Col xs="12" sm={6}  md={4} lg={3} className="p-4 ">
                                <Card className="MytournamentsCard ">
                                  <h5 className="text-capitalize pb-2">
                                    Name : {tournament?.tournmentInfo?.label}
                                  </h5>
                                  <h6 className="text-capitalize pb-2">
                                    Team Name: {tournament?.teamName}
                                  </h6>
                                  <h6 className="text-capitalize pb-2">
                                    format: {tournament?.tournmentInfo?.format}
                                  </h6>
                                </Card>
                              </Col>
                            </>
                          );
                        }
                      )}
                    </Row>
                  </Col>
                </Row>
              </>
            )}
     </div>
     </div>
     </div>
      
    </>
  );
};

const mapStateToProps = (state: any) => {
  console.log("state", state);
  return {
    ...state,
    getUserTournaments: state?.userTournamentReducer?.tournamentDetail,
  };
};
export default connect(mapStateToProps, {
  fetchUserTournament,
})(MyTournaments);
