import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import { types } from "./actionTypes";
import axios, { AxiosResponse } from "axios";

//medthods
import {
  postApi,
  putApi,
  getApi,
  deleteApi,
  putUploadImagesApi,
} from "../.././helpers/auth_helpers";
import { features } from "process";

// workers
function* workAddTournament({ payload, callback }: any) {
  try {
    const url: string = process.env.REACT_APP_SERVERURL + "/tournament/create";
    const response: AxiosResponse = yield call(postApi, url, payload);
    callback(response);
    // console.log("users",response)
    yield put({
      type: types.ADD_TOURNAMENT_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workFetchTournaments({ offset, limit, filters, callback }: any) {
  let name = filters?.name ? filters?.name : "";
  let gameId = filters?.gameId ? filters?.gameId : "";
  let startDate = filters?.startDate ? filters?.startDate : "";
  let endDate = filters?.endDate ? filters?.endDate : "";

  try {
    const url: string =
      process.env.REACT_APP_SERVERURL +
      "/tournament/tournaments?offset=" +
      offset +
      "&limit=" +
      limit +
      "&name=" +
      name +
      "&startDate=" +
      startDate +
      "&endDate=" +
      endDate +
      "&gameId=" +
      gameId;
    const response: AxiosResponse = yield call(getApi, url);
    callback(response);
    // console.log("users",response)
    yield put({
      type: types.FETCH_TOURNAMENTS_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}

function* workFetchTournamentById({ tournamentId, callback }: any) {
  try {
    const url: string =
      process.env.REACT_APP_SERVERURL +
      "/tournament/tournamentById?tournamentId=" +
      tournamentId;
    const response: AxiosResponse = yield call(getApi, url);
    callback(response);
    // console.log("users",response)
    yield put({
      type: types.TOURNAMENT_BY_ID_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workUpdateTournament({ payload, callback }: any) {
  try {
    const url: string = process.env.REACT_APP_SERVERURL + "/tournament/update";
    const response: AxiosResponse = yield call(putApi, url, payload);
    callback(response);
    // console.log("users",response)
    yield put({
      type: types.EDIT_TOURNAMENT_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workDeleteTournament({ payload, callback }: any) {
  try {
    const url: string = process.env.REACT_APP_SERVERURL + "/tournament/delete";
    const response: AxiosResponse = yield call(deleteApi, url, payload);
    callback(response);
    // console.log("users",response)
    yield put({
      type: types.DELETE_TOURNAMENT_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workFetchPresignedUrlLogo({ callback }: any) {
  try {
    const url: string =
      process.env.REACT_APP_SERVERURL + "/upload/tournament/logo";
    const response: AxiosResponse = yield call(getApi, url);
    callback(response);
    yield put({
      type: types.PRESIGNED_URL_LOGO_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workFetchPresignedUrlRule({ callback }: any) {
  try {
    const url: string =
      process.env.REACT_APP_SERVERURL + "/upload/tournament/rules";
    const response: AxiosResponse = yield call(getApi, url);
    callback(response);
    yield put({
      type: types.PRESIGNED_URL_RULE_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workFetchPresignedUrlRuleDocx({ callback }: any) {
  try {
    const url: string =
      process.env.REACT_APP_SERVERURL + "/upload/tournament/rule";
    const response: AxiosResponse = yield call(getApi, url);
    callback(response);
    yield put({
      type: types.PRESIGNED_URL_RULE_DOCX_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}

function* workUploadImages({ uri, data, headers }: any) {
  console.log("test",uri, data, headers)
  try {
    // Try to call the API
    const response: AxiosResponse = yield call(
      putUploadImagesApi,
      uri,
      data,
      headers
    );
    //   Dispatch the action to the reducers
    console.log("images", response);
    yield put({
      type: types.UPLOAD_IMAGES_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    console.log(error);
  }
}
function* workFetchTeams({ offset, limit, filters, callback }: any) {
  console.log("filters", filters);
  let tournamentName = filters?.tournament ? filters?.tournament : "";
  let userId = filters?.userId ? filters?.userId : "";
  let tournamentId = filters?.tournamentId ? filters?.tournamentId : ""
  // let isInTeam = filters?.isInTeam ? filters?.isInTeam : false;
  try {
    const url: string =
      process.env.REACT_APP_SERVERURL +
      "/tournament/teams?offset=" +
      offset +
      "&limit=" +
      limit +
      "&userId=" +
      userId +
      "&tournamentName=" +
      tournamentName +
      "&tournamentId="+
      tournamentId;
      // "&isInTeam=" +
      // isInTeam;
    const response: AxiosResponse = yield call(getApi, url);
    callback(response);
    // console.log("users",response)
    yield put({
      type: types.FETCH_TEAMS_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workDeleteTeamById({ payload, callback }: any) {
  try {
    const url: string =
      process.env.REACT_APP_SERVERURL + "/tournament/deleteTeam";
    const response: AxiosResponse = yield call(deleteApi, url, payload);
    callback(response);
    // console.log("users",response)
    yield put({
      type: types.DELETE_TEAM_BY_ID_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workTotalActiveEvents({ offset, limit, filters, callback }: any) {
  let nameFilter = filters?.name ? filters?.name : "";
  try {
    const url: string =
      process.env.REACT_APP_SERVERURL +
      "/tournament/tournaments?offset=" +
      offset +
      "&limit=" +
      limit +
      "&ongoing=true&name=" +
      nameFilter;

    const response: AxiosResponse = yield call(getApi, url);
    callback(response);
    // console.log("users",response)
    yield put({
      type: types.TOTAL_ACTIVE_EVENTS_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workTotalUpcomingEvents({ offset, limit, filters, callback }: any) {
  let nameFilter = filters?.name ? filters?.name : "";
  try {
    const url: string =
      process.env.REACT_APP_SERVERURL +
      "/tournament/tournaments?offset=" +
      offset +
      "&limit=" +
      limit +
      "&upcoming=true&name=" +
      nameFilter;

    const response: AxiosResponse = yield call(getApi, url);
    callback(response);
    // console.log("users",response)
    yield put({
      type: types.UPCOMING_EVENTS_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workAddTaggedVideo({ payload, callback }: any) {
  try {
    const url: string =
      process.env.REACT_APP_SERVERURL + "/tournament/tagVideo";
    const response: AxiosResponse = yield call(postApi, url, payload);
    callback(response);
    // console.log("users",response)
    yield put({
      type: types.ADD_VIDEO_TAGE_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workGetTaggedVideo({ filters, callback }: any) {
  let tournamentId = filters?.tournamentId ? filters?.tournamentId : "";
  let featured = filters?.featured ? filters?.featured : "";
  console.log("filters", filters);
  try {
    const url: string =
      process.env.REACT_APP_SERVERURL +
      "/tournament/taggedVideo?tournamentId=" +
      tournamentId +
      "&featured=" +
      featured;
    const response: AxiosResponse = yield call(getApi, url);
    callback(response);
    // console.log("users",response)
    yield put({
      type: types.GET_ADD_VIDEO_TAGE_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workUpdateTaggedVideo({ payload, callback }: any) {
  try {
    const url: string =
      process.env.REACT_APP_SERVERURL + "/tournament/updateTagVideo";
    const response: AxiosResponse = yield call(putApi, url, payload);
    callback(response);
    // console.log("users",response)
    yield put({
      type: types.UPDATE_VIDEO_TAGE_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workDeleteTaggedVideo({ payload, callback }: any) {
  try {
    const url: string =
      process.env.REACT_APP_SERVERURL + "/tournament/deleteTagVideo";
    const response: AxiosResponse = yield call(deleteApi, url, payload);
    callback(response);
    // console.log("users",response)
    yield put({
      type: types.DELETE_VIDEO_TAGE_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workDeleteTaggedVideoByID({ payload, callback }: any) {
  try {
    const url: string =
      process.env.REACT_APP_SERVERURL + "/tournament/deleteTagVideo";
    const response: AxiosResponse = yield call(deleteApi, url, payload);
    callback(response);
    // console.log("users",response)
    yield put({
      type: types.DELETE_TEAM_BY_ID_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workFetchPresignedUrlThumbnail({ callback }: any) {
  try {
    const url: string =
      process.env.REACT_APP_SERVERURL + "/upload/tournament/thumbnail";
    const response: AxiosResponse = yield call(getApi, url);
    callback(response);
    yield put({
      type: types.ADD_TOURAMENT_TUMBNAIL_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workFetchPresignedUrlFixtures({ payload, callback }: any) {
  try {
    const url: string =
      process.env.REACT_APP_SERVERURL + "/upload/tournament/fixture";
    const response: AxiosResponse = yield call(postApi, url, payload);
    callback(response);
    yield put({
      type: types.ADD_TOURAMENT_FIXTURE_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workFetchPresignedUrlPoolPrice({ payload, callback }: any) {
  try {
    const url: string =
      process.env.REACT_APP_SERVERURL + "/upload/tournament/poolPrizeImage";
    const response: AxiosResponse = yield call(getApi, url);
    callback(response);
    yield put({
      type: types.ADD_POOL_PRICE_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workTournamentUserStatus({ payload, callback }: any) {
  try {
    const url: string =
      process.env.REACT_APP_SERVERURL + "/tournament/userStatus";
    const response: AxiosResponse = yield call(putApi, url, payload);
    callback(response);
    // console.log("users",response)
    yield put({
      type: types.TOURNAMENT_USER_STATUS_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workGetTeamsOrPlayersByTournamentIdAndUserId({
  filters,
  callback,
}: any) {
  let tournamentId = filters?.tournamentId ? filters?.tournamentId : "";
  let userId = filters?.userId ? filters?.userId : "";
  let email=filters?.email ? filters?.email :""
  try {
    const url: string =
      process.env.REACT_APP_SERVERURL +
      "/tournament/getTeamsOrPlayersByTournamentIdAndUserId?tournamentId=" +
      tournamentId +
      "&userEmail=" +
      email;
    const response: AxiosResponse = yield call(getApi, url);
    callback(response);
    // console.log("users",response)
    yield put({
      type: types.GET_TEAMS_OR_PLAYERS_BY_TOURNAMNTBYID_AND_USERID_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workGetTeamsByTournamentBeforeLogin({
  filters,
  callback,
}: any) {
let tournamentId = filters?.tournamentId ? filters?.tournamentId:"";
let emailId= filters?.emailId ? filters?.emailId :"";
  try {
    const url: string =
      process.env.REACT_APP_SERVERURL +
      "/tournament/getTeamsByTournamentBeforeLogin?tournamentId=" +
      tournamentId + "&emailId="+emailId ;
    const response: AxiosResponse = yield call(getApi, url);
    callback(response);
    // console.log("users",response)
    yield put({
      type: types.GET_TEAMS_BY_TOURNAMENT_BEFORE_LOGIN_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
// watchers
export function* watchAddTournament() {
  yield takeEvery(types.ADD_TOURNAMENT, workAddTournament);
}
export function* watchFetchTournament() {
  yield takeEvery(types.FETCH_TOURNAMENTS, workFetchTournaments);
}
export function* watchFetchTournamentById() {
  yield takeEvery(types.TOURNAMENT_BY_ID, workFetchTournamentById);
}
export function* watchUpdateTournament() {
  yield takeEvery(types.EDIT_TOURNAMENT, workUpdateTournament);
}
export function* watchDeleteTournament() {
  yield takeEvery(types.DELETE_TOURNAMENT, workDeleteTournament);
}
export function* watchPresignedUrlLogo() {
  yield takeEvery(types.PRESIGNED_URL_LOGO, workFetchPresignedUrlLogo);
}
export function* watchPresignedUrlRules() {
  yield takeEvery(types.PRESIGNED_URL_RULE, workFetchPresignedUrlRule);
}
export function* watchPresignedUrlRulesForDocx() {
  yield takeEvery(types.PRESIGNED_URL_RULE_DOCX, workFetchPresignedUrlRuleDocx);
}
export function* watchUploadImages() {
  yield takeEvery(types.UPLOAD_IMAGES, workUploadImages);
}
export function* watchFetchTeams() {
  yield takeEvery(types.FETCH_TEAMS, workFetchTeams);
}
export function* watchDeleteTeamById() {
  yield takeEvery(types.DELETE_TEAM_BY_ID, workDeleteTeamById);
}
export function* watchTotalActiveEvents() {
  yield takeEvery(types.TOTAL_ACTIVE_EVENTS, workTotalActiveEvents);
}
export function* watchTotalUpcomingEvents() {
  yield takeEvery(types.UPCOMING_EVENTS, workTotalUpcomingEvents);
}
export function* watchAddTaggedVideos() {
  yield takeEvery(types.ADD_VIDEO_TAGE, workAddTaggedVideo);
}
export function* watchGetTaggedVideos() {
  yield takeEvery(types.GET_ADD_VIDEO_TAGE, workGetTaggedVideo);
}
export function* watchUpdateTaggedVideos() {
  yield takeEvery(types.UPDATE_VIDEO_TAGE, workUpdateTaggedVideo);
}
export function* watchDeleteTaggedVideos() {
  yield takeEvery(types.DELETE_VIDEO_TAGE, workDeleteTaggedVideo);
}
export function* watchDeleteTaggedVideoByID() {
  yield takeEvery(types.DELETE_VIDEO_TAGE_BY_ID, workDeleteTaggedVideoByID);
}
export function* watchPresignedUrlThumnail() {
  yield takeEvery(types.ADD_TOURAMENT_TUMBNAIL, workFetchPresignedUrlThumbnail);
}
export function* watchPresignedUrlFixtures() {
  yield takeEvery(types.ADD_TOURAMENT_FIXTURE, workFetchPresignedUrlFixtures);
}
export function* watchPresignedUrlPoolPrice() {
  yield takeEvery(types.ADD_POOL_PRICE, workFetchPresignedUrlPoolPrice);
}
export function* watchTournamentUserStatus() {
  yield takeEvery(types.TOURNAMENT_USER_STATUS, workTournamentUserStatus);
}
export function* watchGetTeamsOrPlayersByTournamentIdAndUserId() {
  yield takeEvery(types.GET_TEAMS_OR_PLAYERS_BY_TOURNAMNTBYID_AND_USERID, workGetTeamsOrPlayersByTournamentIdAndUserId);
}
export function* watchGetTeamsByTournamentBeforeLogin() {
  yield takeEvery(types.GET_TEAMS_BY_TOURNAMENT_BEFORE_LOGIN, workGetTeamsByTournamentBeforeLogin);
}
function* tournamentSaga() {
  yield all([
    fork(watchAddTournament),
    fork(watchFetchTournament),
    fork(watchUpdateTournament),
    fork(watchDeleteTournament),
    fork(watchPresignedUrlLogo),
    fork(watchUploadImages),
    fork(watchPresignedUrlRules),
    fork(watchPresignedUrlRulesForDocx),
    fork(watchFetchTournamentById),
    fork(watchFetchTeams),
    fork(watchDeleteTeamById),
    fork(watchTotalActiveEvents),
    fork(watchTotalUpcomingEvents),
    fork(watchAddTaggedVideos),
    fork(watchGetTaggedVideos),
    fork(watchUpdateTaggedVideos),
    fork(watchDeleteTaggedVideos),
    fork(watchDeleteTaggedVideoByID),
    fork(watchPresignedUrlThumnail),
    fork(watchPresignedUrlFixtures),
    fork(watchPresignedUrlPoolPrice),
    fork(watchTournamentUserStatus),
    fork(watchGetTeamsByTournamentBeforeLogin),
    fork(watchGetTeamsOrPlayersByTournamentIdAndUserId)
  ]);
}
export default tournamentSaga;
