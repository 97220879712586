import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import { types } from "./actionTypes";
import axios, { AxiosResponse } from "axios";

//medthods
import { postApi, putApi } from "../.././helpers/auth_helpers";
import { SocialIcon } from "react-social-icons";

// workers
function* workerCreateOrder({ payload, callback }: any) {
  try {
    const url: string =
      process.env.REACT_APP_SERVERURL +
      "/payment/createOrder" ;
    const response: AxiosResponse = yield call(postApi, url,payload);
    callback(response);
    // console.log("users",response)
    yield put({
      type: types.CREATE_ORDER_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workerVerifyOrder({ payload, callback }: any) {
  try {
    const url: string =
      process.env.REACT_APP_SERVERURL +
      "/payment/verifyOrder" ;
    const response: AxiosResponse = yield call(postApi, url,payload);
    callback(response);
    // console.log("users",response)
    yield put({
      type: types.VERIFY_ORDER_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
// watchers
export function* watchCreateOrder() {
  yield takeEvery(types.CREATE_ORDER, workerCreateOrder);
}
export function* watchVerifyOrder() {
  yield takeEvery(types.VERIFY_ORDER, workerVerifyOrder);
}


function* razorPaySaga() {
  yield all([
 fork(watchCreateOrder),
 fork(watchVerifyOrder)
  ]);
}
export default razorPaySaga;
