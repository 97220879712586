import React from "react";
import { Link } from "react-router-dom";
import { FiArrowLeft } from "react-icons/fi";
import { Row, Col, Card } from "reactstrap";
import { useLocation } from "react-router-dom";
import SideBar from "../../components/verticalMenu/SideBar";
const ViewMatchDetails = (props: any) => {
  const location = useLocation();
  console.log("location", location?.state?.matchDetails);
  let propsMatchDetailData = location?.state?.matchDetails;
  return (
    <>
      <div className="pageContent">
        <div className="pageContentChild">
          <div>
            <SideBar></SideBar>
          </div>
          <div className="p-3 w-100">
            <div>
              <Link to="/match-details">
                <button className="m-4">
                  <FiArrowLeft className="cursor-pointer editIcon " />
                </button>
              </Link>
            </div>
            <h3 className="p-4">Match Details</h3>
            <Row>
              <Col xs={12}>
                <h4>Tournament Information</h4>
                <div>
                  <label>Tournament Logo</label>
                  <div>
                    <img
                      src={propsMatchDetailData?.tournamentInfo?.logo}
                      width="200px"
                      height="200px"
                      alt="TournamentLogo"
                    />
                  </div>
                </div>
                <div className="mt-2">
                  <span className="pe-4 ">Tournament Name:</span>
                  <span>{propsMatchDetailData?.tournamentInfo?.label}</span>
                </div>
                <div className="mt-2">
                  <span className="pe-4 ">Tournament Formatt:</span>
                  <span>{propsMatchDetailData?.tournamentInfo?.format}</span>
                </div>
                <div className="mt-2">
                  <span className="pe-4 ">Tournament Formatt:</span>
                  {propsMatchDetailData?.tournamentInfo?.rules ? (
                    <a
                      href={propsMatchDetailData?.tournamentInfo?.rules}
                      target="_blank"
                    >
                      <span>View Rules</span>
                    </a>
                  ) : (
                    "N/A"
                  )}
                </div>
              </Col>
              <Col xs={12}>
                <h4>Winning Team Information</h4>
                <div className="mt-2">
                  Team Name : {propsMatchDetailData?.winningTeamInfo?.teamName}
                </div>
                <Row>
                  {propsMatchDetailData?.winningTeamInfo?.users.map(
                    (user: any, i: any) => (
                      <>
                        <Col xs={4}>
                          <Card className="UserCard1">
                            <span> Name : {user?.name} </span>
                            <br />
                            <span> Phone : {user?.phoneNumber} </span>
                            <br />
                            <span> Email : {user?.email} </span>
                            <br />
                            <span> Game Rank : {user?.gameRank} </span>
                            <br />
                            <span> DiscordId : {user?.discordId} </span>
                          </Card>
                        </Col>
                      </>
                    )
                  )}
                </Row>
              </Col>
              <Col xs={12}>
                <h4>Loosing Team Information</h4>
                <div className="mt-2">
                  Team Name : {propsMatchDetailData?.lossingTeamInfo?.teamName}
                </div>
                <Row>
                  {propsMatchDetailData?.lossingTeamInfo?.users.map(
                    (user: any, i: any) => (
                      <>
                        <Col xs={4}>
                          <Card className="UserCard1">
                            <span> Name : {user?.name} </span>
                            <br />
                            <span> Phone : {user?.phoneNumber} </span>
                            <br />
                            <span> Email : {user?.email} </span>
                            <br />
                            <span> Game Rank : {user?.gameRank} </span>
                            <br />
                            <span> DiscordId : {user?.discordId} </span>
                          </Card>
                        </Col>
                      </>
                    )
                  )}
                </Row>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewMatchDetails;
