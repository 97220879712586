import {types } from "./actionTypes";

// creating action
export const createUser = (payload:any, callback:any) =>{
    return {
        type:types.REGISTER_USER,
        payload,
        callback
    }
}
export const checkUserActivate = (payload:any, callback:any) =>{
    return {
        type:types.CHECK_USER_ACTIVATE,
        payload,
        callback
    }
}
export const checkLoginUser = (payload:any, callback:any) =>{
    return {
        type:types.CHECK_LOGIN_USER,
        payload,
        callback
    }
}
export const sendingForgotPasswordLink = (payload:any, callback:any) =>{
    return {
        type:types.SENDING_FORGOTPASSWORD_LINK,
        payload,
        callback
    }
}
export const sendingPasswordResetToken = (payload:any, callback:any) =>{
    return {
        type:types.PASSWORD_RESET_TOKEN,
        payload,
        callback
    }
}
export const updatePassword= (payload:any, callback:any) =>{
    return {
        type:types.UPDATE_PASSWORD,
        payload,
        callback
    }
}
export const checkSocialRegistration= (payload:any, callback:any) =>{
    return {
        type:types.CHECK_SOCIAL_REGISTERING,
        payload,
        callback
    }
}
export const checkSocialLogin= (payload:any, callback:any) =>{
    return {
        type:types.CHECK_SOCIAL_LOGIN,
        payload,
        callback
    }
}
export const activeUsers= (filters:any, callback:any) =>{
    return {
        type:types.ACTIVE_USERS,
        filters,
        callback
    }
}
// export const getDiscordDetails= ( callback:any) =>{
//     return {
//         type:types.GET_DISCORD_DETAILS,
//         callback
//     }
// }