import React, { useEffect, useState,useCallback } from "react";
import { connect } from "react-redux";
import { Row, Col } from "reactstrap";
import { Grid, Card, CardContent, CardActions } from "@mui/material";
import useRazorpay from "react-razorpay";
//store
import { createOrder, verifyOrder } from "../../Store/razorPay/actions";
import { getProducts } from "../../Store/products/actions";

//components
import SideBar from "../../components/verticalMenu/SideBar";
import notifications from "../../components/Notifications/Notification";
import Loader from "../../components/Loader/Loader";

declare global {
  interface Window {
    Razorpay: any;
  }
}
const Products = (props: any) => {
  const [loading, setLoading] = useState(false);
  const Razorpay = useRazorpay();
  useEffect(() => {
    getAllProducts();
  }, []);
  const getAllProducts = () => {
    setLoading(true);
    props.getProducts((res: any) => {
      setLoading(false);
      // if (res.success) {
      //   notifications({
      //     title: "Success!",
      //     message: "Products fetched successfully!",
      //     type: "success",
      //   });
      // } else {
      //   notifications({
      //     title: "Error!",
      //     message: "Failed !",
      //     type: "danger",
      //   });
      // }
    });
  };
  const initialPayment = useCallback((data: any, product: any) => {
    console.log("data", data);
    let otherInfo = data?.data;
    const options: any = {
      key: process.env.REACT_APP_RAZOR_PAY_ID,
      amount: data?.data?.amount,
      currency: data?.data?.currency,
      name: product?.meta_title,
      description: product?.meta_description,
      image: product?.cover_image,
      order_id: data?.data?.id,
      handler: async (response: any) => {
        console.log("response", response);
        setLoading(true);
        props.verifyOrder(
          {
            notes: otherInfo?.notes,
            amount: otherInfo.amount,
            amount_due: otherInfo.amount_due,
            amount_paid: otherInfo.amount_paid,
            attempts: otherInfo.attempts,
            entity: otherInfo.entity,
            order_id: otherInfo.id,
            product_id: product?.id,
            receipt: otherInfo.receipt,
            status: otherInfo.status,
            razorpay_order_id: response.razorpay_order_id,
            razorpay_payment_id: response.razorpay_payment_id,
            razorpay_signature: response.razorpay_signature,
          },
          (res: any) => {
            setLoading(false);
            if (res.success) {
              notifications({
                title: "Success!",
                message: res?.message,
                type: "success",
              });
            } else {
              notifications({
                title: "Error!",
                message: res?.message,
                type: "danger",
              });
            }
          }
        );
      },
      theme: {
        color: "#3399cc",
      },
    };
    const rzp1 = new Razorpay(options);
    rzp1.open();
  },[Razorpay]);
  let auth: any = localStorage.getItem("authUser") || sessionStorage.getItem("authUser");
  let authUser: any = JSON.parse(auth) || "";
  const handleOnclickBuy = (product: any) => {
    setLoading(true);
    props?.createOrder(
      {
        amount: product?.product_prices[0]?.price,

        user_id: authUser?._id,
        product_id: Number(product?.id),
      },
      (res: any) => {
        setLoading(false);
        console.log("data", res);
        if (res.success) {
          notifications({
            title: "Success!",
            message: res?.message,
            type: "success",
          });
          initialPayment(res, product);
        } else {
          notifications({
            title: "Error!",
            message: res?.message,
            type: "danger",
          });
        }
      }
    );
  };
  console.log("getAllProducts", props.getAllProductsDetails);
  return (
    <>
      <div className="pageContent" >
  <div className="pageContentChild">
   <div>
   <SideBar></SideBar>
   </div>
   <div className="p-3 w-100">
   <h3>Product Details</h3>
            {loading ? (
              <>
                <div>
                  <Loader />
                </div>
              </>
            ) : (
              <>
                <Row>
                  {props.getAllProductsDetails?.message.map(
                    (product: any, i: any) => {
                      return (
                        <React.Fragment key={i}>
                          <Col xs={12} sm={6} md={4} className="p-3">
                            <Card className="ProductCard p-4">
                              <img
                                src={product?.cover_image}
                                width="100%"
                                height="300px"
                                alt="ProductImage"
                              />
                              <CardContent>
                                <h4 className="">{product?.meta_title}</h4>
                                <p className="mt-2">
                                  {product?.meta_description}
                                </p>
                                <h5 className="mt-2">
                                  {product?.product_prices[0]?.currency}{" "}
                                  {product?.product_prices[0]?.price}
                                </h5>
                              </CardContent>
                              <CardActions className="d-flex justify-content-center">
                                <button
                                  className="ProductButton"
                                  onClick={() => {
                                    handleOnclickBuy(product);
                                  }}
                                >
                                  Buy Now
                                </button>
                                <a
                                  rel="noreferrer"
                                  href={`https://gaminggeeks.gg/store/${product?.id}`}
                                  target="_blank"
                                >
                                  <button className="productDetailsButton">
                                    More Details...
                                  </button>
                                </a>
                              </CardActions>
                            </Card>
                          </Col>
                        </React.Fragment>
                      );
                    }
                  )}
                </Row>
              </>
            )}
     </div>
     </div>
     </div>
  
      
    </>
  );
};

const mapStateToProps = (state: any) => {
  console.log("state", state);
  return {
    ...state,
    getAllProductsDetails: state.productsReducer.getProductsDetails,
  };
};
export default connect(mapStateToProps, {
  getProducts,
  createOrder,
  verifyOrder,
})(Products);
