import React ,{useEffect,useState}from "react";
import { connect } from "react-redux";
import { Store } from "react-notifications-component";
import jwt from "jwt-decode";
//use useParams
import { useParams, useLocation, useSearchParams,useNavigate } from "react-router-dom";

import { checkUserActivate } from "../../../Store/auth/registration/actions";

function ActivateUser(props: any) {
  const [resData, setResData] = useState({success:false,message:""})
  const navigate = useNavigate()
    const params = useLocation();
    const search = useLocation().search;
    const userId = new URLSearchParams(search).get("userId");
    const userActivationToken = new URLSearchParams(search).get(
      "userActivationToken"
    );
  useEffect(()=>{
    
    props.checkUserActivate({
      userId: userId,
      userActivationToken: userActivationToken
    },(res:any)=>{
      setResData({
        success:res?.success,
        message:res?.message
      })
      if (res.success) {
        let authUser: any = jwt(res?.data?.accessToken);
        let rememberMe: any = localStorage.getItem("rememberMe");
        if (rememberMe === true) {
          localStorage.setItem("authUser", JSON.stringify(authUser));
          localStorage.setItem("accessToken", res.data.accessToken);
          localStorage.setItem("refreshToken", res.data.refreshToken);
        } else {
          sessionStorage.setItem("authUser", JSON.stringify(authUser));
          sessionStorage.setItem("accessToken", res.data.accessToken);
          sessionStorage.setItem("refreshToken", res.data.refreshToken);
        }
       
          Store.addNotification({
            title: "Success!",
            message: res?.message,
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true,
            },
          });
          navigate("/user/tournament");
          window.scrollTo(0, 0)
        } else {
          Store.addNotification({
            title: "Error!",
            message: res?.message,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true,
            },
          });
        }
    })
  },[userId,userActivationToken])

  return (
    <>
      <div
        className=" text-white"
        style={{
          backgroundColor: "#080404",
          height: "100vh",
        }}
      >
        <div className="d-flex justify-content-center textposition">
          <span className="text-white fontRoboto204">
           {resData?.success === false ?
           <div className="d-flex justify-content-center textposition">
           <span className="text-white fontRoboto204">
            {resData?.message}
           </span>
         </div>:""}
          </span>
        </div>
      </div>
     
    </>
  );
}
const mapStateToProps = (state: any) => {
  return {
    ...state,

  };
};
export default connect(mapStateToProps, { checkUserActivate })(ActivateUser);
