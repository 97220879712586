import React from "react";
import Slider from "react-slick";
import { Card, Row, Col } from "reactstrap";
var taggedVideos: any = [];
const MVPMoments = (props: any) => {
  const settings = {
    // dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1224,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  if (props.TaggedVideos !== undefined && props.TaggedVideos?.length > 0) {
    taggedVideos = taggedVideos.concat(props.TaggedVideos[0]?.featured,props.TaggedVideos[0]?.nonFeatured)
    // taggedVideos = [
    //   props.TaggedVideos[0]?.featured?.length >0 && ...props.TaggedVideos[0]?.featured,
    //   ...props.TaggedVideos[0]?.nonFeatured,
    // ];
  }
  return (
    <>
      <Row className="">
        <Col xs={0} md={1}></Col>
        <Col xs={12} md={10}>
          {taggedVideos?.length > 0 ? (
            <>
              <Slider {...settings}>
                {taggedVideos?.length > 0
                  ? taggedVideos.map((video: any, i: any) => {
                      return (
                        <React.Fragment key={i}>
                          <Card>
                            <iframe
                              {...props}
                              id="ytplayer"
                              title={video?.split("=")[1]}
                              type="text/html"
                              width="100%"
                              height="360"
                              src={`https://www.youtube.com/embed/${
                                video?.split("=")[1]
                              }?autoplay=0&origin=http://example.com&controls=0&rel=1`}
                              frameBorder="0"
                            ></iframe>
                          </Card>
                        </React.Fragment>
                      );
                    })
                  : ""}
              </Slider>
            </>
          ) : (
            <div className="NoTaggedVideos">
              <h4>No tagged videos are available</h4>
            </div>
          )}
        </Col>
        <Col xs={0} md={1}></Col>
      </Row>
    </>
  );
};

export default MVPMoments;
