import { types } from "./actionTypes";
const initialState = {
  addUserTournament: null,
  tournamentDetail: null,
  updateUserTournamentDetails: null,
  deleteUserTournaments: null,
  preSignedUrlTeamLogo: null,
  uploadImages: null,
  invitedPlayers: null,
};
const userTournamentReducer = (state: any = initialState, action: any) => {
  // console.log("action",action.payload)
  switch (action.type) {
    case types.ADD_USER_TOURNAMENT_SUCCESS:
      state = {
        ...state,
        addUserTournament: action.payload,
      };
      return state;
      break;
    case types.FETCH_USER_TOURNAMENTS_SUCCESS:
      state = {
        ...state,
        tournamentDetail: action.payload,
      };
      return state;
      break;
    case types.FETCH_INVITED_PLAYER_SUCCESS:
      state = {
        ...state,
        invitedPlayers: action.payload,
      };
      return state;
      break;
    case types.EDIT_USER_TOURNAMENT_SUCCESS:
      state = {
        ...state,
        updateUserTournamentDetails: action.payload,
      };
      return state;
      break;
    case types.DELETE_USER_TOURNAMENT_SUCCESS:
      state = {
        ...state,
        deleteUserTournaments: action.payload,
      };
      return state;
      break;
    case types.PRESIGNED_URL_TEAM_LOGO_SUCCESS:
      state = {
        ...state,
        preSignedUrlTeamLogo: action.payload,
      };
      return state;
      break;
    case types.UPLOAD_IMAGES_TEAM_LOGO_SUCCESS:
      state = {
        ...state,
        uploadImages: action.payload,
      };
      return state;
      break;
    default:
      return state;
  }
};

export default userTournamentReducer;
