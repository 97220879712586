export const types = {
     REGISTER_USER : "REGISTER_USER",
     REGISTER_USER_SUCCESS : "REGISTER_USER_SUCCESS",
     CHECK_LOGIN_USER : "CHECK_LOGIN_USER",
     CHECK_LOGIN_USER_SUCCESS : "CHECK_LOGIN_USER_SUCCESS",
     CHECK_USER_ACTIVATE:"CHECK_USER_ACTIVATE",
     CHECK_USER_ACTIVATE_SUCCESS:"CHECK_USER_ACTIVATE_SUCCESS",
     SENDING_FORGOTPASSWORD_LINK:"SENDING_FORGOTPASSWORD_LINK",
     SENDING_FORGOTPASSWORD_LINK_SUCCESS:"SENDING_FORGOTPASSWORD_LINK_SUCCESS",
     PASSWORD_RESET_TOKEN:"PASSWORD_RESET_TOKEN",
     PASSWORD_RESET_TOKEN_SUCCESS:"PASSWORD_RESET_TOKEN_SUCCESS",
     UPDATE_PASSWORD:"UPDATE_PASSWORD",
     UPDATE_PASSWORD_SUCCESS:"UPDATE_PASSWORD_SUCCESS",
     CHECK_SOCIAL_REGISTERING:"CHECK_SOCIAL_REGISTERING",
     CHECK_SOCIAL_REGISTERING_SUCCESS:"CHECK_SOCIAL_REGISTERING_SUCCESS",
     GET_DISCORD_DETAILS:"GET_DISCORD_DETAILS",
     GET_DISCORD_DETAILS_SUCCESS:"GET_DISCORD_DETAILS_SUCCESS",
     CHECK_SOCIAL_LOGIN:"CHECK_SOCIAL_LOGIN",
     CHECK_SOCIAL_LOGIN_SUCCESS:"CHECK_SOCIAL_LOGIN_SUCCESS",
     ACTIVE_USERS:"ACTIVE_USERS",
     ACTIVE_USERS_SUCCESS:"ACTIVE_USERS_SUCCESS"
}