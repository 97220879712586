import React from "react";
import { Card, Row, Col,Button } from "reactstrap";
import FoterDiv from "../../components/common/FoterDiv";
import WhatIsMoreCarousel from "../../components/common/WhatIsMoreCarousel";
const TournamentCompleted = () => {
  return (
    <>
      <div className="pageContent">
        <div className="d-grid justify-content-center text-center text-white congratulationDiv">
          <h1>Congratulation!</h1>
          <h1>You have Registered</h1>
        </div>
        <div className="d-flex justify-content-center mt-5 text-white headerText">
          <h4>Last tournaments best moments</h4>
        </div>
        <Row className="LastTournamentCardParent">
          <Col xs={0} md={1}></Col>
          <Col xs={12} md={10}>
            <Row>
              <Col xs={12} md={6}>
                <Card className="LastTournamentCard"></Card>
              </Col>
              <Col xs={12} md={6}>
                <Card className="LastTournamentCard"></Card>
              </Col>
            </Row>
          </Col>
          <Col xs={0} md={1}></Col>
        </Row>
        <div>
          <div className="d-flex justify-content-center mt-5 text-white headerText">
            <h1>Upskill Your Team's Game With GG</h1>
          </div>
          <div className="d-flex justify-content-center  text-white headerTextd-flex justify-content-center mt-5 text-white headerText">
            <h4>
              Increase the chances of winning the tournament <br />
              with GG's pro coaching
            </h4>
          </div>
          <WhatIsMoreCarousel />
        </div>
        <div className="d-grid justify-content-center buttonscss">
          <Button>
          Explore GG Programs
          </Button>
          <Button className="mt-3">
          Book Your Free Slot
          </Button>
        </div>
        <div>
        <FoterDiv />
        </div>
      </div>
    </>
  );
};

export default TournamentCompleted;
