import React, { useEffect, useState } from "react";
import { Row, Col, Card } from "reactstrap";
import { useNavigate, useLocation } from "react-router";
import { DatePicker, Stack } from "rsuite";
import { connect } from "react-redux";
import { Grid, Pagination } from "@mui/material";
import { MdOutlineDelete } from "react-icons/md";
import { Link } from "react-router-dom";
import { FiArrowLeft } from "react-icons/fi";
//components
import Index from "../../components/common";
import Loader from "../../components/Loader/Loader";
import SideBar from "../../components/verticalMenu/SideBar";
import notifications from "../../components/Notifications/Notification";
//images
import Logo from "../../assets/images/Favicon Logo.png";
//store
import {
  getTaggedVideo,
  deleteTaggedVideoById,
} from "../../Store/tournament/actions";
import {
  fecthScoresByTournamentLeader,
  getTournamentWisePlayerAndTeamLeaderBoard,
} from "../../Store/leaderBoard/actions";
import { deleteTaggedVideo } from "../../Store/tournament/actions";
//global variable
var offset: number = 0;
var limit: number = 10;
var totCnt = 0;
var totPages = 0;
var toVal = 0;
var fromVal = 0;
var data: any = [];
let HeaderData = {
  columns: [
    {
      label: "Team Logo",
      field: "teamLogo",
      width: 2,
    },
    {
      label: "Team Name",
      field: "teamName",
      width: 2,
    },
    {
      label: "Player Name",
      field: "fullName",
      width: 2,
    },
    {
      label: "Total Kills",
      field: "totalKills",
      width: 1,
    },
    {
      label: "Total CombatScore",
      field: "totalCombatScore",
      width: 1,
    },
    {
      label: "Position",
      field: "position",
      width: 1,
    },
  ],
  // rows: trips,
};
const ViewTournament = (props: any) => {
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const navigator = useNavigate();
  const tournamentSearch = useLocation().search;
  const tournamentId = new URLSearchParams(tournamentSearch).get(
    "tournamentId"
  );
  console.log("location", location, tournamentId);
  const tournamentDetails = location?.state?.tournamentDetails;
  console.log("tournamentDetails Test", tournamentDetails);
  useEffect(() => {
    if (tournamentId) {
      setLoading(true);
      props.fetchTournamentById(tournamentId, (res: any) => {
        setLoading(false);
        if (res.success) {
          notifications({
            title: "Success!",
            message: res?.message,
            type: "success",
          });
        } else {
          notifications({
            title: "Error!",
            message: res?.message,
            type: "danger",
          });
        }
      });
    }
  }, []);
  useEffect(() => {
    // getAllScoresByTournament();
    getAllTournamentWiseTeamsAndPlayersLeaderBoard();
    fetchTaggedVideos();
  }, []);
  const fetchTaggedVideos = () => {
    props.getTaggedVideo(
      {
        tournamentId: tournamentDetails?._id,
      },
      (res: any) => {}
    );
  };
  const getAllTournamentWiseTeamsAndPlayersLeaderBoard = () => [
    props.getTournamentWisePlayerAndTeamLeaderBoard(
      {
        offset: 0,
        limit: 10,
        tournamentId: "637237940673114ebcdea5e7",
      },
      () => {}
    ),
  ];
  // const getAllScoresByTournament = () => {
  //   props.fecthScoresByTournamentLeader(
  //     0,
  //     10,
  //     { tournamentId: tournamentDetails?._id },
  //     () => {}
  //   );
  // };
  let handleChangePage = (event: any, val?: any) => {
    console.log("val", val);
    offset = (val - 1) * 10;
    toVal = offset + 10;
    setLoading(true);
    props.getTournamentWisePlayerAndTeamLeaderBoard(
      {
        offset: 0,
        limit: 10,
        tournamentId: "637237940673114ebcdea5e7",
      },
      (res: any) => {}
    );
  };
  const deleteTaggedVideoByvideo = (video: any, type: any) => {
    let videoId = props?.fetchFeturedVideos?.data?.docs[0]?._id;
    props.deleteTaggedVideoById(
      {
        taggedVideoId: videoId,
        videoUrl: video,
        featured: type?.featured,
      },
      (res: any) => {
        if (res.success) {
          notifications({
            title: "Success!",
            message: res?.message,
            type: "success",
          });
          fetchTaggedVideos();
        } else {
          notifications({
            title: "Error!",
            message: res?.message,
            type: "danger",
          });
        }
      }
    );
  };
  if (props.playersAndTeams !== undefined) {
    offset = props.playersAndTeams?.offset;
    console.log("test", offset, props.playersAndTeams);
    totCnt = props.playersAndTeams?.playersLength;
    fromVal = totCnt !== 0 ? offset + 1 : fromVal;
    toVal =
      props.playersAndTeams?.offset + props.playersAndTeams?.playersLength;
    totPages = Math.ceil(totCnt / 10);
    data = [];
    props.playersAndTeams?.players?.map((ele: any, i: any) => {
      data.push({
        teamLogo: (
          <span>
            {ele?.teamLogo ? (
              <img src={ele?.teamLogo} width="50px" height="50px" alt="Logo" />
            ) : (
              <img
                style={{
                  backgroundColor: "#000",
                }}
                src={Logo}
                width="50px"
                height="50px"
                alt="Logo"
              />
            )}
          </span>
        ),
        teamName: ele?.teamName,
        fullName: ele?.user[0]?.fullName,
        totalKills: ele?.totalKills,
        totalCombatScore: ele?.totalCombatScore,
        position: ele?.position,

        // matchId: ele?.matchId,
        // user: ele?.userInfo?.fullName,
        // email: ele?.userInfo?.email,
      });
    });
    console.log("data", data);
  }
  const deleteTaggedVideo = (tournamentId: any) => {
    console.log("tournamentId", tournamentId);
    props.deleteTaggedVideo(
      {
        tournamentId: tournamentId,
      },
      (res: any) => {
        if (res.success) {
          notifications({
            title: "Success!",
            message: res?.message,
            type: "success",
          });
          fetchTaggedVideos();
        } else {
          notifications({
            title: "Error!",
            message: res?.message,
            type: "danger",
          });
        }
      }
    );
  };
  // console.log("props",props?.tournamentByIdDetails)
  return (
    <>
      <div
        //  className="pageContent1"
        style={{
          backgroundColor: "#000",
          minHeight: "100%",
        }}
      >
        <div className="pageContentChild">
          <div>
            <SideBar></SideBar>
          </div>
          <div className="p-3 w-100">
            {loading ? (
              <>
                <div>
                  <Loader />
                </div>
              </>
            ) : (
              <>
                <div>
                  <Link to="/tournaments">
                    <button className="m-4">
                      <FiArrowLeft className="cursor-pointer editIcon " />
                    </button>
                  </Link>
                </div>
                <Grid container className="viewTournament">
                  <Grid item xs={12} md={6} lg={4} className="p-4">
                    <label className="mb-3 text-white">Game</label>
                    <input
                      type="string"
                      name="game"
                      value={tournamentDetails?.gameInfo?.label}
                      readOnly
                    />
                  </Grid>

                  <Grid item xs={12} md={6} lg={4} className="p-4">
                    <label className="mb-3 text-white">Tournament Name</label>
                    <input
                      type="text"
                      id="tournamentName"
                      name="label"
                      value={tournamentDetails?.label}
                      readOnly
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={4} className="p-4">
                    <label className="mb-3 text-white">Tournament Format</label>
                    <input
                      type="string"
                      name="formatt"
                      value={tournamentDetails?.format}
                      readOnly
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={4} className="p-4">
                    <label className="mb-3 text-white">
                      Max. number of teams
                    </label>
                    <input
                      type="number"
                      name="teamSize"
                      value={tournamentDetails?.teamSize}
                      readOnly
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={4} className="p-4">
                    <label className="mb-3 text-white">
                      Max. number of players in a team{" "}
                    </label>
                    <input
                      type="number"
                      name="playerInTeam"
                      value={tournamentDetails?.playerInTeam}
                      className=" inp_text text-capitalize"
                      readOnly
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={4} className="p-4">
                    <label className="mb-3 text-white">Start Date</label>
                    <div>
                      <DatePicker
                        style={{
                          width: "100%",
                        }}
                        cleanable={false}
                        format="yyyy-MM-dd HH:mm"
                        name="startDate"
                        id="startDate"
                        value={new Date(tournamentDetails?.startDate) || null}
                        readOnly
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4} className="p-4">
                    <label className="mb-3 text-white">End Date</label>
                    <div>
                      <DatePicker
                        style={{
                          width: "100%",
                        }}
                        cleanable={false}
                        format="yyyy-MM-dd HH:mm"
                        name="endDate"
                        id="endDate"
                        value={new Date(tournamentDetails?.endDate) || null}
                        readOnly
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4} className="p-4">
                    <label className="mb-3 text-white">
                      Max. number of players in a team{" "}
                    </label>
                    <input
                      type="number"
                      name="playerInTeam"
                      value={tournamentDetails?.playerInTeam}
                      className=" inp_text text-capitalize"
                      readOnly
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={4} className="p-4">
                    <label className="mb-3 text-white">Tournament Form</label>
                    <input
                      type="text"
                      name="tournamentForm"
                      value={tournamentDetails?.tournamentForm}
                      className=" inp_text text-capitalize"
                      readOnly
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={4} className="p-4">
                    <label className="mb-3 text-white">Region</label>
                    <input
                      type="text"
                      name="region"
                      value={tournamentDetails?.region}
                      className=" inp_text text-capitalize"
                      readOnly
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={4} className="p-4">
                    <label className="mb-3 text-white">Server</label>
                    <input
                      type="text"
                      name="server"
                      value={tournamentDetails?.server}
                      className=" inp_text text-capitalize"
                      readOnly
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={4} className="p-4">
                    <label className="mb-3 text-white">Prize Pool</label>
                    <input
                      type="Number"
                      name="poolPrize"
                      value={tournamentDetails?.poolPrize}
                      className=" inp_text text-capitalize"
                      readOnly
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={4} className="p-4">
                    <label className="mb-3 text-white">Tournament Logo</label>
                    <div>
                      <span className="ms-3">
                        <img
                          src={tournamentDetails?.logo || ""}
                          alt="TournamentLogo"
                          width="100px"
                          height="100px"
                        />
                      </span>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4} className="p-4">
                    <label className="mb-3 text-white">Prize Pool Image</label>
                    <div>
                      <embed
                        src={tournamentDetails?.prizePoolImage}
                        width="100px"
                        height="100px"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4} className="p-4">
                    <label className="mb-3 text-white">Thumbnail</label>
                    <div>
                      <embed
                        src={tournamentDetails?.thumbnail}
                        width="100px"
                        height="100px"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4} className="p-4">
                    <label className="mb-3 text-white">Fixtures</label>
                    <div>
                      <embed
                        src={tournamentDetails?.fixture}
                        width="100px"
                        height="100px"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4} className="p-4">
                    <label className="mb-3 text-white">Tournament Rule</label>
                    <div>
                      <embed
                        src={tournamentDetails?.rules}
                        width="100%"
                        height="200px"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} className="p-4">
                    {props?.fetchFeturedVideos?.data?.docs[0]?.featured
                      ?.length ? (
                      <>
                        <div className="d-flex flex-row">
                          <h3>Featured video</h3>
                        </div>
                      </>
                    ) : (
                      ""
                    )}

                    <Row>
                      {props?.fetchFeturedVideos?.data?.docs !== undefined
                        ? props?.fetchFeturedVideos?.data?.docs[0]?.featured.map(
                            (video: any, i: any) => {
                              return (
                                <React.Fragment key={1}>
                                  <Col xs={12} md={4}>
                                    <span className="cursor-pointer">
                                      <MdOutlineDelete
                                        onClick={() =>
                                          deleteTaggedVideoByvideo(video, {
                                            featured: "1",
                                          })
                                        }
                                        className="deleteIcon"
                                      />
                                    </span>
                                    <iframe
                                      {...props}
                                      id="ytplayer"
                                      title={video?.split("=")[1]}
                                      type="text/html"
                                      width="100%"
                                      height="360"
                                      src={`https://www.youtube.com/embed/${
                                        video?.split("=")[1]
                                      }?autoplay=0&origin=http://example.com&controls=0&rel=1`}
                                      frameBorder="0"
                                    ></iframe>
                                  </Col>
                                </React.Fragment>
                              );
                            }
                          )
                        : ""}
                    </Row>
                  </Grid>
                  <Grid item xs={12} className="p-4">
                    {props?.fetchFeturedVideos?.data?.docs[0]?.nonFeatured
                      ?.length ? (
                      <h3>NonFeatured video</h3>
                    ) : (
                      ""
                    )}
                    <Row>
                      {props?.fetchFeturedVideos?.data?.docs !== undefined
                        ? props?.fetchFeturedVideos?.data?.docs[0]?.nonFeatured.map(
                            (video: any, i: any) => {
                              return (
                                <React.Fragment key={1}>
                                  <Col xs={12} md={4}>
                                    <MdOutlineDelete
                                      onClick={() =>
                                        deleteTaggedVideoByvideo(video, {
                                          featured: " 0 ",
                                        })
                                      }
                                      className="deleteIcon"
                                    />
                                    <iframe
                                      {...props}
                                      id="ytplayer"
                                      title={video?.split("=")[1]}
                                      type="text/html"
                                      width="100%"
                                      height="360"
                                      src={`https://www.youtube.com/embed/${
                                        video?.split("=")[1]
                                      }?autoplay=0&origin=http://example.com&controls=0&rel=1`}
                                      frameBorder="0"
                                    ></iframe>
                                  </Col>
                                </React.Fragment>
                              );
                            }
                          )
                        : ""}
                    </Row>
                  </Grid>
                  <Grid item xs={12} className="p-4">
                    <h3 className="mb-2">Tournament Scores</h3>
                    <div>
                      {totCnt ? (
                        <>
                          <Index
                            data={data}
                            headerData={HeaderData}
                            limit={limit}
                            rowHeight={"60px"}
                          />

                          <div className=" mt-4">
                            <Row>
                              <Col xs={6} className="text-white">
                                <Stack spacing={2}>
                                  <Pagination
                                    className="pagination"
                                    size="small"
                                    shape="rounded"
                                    page={offset / 10 + 1}
                                    count={totPages}
                                    color="primary"
                                    // onChange={(e)=>{handleChangePage(e,offset / 10 + 1)}}
                                    onChange={(e, val) => {
                                      handleChangePage(e, val);
                                      console.log("event", e, val);
                                    }}
                                    defaultPage={1}
                                    siblingCount={1}
                                  />
                                </Stack>
                              </Col>
                              <Col xs={6}>
                                <span className="text-white">
                                  Showing {fromVal} to {toVal} of {totCnt}{" "}
                                  entries
                                </span>
                              </Col>
                            </Row>
                          </div>
                        </>
                      ) : (
                        <div className="NoDataDiv">
                          <span>No data exists</span>
                        </div>
                      )}
                    </div>
                    <button
                      className="submitButton"
                      onClick={() => {
                        navigator("/match-details", {
                          state: {
                            tournament: location?.state?.tournamentDetails,
                            mode: "filter",
                          },
                        });
                        window.scrollTo(0, 0)

                      }}
                    >
                      View Matchs
                    </button>
                  </Grid>
                </Grid>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  console.log(
    "state",
    state,
    state?.tournamentReducer?.tournamentDetail?.data?.docs
  );
  return {
    ...state,
    fetchScoresByTournament:
      state?.leaderBoardReducer?.getScoresByTournamntLeader,
    fetchFeturedVideos: state?.tournamentReducer?.getTaggedVidoes,
    playersAndTeams:
      state?.leaderBoardReducer?.getTournamentWisePlayersAndTeams?.data,
    //   tournamentByIdDetails: state?.tournamentReducer?.tournamentDetail?.data?.docs,
  };
};
export default connect(mapStateToProps, {
  getTaggedVideo,
  deleteTaggedVideoById,
  fecthScoresByTournamentLeader,
  deleteTaggedVideo,
  getTournamentWisePlayerAndTeamLeaderBoard,
  // fetchTournamentById
})(ViewTournament);
