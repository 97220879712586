import React from "react";
import { Button, Row, Col, Card } from "reactstrap";
import YourwordsCarousal from "../../components/common/YourwordsCarousal";
import FrequentlyAsked from "../../components/common/FrequentlyAsked";
import FoterDiv from "../../components/common/FoterDiv";
import raze from "../../assets/images/raze.png";
import WhatMoreIntermediateCarousel from "./WhatMoreIntermediateCarousel";

// videos
import TeamCom from "../../assets/videos/Intermediate/Adcance Team Comp.mov";
import AimTraining from "../../assets/videos/Intermediate/Advance Aim Training.mov";
import MapTac from "../../assets/videos/Intermediate/Map Specific Tactics.mov";
import MapTraining from "../../assets/videos/Intermediate/Map training.mov";

var frequentlyAskedQuestions = [
  {
    question: "Who is the course for ?",
    answer: (
      <p>
        The courses are for gamers - newbies and professionals both. According
        to the level of gamers we have divided the course in three parts.
        <br />
        <br />
        1. Beginner Level - For complete newbies, who want to learn Valorant
        from scratch.
        <br />
        2. Intermediate level - OG’s looking to improve their game and ranking
        in Valorant. <br />
        3. Advance Level - Professional gamers who are looking to win every game
        they play.
      </p>
    ),
  },
  {
    question: "Why are our courses better than other video tutorials ? ",
    answer: (
      <p>
        {/* YouTube is a universal option and has some great content. But here’s
        what extra gaming geek has to offer that will help you upskill faster-{" "}
        <br /><br /> */}
        1. Live Interactive Sessions <br />
        2. Individually assessed by Professional coaches <br />
        3. Structural learning - Fast Learning without leaving anything! <br />
        4. Daily Drills and Weekly Scrims <br />
        5. Exclusive GG Global tournaments - Prize upto 20K
      </p>
    ),
  },
  {
    question: "What will you learn in this program ? ",
    answer: (
      <p>
        Valorant programs are combined learning of practice and strategical
        knowledge. With every lesson, you gain an advantage over your enemy.{" "}
        <br />
        <br />
        1.Map tactics and strategies - smoke and ult line-ups, choke points,
        cover points, and much more. <br />
        2.Best agents to use for different maps and based on your skills.
        <br />
        3.The Dos-and-Donts of Valorant. <br />
        4.Practice drills to master your aim by our pro coaches. <br />
        <br />
        You learn to play like a pro by the top OGs of Valorant. Furthermore,
        you get personalised drills and weekly scrims where you implement what
        you learn with the own teammates.
      </p>
    ),
  },
  {
    question: "What is Exclusive GG Global Tournaments ?",
    answer:
      "After the completion of the course, gamers are eligible to participate in our Exclusive GG Global Tournaments with Prize Pool of ₹20K.",
  },
  {
    question: "How will you attend the session ?",
    answer:
      "All are sessions are held live. You will be provided with a link to join the live session in your learning management system (LMS).",
  },
  {
    question: "What if you miss a session ? ",
    answer:
      "No worries, all the live sessions are recorded. You will get the recording to all your sessions in your LMS",
  },
  {
    question: "What all is included in the program ? ",
    answer: (
      <p>
        Live Sessions - All courses irrespective of game or level will have live
        sessions with our pro valorant e-coaches. You can ask your doubts,
        interact with coaches, and be a part of a healthy active community.
        <br />
        <br />
        Weekly Scrims - After completing all the live sessions, you will participate
        in a weekly scrim where our valorant coaches will give you pro tips on
        improving your game sense and what to avoid - the dos and don'ts of each
        game. <br />
        <br />
        Video analysis - To get personalized feedback from our Valorant coaches, you
        will be asked to upload 150 seconds video of your game. <br />
        <br />
        Daily practice drills - We have curated a lot of practice drills for you
        to improve specific game skills which will be uploaded on a weekly basis.
        <br />
        <br />
      </p>
    ),
  },
  {
    question: "Are the classes live ? ",
    answer: "Yes, all the classes are live, engaging and fun. ",
  },
  {
    question: "What is included in the sessions ?",
    answer:
      "Each live session will go in-depth on a specific topic to help you become a better gamer and rank faster. From game tactics, aim training, crosshair settings, and attack & defend strategies, we have it all covered.",
  },
];
const Intermediate = (props: any) => {
  return (
    <>
      <div className="pageContent Intermediate">
        <div className="yourJourney">
          <div className="d-flex justify-content-center">
            <img src={raze} alt="yoru" />
          </div>
          <div className="d-grid justify-content-center text-center yourJourneyText">
            <h1>Your Journey Starts Here</h1>
            <h2>Welcome to Valorant Intermediate Program</h2>
            <span>
              <Button>
                Enroll Now
                <div className="Redeemtext">Redeem 50% Discount</div>
              </Button>
            </span>
          </div>
        </div>
        <div>
          <div className="d-flex justify-content-center text-white fontText">
            <h1>What do you get?</h1>
          </div>
          <div className="container">
            <Row className="mt-5">
              <Col xs={0} md={1}></Col>
              <Col xs={12} lg={4}>
                <Card className="whatDoyowantCard1"></Card>
              </Col>
              <Col xs={12} lg={6}>
                <div className="cardText1">
                  <h1>Advanced Valorant Tactics</h1>
                  <p>
                    Filled with map knowledge and game tactics, this session
                    will help you and your team rush towards seamless victory in
                    every round.
                  </p>
                </div>
              </Col>
              <Col xs={0} md={1}></Col>
            </Row>
            <div className="mt-5 mobileDisplay">
              <hr className="text-white" />
            </div>
            <Row className="mt-5">
              <Col xs={0} md={1}></Col>
              <Col xs={12} lg={4}>
                <Card className="whatDoyowantCard1">
                  <video
                    className="video-section"
                    // width="100%"
                    // height="100%"
                    
                    playsInline
                    // muted
                    loop
                    controls
                  >
                    <source src={MapTraining} type="video/mp4" />
                  </video>
                </Card>
              </Col>
              <Col xs={12} lg={6}>
                <div className="cardText1">
                  <h1>Advanced Map Tactics</h1>
                  <p>
                    The difference between victory and defeat, 8 times out of
                    10 is map knowledge. Gaining the knowledge of specific maps
                    is all you want to push to the rank you desire. In this
                    session, we decode the map Ascent.
                  </p>
                </div>
              </Col>
              <Col xs={0} md={1}></Col>
            </Row>
            <div className="mt-5 mobileDisplay">
              <hr className="text-white" />
            </div>
            <Row className="mt-5">
              <Col xs={0} md={1}></Col>
              <Col xs={12} lg={4}>
                <Card className="whatDoyowantCard1">
                  <video
                    className="video-section"
                    // width="100%"
                    // height="100%"
                  
                    loop
                    muted
                    controls
                  >
                    <source src={AimTraining} type="video/mp4" />
                  </video>
                </Card>
              </Col>
              <Col xs={12} lg={6}>
                <div className="cardText1">
                  <h1>Advance Aim Training</h1>
                  <p>
                    From picking up good gaming gears to customising your
                    crosshair, this session covers everything related to aiming
                    and how to master your aiming gaming.
                  </p>
                </div>
              </Col>
              <Col xs={0} md={1}></Col>
            </Row>
            <div className="mt-5 mobileDisplay">
              <hr className="text-white" />
            </div>
            <Row className="mt-5">
              <Col xs={0} md={1}></Col>
              <Col xs={12} lg={4}>
                <Card className="whatDoyowantCard1">
                  <video
                    className="video-section"
                    // width="100%"
                    // height="100%"
                    
                    loop
                    muted
                    controls
                  >
                    <source src={MapTac} type="video/mp4" />
                  </video>
                </Card>
              </Col>
              <Col xs={12} lg={6}>
                <div className="cardText1">
                  <h1>Map Specific Tactics</h1>
                  <p>
                    After gaining knowledge of the map, it’s quite essential to
                    explore all scenarios arising in a particular map. In this
                    session, we go deep into attacking, defending, and mid play
                    of the map Ascent.
                  </p>
                </div>
              </Col>
              <Col xs={0} md={1}></Col>
            </Row>
            <div className="mt-5 mobileDisplay">
              <hr className="text-white" />
            </div>
            <Row className="mt-5">
              <Col xs={0} md={1}></Col>
              <Col xs={12} lg={4}>
                <Card className="whatDoyowantCard1">
                  <video
                    className="video-section"
                    // width="100%"
                    // height="100%"
                    {...props}
                   
                    loop
                    muted
                    controls
                    src={TeamCom} type="video/mp4"
                  >
                    {/* <source  /> */}
                  </video>
                </Card>
              </Col>
              <Col xs={12} lg={6}>
                <div className="cardText1">
                  <h1>Advance Team Composition</h1>
                  <p>
                    In this last session, we get inside the battle field and
                    learn in-depth about character synergy. We will discuss
                    which agents are essential in the game and how to combine
                    their abilities for a guaranteed win.
                  </p>
                </div>
              </Col>
              <Col xs={0} md={1}></Col>
            </Row>
            <div className="mt-5 mobileDisplay">
              <hr className="text-white" />
            </div>
          </div>
        </div>
        <div className="sepDiv"></div>
        <div className="mt-5">
          <div className="d-flex justify-content-center text-white mt-5 fontText">
            <h1>What’s more?</h1>
          </div>
          <div className="whatMoreParentDiv">
            <WhatMoreIntermediateCarousel />
          </div>
        </div>
        <div className="d-grid justify-content-center text-center enrollowBtn">
          <span>
            <Button>
              Enroll Now !<div className="Redeemtext">Redeem 50% Discount</div>
            </Button>
          </span>
        </div>
        <div className="sepDiv"></div>
        <div>
          <div className="d-flex justify-content-center text-white fontText">
            <h1>What our Geeks say?</h1>
          </div>
          <div>
            <Row>
              <Col xs={1}></Col>
              <Col xs={10}>
                <YourwordsCarousal />
              </Col>
              <Col xs={1}></Col>
            </Row>
          </div>
          {/* <div>
            <WhatGeeksCarousel />
          </div> */}
        </div>
        <div className="sepDiv"></div>
        <div>
          <div className="d-flex justify-content-center text-white fontText">
            <h1>Frequently Asked Questions!</h1>
          </div>
          <div>
            <FrequentlyAsked
              {...props}
              frequentlyAskedQuestions={frequentlyAskedQuestions}
            />
          </div>
        </div>
        <FoterDiv />
      </div>
    </>
  );
};

export default Intermediate;
