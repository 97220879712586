import React from "react";
import { Label, Input } from "reactstrap";
import axios from "axios";

const ViewWhatsAppBulkMsg = (props: any) => {
  const [form, setForm]: any = React.useState(
    props.editData?._id
      ? {
          uploadExcel: props.editData?.excelFileURL,
          IdentificationName: props.editData?.templateName,
          contentName: props.editData?.templateMessage,
          mediaURL:props.editData?.mediaURL
        }
      : ""
  );
  let rulesType = form?.uploadExcel.substring(
    form?.uploadExcel.lastIndexOf(".") + 1,
    form?.uploadExcel.length
  );
  const downloadExcel = async (url: any) => {
    try {
      const response = await axios({
        url,
        method: "GET",
        responseType: "blob",
      });
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "BulkExcelsheet.xlsx";
      link.click();
    } catch (error) {
      console.error(error);
    }
  };
  let excelSheetName: any = form?.uploadExcel.split("/");

  return (
    <>
      <div className="WhatsAppBulk">
        <div className="form1">
          <div className="formDiv">
            <Label>Identification Name</Label>
            <Input
              name="IdentificationName"
              id="IdentificationName"
              className="form-control"
              placeholder="Enter IdentificationName"
              value={form?.IdentificationName}
              disabled={true}
              readOnly
            />
          </div>
          <div className="formDiv">
            <Label>Content</Label>
            <div>
              <textarea
                placeholder="Type Content here"
                name="contentName"
                value={form?.contentName}
                readOnly
                disabled={true}
              />
            </div>
            <Label>Media URL</Label>
            <div>
              <textarea
              style={{
                height:"150px"
              }}
                placeholder="Type MediaUrl here"
                name="contentName"
                value={form?.mediaURL}
                readOnly
                disabled={true}
              />
            </div>
            <Label>ExcelSheet Name</Label>
            <h5>{excelSheetName[excelSheetName?.length - 1]}</h5>
            <button
              className="mt-2 p-2 ps-3 pe-3"
              onClick={() => {
                downloadExcel(form?.uploadExcel);
              }}
            >
              Dowload Excel
            </button>
          </div>
          
        </div>
      </div>
    </>
  );
};

export default ViewWhatsAppBulkMsg;
