export const HeaderData = {
    columns: [
      {
        label: "Player Name",
        field: "playerName",
        width: 2,
      },
      {
        label: "Total Kills",
        field: "totalKills",
        width: 1,
      },{
        label: "Total Deaths",
        field: "totalDeaths",
        width: 1,
      },{
        label: "total Assists",
        field: "totalAssists",
        width: 1,
      },{
        label: "Total First Blood",
        field: "totalFirstBlood",
        width: 1,
      },
      {
        label: "Total Plants",
        field: "totalPlants",
        width: 1,
      },
      {
        label: "Total CombatScore",
        field: "totalCombatScore",
        width: 1,
      },
      {
        label: "Position",
        field: "position",
        width: 1,
      },
    ],
  };