import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import { types } from "./actionTypes";
import axios, { AxiosResponse } from "axios";

//medthods
import {
  postApi,
  putApi,
  getApi,
  deleteApi,
  putUploadImagesApi
} from "../.././helpers/auth_helpers";

// workers
function* workAddUserTournament({ payload, callback }: any) {
  try {
    const url: string = process.env.REACT_APP_SERVERURL + "/tournament/register";
    const response: AxiosResponse = yield call(postApi, url, payload);
    callback(response);
    // console.log("users",response)
    yield put({
      type: types.ADD_USER_TOURNAMENT_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workFetchUserTournaments({ offset,limit,filters,callback }: any) {
  let userId = filters?.userId ? filters?.userId : "";
  try {
    const url: string =
      process.env.REACT_APP_SERVERURL +
      "/tournament/tournamentUsers?offset="+
      offset +
      "&limit=" +
      limit+
      "&userId="+
      userId;
    const response: AxiosResponse = yield call(getApi, url);
    callback(response);
    // console.log("users",response)
    yield put({
      type: types.FETCH_USER_TOURNAMENTS_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workFetchInvitedPlayer({offset,limit,filters, callback }: any) {
  let teamName= filters?.teamName ? filters?.teamName  : "";
  let tournamentId = filters?.tournamentId ? filters?.tournamentId :"";
  try {
    const url: string =
      process.env.REACT_APP_SERVERURL +
      "/tournament/invitedPlayers?offset=" +
      offset +
      "&limit=" +
      limit+
      "&teamName="+
      teamName+
      "&tournamentId="+
      tournamentId;
    const response: AxiosResponse = yield call(getApi, url);
    callback(response);
    // console.log("users",response)
    yield put({
      type: types.FETCH_INVITED_PLAYER_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workUpdateUserTournament({ payload, callback }: any) {
  try {
    const url: string = process.env.REACT_APP_SERVERURL + "/tournament/update";
    const response: AxiosResponse = yield call(putApi, url, payload);
    callback(response);
    // console.log("users",response)
    yield put({
      type: types.EDIT_USER_TOURNAMENT_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workDeleteUserTournament({ payload, callback }: any) {
  try {
    const url: string = process.env.REACT_APP_SERVERURL + "/tournament/delete";
    const response: AxiosResponse = yield call(deleteApi, url, payload);
    callback(response);
    // console.log("users",response)
    yield put({
      type: types.DELETE_USER_TOURNAMENT_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workFetchPresignedUrlTeamLogo({ callback }: any) {
  try {
    const url: string =
      process.env.REACT_APP_SERVERURL +
      "/upload/team/logo";
    const response: AxiosResponse = yield call(getApi, url);
    callback(response);
    yield put({
      type: types.PRESIGNED_URL_TEAM_LOGO_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}

function* workUploadImages({ uri, data, headers }:any) {
  try {
    // Try to call the API
    const response: AxiosResponse = yield call(putUploadImagesApi, uri, data, headers);
    //   Dispatch the action to the reducers
    console.log("images",response)
    yield put({
      type: types.UPLOAD_IMAGES_TEAM_LOGO_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    console.log(error);
  }
}
// watchers
export function* watchAddUserTournament() {
  yield takeEvery(types.ADD_USER_TOURNAMENT, workAddUserTournament);
}
export function* watchFetchUserTournament() {
  yield takeEvery(types.FETCH_USER_TOURNAMENTS, workFetchUserTournaments);
}
export function* watchFetchInvitedPlayer() {
  yield takeEvery(types.FETCH_INVITED_PLAYER, workFetchInvitedPlayer);
}
export function* watchUpdateUserTournament() {
  yield takeEvery(types.EDIT_USER_TOURNAMENT, workUpdateUserTournament);
}
export function* watchDeleteUserTournament() {
  yield takeEvery(types.DELETE_USER_TOURNAMENT, workDeleteUserTournament);
}
export function* watchPresignedUrlTeamLogo(){
  yield takeEvery(types.PRESIGNED_URL_TEAM_LOGO, workFetchPresignedUrlTeamLogo)
}

export function* watchUploadImages(){
  yield takeEvery(types.UPLOAD_IMAGES_TEAM_LOGO, workUploadImages)
}

function* userTournamentSaga() {
  yield all([
    fork(watchAddUserTournament),
    fork(watchFetchUserTournament),
    fork(watchUpdateUserTournament),
    fork(watchDeleteUserTournament),
    fork(watchPresignedUrlTeamLogo),
    fork(watchUploadImages),
    fork(watchFetchInvitedPlayer)
  ]);
}
export default userTournamentSaga;
