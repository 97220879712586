import { createStore, applyMiddleware , compose} from "redux";
import createSagaMiddleware from "redux-saga";

// importing parent reducer
import rootReducer from "./reducers";

// importing Parent Sagas
import rootSaga from "./sagas"

declare global {
    interface Window {
      __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    }
}
const sagaMiddleWare = createSagaMiddleware();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__|| compose;

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(sagaMiddleWare )));
sagaMiddleWare.run(rootSaga);

export default store;
