import React, { lazy } from "react";
import { Button, Row, Col, Card } from "reactstrap";
import WhatIsMoreCarousel from "../../components/common/WhatIsMoreCarousel";
import FrequentlyAsked from "../../components/common/FrequentlyAsked";

import YourwordsCarousal from "../../components/common/YourwordsCarousal";
import yoru from "../../assets/images/yoru.png";
import GGCommunity from "../../assets/images/GG Community.png";

import QASession from "../../assets/images/Q_A Session.png";

import BeginerGuide from "../../assets/images/Valorant Begineer Guide.png";
import FoterDiv from "../../components/common/FoterDiv";

var frequentlyAskedQuestions = [
  {
    question: "Who is the course for ?",
    answer: (
      <p>
        The courses are for gamers - newbies and professionals both. According
        to the level of gamers we have divided the course in three parts.
        <br />
        <br />
        1. Beginner Level - For complete newbies, who want to learn Valorant
        from scratch.
        <br />
        2. Intermediate level - OG’s looking to improve their game and ranking
        in Valorant. <br />
        3. Advance Level - Professional gamers who are looking to win every game
        they play.
      </p>
    ),
  },
  {
    question: "Why are our courses better than other video tutorials ? ",
    answer: (
      <p>
        {/* YouTube is a universal option and has some great content. But here’s
        what extra gaming geek has to offer that will help you upskill faster-{" "}
        <br /><br /> */}
        1. Live Interactive Sessions <br />
        2. Individually assessed by Professional coaches <br />
        3. Structural learning - Fast Learning without leaving anything! <br />
        4. Daily Drills and Weekly Scrims <br />
        5. Exclusive GG Global tournaments - Prize upto 20K
      </p>
    ),
  },
  {
    question: "What will you learn in this program ? ",
    answer: (
      <p>
        Valorant programs are combined learning of practice and strategical
        knowledge. With every lesson, you gain an advantage over your enemy.{" "}
        <br />
        <br />
        1.Map tactics and strategies - smoke and ult line-ups, choke points,
        cover points, and much more. <br />
        2.Best agents to use for different maps and based on your skills.
        <br />
        3.The Dos-and-Donts of Valorant. <br />
        4.Practice drills to master your aim by our pro coaches. <br />
        <br />
        You learn to play like a pro by the top OGs of Valorant. Furthermore,
        you get personalised drills and weekly scrims where you implement what
        you learn with the own teammates.
      </p>
    ),
  },
  {
    question: "What is Exclusive GG Global Tournaments ?",
    answer:
      "After the completion of the course, gamers are eligible to participate in our Exclusive GG Global Tournaments with  Prize Pool of ₹20K.",
  },
  {
    question: "How will you attend the session ?",
    answer:
      "All are sessions are held live. You will be provided with a link to join the live session in your learning management system (LMS).",
  },
  {
    question: "What if you miss a session ? ",
    answer:
      "No worries, all the live sessions are recorded. You will get the recording to all your sessions in your LMS",
  },
  {
    question: "What all is included in the program ? ",
    answer: (
      <p>
        Live Sessions - All courses irrespective of game or level will have live
        sessions with our pro valorant coaches. You can ask your doubts,
        interact with coaches, and be a part of a healthy active community
        <br />
        <br />
        Weekly Scrims - After completing all the live sessions, you will
        participate in a weekly scrim where our valorant coaches will give you
        pro tips on improving your game sense and what to avoid - the dos and
        don'ts of each game. <br />
        <br />
        Video analysis - To get personalized feedback from our Valorant coaches,
        you will be asked to upload 150 seconds video of your game. <br />
        <br />
        Daily practice drills - We have curated a lot of practice drills for you
        to improve specific game skills which will be uploaded on a weekly
        basis.
        <br />
        <br />
      </p>
    ),
  },
  {
    question: "Are the classes live ? ",
    answer: "Yes, all the classes are live, engaging and fun. ",
  },
  {
    question: "What is included in the sessions ?",
    answer:
      "Each live session will go in-depth on a specific topic to help you become a better gamer and rank faster. From game tactics, aim training, crosshair settings, and attack & defend strategies, we have it all covered.",
  },
];
const Beginner = (props: any) => {
  return (
    <>
      <div className="pageContent Beginner">
        <div className="yourJourney">
          <div className="d-flex justify-content-center">
            <img src={yoru} alt="yoru" />
          </div>
          <div className="d-grid justify-content-center text-center yourJourneyText">
            <h1>Your Journey Starts Here</h1>
            <h2>Welcome to Valorant Beginner Program</h2>
            <span>
              <Button>
                Enroll Now !
                <div className="Redeemtext">Redeem 50% Discount</div>
              </Button>
            </span>
          </div>
        </div>
        <div>
          <div className="d-flex justify-content-center text-white fontText">
            <h1>What do you get?</h1>
          </div>
          <div className="container">
            <Row className="mt-5">
              <Col xs={0} md={1}></Col>
              <Col xs={12} md={6}>
                <Card className="whatDoyowantCard">
                  <img
                    src={BeginerGuide}
                    width="100%"
                    height={"100%"}
                    alt="Beginner Guide"
                  />
                </Card>
              </Col>
              <Col xs={12} md={4}>
                <div className="cardText">
                  <h1>Valorant Beginner Guide</h1>
                  <p>
                    Character selection Weapon selection Aim training & warmup
                    routines Movement tactics for beginners Map understanding &
                    planning
                  </p>
                </div>
              </Col>
              <Col xs={0} md={1}></Col>
            </Row>
            <div className="mt-5 mobileDisplay">
              <hr className="text-white" />
            </div>
            <Row className="mt-5 desktopDisplayCategory">
              <Col xs={0} md={1}></Col>

              <Col xs={12} md={4}>
                <div className="cardText">
                  <h1> Access To GG's Gaming Community</h1>
                  <p>
                    Find players of your own rank or from your own program in
                    our exclusive gaming community of over 2500+ gamers.
                  </p>
                </div>
              </Col>
              <Col xs={12} md={6}>
                <Card className="whatDoyowantCard">
                  <img
                    src={GGCommunity}
                    width="100%"
                    height={"100%"}
                    alt="GGCommunity"
                  />
                </Card>
              </Col>
              <Col xs={0} md={1}></Col>
            </Row>
            <Row className="mt-5 mobileDisplayCategory">
              <Col xs={0} md={1}></Col>
              <Col xs={12}>
                <Card className="whatDoyowantCard">
                  <img
                    src={GGCommunity}
                    width="100%"
                    height={"100%"}
                    alt="GGCommunity"
                  />
                </Card>
              </Col>
              <Col xs={12}>
                <div className="cardText">
                  <h1> Access To GG's Gaming Community</h1>
                  <p>
                    Find players of your own rank or from your own program in
                    our exclusive gaming community of over 2500+ gamers.
                  </p>
                </div>
              </Col>

              <Col xs={0} md={1}></Col>
            </Row>
            <div className="mt-5 mobileDisplay">
              <hr className="text-white" />
            </div>
            <Row className="mt-5">
              <Col xs={0} md={1}></Col>

              <Col xs={12} md={6}>
                <Card className="whatDoyowantCard">
                  <img
                    src={QASession}
                    width="100%"
                    height={"100%"}
                    alt="QASession"
                  />
                </Card>
              </Col>
              <Col xs={12} md={4}>
                <div className="cardText">
                  <h1>Live Q&A Session</h1>
                  <p>
                    Get an hour of interactive session with our top coaches who
                    clarify all your questions and doubts. Go in-depth with game
                    analysis and personalised drills prepared based on your
                    difficulty level.
                  </p>
                </div>
              </Col>
              <Col xs={0} md={1}></Col>
            </Row>
            <div className="mt-5 mobileDisplay">
              <hr className="text-white" />
            </div>
          </div>
        </div>
        <div className="sepDiv"></div>
        <div className="mt-5">
          <div className="d-flex justify-content-center text-white mt-5 fontText">
            <h1>What’s more?</h1>
          </div>
          <div className="whatMoreParentDiv">
            <WhatIsMoreCarousel />
          </div>
        </div>
        <div className="d-grid justify-content-center text-center enrollowBtn">
          <span>
            <Button>
              Enroll Now !<div className="Redeemtext">Redeem 50% Discount</div>
            </Button>
          </span>
        </div>
        <div className="sepDiv"></div>
        <div>
          <div className="d-flex justify-content-center text-white fontText">
            <h1>What our Geeks say?</h1>
          </div>
          <div>
            <Row>
              <Col xs={1}></Col>
              <Col xs={10}>
                <YourwordsCarousal />
              </Col>
              <Col xs={1}></Col>
            </Row>
          </div>
          {/* <div>
            <WhatGeeksCarousel />
          </div> */}
        </div>
        <div className="sepDiv"></div>
        <div>
          <div className="d-flex justify-content-center text-white fontText">
            <h1>Frequently Asked Questions!</h1>
          </div>
          <div>
            <FrequentlyAsked
              {...props}
              frequentlyAskedQuestions={frequentlyAskedQuestions}
            />
          </div>
        </div>
        <div>
          <FoterDiv />
        </div>
      </div>
    </>
  );
};

export default Beginner;
