import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Row, Col } from "reactstrap";
import { Pagination, Stack } from "@mui/material";
import { Input } from "reactstrap";
import { Modal, ModalBody } from "reactstrap";
import ActionVerticalButton from "../../assets/images/more-vertical.png";
import { useNavigate } from "react-router-dom";
// store
import { getUsers, userActivateDeactivate } from "../.././Store/user/actions";
// components
import { HeaderData } from "./HeaderData";
import Index from "../../components/common";
import notifications from "../../components/Notifications/Notification";
import SideBar from "../../components/verticalMenu/SideBar";
import UpdateRole from "./UpdateRole";
import Loader from "../../components/Loader/Loader";
var offset: number = 0;
var limit: number = 10;
var totCnt = 0;
var totPages = 0;
var toVal = 0;
var fromVal = 0;

const Users = (props: any) => {
  const navigator = useNavigate();
  const [nameFilter, setName]: any = useState("");
  const [emailFilter, setEmail]: any = useState();
  const [user, setUser]: any = useState();
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  // Api Calling
  useEffect(() => {
    getAllUsersData();
  }, []);
  let getAllUsersData = () => {
    let offset = 0;
    let filters = {
      name: nameFilter,
      email: emailFilter,
      social: false,
    };
    setLoading(true);
    props.getUsers(offset, limit, filters, (res: any) => {
      setLoading(false);
    });
  };
  // filters
  const handleChangeName = (type: any) => {
    if (type.name === "name") {
      setName(type?.value);
    }
  };
  const handleChangeEmail = (type: any) => {
    if (type.name === "email") {
      setEmail(type?.value);
    }
  };
  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      getAllUsersData();
    }
  };
  // reset
  const handleReset = () => {
    setName("");
    setEmail("");
    props.getUsers(offset, limit, {}, (res: any) => {});
  };
  // pagination
  let handleChangePage = (event: any, val?: any) => {
    setLoading(true);
    offset = (val - 1) * 10;
    toVal = offset + 10;
    let filters = {
      name: nameFilter,
      email: emailFilter,
      social: false,
    };
    props.getUsers(offset, limit, filters, (res: any) => {
      setLoading(false);
    });
  };
  const openUpdateRoleModale = () => {
    setOpenModal(true);
  };
  const closeModal = () => {
    setOpenModal(false);
  };
  let action = (user: any) => {
    return (
      <>
        <div className="dropdown">
          <img
            style={{ margin: "-4px" }}
            src={ActionVerticalButton}
            alt="actions"
          />
          <div className="dropdown-content">
            {user?.isActive === true ? (
              <div
                className="action-button"
                onClick={() => {
                  props.userActivateDeactivate(
                    { userId: user?._id, isActive: "false" },
                    (res: any) => {
                      if (res.success) {
                        getAllUsersData();
                        notifications({
                          title: "Success!",
                          message: res?.message,
                          type: "success",
                        });
                      } else {
                        notifications({
                          title: "Error!",
                          message: res?.message,
                          type: "danger",
                        });
                      }
                    }
                  );
                }}
              >
                DeActivated
              </div>
            ) : (
              <div
                className="action-button"
                onClick={() => {
                  props.userActivateDeactivate(
                    { userId: user?._id, isActive: "true" },
                    (res: any) => {
                      if (res.success) {
                        notifications({
                          title: "Success!",
                          message: res?.message,
                          type: "success",
                        });
                        getAllUsersData();
                      } else {
                        notifications({
                          title: "Error!",
                          message: res?.message,
                          type: "danger",
                        });
                      }
                    }
                  );
                }}
              >
                Activated
              </div>
            )}
            <div
              className="action-button"
              onClick={() => {
                setUser(user);
                openUpdateRoleModale();
              }}
            >
              Update Role
            </div>
            <div
              className="action-button"
              onClick={() => {
                navigator("/userProfile", { state: { userDetails: user } });
                window.scrollTo(0, 0)

              }}
            >
              View Profile
            </div>
          </div>
        </div>
      </>
    );
  };

  let data: any = [];

  if (props.userDetails !== undefined) {
    let userDetailsData = props?.userDetails?.data;
    totCnt = userDetailsData?.length;
    fromVal = totCnt !== 0 ? offset + 1 : fromVal;
    toVal = userDetailsData?.offset + userDetailsData?.docs?.length;
    totPages = Math.ceil(totCnt / 10);
    data = [];
    userDetailsData?.docs?.map((user: any, i: any) => {
      data.push({
        fullName: user?.fullName,
        email: user?.email,
        phone: user?.phone ? user?.phone : "N/A",
        isActive: user?.isActive ? "Active" : "InActive",
        role: user?.role,
        action: action(user),
      });
    });
  }

  console.log("data", data);
  return (
    <>
      <Modal
        isOpen={openModal}
        toggle={openUpdateRoleModale}
        size="lg"
        className="RegistrationModal"
      >
        <ModalBody>
          <UpdateRole
            {...props}
            closeModal={closeModal}
            userDetails={user}
            getAllUserData={getAllUsersData}
          />
        </ModalBody>
      </Modal>
      <div className="pageContent">
        <div className="pageContentChild">
          <div>
            <SideBar></SideBar>
          </div>
          <div className="p-3 w-100">
            <Row className="mb-3">
              <Col xs={12} md={4} lg={3} className="d-flex p-3">
                <Input
                  className="form-control"
                  type="text"
                  name="name"
                  placeholder="Search By Name..."
                  aria-label="Search"
                  value={nameFilter}
                  onKeyDown={(event: any) => handleKeyDown(event)}
                  onChange={(e) => {
                    console.log("e", e.target.value);
                    handleChangeName({
                      name: "name",
                      value: e.target.value,
                    });
                  }}
                />
                <svg
                  onClick={() => getAllUsersData()}
                  className="SearchIcon cursor-pointer"
                  width="40"
                  height="20"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.4 11.8C9.38234 11.8 11.8 9.38234 11.8 6.4C11.8 3.41766 9.38234 1 6.4 1C3.41766 1 1 3.41766 1 6.4C1 9.38234 3.41766 11.8 6.4 11.8Z"
                    stroke="#FFF"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M12.9992 13L11.1992 11.2"
                    stroke="#FFF"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </Col>
              <Col xs={12} md={4} lg={3} className="d-flex flex-row p-3">
                <Input
                  className="form-control"
                  type="text"
                  name="email"
                  placeholder="Search By Email..."
                  aria-label="Search"
                  value={emailFilter}
                  onKeyDown={(event: any) => handleKeyDown(event)}
                  onChange={(e) => {
                    console.log("e", e.target.value);
                    handleChangeEmail({
                      name: "email",
                      value: e.target.value,
                    });
                  }}
                />

                <svg
                  onClick={() => getAllUsersData()}
                  className="SearchIcon cursor-pointer"
                  width="40"
                  height="20"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.4 11.8C9.38234 11.8 11.8 9.38234 11.8 6.4C11.8 3.41766 9.38234 1 6.4 1C3.41766 1 1 3.41766 1 6.4C1 9.38234 3.41766 11.8 6.4 11.8Z"
                    stroke="#FFF"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M12.9992 13L11.1992 11.2"
                    stroke="#FFF"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </Col>
              <Col xs={6} md={2} className="p-3">
                <button
                  onClick={() => handleReset()}
                  style={{
                    color: "black",
                    background: "Green",
                    height: "40px",
                    width: "100%",
                    padding: "10px 20px",
                  }}
                >
                  Reset
                </button>
              </Col>
            </Row>
            {loading ? (
              <>
                <div>
                  <Loader />
                </div>
              </>
            ) : (
              <>
                <h3 className=" mb-4">User Details</h3>
                <Index data={data} headerData={HeaderData} limit={limit} />
                {totCnt ? (
                  <div className=" mt-4">
                    <Row>
                      <Col xs={6} className="text-white">
                        <Stack spacing={2}>
                          <Pagination
                            className="pagination"
                            size="small"
                            shape="rounded"
                            page={offset / 10 + 1}
                            count={totPages}
                            color="primary"
                            // onChange={(e)=>{handleChangePage(e,offset / 10 + 1)}}
                            onChange={(e, val) => {
                              handleChangePage(e, val);
                              console.log("event", e, val);
                            }}
                            defaultPage={1}
                            siblingCount={1}
                          />
                        </Stack>
                      </Col>
                      <Col xs={6}>
                        <span className="text-white">
                          Showing {fromVal} to {toVal} of {totCnt} entries
                        </span>
                      </Col>
                    </Row>
                  </div>
                ) : (
                  ""
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state: any) => {
  console.log("state", state);
  return {
    ...state,
    userDetails: state?.userReducer?.usersDetails,
  };
};
export default connect(mapStateToProps, { getUsers, userActivateDeactivate })(
  Users
);
