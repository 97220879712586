const HeaderData:any = {
    columns: [
      {
        label: " Full Name",
        field: "fullName",
        width: 2,
      },
      {
        label: "Email",
        field: "email",
        width: 4,
      },
      {
        label: "Phone",
        field: "phone",
        width: 1,
      },
      {
        label: "Status",
        field: "isActive",
        width: 1,
      },
      {
        label: "Role",
        field: "role",
        width: 1,
      },
      {
        label: "Action",
        field: "action",
        width: 1,
      },
    ],
  };
  export {HeaderData}