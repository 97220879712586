import React from "react";
import { BallTriangle } from "react-loader-spinner";
const Loader = (props: any) => {
  return (
    <>
      <div className="d-flex justify-content-center">
        <BallTriangle
          {...props}
          height={400}
          width={150}
          radius={5}
          color="#4fa94d"
          ariaLabel="ball-triangle-loading"
          wrapperClass={{}}
          wrapperStyle=""
          visible={true}
        />
      </div>
    </>
  );
};

export default Loader;
