import React from "react";
import { Card, Row, Col } from "reactstrap";
import Slider from "react-slick";
import valorant from "../../assets/images/beginnerC1.jpeg";
import proCoaching from "../../assets/images/beginnerC2.jpg";
import practice from "../../assets/images/beginnerC3.jpg";

const WhatIsMoreCarousel = () => {
  const settings = {
    // dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1224,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <Row className="WhatCaurosel">
        <Col xs={0} md={1}></Col>
        <Col xs={12} md={10}>
          <Slider {...settings}>
            <Card className="whatCardCarousel">
              <img src={valorant} alt="valorant" />

              <div className="structuredDiv">
                <h1>Structured Learning</h1>
              </div>
              <div className="structuredDiv1">
                <span>
                  Unlike other esports learning platforms, GG provides you with
                  structured learning modules along with personalized
                  improvisation. With GG programs, you follow the same path that
                  is taken by a professional esports player to become an esports
                  athlete.
                </span>
              </div>
            </Card>
            <Card className="whatCardCarousel">
              <img src={proCoaching} alt="valorant" />

              <div className="structuredDiv">
                <h1>Pro Coaching Support</h1>
              </div>
              <div className="structuredDiv1">
                <span>
                  All GG coaches are pro coaches who have trained tire1
                  competitive esports teams in the past. Collectively, our
                  coaches hold a combined experience of 50+ years in the esports
                  coaching ecosystem.
                </span>
              </div>
            </Card>
            <Card className="whatCardCarousel">
              <img src={practice} alt="valorant" />

              <div className="structuredDiv">
                <h1>Practice With Pros</h1>
              </div>
              <div className="structuredDiv1">
                <span>
                  With GG, you get a competitive advantage over other gamers.
                  With our regular scrims, we guarantee that you not only learn
                  but also practice your skills o perfection. Along with the
                  in-depth game analysis, you are destined to bring out the best
                  in yourself.
                </span>
              </div>
            </Card>
          </Slider>
        </Col>
        <Col xs={0} md={1}></Col>
      </Row>
    </>
  );
};

export default WhatIsMoreCarousel;
