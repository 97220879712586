import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import { types } from "./actionTypes";
import  { AxiosResponse } from "axios";

//medthods
import {
  postApi,
  putApi,
  getApi,
  deleteApi,
} from "../../helpers/auth_helpers";

// workers
function* workAddMatchPerfomance({ payload, callback }: any) {
  try {
    const url: string = process.env.REACT_APP_SERVERURL + "/match/addUserPerformance";
    const response: AxiosResponse = yield call(postApi, url, payload);
    callback(response);
    // console.log("users",response)
    yield put({
      type: types.ADD_MATCH_PERFOMANCE_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workFetchMatchPerfomances({ offset, limit, filters, callback }: any) {
let userId = filters?.userId ? filters?.userId :""
  try {
    const url: string =
      process.env.REACT_APP_SERVERURL +
      "/match/performance?offset=" +
      offset +
      "&limit=" +
      limit +
      "&userId="+
      userId;
    const response: AxiosResponse = yield call(getApi, url);
    callback(response);
    // console.log("users",response)
    yield put({
      type: types.FETCH_MATCH_PERFOMANCES_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workUpdateMatchPerfomance({ payload, callback }: any) {
  try {
    const url: string = process.env.REACT_APP_SERVERURL + "/match/updateUserPerformance";
    const response: AxiosResponse = yield call(putApi, url, payload);
    callback(response);
    // console.log("users",response)
    yield put({
      type: types.EDIT_MATCH_PERFOMANCE_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workDeleteMatchPerfomance({ payload, callback }: any) {
  try {
    const url: string = process.env.REACT_APP_SERVERURL + "/match/deleteUserPerformance";
    const response: AxiosResponse = yield call(deleteApi, url, payload);
    callback(response);
    // console.log("users",response)
    yield put({
      type: types.DELETE_MATCH_PERFOMANCE_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
// watchers
export function* watchAddMatchPerfomance() {
  yield takeEvery(types.ADD_MATCH_PERFOMANCE, workAddMatchPerfomance);
}
export function* watchFetchMatchPerfomance() {
  yield takeEvery(types.FETCH_MATCH_PERFOMANCES, workFetchMatchPerfomances);
}
export function* watchUpdateMatchPerfomance() {
  yield takeEvery(types.EDIT_MATCH_PERFOMANCE, workUpdateMatchPerfomance);
}
export function* watchDeleteMatchPerfomance() {
  yield takeEvery(types.DELETE_MATCH_PERFOMANCE, workDeleteMatchPerfomance);
}

function* matchPerfomanceSaga() {
  yield all([
    fork(watchAddMatchPerfomance),
    fork(watchFetchMatchPerfomance),
    fork(watchUpdateMatchPerfomance),
    fork(watchDeleteMatchPerfomance),
  ]);
}
export default matchPerfomanceSaga;
