import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Modal, ModalBody } from "reactstrap";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import SendingMail from "../../pages/Mail/SendingMail";

const SideBar = (props: any) => {
  const [dropDownisOpen, setDropDownisOpen] = useState(false);
  const [openModal, setOpenModal]: any = useState(false);
  let auth: any =
    localStorage.getItem("authUser") || sessionStorage.getItem("authUser");
  let authUser: any = JSON.parse(auth);
  let toggle = () => {
    setDropDownisOpen(!dropDownisOpen);
  };
  const openModalPopUp = () => {
    setOpenModal(true);
  };
  const closeModal = () => {
    setOpenModal(false);
  };

  return (
    <>
      <Modal
        isOpen={openModal}
        toggle={openModalPopUp}
        size="lg"
        className="RegistrationModal"
      >
        <ModalBody>
          <SendingMail
            {...props}
            closeModal={closeModal}
            // handleSubmitUpdateGame={handleSubmitUpdateGame}
            // handleSubmitCreateGame={handleSubmitCreateGame}
          />
        </ModalBody>
      </Modal>

      {authUser?.role === "Admin" ? (
        <div className="sideBar">
          <div className="displayMobile">
            <span className="navbar-text text-white fontBwNista20 p-2">
              <Dropdown isOpen={dropDownisOpen} toggle={() => toggle()}>
                <DropdownToggle>
                  {" "}
                  <svg
                    width="15"
                    height="10"
                    viewBox="0 0 21 15"
                    fill="#fff"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.71582 7.09619H19.7158"
                      stroke="#f4eded"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M1.71582 1.09619H19.7158"
                      stroke="#f4eded"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M1.71582 13.0962H19.7158"
                      stroke="#f4eded"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </DropdownToggle>
                <DropdownMenu>
                  <Link to="/admin-dashboard">
                    <DropdownItem> Dashboard</DropdownItem>
                  </Link>
                  <DropdownItem divider />
                  <Link to="/tournaments">
                    <DropdownItem> Tournament</DropdownItem>
                  </Link>
                  <DropdownItem divider />
                  <Link to="/users">
                    <DropdownItem> Users</DropdownItem>
                  </Link>
                  <DropdownItem divider />
                  <Link to="/games">
                    <DropdownItem> Games</DropdownItem>
                  </Link>
                  <DropdownItem divider />
                  <Link to="/match-details">
                    <DropdownItem> Match Details</DropdownItem>
                  </Link>
                  <DropdownItem divider />
                  <Link to="/sending-email">
                    <DropdownItem> Notifications</DropdownItem>
                  </Link>
                  <DropdownItem divider />
                  <Link to="/whatsapp-bulk">
                    <DropdownItem> WhatsApp Bulk</DropdownItem>
                  </Link>
                  <DropdownItem divider />
                  <Link to="/whatsapp-media">
                    <DropdownItem> WhatsApp Media</DropdownItem>
                  </Link>
                  <DropdownItem divider />
                </DropdownMenu>
              </Dropdown>
            </span>
            <div></div>
          </div>
          <div className="displayDesktop">
            <div>
              <Link to="/admin-dashboard"> Dashboard</Link>
            </div>
            <div>
              <Link to="/tournaments"> Tournaments</Link>
            </div>
            <div>
              <Link to="/users">Users</Link>
            </div>
            <div>
              <span className="navbar-text text-white fontBwNista20">
                <Link to="/games">Games</Link>
              </span>
            </div>
            {/* <div>
            <span className="navbar-text text-white fontBwNista20">
              <Link to="/teams">Teams</Link>
            </span>
          </div> */}
            <div>
              <span className="navbar-text text-white fontBwNista20">
                <Link to="/match-details">Match Details</Link>
              </span>
            </div>
            <div>
              <span className="navbar-text cursor-pointer text-white fontBwNista20">
                <Link to="/sending-email">Notifications</Link>
              </span>
            </div>
            <div>
              <span className="navbar-text cursor-pointer text-white fontBwNista20">
                <Link to="/whatsapp-bulk">WhatsApp Bulk</Link>
              </span>
            </div>
            <div>
              <span className="navbar-text cursor-pointer text-white fontBwNista20">
                <Link to="/whatsapp-media">WhatsApp Media</Link>
              </span>
            </div>
          </div>
        </div>
      ) : (
        <div className="sideBar">
          <div className="displayMobile">
            <span className="navbar-text text-white fontBwNista20 p-2">
              <Dropdown isOpen={dropDownisOpen} toggle={() => toggle()}>
                <DropdownToggle>
                  {" "}
                  <svg
                          width="15"
                          height="10"
                          viewBox="0 0 21 15"
                          fill="#fff"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1.71582 7.09619H19.7158"
                            stroke="#f4eded"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M1.71582 1.09619H19.7158"
                            stroke="#f4eded"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M1.71582 13.0962H19.7158"
                            stroke="#f4eded"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                </DropdownToggle>
                <DropdownMenu>
                  <Link to="/my-dasboard">
                    <DropdownItem> Dashboard</DropdownItem>
                  </Link>
                  <DropdownItem divider />
                  <Link to="/user/tournament">
                    <DropdownItem> Tournament</DropdownItem>
                  </Link>
                  <DropdownItem divider />
                  {/* <Link to="/products">
                    <DropdownItem> Products</DropdownItem>
                  </Link>
                  <DropdownItem divider /> */}
                </DropdownMenu>
              </Dropdown>
            </span>
          </div>
          <div className="displayDesktop">
            <div>
              <Link to="/my-dasboard">Dashboard</Link>
            </div>
            <div>
              <Link to="/user/tournament">Tournament</Link>
            </div>
            {/* <div>
            <Link to="/tournaments"> Tournaments</Link>
          </div> */}
            {/* <div>
              <Link to="/products">Products</Link>
            </div> */}
          </div>
        </div>
      )}
    </>
  );
};

export default SideBar;
