import React, { useState,useEffect } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Col,
} from "reactstrap";
import { Grid, Card } from "@mui/material";
import { Link } from "react-router-dom";
// components
import YourwordsCarousal from "../../components/common/YourwordsCarousal";
import CoachesCarousal from "./CoachesCarousal";
import FoterDiv from "../../components/common/FoterDiv";
// images
import CoachesAsset3 from "../../assets/images/CoachesAsset3.png";
import CoachesAsset4 from "../../assets/images/CoachesAsset4.png";
import CoachesAsset5 from "../../assets/images/CoachesAsset5.png";
import B1 from "../../assets/images/B1.png";
import B2 from "../../assets/images/B2.png";
import B3A8 from "../../assets/images/B3A8.png";
import I1A1 from "../../assets/images/I1A1.png";
import I2A2 from "../../assets/images/I2A2.png";
import I3A5 from "../../assets/images/I3A5.png";
import I4 from "../../assets/images/I4.png";
import I5A6 from "../../assets/images/I5A6.png";
import I6A7 from "../../assets/images/I6A7.png";
import Copy_of_meeting from "../../assets/images/Copy_of_meeting.png";
import Copy_of_team from "../../assets/images/Copy_of_team.png";
import Copy_of_whistle from "../../assets/images/Copy_of_whistle.png";

const ValorentNew = () => {
  const [dropDownisOpen, setDropDownisOpen] = useState(false);
  const [type, setType]: any = useState("");
  let toggle = () => {
    setDropDownisOpen(!dropDownisOpen);
  };
  function ChangeColor(type: any) {
    setType(type);
    console.log("hello world", type);
  }
  useEffect(()=>{
    if(type?.name?.length){
      let x: any = document.getElementById("pageContent2");
      x.addEventListener("click", ()=>{
        setType("")
      });
     
    }
  })
  return (
    <>
      <div id="pageContent2" className="pageContent">
        <div className="MainvalorantPage">
          <div className="UpperHeading PaddingTopImage">
            <Row>
              <Col xs={12}>
                <span>Ready to get better?</span>
              </Col>
              <Col xs={12} className="z-index99">
                <Dropdown
                  className="SelectDeopdown"
                  isOpen={dropDownisOpen}
                  toggle={() => toggle()}
                >
                  <DropdownToggle className="DropdownButton">
                    {" "}
                    <span className="selectRankSpan">Select your rank</span>
                  </DropdownToggle>
                  <DropdownMenu>
                    <a
                      href="#Intermediate_Program"
                      onClick={() =>
                        ChangeColor({ name: "#Intermediate_Program" })
                      }
                    >
                      <DropdownItem>Iron</DropdownItem>
                    </a>
                    <DropdownItem divider />
                    <a
                      href="#Intermediate_Program"
                      onClick={() =>
                        ChangeColor({ name: "#Intermediate_Program" })
                      }
                    >
                      <DropdownItem>Bronze</DropdownItem>
                    </a>
                    <DropdownItem divider />
                    <a
                      href="#Intermediate_Program"
                      onClick={() =>
                        ChangeColor({ name: "#Intermediate_Program" })
                      }
                    >
                      <DropdownItem>Silver</DropdownItem>
                    </a>
                    <DropdownItem divider />
                    <a
                      href="#Intermediate_Program"
                      onClick={() =>
                        ChangeColor({ name: "#Intermediate_Program" })
                      }
                    >
                      <DropdownItem>Gold</DropdownItem>
                    </a>
                    <DropdownItem divider />
                    <a
                      href="#Intermediate_Program"
                      onClick={() =>
                        ChangeColor({ name: "#Intermediate_Program" })
                      }
                    >
                      <DropdownItem>Platinum</DropdownItem>
                    </a>
                    <DropdownItem divider />
                    <a
                      href="#Intermediate_Program"
                      onClick={() =>
                        ChangeColor({ name: "#Intermediate_Program" })
                      }
                    >
                      <DropdownItem>Diamond</DropdownItem>
                    </a>
                    <DropdownItem divider />
                    <a
                      href="#Advance_Program"
                      onClick={() => ChangeColor({ name: "#Advance_Program" })}
                    >
                      <DropdownItem>Ascendant</DropdownItem>
                    </a>
                    <DropdownItem divider />
                    <a
                      href="#Advance_Program"
                      onClick={() => ChangeColor({ name: "#Advance_Program" })}
                    >
                      <DropdownItem>Immortal</DropdownItem>
                    </a>
                    <DropdownItem divider />
                    <a
                      href="#Advance_Program"
                      onClick={() => ChangeColor({ name: "#Advance_Program" })}
                    >
                      <DropdownItem>Radiant</DropdownItem>
                    </a>
                    <DropdownItem divider />
                  </DropdownMenu>
                </Dropdown>
              </Col>
            </Row>
          </div>
          <div className="sepDivV1"></div>
          <div className="UpperHeading">
            <Row>
              <Col>
                <span>Our Programs</span>
              </Col>
            </Row>
          </div>
          <div className="ThreeDivSection">
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <Card
                  className={
                    type?.name === "#Advance_Program" ||
                    type?.name === "#Intermediate_Program"
                      ? "beginnerDiv borderIntermediateProgram"
                      : "beginnerDiv"
                  }
                >
                  <div className="CardHeaderDiv">Beginner Program</div>
                  <div className="mt-5 pt-3">
                    <img src={CoachesAsset3} alt="Beginner" className="imageBeginner" />
                  </div>
                  <div className="ContentPara">
                    <p>
                      Curated for all newbies who recently got hooked to
                      Valorant. It has all essential information about the game
                      like agent abilities, weapon choices, agents, and more.
                    </p>
                  </div>
                  <div className="IconsHeader">
                  
                    <Row className="IconsRow">
                      <Col xs={3} md={3}>
                        <img src={B1} alt="" className="" />
                      </Col>
                      <Col xs={9} md={9}>
                        <div className="iconContent ">
                          self paced short videos
                        </div>
                      </Col>
                    </Row>
                    <Row className="IconsRow">
                      <Col xs={3} md={3}>
                        <div className="">
                          <img src={B2} alt="" className="" />
                        </div>
                      </Col>
                      <Col xs={9} md={9}>
                        <div className="iconContent">
                          Access to our global
                          <br />
                          gaming community
                        </div>
                      </Col>
                    </Row>
                    <Row className="IconsRow">
                      <Col xs={3} md={3}>
                        <div className="  ">
                          <img src={B3A8} alt="" className="" />
                        </div>
                      </Col>
                      <Col xs={9} md={9}>
                        <div className="iconContent">
                          1 hour live interactive
                          <br />
                          session
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div className="LastDivOff">
                    <Row>
                      <Col xs={6}>
                        <div className="">
                          <p className="NumberCutt">
                            <span>
                              <del>₹2000</del>
                            </span>
                            <span className="OffNum">₹1000</span>
                          </p>
                          <p className="text-nowrap fiftyOff">50% off</p>
                        </div>
                      </Col>
                      <Col xs={6}>
                        <div className="">
                          <button
                            type="button"
                            className="btn btn-success text-nowrap BuyNowBtn"
                          >
                            Buy NOW !
                          </button>
                          <br />
                          <Link to="/beginner"
                          onClick={()=>{
                            window.scrollTo(0, 0)
                          }}
                          >
                            <p className="MoreInfo">More Info...</p>
                          </Link>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Card>
              </Grid>
              <Grid item xs={12} md={4} id="Intermediate_Program">
                <Card
                  className={
                    type?.name === "#Advance_Program"
                      ? "interMediateDiv borderIntermediateProgram"
                      : "interMediateDiv"
                  }
                >
                  <div className="CardHeaderDiv">Intermediate Program</div>
                  <div className="mt-5 pt-3">
                    <img src={CoachesAsset4} alt="Intermediate" className="imageIntermediate" />
                  </div>
                  <div className="ContentPara">
                    <p>
                      Made for gamers who have been hard stuck on a rank and
                      want to push higher. Filled with strategies, map tactics,
                      this course aims to make you the best among your squad.
                    </p>
                  </div>

                  <div className="IconsHeader">
                    <Row className="IconsRow">
                      <Col xs={3} md={3}>
                        <div className="  ">
                          <img src={I1A1} alt="Live" />
                        </div>
                      </Col>
                      <Col xs={9} md={9}>
                        <div className="iconContent">
                          5 live interactive sessions
                        </div>
                      </Col>
                    </Row>
                    <Row className="IconsRow">
                      <Col xs={3} md={3}>
                        <img src={I2A2} alt="" />
                      </Col>
                      <Col xs={9} md={9}>
                        <div className="iconContent pt-3">
                          {" "}
                          4 practice scrims with
                          <br />
                          coaches
                        </div>
                      </Col>
                    </Row>
                    <Row className="IconsRow">
                      <Col xs={3} md={3}>
                        <div className="">
                          <img src={I3A5} alt="" className="" />
                        </div>
                      </Col>
                      <Col xs={9} md={9}>
                        <div className="iconContent">
                          Individual
                          <br />
                          game feedback
                        </div>
                      </Col>
                    </Row>
                    <Row className="IconsRow">
                      <Col xs={3} md={3}>
                        <div className="  ">
                          <img src={I4} alt="" />
                        </div>
                      </Col>
                      <Col xs={9} md={9}>
                        <div className="iconContent">
                          Personalised drills to
                          <br />
                          improve
                        </div>
                      </Col>
                    </Row>
                    <Row className="IconsRow">
                      <Col xs={3} md={3}>
                        <div className="  ">
                          <img src={I5A6} alt="" />
                        </div>
                      </Col>
                      <Col xs={9} md={9}>
                        <div className="iconContent">
                          Video submission for pro
                          <br />
                          analysis
                        </div>
                      </Col>
                    </Row>
                    <Row className="IconsRow">
                      <Col xs={3} md={3}>
                        <div className="  ">
                          <img src={I6A7} alt="" />
                        </div>
                      </Col>
                      <Col xs={9} md={9}>
                        <div className="iconContent">
                          Access to GG Exclusive
                          <br />
                          tournaments
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div className="LastDivOff1">
                    <Row>
                      <Col xs={6}>
                        <div className="">
                          <p className="NumberCutt">
                            <span>
                              <del>₹2000</del>
                            </span>
                            <span className="OffNum">₹1000</span>
                          </p>
                          <p className="text-nowrap fiftyOff">50% off</p>
                        </div>
                      </Col>
                      <Col xs={6}>
                        <div className="">
                          <button
                            type="button"
                            className="btn btn-success text-nowrap BuyNowBtn"
                          >
                            Buy NOW !
                          </button>
                          <br />
                          <Link to="/intermediate"
                           onClick={()=>{
                            window.scrollTo(0, 0)
                          }}
                          >
                            <p className="MoreInfo">More Info...</p>
                          </Link>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Card>
              </Grid>
              <Grid item xs={12} md={4} id="Advance_Program">
                <Card
                  className={
                    type?.name === "#Intermediate_Program"
                      ? // type?.name === "#Advance_Program"
                        "addvanceProgramDiv borderIntermediateProgram"
                      : "addvanceProgramDiv"
                  }
                >
                  <div className="CardHeaderDiv">Advance Program</div>
                  <div className="mt-5 pt-3">
                    <img src={CoachesAsset5} alt="Addvance" className="imageAddvance"/>
                  </div>
                  <div className="ContentPara ContentParaMA">
                    <p>
                      Curated with the contribution of pro athlete, this course
                      is designed to turn you into a pro gamer.
                    </p>
                  </div>
                  <div className="IconsHeader1">
                    <Row className="IconsRow">
                      <Col xs={3} md={3}>
                        <div className="  ">
                          <img src={I1A1} alt="" />
                        </div>
                      </Col>
                      <Col xs={9} md={9}>
                        <div className="iconContent">
                          X live interactive sessions
                        </div>
                      </Col>
                    </Row>
                    <Row className="IconsRow">
                      <Col xs={3} md={3}>
                        <img src={I2A2} alt="" />
                      </Col>
                      <Col xs={9} md={9}>
                        <div className="iconContent pt-3">
                          8 practice scrims with
                          <br />
                          head coaches
                        </div>
                      </Col>
                    </Row>
                    <Row className="IconsRow">
                      <Col xs={3} md={3}>
                        <div className="">
                          <img src={Copy_of_meeting} alt="" />{" "}
                        </div>
                      </Col>
                      <Col xs={9} md={9}>
                        <div className="iconContent">
                          2 hours 1-1 session with
                          <br />
                          pro coaches
                        </div>
                      </Col>
                    </Row>
                    <Row className="IconsRow">
                      <Col xs={3} md={3}>
                        <div className="  ">
                          <img src={I3A5} alt="" />
                        </div>
                      </Col>
                      <Col xs={9} md={9}>
                        <div className="iconContent">
                          Performance report and
                          <br />
                          personal drills
                        </div>
                      </Col>
                    </Row>
                    <Row className="IconsRow">
                      <Col xs={3} md={3}>
                        <div className="  ">
                          <img src={I5A6} alt="" />
                        </div>
                      </Col>
                      <Col xs={9} md={9}>
                        <div className="iconContent">
                          Individual game
                          <br />
                          feedback
                        </div>
                      </Col>
                    </Row>
                    <Row className="IconsRow">
                      <Col xs={3} md={3}>
                        <div className="  ">
                          <img src={I1A1} alt="" />
                        </div>
                      </Col>
                      <Col xs={9} md={9}>
                        <div className="iconContent">
                          Match submission for
                          <br />
                          pro analysis
                        </div>
                      </Col>
                    </Row>
                    <Row className="IconsRow">
                      <Col xs={3} md={3}>
                        <div className="  ">
                          <img src={I6A7} alt="" />
                        </div>
                      </Col>
                      <Col xs={9} md={9}>
                        <div className="iconContent">
                          Access to GG Exclusive
                          <br />
                          Global Tournaments
                        </div>
                      </Col>
                    </Row>
                    <Row className="IconsRow">
                      <Col xs={3} md={3}>
                        <div className="  ">
                          <img src={B3A8} alt="" />
                        </div>
                      </Col>
                      <Col xs={9} md={9}>
                        <div className="iconContent">
                          24x7 coaching support
                          <br />
                          via live chats
                        </div>
                      </Col>
                    </Row>
                    <Row className="IconsRow">
                      <Col xs={3} md={3}>
                        <div className="  ">
                          <img src={Copy_of_team} alt="" />{" "}
                        </div>
                      </Col>
                      <Col xs={9} md={9}>
                        <div className="iconContent">
                          Chance to create your
                          <br />
                          own esports team
                        </div>
                      </Col>
                    </Row>
                    <Row className="IconsRow">
                      <Col xs={3} md={3}>
                        <div className="  ">
                          <img src={Copy_of_whistle} alt="" />{" "}
                        </div>
                      </Col>
                      <Col xs={9} md={9}>
                        <div className="iconContent">
                          Scouting opportunities
                          <br />
                          for global teams
                          <br />
                          Rouster opportunity with
                          <br />
                          Pro Geeks Esports
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div className="LastDivOff2">
                    <Row>
                      <Col xs={6}>
                        <div className="">
                          <p className="NumberCutt">
                            <span>
                              <del>₹2000</del>
                            </span>
                            <span className="OffNum">₹1000</span>
                          </p>
                          <p className="text-nowrap fiftyOff">50% off</p>
                        </div>
                      </Col>
                      <Col xs={6}>
                        <div className="">
                          <button
                            type="button"
                            className="btn btn-success text-nowrap BuyNowBtn"
                          >
                            Buy NOW !
                          </button>
                          <br />
                          <p className="MoreInfo">More Info...</p>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Card>
              </Grid>
            </Grid>
          </div>
        </div>
        <div
          className=" text-white MainvalorantPage2 "
          style={{
            backgroundColor: "#000",
            height: "auto",
          }}
        >
          {" "}
          <div className="UpperHeading PaddingTopImage">
            <Row>
              <Col>
                <span>What our Geeks Say!</span>
              </Col>
            </Row>
          </div>
          <div>
            <Row>
              <Col xs={1}></Col>
              <Col xs={10} className="CauroselDivYours">
                <YourwordsCarousal />
              </Col>
              <Col xs={1}></Col>
            </Row>
          </div>
          {/* meet our coaches section section starts */}
          <div className="UpperHeading">
            <Row>
              <Col>
                <span>Meet our coaches</span>
              </Col>
            </Row>
          </div>
          <div>
            <Row>
              <Col xs={1}></Col>
              <Col xs={10} className="CauroselDivCoach1">
                <CoachesCarousal />
              </Col>
              <Col xs={1}></Col>
            </Row>
          </div>
          {/* meet our coaches section section ends */}
          {/* footer div starts */}
          <FoterDiv />
          {/* footer div ends */}
        </div>
      </div>
    </>
  );
};

export default ValorentNew;
