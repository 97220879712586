import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Card } from "reactstrap";
import Select from "react-select";
import { Grid } from "@mui/material";
import { connect } from "react-redux";
import * as Yup from "yup";
import moment from "moment";
import { Link } from "react-router-dom";
import { FiArrowLeft } from "react-icons/fi";
import { useNavigate, useLocation } from "react-router";
import { DatePicker, Stack } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import isBefore from "date-fns/isBefore";
//store
import { fetchGame } from "../../Store/game/actions";
import {
  fetchPresignedUrlLogo,
  uploadImages,
  addTournament,
  fetchPresignedUrlRules,
  fetchPresignedUrlRulesForDocx,
  fetchPresignedUrlTumbnail,
  fetchPresignedUrlPoolPrice,
  updateTournament,
} from "../../Store/tournament/actions";

// components
import Loader from "../../components/Loader/Loader";
import SideBar from "../../components/verticalMenu/SideBar";
import notifications from "../../components/Notifications/Notification";
import { error } from "console";
// validations
const schema = Yup.object().shape({
  gameName: Yup.string().required("Game name  is required"),
  tournamentName: Yup.string().required("Tournament Name is required"),
  tournamentFormat: Yup.string().required("Tournament Format is required"),
  MaxNoOfplayerInTeam: Yup.number().required("Players in team is require"),
  MaxteamSize: Yup.number().required("Team Size is required"),
  StartDate: Yup.string().required("Start Date is required"),
  EndDate: Yup.string().required("End Date is required"),
});
// let gameOptions: any = [];

//options
var tournamentFormatOptons: any = [
  { value: "Round Robin", label: "Round Robin" },
  { value: "Knockout", label: "Knockout" },
  { value: "League", label: "League" },
];

var limit = 10;
var formCreate = {
  gameId: "",
  label: null,
  thumbnail: "",
  logo: "",
  format: "",
  startDate: null,
  endDate: null,
  rules: "",
  teamSize: null,
  playerInTeam: null,
  tournamentForm: "",
  region: "",
  server: "",
  poolPrize: 0,
  poolPrizeImageURL: "",
};

let gameOptionsArry: any = [];
let formValues: any;
const CreateTournament = (props: any) => {
  const [loading, setLoading] = useState(false);
  const [baseImageLogo, setBaseImageLogo]: any = useState("");
  const [baseImageThumbnail, setBaseImageThumbnail]: any = useState("");
  const [baseImagePoolPrice, setBaseImagePoolPrice]: any = useState("");

  const [selectedFile, setSelectedFile]: any = useState();
  const [selectedFileRules, setSelectedFileRules]: any = useState();
  const [selectedFileThumbnail, setSelectedFileThumbnail]: any = useState();
  const [selectedFilePoolPrice, setSelectedFilePoolPrice]: any = useState();
  const [errors, setErrors]: any = useState("");
  const [startDateValue, setStartDateValue]: any = useState();
  const [form, setForm]: any = useState(formCreate);
  const fileInputLogo: any = useRef();
  const fileInputRules: any = useRef();
  const fileInputThumbnail: any = useRef();
  const fileInputPoolPrice: any = useRef();
  const navigator = useNavigate();
  const validate = () => {
    let error: any = "";
    let errorObj: any = {};
    console.log("formData", form);
    if (form?.gameId === "") {
      error = "required!";
      errorObj.gameId = "Game name is Required!";
    }
    if (form.label === null) {
      error = "Required!";
      errorObj.label = " Tournament name is Required!";
    }
    if (form.thumbnail === "") {
      error = "Required!";
      errorObj.thumbnail = " Thumbnail is Required!";
    }
    if (form.logo === "") {
      error = "Required!";
      errorObj.logo = " Logo is Required!";
    }
    if (form.format === "") {
      error = "Required!";
      errorObj.format = "Format is Required!";
    }
    if (form.startDate === null) {
      error = "Required!";
      errorObj.startDate = " Start Date is Required!";
    }
    if (form.endDate === null) {
      error = "Required!";
      errorObj.endDate = " End Date is Required!";
    }
    if (form.rules === "") {
      error = " Required!";
      errorObj.rules = " Rules are  Required!";
    }
    if (form.teamSize === null) {
      error = "Required!";
      errorObj.teamSize = " Team Size is Required!";
    }
    if (form.playerInTeam === null) {
      error = "Required!";
      errorObj.playerInTeam = " Players In Team is Required!";
    }
    if (form.tournamentForm === "") {
      error = "Required!";
      errorObj.tournamentForm = " Tournament Form is Required!";
    }
    if (form.region === "") {
      error = "Required!";
      errorObj.region = " Region is Required!";
    }
    if (form.server === "") {
      error = "Required!";
      errorObj.server = " Server name is Required!";
    }
    // if (form.poolPrizeImageURL === "") {
    //   error = " Required!";
    //   errorObj.poolPrizeImageURL = " Pool Prize Image name is Required!";
    // }
    setErrors(errorObj);
    console.log("error", errors);

    return error;
  };
  useEffect(() => {
    getAllGamesData();

    return () => {};
  }, []);

  useEffect(() => {
    console.log("formData", form, errors);
  }, [form, errors]);
  useEffect(() => {
    if (selectedFile) {
      S3Upload();
    }
  }, [selectedFile]);
  useEffect(() => {
    if (selectedFileThumbnail) {
      S3UploadThumbnail();
    }
  }, [selectedFileThumbnail]);
  useEffect(() => {
    if (selectedFileRules) {
      S3UploadRules();
    }
  }, [selectedFileRules]);
  useEffect(() => {
    if (selectedFilePoolPrice) {
      S3UploadPoolPrice();
    }
  }, [selectedFilePoolPrice]);

  let currentDate = new Date();
  let tommorowsDate = new Date(currentDate);

  let yesterDate = new Date(tommorowsDate.setDate(currentDate.getDate()));
  let yesDate = moment(yesterDate).format("MM/DD/yyyy");
  let tournamentStartDate = moment(new Date(form?.startDate)).format(
    "MM/DD/yyyy"
  );
  let currentDateForm = moment(new Date()).format("yyyy-MM-DD");
  let getAllGamesData = () => {
    let offset = 0;
    props.fetchGame(offset, 1000, {}, (res: any) => {});
  };
  if (props.getGamesDetails !== undefined) {
    let getGamesDetails = props?.getGamesDetails?.data;
    gameOptionsArry = [];
    getGamesDetails?.docs?.map((ele: any, i: any) => {
      gameOptionsArry.push({
        value: ele?._id,
        label: ele?.label,
      });
    });
  }

  const fileUploadTrigger = () => {
    fileInputLogo.current.click();
  };
  const fileUploadTriggerPoolPrice = () => {
    fileInputPoolPrice.current.click();
  };
  const fileUploadTriggerThumbnail = () => {
    fileInputThumbnail.current.click();
  };
  const fileUploadTriggerRules = () => {
    fileInputRules.current.click();
  };

  const S3Upload = async () => {
    props.fetchPresignedUrlLogo((res: any) => {
      console.log("res", res?.data);
      if (selectedFile) {
        let softCopy = "";
        // console.log("Calling Signed",res)
        softCopy = res.data.split("?")[0];
        console.log("softCopy", softCopy);
        setForm({ ...form, logo: softCopy });
        const formData = new FormData();
        formData.append(selectedFile, selectedFile.name);
        console.log("Calling");
        // console.log("--------", formData);
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", selectedFile.type);
        console.log("Calling");
        props.uploadImages(res.data, selectedFile, myHeaders);
        console.log("Calling");
      }
    });
  };
  const S3UploadThumbnail = async () => {
    props.fetchPresignedUrlTumbnail((res: any) => {
      console.log("res", res?.data);
      if (selectedFileThumbnail) {
        let softCopy = "";
        // console.log("Calling Signed",res)
        softCopy = res.data.split("?")[0];
        console.log("softCopy", softCopy);
        setForm({ ...form, thumbnail: softCopy });
        const formData = new FormData();
        formData.append(selectedFileThumbnail, selectedFileThumbnail.name);
        // console.log("--------", formData);
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", selectedFileThumbnail.type);
        props.uploadImages(res.data, selectedFileThumbnail, myHeaders);
      }
    });
  };
  const S3UploadPoolPrice = async () => {
    props.fetchPresignedUrlPoolPrice((res: any) => {
      console.log("res", res?.data);
      if (selectedFilePoolPrice) {
        let softCopy = "";
        // console.log("Calling Signed",res)
        softCopy = res.data.split("?")[0];
        console.log("softCopy", softCopy);
        setForm({ ...form, poolPrizeImageURL: softCopy });
        const formData = new FormData();
        formData.append(selectedFilePoolPrice, selectedFilePoolPrice.name);
        // console.log("--------", formData);
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", selectedFilePoolPrice.type);
        props.uploadImages(res.data, selectedFilePoolPrice, myHeaders);
      }
    });
  };
  const S3UploadRules = async () => {
    console.log(
      "selectedFileRules?.type",
      selectedFileRules?.type,
      selectedFileRules?.type === "application/pdf"
    );
    if (selectedFileRules?.type === "application/pdf") {
      props.fetchPresignedUrlRules((res: any) => {
        console.log("res", res?.data);
        if (selectedFileRules) {
          let softCopy = "";
          // console.log("Calling Signed",res)
          softCopy = res.data.split("?")[0];
          console.log("softCopy", softCopy);
          setForm({ ...form, rules: softCopy });
          const formData = new FormData();
          formData.append(selectedFileRules, selectedFileRules.name);
          console.log("Calling");
          // console.log("--------", formData);
          const myHeaders = new Headers();
          myHeaders.append("Content-Type", selectedFileRules.type);
          props.uploadImages(res.data, selectedFileRules, myHeaders);
        }
      });
    } else {
      props.fetchPresignedUrlRulesForDocx((res: any) => {
        console.log("res", res?.data);
        if (selectedFileRules) {
          let softCopy = "";
          // console.log("Calling Signed",res)
          softCopy = res.data.split("?")[0];
          console.log("softCopy", softCopy);
          setForm({ ...form, rules: softCopy });
          const formData = new FormData();
          formData.append(selectedFileRules, selectedFileRules.name);
          console.log("Calling");
          // console.log("--------", formData);
          const myHeaders = new Headers();
          myHeaders.append("Content-Type", selectedFileRules.type);
          console.log("Calling");
          props.uploadImages(res.data, selectedFileRules, myHeaders);
          console.log("Calling");
        }
      });
    }
  };
  const fileUpload = (event: any) => {
    console.log("EVENT console", event, event.target.files[0]);
    let name = event.target.name;
    if (name === "Logo") {
      uploadImage(event);
      setSelectedFile(event.target.files[0]);
      console.log("selectedFile", selectedFile, event.target.files[0]);
    } else if (name === "Rules") {
      setSelectedFileRules(event.target.files[0]);
      console.log("selectedFile", selectedFileRules, event.target.files[0]);
    } else if (name === "Thumbnail") {
      uploadImageThumnail(event);
      setSelectedFileThumbnail(event.target.files[0]);
      console.log("selectedFile", selectedFileThumbnail, event.target.files[0]);
    } else if (name === "poolPrizeImageURL") {
      uploadImagePoolPrice(event);
      setSelectedFilePoolPrice(event.target.files[0]);
      console.log("selectedFile", selectedFileThumbnail, event.target.files[0]);
    }
  };
  const uploadImage = async (e: any) => {
    const name = e.target.name;
    const file = e.target.files[0];
    const base64 = await convertBase64(file);
    setBaseImageLogo(base64);

    // console.log("base Image", this.state.baseImage);
  };
  const uploadImageThumnail = async (e: any) => {
    const file = e.target.files[0];
    const base64 = await convertBase64(file);
    setBaseImageThumbnail(base64);

    // console.log("base Image", this.state.baseImage);
  };
  const uploadImagePoolPrice = async (e: any) => {
    const file = e.target.files[0];
    const base64 = await convertBase64(file);
    setBaseImagePoolPrice(base64);

    // console.log("base Image", this.state.baseImage);
  };

  const convertBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  const handleSubmitChange = (type: any) => {
    console.log("type", type);
    if (type?.name === "startDate") {
      setStartDateValue(type?.value);
    }
    setForm({ ...form, [type.name]: type.value });
  };
  const handleOnInputChangeGame = (e: any) => {
    if (e.length > 2) {
      props.fetchGame(0, 10, { name: e.length }, (res: any) => {});
    }
  };

  return (
    <>
      <div className="pageContent">
        <div className="pageContentChild">
          <div>
            <SideBar></SideBar>
          </div>
          <div className="p-3 w-100">
            <div>
              <Link to="/tournaments">
                <button className="m-4">
                  <FiArrowLeft className="cursor-pointer editIcon " />
                </button>
              </Link>
            </div>
            {loading ? (
              <>
                <div>
                  <Loader />
                </div>
              </>
            ) : (
              <>
                <div className="container">
                  <Card className="CardTournament">
                    <Grid container>
                      <Grid item xs={12} md={6} lg={4} className="p-4">
                        <label className="mb-3 text-white">
                          Game{" "}
                          <span
                            style={{
                              color: "red",
                            }}
                          >
                            *
                          </span>
                        </label>
                        <Select
                          className="Game_selector text-white"
                          classNamePrefix="react-select"
                          placeholder="Select Game..."
                          autoFocus={true}
                          isSearchable
                          name="gameName"
                          id="gameName"
                          value={gameOptionsArry?.find(
                            (ele: any) => ele?.value === form?.gameId
                          )}
                          options={gameOptionsArry}
                          onChange={(e: any) => {
                            console.log("e test", e);
                            handleSubmitChange({
                              name: "gameId",
                              value: e,
                            });
                          }}
                          onInputChange={(e) => handleOnInputChangeGame(e)}
                        />
                        {errors.gameId && form?.gameId?.length <= 0 ? (
                          <p className="RequiredField">{errors?.gameId} </p>
                        ) : (
                          ""
                        )}
                      </Grid>
                      <Grid item xs={12} md={6} lg={4} className="p-4">
                        <label className="mb-3 text-white">
                          Tournament Name
                          <span
                            style={{
                              color: "red",
                            }}
                          >
                            *
                          </span>
                        </label>
                        <input
                          type="text"
                          id="tournamentName"
                          name="tournamentName"
                          value={form?.label}
                          placeholder="Tournament Name..."
                          className="form-control inp_text text-white"
                          onChange={(e: any) => {
                            console.log("tournament Name", e.target.value);
                            handleSubmitChange({
                              name: "label",
                              value: e.target.value,
                            });
                          }}
                        />
                        {errors.label && form?.label === null ? (
                          <p className="RequiredField">{errors?.label} </p>
                        ) : (
                          ""
                        )}
                      </Grid>
                      <Grid item xs={12} md={6} lg={4} className="p-4">
                        <label className="mb-3 text-white">
                          Tournament Format
                          <span
                            style={{
                              color: "red",
                            }}
                          >
                            *
                          </span>
                        </label>
                        <Select
                          className="Game_selector"
                          classNamePrefix="react-select"
                          placeholder="Select Tournament Format..."
                          autoFocus={true}
                          isSearchable
                          name="tournamentFormat"
                          value={form?.format}
                          // value={tournamentFormatOptons?.find(
                          //   (ele: any) => ele?.value === form?.format
                          // )}
                          options={tournamentFormatOptons}
                          onChange={(e: any) => {
                            handleSubmitChange({
                              name: "format",
                              value: e,
                            });
                          }}
                        />
                        {errors.format && form?.format?.length <= 0 ? (
                          <p className="RequiredField">{errors?.format} </p>
                        ) : (
                          ""
                        )}
                      </Grid>
                      <Grid item xs={12} md={6} lg={4} className="p-4">
                        <label className="mb-3 text-white">
                          Max. number of teams
                          <span
                            style={{
                              color: "red",
                            }}
                          >
                            *
                          </span>
                        </label>
                        <input
                          type="number"
                          name="MaxteamSize"
                          id="MaxteamSize"
                          value={form.teamSize}
                          placeholder="Team Size..."
                          className="form-control inp_text text-capitalize"
                          onChange={(e: any) => {
                            console.log("tournament Nam", e.target.value);
                            handleSubmitChange({
                              name: "teamSize",
                              value: Number(e.target.value),
                            });
                          }}
                        />
                        {errors.teamSize && !form?.teamSize ? (
                          <p className="RequiredField">{errors?.teamSize} </p>
                        ) : (
                          ""
                        )}
                      </Grid>
                      <Grid item xs={12} md={6} lg={4} className="p-4">
                        <label className="mb-3 text-white">
                          Max. number of players in a team{" "}
                          <span
                            style={{
                              color: "red",
                            }}
                          >
                            *
                          </span>
                        </label>
                        <input
                          type="number"
                          name="MaxNoOfplayerInTeam"
                          id={"MaxNoOfplayerInTeam"}
                          value={form?.playerInTeam}
                          placeholder="Players In Team..."
                          className="form-control inp_text text-capitalize"
                          onChange={(e: any) => {
                            console.log("tournament Nam", e.target.value);
                            handleSubmitChange({
                              name: "playerInTeam",
                              value: Number(e.target.value),
                            });
                          }}
                        />
                        {errors.playerInTeam && !form?.playerInTeam ? (
                          <p className="RequiredField">
                            {errors?.playerInTeam}{" "}
                          </p>
                        ) : (
                          ""
                        )}
                      </Grid>
                      <Grid item xs={12} md={6} lg={4} className="p-4">
                        <label className="mb-3 text-white">
                          Start Date{" "}
                          <span
                            style={{
                              color: "red",
                            }}
                          >
                            *
                          </span>
                        </label>
                        <div>
                          <DatePicker
                            {...props}
                            style={{
                              width: "100%",
                            }}
                            cleanable={false}
                            format="yyyy-MM-dd HH:mm"
                            name="StartDate"
                            minDate={new Date()}
                            disabledDate={(date: any) =>
                              isBefore(date, new Date(yesDate))
                            }
                            value={startDateValue ? startDateValue : null}
                            onChange={(newValue: any) => {
                              console.log("newValue", newValue);
                              handleSubmitChange({
                                name: "startDate",
                                value: newValue,
                              });
                            }}
                          />
                          {errors.startDate && !form?.startDate ? (
                            <p className="RequiredField">
                              {errors?.startDate}{" "}
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                      </Grid>
                      <Grid item xs={12} md={6} lg={4} className="p-4">
                        <label className="mb-3 text-white">
                          End Date{" "}
                          <span
                            style={{
                              color: "red",
                            }}
                          >
                            *
                          </span>
                        </label>
                        <div>
                          <DatePicker
                            {...props}
                            style={{
                              width: "100%",
                            }}
                            format="yyyy-MM-dd HH:mm"
                            name="EndDate"
                            id="EndDate"
                            cleanable={false}
                            minDate={new Date()}
                            value={form?.endDate || null}
                            disabledDate={(date: any) =>
                              isBefore(date, new Date(tournamentStartDate))
                            }
                            onChange={(newValue: any) => {
                              console.log("newValue", newValue);
                              handleSubmitChange({
                                name: "endDate",
                                value: newValue,
                              });
                            }}
                          />
                          {errors.endDate && !form?.endDate ? (
                            <p className="RequiredField">{errors?.endDate} </p>
                          ) : (
                            ""
                          )}
                        </div>
                      </Grid>

                      <Grid item xs={12} md={6} lg={4} className="p-4">
                        <label className="mb-3 text-white">
                          Tournament Form
                          <span
                            style={{
                              color: "red",
                            }}
                          >
                            *
                          </span>
                        </label>
                        <input
                          type="text"
                          id="tournamentForm"
                          name="tournamentForm"
                          value={form?.tournamentForm}
                          placeholder="Tournament Form..."
                          className="form-control inp_text text-capitalize"
                          onChange={(e: any) => {
                            console.log("tournamentForm", e.target.value);
                            handleSubmitChange({
                              name: "tournamentForm",
                              value: e.target.value,
                            });
                          }}
                        />
                        {errors.tournamentForm &&
                        form?.tournamentForm?.length <= 0 ? (
                          <p className="RequiredField">
                            {errors?.tournamentForm}{" "}
                          </p>
                        ) : (
                          ""
                        )}
                      </Grid>
                      <Grid item xs={12} md={6} lg={4} className="p-4">
                        <label className="mb-3 text-white">
                          Region{" "}
                          <span
                            style={{
                              color: "red",
                            }}
                          >
                            *
                          </span>
                        </label>
                        <input
                          type="text"
                          id="region"
                          name="region"
                          value={form?.region}
                          placeholder="Region..."
                          className="form-control inp_text text-capitalize"
                          onChange={(e: any) => {
                            console.log("region", e.target.value);
                            handleSubmitChange({
                              name: "region",
                              value: e.target.value,
                            });
                          }}
                        />
                        {errors.region && form?.region?.length <= 0 ? (
                          <p className="RequiredField">{errors?.region} </p>
                        ) : (
                          ""
                        )}
                      </Grid>
                      <Grid item xs={12} md={6} lg={4} className="p-4">
                        <label className="mb-3 text-white">
                          Server{" "}
                          <span
                            style={{
                              color: "red",
                            }}
                          >
                            *
                          </span>
                        </label>
                        <input
                          type="text"
                          id="server"
                          name="server"
                          value={form?.server}
                          placeholder="Server..."
                          className="form-control inp_text text-capitalize"
                          onChange={(e: any) => {
                            console.log("server", e.target.value);
                            handleSubmitChange({
                              name: "server",
                              value: e.target.value,
                            });
                          }}
                        />
                        {errors.server && form?.server?.length <= 0 ? (
                          <p className="RequiredField">{errors?.server} </p>
                        ) : (
                          ""
                        )}
                      </Grid>
                      <Grid item xs={12} md={6} lg={4} className="p-4">
                        <label className="mb-3 text-white">
                        Prize Pool{" "}
                         
                        </label>
                        <input
                          type="number"
                          id="poolPrize"
                          name="poolPrize"
                          value={form?.poolPrize}
                          placeholder="Prize Pool..."
                          className="form-control inp_text text-capitalize"
                          onChange={(e: any) => {
                            console.log("poolPrize", e.target.value);
                            handleSubmitChange({
                              name: "poolPrize",
                              value: e.target.value,
                            });
                          }}
                        />
                        {/* {errors.poolPrize &&
                        form?.poolPrize?.length <= 0 ? (
                          <p className="RequiredField">{errors?.poolPrize} </p>
                        ) : (
                          ""
                        )} */}
                      </Grid>
                      <Grid item xs={12} md={6} lg={4} className="p-4">
                        <label className="mb-3 text-white">
                          Tournament Logo
                          <span
                            style={{
                              color: "red",
                            }}
                          >
                            *
                          </span>
                        </label>
                        <div>
                          <input
                            type="file"
                            name="Image"
                            multiple={true}
                            style={{ display: "none" }}
                            ref={fileInputLogo}
                            onChange={(e) => {
                              console.log(e.target.files);
                              fileUpload({
                                target: {
                                  name: "Logo",
                                  files: e.target.files,
                                },
                              });
                            }}
                          />
                          <span
                            style={{
                              cursor: "pointer",
                              color: "#5664D2",
                            }}
                            onClick={() => fileUploadTrigger()}
                          >
                            <svg
                              style={{
                                marginTop: "10px",
                                marginLeft: "20px",
                              }}
                              width="51"
                              height="51"
                              viewBox="0 0 51 51"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M50.7061 0H0.293948C0.131605 0 0 1.60235 0 3.57895V47.4211C0 49.3977 0.131605 51 0.293948 51H50.7061C50.8684 51 51 49.3977 51 47.4211V3.57895C51 1.60235 50.8684 0 50.7061 0Z"
                                fill="#05284E"
                                fillOpacity="0.59"
                              />
                              <path
                                d="M50.7062 0.224609H0.294131C0.141934 0.224609 0.0185547 1.72681 0.0185547 3.57987V47.422C0.0185547 49.275 0.141934 50.7772 0.294131 50.7772H50.7062C50.8584 50.7772 50.9818 49.275 50.9818 47.422V3.57987C50.9818 1.72681 50.8584 0.224609 50.7062 0.224609Z"
                                fill="#05284E"
                                fillOpacity="0.59"
                              />
                              <path
                                d="M26 21V31"
                                stroke="white"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M21 26H31"
                                stroke="white"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </span>
                          <span className="ms-3">
                            {baseImageLogo ? (
                              // <link href={`${form?.logo}`} >
                              <img
                                src={baseImageLogo}
                                alt="Logo"
                                width="100px"
                                height="100px"
                              />
                            ) : (
                              // </link>
                              ""
                            )}
                          </span>
                        </div>
                        {errors.logo && form?.logo?.length <= 0 ? (
                          <p className="RequiredField">{errors?.logo} </p>
                        ) : (
                          ""
                        )}
                      </Grid>
                      <Grid item xs={12} md={6} lg={4} className="p-4">
                        <label className="mb-3 text-white">
                          Tournament Thumbnail
                          <span
                            style={{
                              color: "red",
                            }}
                          >
                            *
                          </span>
                        </label>
                        <div>
                          <input
                            type="file"
                            name="Image"
                            multiple={true}
                            style={{ display: "none" }}
                            ref={fileInputThumbnail}
                            onChange={(e) => {
                              console.log(e.target.files);
                              fileUpload({
                                target: {
                                  name: "Thumbnail",
                                  files: e.target.files,
                                },
                              });
                            }}
                          />
                          <span
                            style={{
                              cursor: "pointer",
                              color: "#5664D2",
                            }}
                            onClick={() => fileUploadTriggerThumbnail()}
                          >
                            <svg
                              style={{
                                marginTop: "10px",
                                marginLeft: "20px",
                              }}
                              width="51"
                              height="51"
                              viewBox="0 0 51 51"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M50.7061 0H0.293948C0.131605 0 0 1.60235 0 3.57895V47.4211C0 49.3977 0.131605 51 0.293948 51H50.7061C50.8684 51 51 49.3977 51 47.4211V3.57895C51 1.60235 50.8684 0 50.7061 0Z"
                                fill="#05284E"
                                fillOpacity="0.59"
                              />
                              <path
                                d="M50.7062 0.224609H0.294131C0.141934 0.224609 0.0185547 1.72681 0.0185547 3.57987V47.422C0.0185547 49.275 0.141934 50.7772 0.294131 50.7772H50.7062C50.8584 50.7772 50.9818 49.275 50.9818 47.422V3.57987C50.9818 1.72681 50.8584 0.224609 50.7062 0.224609Z"
                                fill="#05284E"
                                fillOpacity="0.59"
                              />
                              <path
                                d="M26 21V31"
                                stroke="white"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M21 26H31"
                                stroke="white"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </span>
                          <span className="ms-3">
                            {baseImageThumbnail ? (
                              // <link href={`${form?.logo}`} >
                              <img
                                src={baseImageThumbnail}
                                alt="Thumbnail"
                                width="100px"
                                height="100px"
                              />
                            ) : (
                              // </link>
                              ""
                            )}
                          </span>
                        </div>
                        {errors.thumbnail && form?.thumbnail?.length <= 0 ? (
                          <p className="RequiredField">{errors?.thumbnail} </p>
                        ) : (
                          ""
                        )}
                      </Grid>
                      <Grid item xs={12} md={6} lg={4} className="p-4">
                        <label className="mb-3 text-white">
                          Tournament Rule
                          <span
                            style={{
                              color: "red",
                            }}
                          >
                            *
                          </span>
                        </label>
                        <div>
                          <input
                            type="file"
                            name="Image"
                            accept=".doc,.docx,.pdf,"
                            multiple={true}
                            style={{ display: "none" }}
                            ref={fileInputRules}
                            onChange={(e) => {
                              console.log(e.target.files);
                              fileUpload({
                                target: {
                                  name: "Rules",
                                  files: e.target.files,
                                },
                              });
                            }}
                          />
                          <span
                            style={{
                              cursor: "pointer",
                              color: "#5664D2",
                            }}
                            onClick={() => fileUploadTriggerRules()}
                          >
                            <svg
                              style={{
                                marginTop: "10px",
                                marginLeft: "20px",
                              }}
                              width="51"
                              height="51"
                              viewBox="0 0 51 51"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M50.7061 0H0.293948C0.131605 0 0 1.60235 0 3.57895V47.4211C0 49.3977 0.131605 51 0.293948 51H50.7061C50.8684 51 51 49.3977 51 47.4211V3.57895C51 1.60235 50.8684 0 50.7061 0Z"
                                fill="#05284E"
                                fillOpacity="0.59"
                              />
                              <path
                                d="M50.7062 0.224609H0.294131C0.141934 0.224609 0.0185547 1.72681 0.0185547 3.57987V47.422C0.0185547 49.275 0.141934 50.7772 0.294131 50.7772H50.7062C50.8584 50.7772 50.9818 49.275 50.9818 47.422V3.57987C50.9818 1.72681 50.8584 0.224609 50.7062 0.224609Z"
                                fill="#05284E"
                                fillOpacity="0.59"
                              />
                              <path
                                d="M26 21V31"
                                stroke="white"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M21 26H31"
                                stroke="white"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </span>

                          {selectedFileRules ? (
                            <div
                              className="container"
                              style={{ display: "flex" }}
                            >
                              <div style={{ width: "30%" }}>
                                <i className="fas fa-file-alt fa-2x m-1"></i>
                                {selectedFileRules?.name}
                              </div>
                              <div style={{ flexGrow: "1" }}>
                                <i
                                  className="fas fa-times"
                                  style={{
                                    cursor: "pointer",
                                    color: "red",
                                  }}
                                ></i>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        {errors.rules && form?.rules?.length <= 0 ? (
                          <p className="RequiredField">{errors?.rules} </p>
                        ) : (
                          ""
                        )}
                      </Grid>
                      <Grid item xs={12} md={6} lg={4} className="p-4">
                        <label className="mb-3 text-white">
                          Prize Pool Image
                          
                        </label>
                        <div>
                          <input
                            type="file"
                            name="Image"
                            multiple={true}
                            style={{ display: "none" }}
                            ref={fileInputPoolPrice}
                            onChange={(e) => {
                              console.log(e.target.files);
                              fileUpload({
                                target: {
                                  name: "poolPrizeImageURL",
                                  files: e.target.files,
                                },
                              });
                            }}
                          />
                          <span
                            style={{
                              cursor: "pointer",
                              color: "#5664D2",
                            }}
                            onClick={() => fileUploadTriggerPoolPrice()}
                          >
                            <svg
                              style={{
                                marginTop: "10px",
                                marginLeft: "20px",
                              }}
                              width="51"
                              height="51"
                              viewBox="0 0 51 51"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M50.7061 0H0.293948C0.131605 0 0 1.60235 0 3.57895V47.4211C0 49.3977 0.131605 51 0.293948 51H50.7061C50.8684 51 51 49.3977 51 47.4211V3.57895C51 1.60235 50.8684 0 50.7061 0Z"
                                fill="#05284E"
                                fillOpacity="0.59"
                              />
                              <path
                                d="M50.7062 0.224609H0.294131C0.141934 0.224609 0.0185547 1.72681 0.0185547 3.57987V47.422C0.0185547 49.275 0.141934 50.7772 0.294131 50.7772H50.7062C50.8584 50.7772 50.9818 49.275 50.9818 47.422V3.57987C50.9818 1.72681 50.8584 0.224609 50.7062 0.224609Z"
                                fill="#05284E"
                                fillOpacity="0.59"
                              />
                              <path
                                d="M26 21V31"
                                stroke="white"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M21 26H31"
                                stroke="white"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </span>
                          <span className="ms-3">
                            {baseImagePoolPrice ? (
                              // <link href={`${form?.logo}`} >
                              <img
                                src={baseImagePoolPrice}
                                alt="Logo"
                                width="100px"
                                height="100px"
                              />
                            ) : (
                              // </link>
                              ""
                            )}
                          </span>
                        </div>
                        {/* {errors.poolPrizeImageURL &&
                        form?.poolPrizeImageURL?.length <= 0 ? (
                          <p className="RequiredField">{errors?.poolPrizeImageURL} </p>
                        ) : (
                          ""
                        )} */}
                      </Grid>
                    </Grid>

                    <button
                      className=" p-1 w-25 TorunamentSubmitButton ms-4 mb-5 mt-4"
                      onClick={() => {
                        const result = validate();
                        if (result?.length === 0) {
                          setLoading(true);
                          props?.addTournament(
                            {
                              gameId: form?.gameId?.value,
                              label: form?.label,
                              logo: form?.logo,
                              format: form?.format?.value,
                              startDate: moment(form?.startDate).toISOString(),
                              endDate: moment(form?.endDate).toISOString(),
                              rules: form?.rules,
                              teamSize: form?.teamSize,
                              playerInTeam: form?.playerInTeam,
                              thumbnail: form?.thumbnail,
                              tournamentForm: form?.tournamentForm,
                              region: form?.region,
                              server: form.server,
                              poolPrize: Number(form?.poolPrize),
                              prizePoolImage: form?.poolPrizeImageURL,
                            },
                            (res: any) => {
                              setLoading(false);
                              if (res.success) {
                                navigator("/tournaments");
                                window.scrollTo(0, 0)
                                notifications({
                                  title: "Success!",
                                  message: res?.message,
                                  type: "success",
                                });
                              } else {
                                notifications({
                                  title: "Error!",
                                  message: res?.message,
                                  type: "danger",
                                });
                              }
                            }
                          );
                        }
                      }}
                    >
                      Submit
                    </button>
                  </Card>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  console.log("state", state);
  return {
    ...state,
    getGamesDetails: state?.gameReducer?.gameDetail,
  };
};
export default connect(mapStateToProps, {
  fetchGame,
  fetchPresignedUrlLogo,
  uploadImages,
  addTournament,
  fetchPresignedUrlRules,
  fetchPresignedUrlRulesForDocx,
  fetchPresignedUrlTumbnail,
  fetchPresignedUrlPoolPrice,
})(CreateTournament);
