import {types } from "./actionTypes";

// creating action
export const getUsers = ( offset:any, limit:any,filters:any,callback:any) =>{
    return {
        type:types.GET_USERS,
        offset,
         limit,
         filters,
        callback
    }
}
export const userActivateDeactivate = ( payload:any,callback:any) =>{
    return {
        type:types.USER_ACTIVE_DE_ACTIVE,
        payload,
        callback
    }
}
export const updateUser = ( payload:any,callback:any) =>{
    return {
        type:types.UPDATE_USER,
        payload,
        callback
    }
}
export const tournamentUserStatus = ( payload:any,callback:any) =>{
    return {
        type:types.USER_STATUS,
        payload,
        callback
    }
}
export const sendingEmail = ( payload:any,callback:any) =>{
    return {
        type:types.SENDING_EMAIL,
        payload,
        callback
    }
}
export const storingEmailsWithSaveToGroup = ( payload:any,callback:any) =>{
    return {
        type:types.STORING_MAIL_WITH_SAVE_TO_GROUP,
        payload,
        callback
    }
}
export const getingMailWithSavedToGroup = (offset:any,limit:any,callback:any) =>{
    return {
        type:types.GETTING_MAIL_WITH_SAVE_TO_GROUP,
        offset,
        limit,
        callback
    }
}
export const sendingWhatsApp = ( payload:any,callback:any) =>{
    return {
        type:types.SENDING_WHATS_APP_NOTIFICATIONS,
        payload,
        callback
    }
}
export const updateUserProfile = ( payload:any,callback:any) =>{
    return {
        type:types.UPDATE_USER_PROFILE,
        payload,
        callback
    }
}
export const getUsersByEmailId = (emailId:any,callback:any) =>{
    return {
        type:types.GET_USER_BY_EMAILID,
         emailId,
        callback
    }
}
export const getUsersByUserId= (userId:any,callback:any) =>{
    return {
        type:types.GET_USER_BY_USERID,
        userId,
        callback
    }
}