import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { connect } from "react-redux";
import { Row, Col } from "reactstrap";
import { useNavigate, useLocation } from "react-router";
//store
import { addTaggedVideo } from "../../Store/tournament/actions";
// components
import CloseIcon from "../../components/Icons/CloseIcon";
import SideBar from "../../components/verticalMenu/SideBar";
import notifications from "../../components/Notifications/Notification";
const AddVideoTag = (props: any) => {
  const location = useLocation();
  const navigator = useNavigate();
  const tournamentId = location?.state?.tournament?._id;
  console.log(tournamentId, "tournamentId");
  const [taggedVidoes, setTagedVideos]: any = useState([]);
  const [featuredVideo, setFeaturedVideo]: any = useState({
    videoUrl: "",
    featured: 1,
  });
  const [featuredVideoArray, setFeaturedVideoArray]: any = useState([]);
  const [nonFeaturedVido, setNonFeaturedVideo]: any = useState("");
  const [nonFeaturedVidoArray, setNonFeaturedVideoArray]: any = useState([]);
  useEffect(() => {
    console.log(
      "Tagged",
      featuredVideo,
      nonFeaturedVido,
      taggedVidoes,
      featuredVideoArray,
      nonFeaturedVidoArray
    );
  }, [
    featuredVideo,
    nonFeaturedVido,
    taggedVidoes,
    featuredVideoArray,
    nonFeaturedVidoArray,
  ]);
  const handleSubmit = () => {
    let payload: any = {
      tournamentId: tournamentId,
      tagVideo: [...featuredVideoArray, ...nonFeaturedVidoArray],
    };
    props.addTaggedVideo(payload, (res: any) => {
      if (res.success) {
        // navigator("/tournaments");
        notifications({
          title: "Success!",
          message: res?.message,
          type: "success",
        });
        navigator("/tournaments");
        window.scrollTo(0, 0)
      } else {
        notifications({
          title: "Error!",
          message: res?.message,
          type: "danger",
        });
      }
    });
  };
  return (
    <>
      <div className="pageContent text-white">
        <div className="pageContentChild">
          <div>
            <SideBar></SideBar>
          </div>
          <div className="p-3 w-100">
            <h3 className="text-white">Tagged Videos</h3>
            <Grid container>
              <Grid item xs={12} md={6}>
              <h5 className="p-2">Featured</h5>
                <div>
                  <Row>
                    <Col xs={8}>
                      <input
                        {...props}
                        type="text"
                        placeholder="Fetured Videos..."
                        name="Featured"
                        value={featuredVideo?.videoUrl}
                        onChange={(e: any) => {
                          // console.log("test",e.target.value)
                          setFeaturedVideo({
                            videoUrl: e.target.value,
                            featured: 1,
                          });
                        }}
                      />
                    </Col>
                    <Col xs={4}>
                      <button
                        className="p-3"
                        style={{
                          color:"#000",
                          fontFamily:"Poppins",
                          fontSize:"20px"
                        }}
                        onClick={() => {
                          setFeaturedVideoArray((prev: any) => [
                            ...prev,
                            featuredVideo,
                          ]);
                          setFeaturedVideo({
                            videoUrl: "",
                          });
                        }}
                      >
                        Add featured video
                      </button>
                    </Col>
                  </Row>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <h5 className="p-2">Non Featured</h5>
                <div>
                  <Row>
                    <Col xs={8}>
                      <input
                        {...props}
                        type="text"
                        placeholder="NonFetured Videos..."
                        name="nonFeatured"
                        value={nonFeaturedVido}
                        onChange={(e: any) => {
                          // console.log("test",e.target.value)
                          setNonFeaturedVideo(e.target.value);
                          // setFeaturedVideo({
                          //   videoUrl: e.target.value,
                          //   featured: 1,
                          // });
                        }}
                      />
                    </Col>
                    <Col xs={4}>
                      <button
                        style={{
                          color:"#000",
                          fontFamily:"Poppins",
                          fontSize:"20px"
                        }}
                        className="p-3"
                        onClick={() => {
                          setNonFeaturedVideoArray((prev: any) => [
                            ...prev,
                            {
                              videoUrl: nonFeaturedVido,
                              featured: 0,
                            },
                          ]);
                          setNonFeaturedVideo("");
                        }}
                      >
                        Add Non-Featured video
                      </button>
                    </Col>
                  </Row>
                </div>
              </Grid>
              <Grid item xs={12}>
                <button
                  style={{
                    color:"#000",
                    fontFamily:"Poppins",
                    fontSize:"20px"
                  }}
                  className="p-3 ps-5 pe-5 float-end mt-4 me-4"
                  onClick={() => handleSubmit()}
                >
                  Submit
                </button>
              </Grid>
              <Grid item xs={12}>
                {featuredVideoArray?.length ? <h3>Featured Videos</h3> : ""}

                <Row>
                  {featuredVideoArray?.length
                    ? featuredVideoArray.map((video: any, i: any) => {
                        console.log("video", video?.videoUrl?.split("=")[1]);
                        return (
                          <React.Fragment key={i}>
                            <Col xs={12} md={4} lg={3}>
                              <span className="float-end">
                                <svg
                                  key={i}
                                  className="SvgIcon"
                                  onClick={() => {
                                    let deletefeturedTagedVideo =
                                      featuredVideoArray.filter(
                                        (video: any, index: any) => i !== index
                                      );
                                    console.log(
                                      "deletefeturedTagedVideo",
                                      deletefeturedTagedVideo
                                    );
                                    setFeaturedVideoArray(
                                      deletefeturedTagedVideo
                                    );
                                  }}
                                  width="20"
                                  height="15"
                                  viewBox="0 0 7 7"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M6 1L1 6"
                                    stroke="#2800FC"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M1 1L6 6"
                                    stroke="#2800FC"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </span>
                              <iframe
                                {...props}
                                id="ytplayer"
                                title={video?.videoUrl.split("=")[1]}
                                type="text/html"
                                width="100%"
                                height="360"
                                src={`https://www.youtube.com/embed/${
                                  video?.videoUrl.split("=")[1]
                                }?autoplay=0&origin=http://example.com&controls=0&rel=1`}
                                frameBorder="0"
                              ></iframe>
                            </Col>
                          </React.Fragment>
                        );
                      })
                    : ""}
                </Row>
              </Grid>
              <Grid item xs={12}>
                {nonFeaturedVidoArray?.length ? (
                  <h3>Non Featured Videos</h3>
                ) : (
                  ""
                )}

                <Row>
                  {nonFeaturedVidoArray?.length
                    ? nonFeaturedVidoArray.map((video: any, i: any) => {
                        console.log("video", video?.videoUrl?.split("=")[1]);
                        return (
                          <React.Fragment key={i}>
                            <Col xs={12} md={4} lg={3}>
                              <span className="float-end">
                                <svg
                                  key={i}
                                  className="SvgIcon"
                                  onClick={() => {
                                    let deleteNonfeturedTagedVideo =
                                      nonFeaturedVidoArray.filter(
                                        (video: any, index: any) => i !== index
                                      );
                                    setNonFeaturedVideoArray(
                                      deleteNonfeturedTagedVideo
                                    );
                                  }}
                                  width="20"
                                  height="15"
                                  viewBox="0 0 7 7"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M6 1L1 6"
                                    stroke="#2800FC"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M1 1L6 6"
                                    stroke="#2800FC"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </span>
                              <iframe
                                {...props}
                                id="ytplayer"
                                title={video?.videoUrl.split("=")[1]}
                                type="text/html"
                                width="100%"
                                height="360"
                                src={`https://www.youtube.com/embed/${
                                  video?.videoUrl.split("=")[1]
                                }?autoplay=0&origin=http://example.com&controls=0&rel=1`}
                                frameBorder="0"
                              ></iframe>
                            </Col>
                          </React.Fragment>
                        );
                      })
                    : ""}
                </Row>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  console.log("state", state);
  return {
    ...state,
    // getGamesDetails: state?.gameReducer?.gameDetail,
  };
};
export default connect(mapStateToProps, {
  addTaggedVideo,
})(AddVideoTag);
