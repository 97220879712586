export const HeaderData = {
    columns: [
      {
        label: "Logo",
        field: "logo",
        width: 2,
      },
      {
        label: "Label",
        field: "label",
        width: 3,
      },
      {
        label: "Game",
        field: "game",
        width: 2,
      },
      {
        label: "Format",
        field: "format",
        width: 2,
      },
      // {
      //   label: "Players In Team",
      //   field: "playerInTeam",
      //   width: 2,
      // },
      // {
      //   label: "Team Size",
      //   field: "teamSize",
      //   width: 2,
      // },
      {
        label: "Start Date",
        field: "startDate",
        width: 2,
      },
      {
        label: "End Date",
        field: "endDate",
        width: 3,
      },
      {
        label: "Rules",
        field: "rule",
        width: 2,
      },
      {
        label: "Action",
        field: "action",
        width: 2,
      },
    ],
  };