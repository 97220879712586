import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Row, Col, Button, Input } from "reactstrap";
import notifications from "../../components/Notifications/Notification";
import {  Pagination, Stack } from "@mui/material";
import Loader from "../../components/Loader/Loader";
import Index from "../../components/common";
// store

import { fetchInvitedPlayers } from "../../Store/userTournament/actions";
// global
var offset = 0;
var limit = 10;
var totCnt = 0;
var totPages = 0;
var toVal = 0;
var fromVal = 0;
var data: any = [];

const InvitedPlayerUserTournament = (props: any) => {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getAllData();
  }, []);
  let HeaderData = {
    columns: [
      {
        label: " Player Name",
        field: "name",
        width: 2,
      },
      {
        label: "Email",
        field: "email",
        width: 4,
      },
      {
        label: "Phone",
        field: "phoneNumber",
        width: 2,
      },
      {
        label: "Game Rank",
        field: "gameRank",
        width: 2,
      },
      {
        label: "Discord ID",
        field: "discordId",
        width: 2,
      },
      {
        label: "Status",
        field: "acceptanceStatus",
        width: 2,
      },
    ],
    // rows: trips,
  };
  const getAllData = () => {
    let offset = 0;
    setLoading(true);
    props.fetchInvitedPlayers(
      offset,
      limit,
      { tournamentId: props?.tournamentId },
      (res: any) => {
        setLoading(false);
       
      }
    );
  };
  let handleChangePage = (event: any, val?: any) => {
    console.log("val", val);
    offset = (val - 1) * 10;
    toVal = offset + 10;
    setLoading(true);
    props.fetchInvitedPlayers(
      offset,
      limit,
      { tournamentId: props?.tournamentId },
      (res: any) => {
        setLoading(false);
      
      }
    );
  };
  if (
    props?.InvitedPlayerDetails !== undefined ||
    props?.InvitedPlayerDetails !== null
  ) {
    let InvitedPlayerDetails = props?.InvitedPlayerDetails?.data;
    offset = InvitedPlayerDetails?.offset;
    totCnt = InvitedPlayerDetails?.length;
    fromVal = totCnt !== 0 ? offset + 1 : fromVal;
    toVal = InvitedPlayerDetails?.offset + InvitedPlayerDetails?.docs?.length;
    totPages = Math.ceil(totCnt / 10);
    data = [];
    props.InvitedPlayerDetails?.data?.docs?.map((ele: any, i: any) => {
      data.push({
        id: ele?._id,
        teamLogo: ele?.teamLogo,
        teamName: ele?.teamName,
        region:ele?.tournmentInfo?.region,
        users: ele?.users,
        // action: action(ele),
      });
    });
  }
  return (
    <>
      {loading ? (
        <>
          <div>
            <Loader />
          </div>
        </>
      ) : (
        <>
          {totCnt ? (
            <>
            <div className="p-4 tableHeader">
              <Row className="TeamHeader">
                <Col xs={3}>
                  Team Name
                </Col>
                <Col xs={3}>
                  Players
                </Col>
                <Col xs={3}>
                  Region
                </Col>
                <Col xs={3}>
                  Wins
                </Col>
              </Row>
              {
                data?.map((ele:any,i:any)=>{
                  return(
                    <React.Fragment>
                      <Row className="TeamBody">
                        <Col xs={3}>
                          <span>
                            {
                              <img src={ele?.teamLogo } width="50px" height="50px"  alt="Logo" />
                            }
                          </span>
                         {ele?.teamName}
                        </Col>
                        <Col xs={3}>
                          {ele?.users?.length} Players
                        </Col>
                        <Col xs={3}>
                          {ele?.region}
                        </Col>
                         <Col xs={3}>
                        </Col>
                      </Row>
                    </React.Fragment>
                  )
                })
              }
              </div>
            
            </>
          ) : (
            <div className="d-flex justify-content-center  text-white">
           <span className="text-center">
            Team Data does not exist's
           </span>
           </div>
          )}
        </>
      )}
    </>
  );
};

const mapStateToProps = (state: any) => {
  console.log("state", state);
  return {
    ...state,
    InvitedPlayerDetails: state?.userTournamentReducer?.invitedPlayers,
  };
};
export default connect(mapStateToProps, {
  fetchInvitedPlayers,
})(InvitedPlayerUserTournament);
