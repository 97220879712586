import {types } from "./actionTypes";

// creating action
export const addMatchPerfomance = ( payload:any,callback:any) =>{
    return {
        type:types.ADD_MATCH_PERFOMANCE,
        payload,
        callback
    }
}
export const fetchMatchPerfomance = ( offset:any,limit:any,filters:any,callback:any) =>{
    return {
        type:types.FETCH_MATCH_PERFOMANCES,
        offset,
        limit,
        filters,
        callback
    }
}
export const updateMatchPerfomance = ( payload:any,callback:any) =>{
    return {
        type:types.EDIT_MATCH_PERFOMANCE,
        payload,
        callback
    }
}
export const deleteMatchPerfomance = ( payload:any,callback:any) =>{
    return {
        type:types.DELETE_MATCH_PERFOMANCE,
        payload,
        callback
    }
}
