import { AnyAaaaRecord } from "dns";
import {types } from "./actionTypes";

// creating action
export const addGame = ( payload:any,callback:any) =>{
    return {
        type:types.ADD_GAME,
        payload,
        callback
    }
}
export const fetchGame = ( offset:any,limit:any,filters:any,callback:any) =>{
    return {
        type:types.FETCH_GAMES,
        offset,
        limit,
        filters,
        callback
    }
}
export const updateGame = ( payload:any,callback:any) =>{
    return {
        type:types.EDIT_GAME,
        payload,
        callback
    }
}
export const deleteGame = ( payload:any,callback:any) =>{
    return {
        type:types.DELETE_GAME,
        payload,
        callback
    }
}
