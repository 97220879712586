import { types } from "./actionTypes";
const initialState = {
 usersDetails:null,
 userActivateDeactivate:null,
 gettingEmailsWithSavingGroup:null,
 getUserByEmailId:null,
 userDetailsByUserId:null
};
const userReducer = (state: any = initialState, action: any) => {
  // console.log("action",action.payload)
  switch (action.type) {
    case types.GET_USERS_SUCCESS:
      state = {
        ...state,
        usersDetails: action.payload,
      };
      return state;
      case types.USER_ACTIVE_DE_ACTIVE_SUCCESS:
      state = {
        ...state,
        userActivateDeactivate: action.payload,
      };
      return state;
      case types.GETTING_MAIL_WITH_SAVE_TO_GROUP_SUCCESS:
        state = {
          ...state,
          gettingEmailsWithSavingGroup: action.payload,
        };
        return state;
        case types.GET_USER_BY_EMAILID_SUCCESS:
      state = {
        ...state,
        getUserByEmailId: action.payload,
      };
      return state;
      case types.GET_USER_BY_USERID_SUCCESS:
        state = {
          ...state,
          userDetailsByUserId: action.payload,
        };
        return state;
    default:
      return state;
  }

};

export default userReducer;
