import React from "react";
import { Card, Row, Col } from "reactstrap";
import Slider from "react-slick";
import valorant from "../../assets/images/beginnerC1.jpeg";
import proCoaching from "../../assets/images/beginnerC2.jpg";
import practice from "../../assets/images/beginnerC3.jpg";
const WhatMoreIntermediateCarousel = () => {
  const settings = {
    // dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1224,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <Row className="WhatCaurosel">
        <Col xs={0} md={1}></Col>
        <Col xs={12} md={10}>
          <Slider {...settings}>
            <Card className="whatCardCarousel">
              <img src={valorant} alt="valorant" />

              <div className="structuredHeader">
                <h1>4 Practice Scrims</h1>
              </div>
              <div className="structuredDiv2">
                <span>
                  You play 2 matches per scrim where the coach observe you personally.
                  After every match your performance is individually analysed by
                  our Pro coaches.
                </span>
              </div>
            </Card>
            <Card className="whatCardCarousel">
              <img src={proCoaching} alt="valorant" />

              <div className="structuredHeader">
                <h1>Personalised Drills</h1>
              </div>
              <div className="structuredDiv2">
                <span>
                  Based on your weekly scrims, our coaches prepare personalised
                  training drills for your specific needs. We make sure you
                  master those aspects you need to improve on the most.
                </span>
              </div>
            </Card>
            <Card className="whatCardCarousel">
              <img src={practice} alt="valorant" />

              <div className="structuredHeader">
                <h1>Submit your game</h1>
              </div>
              <div className="structuredDiv2">
                <span>
                  In between the week, all students submit 2-3 rounds of their
                  game to get reviewed. Here the coach pinpoint your mistakes
                  and suggest how to improve them.
                </span>
              </div>
            </Card>
          </Slider>
        </Col>
        <Col xs={0} md={1}></Col>
      </Row>
    </>
  );
};

export default WhatMoreIntermediateCarousel;
