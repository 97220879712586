export const types = {
  GET_WHATSAPP_BULK_DETAILS: "GET_WHATSAPP_BULK_DETAILS",
  GET_WHATSAPP_BULK_DETAILS_SUCCESS: "GET_WHATSAPP_BULK_DETAILS_SUCCESS",
  CREATE_WHATSAPP_BULK_DETAILS: "CREATE_WHATSAPP_BULK_DETAILS",
  CREATE_WHATSAPP_BULK_DETAILS_SUCCESS: "CREATE_WHATSAPP_BULK_DETAILS_SUCCESS",
  UPDATE_WHATSAPP_BULK_DETAILS: "UPDATE_WHATSAPP_BULK_DETAILS",
  UPDATE_WHATSAPP_BULK_DETAILS_SUCCESS: "UPDATE_WHATSAPP_BULK_DETAILS_SUCCESS",
  SEND_WHATSAPP_BULK_DETAILS_SUCCESS: "SEND_WHATSAPP_BULK_DETAILS_SUCCESS",
  SEND_WHATSAPP_BULK_DETAILS: "SEND_WHATSAPP_BULK_DETAILS",
  DELETE_WHATSAPP_BULK_DETAILS:"DELETE_WHATSAPP_BULK_DETAILS",
  DELETE_WHATSAPP_BULK_DETAILS_SUCCESS:"DELETE_WHATSAPP_BULK_DETAILS_SUCCESS",
  PRESIGNED_URL_UPLOAD_EXCEL_SHEET:"PRESIGNED_URL_UPLOAD_EXCEL_SHEET",
  PRESIGNED_URL_UPLOAD_EXCEL_SHEET_SUCCESS:"PRESIGNED_URL_UPLOAD_EXCEL_SHEET_SUCCESS"
};
