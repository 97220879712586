import { AnyAaaaRecord } from "dns";
import { type } from "os";
import {types } from "./actionTypes";

// creating action
export const addMatchDetails = ( payload:any,callback:any) =>{
    return {
        type:types.ADD_MATCH,
        payload,
        callback
    }
}
export const fetchMatchDetails = ( offset:any,limit:any,filters:any,callback:any) =>{
    return {
        type:types.FETCH_MATCHS,
        offset,
        limit,
        filters,
        callback
    }
}
export const updateMatchDetails = ( payload:any,callback:any) =>{
    return {
        type:types.EDIT_MATCH,
        payload,
        callback
    }
}
export const deleteMatchDetails = ( payload:any,callback:any) =>{
    return {
        type:types.DELETE_MATCH,
        payload,
        callback
    }
}
export const getProfileScoreFromeMatches=(userId:any,callback:any)=>{
    return{
        type:types.GET_PROFILE_SCORES_MATCHCHES,
        userId,
        callback
    }
}