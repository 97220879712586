import React, { useEffect, useState } from "react";
import { Pagination, Stack } from "@mui/material";
import { Row, Col } from "reactstrap";
import { connect } from "react-redux";
//components
import { HeaderData } from "./HeaderData";
import Index from "../../components/common";
import Loader from "../../components/Loader/Loader";
import SideBar from "../../components/verticalMenu/SideBar";
//store
import { 
  // fecthScoresByLeader,
  getOverAllLeaderboardScores
 } from "../../Store/leaderBoard/actions";

// global
var offset = 0;
var limit = 10;
var totCnt = 0;
var totPages = 0;
var toVal = 0;
var fromVal = 0;
var data: any = [];
const LeaderBoard = (props: any) => {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getAllleaderBoardData();
  }, []);
  let auth: any =
    localStorage.getItem("authUser") || sessionStorage.getItem("authUser");
  let authUser: any = JSON.parse(auth) || "";
  const getAllleaderBoardData = () => {
    setLoading(true);
    let offset = 0;
    props.getOverAllLeaderboardScores(offset, limit, (res: any) => {
      setLoading(false);
    });
  };
  let handleChangePage = (event: any, val?: any) => {
    console.log("val", val);
    offset = (val - 1) * 10;
    toVal = offset + 10;
    props.getOverAllLeaderboardScores(offset, limit,(res: any) => {
      setLoading(false);
    
    });
  };
  if (props?.getLeaderBoardDetails !== undefined) {
    let getLeaderBoardDetails = props?.getLeaderBoardDetails?.data;
    offset = getLeaderBoardDetails?.offset;
    totCnt = getLeaderBoardDetails?.length;
    fromVal = totCnt !== 0 ? offset + 1 : fromVal;
    toVal = getLeaderBoardDetails?.offset + getLeaderBoardDetails?.docs?.length;
    totPages = Math.ceil(totCnt / 10);
    data = [];
    props.getLeaderBoardDetails?.data?.docs?.map((ele: any, i: any) => {
      data.push({
        id: ele?._id,
        // tournamentName: ele?.tournamentInfo?.label,
        // email: ele?.userInfo?.email,
        // playerName: ele?.userInfo?.fullName,
        // position: ele?.position,
        // action: action(ele),
        playerName:ele?.userInfo[0]?.fullName,
        totalKills:ele?.scores[0]?.totalKills ? ele?.scores[0]?.totalKills :0,
        totalDeaths:ele?.scores[0]?.totalDeaths ? ele?.scores[0]?.totalDeaths :0,
        totalCombatScore:ele?.scores[0]?.totalCombatScore ? ele?.scores[0]?.totalCombatScore :0,
        totalAssists:ele?.scores[0]?.totalAssists ? ele?.scores[0]?.totalAssists :0,
        totalPlants:ele?.scores[0]?.totalPlants ? ele?.scores[0]?.totalPlants :0,
        totalFirstBlood:ele?.scores[0]?.totalFirstBlood ? ele?.scores[0]?.totalFirstBlood:0,
        position:ele?.position
      });
    });
  }
  return (
    <>
    <div className="pageContent1" >
  <div className="pageContentChild">
 
   <div className="p-3 w-100">
   <h3>Leaderboard Scores</h3>
            {loading ? (
              <>
                <div>
                  <Loader />
                </div>
              </>
            ) : (
              <>
                {totCnt ? (
                  <>
                    <Index
                      data={data}
                      headerData={HeaderData}
                      limit={limit}
                      rowHeight={"60px"}
                    />

                    <div className=" m-2">
                      <Row className="m-4">
                        <Col xs={6} className="text-white">
                          <Stack spacing={2}>
                            <Pagination
                              className="pagination"
                              size="small"
                              shape="rounded"
                              page={offset / 10 + 1}
                              count={totPages}
                              color="primary"
                              // onChange={(e)=>{handleChangePage(e,offset / 10 + 1)}}
                              onChange={(e, val) => {
                                handleChangePage(e, val);
                                console.log("event", e, val);
                              }}
                              defaultPage={1}
                              siblingCount={1}
                            />
                          </Stack>
                        </Col>
                        <Col xs={6}>
                          <span className="text-white">
                            Showing {fromVal} to {toVal} of {totCnt} entries
                          </span>
                        </Col>
                      </Row>
                    </div>
                  </>
                ) : (
                  <div className="NoDataDiv">
                    <span>No data exists</span>
                  </div>
                )}
              </>
            )}
     </div>
     </div>
     </div>
     
    </>
  );
};

const mapStateToProps = (state: any) => {
  console.log("state", state);
  return {
    ...state,
    // getLeaderBoardDetails:
    //   state?.leaderBoardReducer.getScoresByLeade,
      getLeaderBoardDetails:
      state?.leaderBoardReducer.getOverAllLeaderBoard
  };
};
export default connect(mapStateToProps, {
  // fecthScoresByLeader,
  getOverAllLeaderboardScores
})(LeaderBoard);
