import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import { types } from "./actionTypes";
import  { AxiosResponse } from "axios";

//medthods
import {
  postApi,
  putApi,
  getApi,
  deleteApi,
} from "../.././helpers/auth_helpers";

// workers
function* workAddMatch({ payload, callback }: any) {
  try {
    const url: string = process.env.REACT_APP_SERVERURL + "/match/details";
    const response: AxiosResponse = yield call(postApi, url, payload);
    callback(response);
    // console.log("users",response)
    yield put({
      type: types.ADD_MATCH_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workFetchMatchs({ offset, limit, filters, callback }: any) {
  console.log("filetrs",filters)
  let tournamentId = filters?.tournamentId ? filters?.tournamentId :"";
  try {
    const url: string =
      process.env.REACT_APP_SERVERURL +
      "/match/matches?offset=" +
      offset +
      "&limit=" +
      limit +
      "&tournamentId="+
      tournamentId;
    const response: AxiosResponse = yield call(getApi, url);
    callback(response);
    // console.log("users",response)
    yield put({
      type: types.FETCH_MATCHS_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workUpdateMatch({ payload, callback }: any) {
  try {
    const url: string = process.env.REACT_APP_SERVERURL + "/match/update";
    const response: AxiosResponse = yield call(putApi, url, payload);
    callback(response);
    // console.log("users",response)
    yield put({
      type: types.EDIT_MATCH_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workDeleteMatch({ payload, callback }: any) {
  try {
    const url: string = process.env.REACT_APP_SERVERURL + "/match/delete";
    const response: AxiosResponse = yield call(deleteApi, url, payload);
    callback(response);
    // console.log("users",response)
    yield put({
      type: types.DELETE_MATCH_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workGetProfileFromMatches({  userId, callback }: any) {
  try {
    const url: string =
      process.env.REACT_APP_SERVERURL +
      "/match/getProfileScoresFromMatches?userId=" +userId;
      
    const response: AxiosResponse = yield call(getApi, url);
    callback(response);
    // console.log("users",response)
    yield put({
      type: types.GET_PROFILE_SCORES_MATCHCHES_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
// watchers
export function* watchAddMatch() {
  yield takeEvery(types.ADD_MATCH, workAddMatch);
}
export function* watchFetchMatch() {
  yield takeEvery(types.FETCH_MATCHS, workFetchMatchs);
}
export function* watchUpdateMatch() {
  yield takeEvery(types.EDIT_MATCH, workUpdateMatch);
}
export function* watchDeleteMatch() {
  yield takeEvery(types.DELETE_MATCH, workDeleteMatch);
}
export function* watchGetProfileFromMatches() {
  yield takeEvery(types.GET_PROFILE_SCORES_MATCHCHES, workGetProfileFromMatches);
}

function* matchSaga() {
  yield all([
    fork(watchAddMatch),
    fork(watchFetchMatch),
    fork(watchUpdateMatch),
    fork(watchDeleteMatch),
    fork(watchGetProfileFromMatches)
  ]);
}
export default matchSaga;
