import React, { useState, useEffect } from "react";
import { Label, Input, Button } from "reactstrap";
import { connect } from "react-redux";
import axios from "axios";

//components
import notifications from "../../components/Notifications/Notification";
//store
import { uploadImages } from "../../Store/tournament/actions";
import {
  createWhatsAppBulkDetails,
  getPresignedUrlForExcel,
  updateWhatsAppBulkDetails,
} from "../../Store/actions";
const AddorEditWhatsAppRecords = (props: any) => {
  const [form, setForm]: any = useState(
    props.editData?._id
      ? {
          uploadExcel: props.editData?.excelFileURL,
          IdentificationName: props.editData?.templateName,
          contentName: props.editData?.templateMessage,
          mediaURL:props.editData?.mediaURL
        }
      : {
          uploadExcel: "",
          IdentificationName: "",
          contentName: "",
          mediaURL:""

        }
  );
  const [Error, setError]: any = React.useState({
    uploadExcel: "",
    IdentificationName: "",
    contentName: "",
    mediaURL:""
  });
  const [baseImageLogo, setBaseImageLogo]: any = useState("");
  const [selectedFile, setSelectedFile]: any = useState();
  const fileInputProfile: any = React.useRef();
  const fileUploadTrigger = () => {
    fileInputProfile.current.click();
  };

  useEffect(() => {
    if (selectedFile) {
      S3Upload();
    }
  }, [selectedFile]);
  const validate = () => {
    let error = "";
    if (form?.uploadExcel === "") {
      error = "Required";
      setError({
        uploadExcel: "uploadExcel sheet is Required!",
      });
    }
    if (form?.contentName === "") {
      error = "Required";
      setError({
        contentName: "Content is Required!",
      });
    }
    if (form?.IdentificationName === "") {
      error = "Required";
      setError({
        IdentificationName: "Identification Name is Required!",
      });
    }
    return error;
  };
  const S3Upload = async () => {
    console.log("selectedFile?.type", selectedFile?.type, selectedFile?.type);
    if (selectedFile) {
      props.getPresignedUrlForExcel((res: any) => {
        console.log("res", res?.data);
        let softCopy = "";

        softCopy = res.data.split("?")[0];
        console.log("softCopy", softCopy);
        setForm({ ...form, uploadExcel: softCopy });
        const formData = new FormData();
        formData.append(selectedFile, selectedFile.name);
        console.log("--------", formData);
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", selectedFile.type);
        props.uploadImages(res.data, selectedFile, myHeaders);
      });
    }
  };
  const fileUpload = (event: any) => {
    console.log("EVENT console", event, event.target.files[0]);
    let name = event.target.name;
    if (name === "profileURL") {
      setSelectedFile(event.target.files[0]);
    }
  };
  const handleSubmit = () => {
    let createPayload = {
      templateName: form?.IdentificationName,
      templateMessage: form?.contentName,
      excelFileURL: form?.uploadExcel,
      mediaURL:form?.mediaURL
    };
    let updatePayload = {
      id: props.editData?._id,
      templateName: form?.IdentificationName,
      templateMessage: form?.contentName,
      excelFileURL: form?.uploadExcel,
      mediaURL:form?.mediaURL
    };
    let result: any = validate();
    if (result?.length === 0) {
      props?.editData?._id
        ? props.updateWhatsAppBulkDetails(updatePayload, (res: any) => {
            if (res.success) {
              notifications({
                title: "Success!",
                message: res?.message,
                type: "success",
              });
              props.closeModal();
              props.getAllData();
            } else {
              notifications({
                title: "Error!",
                message: res?.message,
                type: "danger",
              });
            }
          })
        : props.createWhatsAppBulkDetails(createPayload, (res: any) => {
            if (res.success) {
              notifications({
                title: "Success!",
                message: res?.message,
                type: "success",
              });
              props.closeModal();
              props.getAllData();
            } else {
              notifications({
                title: "Error!",
                message: res?.message,
                type: "danger",
              });
            }
          });
    }
  };
  const downloadExcel = async (url:any) => {
    try {
      const response = await axios({
        url,
        method: 'GET',
        responseType: 'blob',
      });
      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = "BulkExcelsheet.xlsx";
      link.click();
    } catch (error) {
      console.error(error);
    }
  };
  let excelSheetName: any = form?.uploadExcel.split("/");
  return (
    <>
      <div className="WhatsAppBulk">
        <div className="form1">
          <div className="formDiv">
            <Label>
              Identification Name<span>*</span>
            </Label>
            <Input
              name="IdentificationName"
              id="IdentificationName"
              className="form-control"
              placeholder="Enter IdentificationName"
              value={form?.IdentificationName}
              onChange={(e: any) =>
                setForm({
                  ...form,
                  IdentificationName: e.target.value,
                })
              }
            />
            {Error.IdentificationName && !form?.IdentificationName ? (
              <p className="RequiredField">{Error?.IdentificationName} </p>
            ) : (
              ""
            )}
          </div>
          <div className="formDiv">
            <Label>
              Content<span>*</span>
            </Label>
            <div>
              <textarea
                placeholder="Type Content here"
                name="contentName"
                value={form?.contentName}
                onChange={(e: any) =>
                  setForm({
                    ...form,
                    contentName: e.target.value,
                  })
                }
              />
            </div>
            {Error.contentName && !form?.contentName ? (
              <p className="RequiredField">{Error?.contentName} </p>
            ) : (
              ""
            )}
          </div>
          <div className="formDiv">
            <Label>
              Media URL<span>*</span>
            </Label>
            <div>
              <textarea
              style={{
                height:"150px"
              }}
              className="mediaUrl"
                placeholder="Media Url"
                name="contentName"
                value={form?.mediaURL}
                onChange={(e: any) =>
                  setForm({
                    ...form,
                    mediaURL: e.target.value,
                  })
                }
              />
            </div>
           
          </div>
          <div className="formDiv">
            <Label>
              Upload Excel Sheet<span>*</span>
            </Label>
            <div>
              <div className="d-flex">
                <div className="imageDiv">
                  <div className="d-block">
                    <svg
                      onClick={() => fileUploadTrigger()}
                      width="50"
                      height="50"
                      viewBox="0 0 50 50"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0 31.6534C0.0758962 31.2107 0.126494 30.768 0.227689 30.3253C0.809559 27.6309 2.23894 25.5185 4.51582 23.9753C4.65497 23.8741 4.80676 23.7729 4.9712 23.659C4.17429 20.9521 4.57907 18.4855 6.60297 16.4616C8.61422 14.4503 11.0808 13.9949 13.7372 14.8045C17.0893 8.92253 22.0225 6.15232 28.7773 7.22752C33.9256 8.04973 39.1245 12.4517 39.9214 19.3709C40.2376 19.4468 40.5665 19.4974 40.8827 19.5986C44.8167 20.7876 47.511 23.3428 48.8265 27.2009C50.6607 32.5515 48.7633 37.9781 44.0704 41.1531C42.2995 42.3548 40.3262 42.9873 38.1631 42.9873C35.0008 42.9873 31.8511 42.9873 28.6888 42.9873C28.499 42.9873 28.3093 42.9873 28.0942 42.9873C28.0942 39.3949 28.0942 35.8657 28.0942 32.2733C28.2587 32.2606 28.4105 32.248 28.5623 32.248C29.4351 32.248 30.3079 32.2353 31.168 32.248C31.7246 32.2606 32.2432 32.2227 32.5342 31.6534C32.8378 31.0842 32.5595 30.6415 32.2306 30.1988C31.0415 28.5543 29.8525 26.9099 28.6635 25.2655C27.7021 23.9373 26.7408 22.5965 25.7794 21.2683C25.1343 20.3829 24.35 20.3702 23.7176 21.2683C21.5419 24.2662 19.3788 27.2768 17.2031 30.2747C17.1525 30.3379 17.1146 30.4012 17.064 30.4771C16.811 30.8565 16.7351 31.2487 16.9501 31.6661C17.1652 32.0709 17.5194 32.2606 17.9874 32.2606C19.1132 32.2606 20.239 32.2606 21.4027 32.2606C21.4027 35.853 21.4027 39.3949 21.4027 42.9999C21.2003 42.9999 21.0106 42.9999 20.8209 42.9999C17.3043 42.9999 13.7878 42.9873 10.2839 42.9999C6.78006 43.0126 4.11104 41.4314 2.0745 38.6865C0.974001 37.1939 0.328883 35.5115 0.0885456 33.6647C0.0758962 33.5761 0.0252987 33.4876 0 33.3991C0 32.8172 0 32.2353 0 31.6534Z"
                        fill="#C6C6C6"
                      />
                    </svg>
                    <input
                      type="file"
                      name="Image"
                      multiple={true}
                      accept=".xlsx"
                      style={{ display: "none" }}
                      ref={fileInputProfile}
                      onChange={(e) => {
                        console.log(e.target.files);
                        fileUpload({
                          target: {
                            name: "profileURL",
                            files: e.target.files,
                          },
                        });
                      }}
                    />
                    {/* <span className="clickText">click to upload</span> */}
                  </div>
                </div>
                <div style={{ width: "30%" }} className="ms-4">
                  {
                    props.editData?.excelFileURL ? 
                    <>
                    <h5>{excelSheetName[excelSheetName?.length - 1]}</h5>
                    <button
                      className="mt-2 p-2 ps-3 pe-3"
                      onClick={() => {
                        downloadExcel(form?.uploadExcel);
                      }}
                    >
                      Dowload Excel
                    </button>
                  </>:
                   <>
                   <h5>
                   {selectedFile?.name}
                   </h5>
                 </>
                  }
                  
                </div>
              </div>
              {Error.uploadExcel && !form?.uploadExcel ? (
                <p className="RequiredField">{Error?.uploadExcel} </p>
              ) : (
                ""
              )}
            </div>
          </div>

          <Button className="artButton" onClick={() => handleSubmit()}>
            Save
          </Button>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    ...state,
  };
};
export default connect(mapStateToProps, {
  createWhatsAppBulkDetails,
  getPresignedUrlForExcel,
  uploadImages,
  updateWhatsAppBulkDetails,
})(AddorEditWhatsAppRecords);
