import React from "react";
import Grid from "@mui/material/Grid";
import Live from "../../assets/videos/Live Interactive Session _ Home Web.mov";
import {
  Player,
  ControlBar,
  PlayToggle,
  CurrentTimeDisplay,
  TimeDivider,
  PlaybackRateMenuButton,
  VolumeMenuButton,
} from "video-react";
// import video from "../../../public/assets/videos/LiveInteractiveSession"
export default function About(props: any) {
  return (
    <div
      className=" text-white"
      style={{
        backgroundColor: "#080404",
        height: "100vh",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={2}></Grid>
        <Grid item xs={8}>
          <h1 className="textStyles pt-2"> About Page</h1>
          {/* <Player>
            <source src={Live} />
            <ControlBar autoHide={false}>
              <PlayToggle />
              <CurrentTimeDisplay />
              <TimeDivider />
              <PlaybackRateMenuButton
                {...props}
                rates={[5, 2, 1, 0.5, 0.1]}
                order={7.1}
              />
              <VolumeMenuButton />
            </ControlBar>
          </Player> */}
        </Grid>
        <Grid item xs={2}></Grid>
      </Grid>
    </div>
  );
}
