import React, { useState } from "react";
import { Formik } from "formik";
import Select from "react-select";
import * as Yup from "yup";
import { connect } from "react-redux";
import { updateUser} from "../../Store/user/actions"
//components
import CloseIcon from "../../components/Icons/CloseIcon";
import notifications from "../../components/Notifications/Notification";

var roleOptions: any = [
  { value: "Free", label: "Free" },
  { value: "Paid", label: "Paid" },
];
let gameDetails: any;
const UpdateRole = (props: any) => {
  const [user, setUser]:any = useState(props?.userDetails);
  console.log(props?.userDetails)
  const handleSubmitChange=(type:any)=>{
   console.log("type",type)
   if(type?.name == "role"){
    setUser({...user,role:type?.value})
   }
  }
  const handleSubmit = (e: any) => {
    e.preventDefault();
    if(user){
        let payload = {
            userId:user?._id,
            role:user?.role?.value
        }
        console.log( "level1",props.updateUser(payload,(res:any)=>{}))
        props.updateUser(payload,(res:any)=>{
            if (res.success) { 
                notifications({
                  title: "Success!",
                  message: res?.message,
                  type: "success",
                });
                props?.getAllUserData();
                props.closeModal();
              } else {
                notifications({
                  title: "Error!",
                  message: res?.message,
                  type: "danger",
                });
              }
        })
        console.log("payload",payload)
    }
   
  };
  console.log(props?.userDetails)
  return (
    <>
      <div className="text-white AddGamePage">
        <div className="">
          <div className="login">
            <div className="form">
              <div className="">
                <div className="d-flex justify-content-end alignItemPopUpLogin1 ">
                  <CloseIcon
                    className="float-end"
                    onClickIcon={props.closeModal}
                  />
                </div>
              </div>

              <form noValidate onSubmit={handleSubmit} className="addGameForm">
                <div className="formLoginCard">
                  <span className="spanHeading">Edit Role</span>
                  <label className="mb-3 text-white text-start w-100">Role</label>
                  <Select
                    {...props}
                    className="Game_selector text-white"
                    classNamePrefix="react-select"
                    placeholder="Select Role..."
                    autoFocus={true}
                    isSearchable
                    name="role"
                    id="role"
                     value={user?.role ? roleOptions?.find(
                      (ele: any) => ele?.value == user?.role
                    ) : user?.role}
                    options={roleOptions}
                    onChange={(e: any) => {
                      console.log("e value", e);
                      handleSubmitChange({
                        name: "role",
                        value: e,
                      });
                    }}

                  />
                  <button
                    className="addGameButton p-2 mt-3"
                    type="submit"
                    onClick={() => props.closeModal()}
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
    console.log("state", state);
    return {
      ...state,
    };
  };
  export default connect(mapStateToProps, { updateUser })(
    UpdateRole
  );

