import React, { Component } from "react";
import Slider from "react-slick";
import { Grid } from "@mui/material";
import "./ValorantTheme.scss";
import brad from "../../assets/images/brad_edited.png";
import moumen from "../../assets/images/Moumen.png";
import Prince from "../../assets/images/Prince _ GG - Coach.png";
import shashank from "../../assets/images/shashank.png";
const Carousel = () => {
  const settings = {
    // dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      // {
      //   breakpoint: 992,
      //   settings: {
      //     slidesToShow: 1,
      //     slidesToScroll: 1,
      //     initialSlide: 2,
      //   },
      // },
      {
        breakpoint: 1224,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <div className="CoachesDesktopDisplay">
        <Slider {...settings}>
          <div className="CoachesCarousal1">
            <div className="container">
              <div className="Bgdiv"></div>
              <img src={Prince} alt="" className="img-fluid Bgimg4" />
              <p className="BgdivContent">
                <span
                  style={{
                    fontWeight: 1000,
                  }}
                >
                  Prince :
                </span>{" "}
                <br />
                Prince has been a professional esports coach with over 14 years
                of experience in FPS games. 15+ active Radiant students and
                1000+ hours of coaching experience. Achieved GB coach of the
                year - 2021.
              </p>
            </div>
          </div>

          <div className="CoachesCarousal1">
            <div className="container">
              <div className="Bgdiv"></div>
              <img src={brad} alt="" className="img-fluid Bgimg3" />
              <p className="BgdivContent">
                <span
                  style={{
                    fontWeight: 1000,
                  }}
                >
                  Brad Gil :
                </span>{" "}
                <br />
                Brad Gil - Brad Gil a.k.a. Major Gil has been a professional
                esports coach for the last 6 years. With over 10,000+ hours of
                FPS games, he has taught over 5000+ gamers from across the
                globe.
              </p>
            </div>
          </div>
          <div className="CoachesCarousal1">
            <div className="container">
              <div className="Bgdiv"></div>
              <img src={shashank} alt="" className="img-fluid Bgimg2" />
              <p className="BgdivContent">
                <span
                  style={{
                    fontWeight: 1000,
                  }}
                >
                  Shashank :
                </span>{" "}
                <br />
                Shashank a.k.a. Shanks has been a professional esports coach and
                has 7 years of experience in FPS games. 100+ students from
                across the globe, he has coached the EU/SEA team - Zenith.
              </p>
            </div>
          </div>
        </Slider>
      </div>
      <div className="CoachesMobileDisplay">
        <Slider {...settings}>
          <div className="container">
            <div>
              <img
                src={Prince}
                alt="Prince"
                width={"100%"}
                className="coachImg"
              />
            </div>
            <div className="coachesPara">
              <span
                style={{
                  fontWeight: 1000,
                  fontSize: "17px",
                }}
              >
                Prince{" "}:
              </span>{" "}
              <br />
              <span>
                Prince has been a professional esports coach with over 14 years
                of experience in FPS games. 15+ active Radiant students and
                1000+ hours of coaching experience. Achieved GB coach of the
                year - 2021.
              </span>
            </div>
          </div>

          <div className="container">
            <div>
              <img src={brad} alt="brad" width={"100%"} className="coachImg" />
            </div>
            <div className="coachesPara">
              <span
                style={{
                  fontWeight: 1000,
                  fontSize: "17px",
                }}
              >
                Brad Gil{" "}:
              </span>{" "}
              <br />
              <span>
                Brad Gil - Brad Gil a.k.a. Major Gil has been a professional
                esports coach for the last 6 years. With over 10,000+ hours of
                FPS games, he has taught over 5000+ gamers from across the
                globe.
              </span>
            </div>
          </div>
          <div className="container">
            <div>
              <img
                src={shashank}
                alt="shashank"
                width={"100%"}
                className="coachImg"
              />
            </div>
            <div className="coachesPara">
              <span
                style={{
                  fontWeight: 1000,
                  fontSize: "17px",
                }}
              >
                Shashank {" "}:
              </span>{" "}
              <br />
              <span>
                Shashank a.k.a. Shanks has been a professional esports coach and
                has 7 years of experience in FPS games. 100+ students from
                across the globe, he has coached the EU/SEA team - Zenith.
              </span>
            </div>
          </div>
        </Slider>
      </div>
    </>
  );
};
export default Carousel;
