import React, { useEffect, useState } from "react";
import { Grid, Card } from "@mui/material";
import { Row, Col } from "reactstrap";
import { connect } from "react-redux";
import { DatePicker } from "rsuite";
import moment from "moment";

//store
import { activeUsers } from "../../Store/auth/registration/actions";
import { getUsers } from "../.././Store/user/actions";
import {
  fetchTournament,
  totalActiveEvents,
} from "../../Store/tournament/actions";

//components
import SideBar from "../../components/verticalMenu/SideBar";
import Loader from "../../components/Loader/Loader";
import notifications from "../../components/Notifications/Notification";

const AdminDashboard = (props: any) => {
  const [startDateValue, setStartDate]: any = useState(null);
  const [startDateNewValue, setStartDateNewValue]: any = useState(null);
  const [endDateValue, setEndDate]: any = useState(null);
  const [endDateNewValue, setEndDateNewValue]: any = useState(null);
  const [resetFlag, setResetFlag] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getAllData();
    getAllUsersData();
    getAllEvent();
    getAllTotalActiveEvents();
  }, []);
  useEffect(() => {
    if (
      startDateNewValue ||
      endDateNewValue ||
      endDateValue ||
      (resetFlag && !endDateValue && !endDateNewValue && !startDateNewValue)
    ) {
      getAllData();
    }
  }, [
    startDateValue,
    resetFlag,
    startDateNewValue,
    endDateNewValue,
    endDateValue,
  ]);
  let getAllUsersData = () => {
    let offset = 0;
    setLoading(true);
    props.getUsers(offset, 10, {}, (res: any) => {
      setLoading(false);
    });
  };
  const getAllData = () => {
    setLoading(true);
    props.activeUsers(
      { startDate: startDateValue, endDate: endDateValue },
      (res:any) => {
        setLoading(false);
      }
    );
  };
  const getAllEvent = () => {
    setLoading(true);
    props.fetchTournament(0, 10, {}, (res: any) => {
      setLoading(false);
    });
  };
  const getAllTotalActiveEvents = () => {
    setLoading(true);
    props.totalActiveEvents("","", "",(res:any) => {
      setLoading(false);
    });
  };
  const handleSubmitChangeStartDate = (type: any) => {
    if (type?.name ==="startDate") {
      setStartDateNewValue(type?.value);
      setStartDate(moment(type?.value).format("yyyy-MM-DD"));
    } else if (type?.name === "endDate") {
      setEndDateNewValue(type?.value);
      setEndDate(moment(type?.value).format("yyyy-MM-DD"));
    }
  };
  const handleReset = () => {
    setStartDate("");
    setStartDateNewValue("");
    setEndDate("");
    setEndDateNewValue("");
    setResetFlag(true);
  };
  let activeUser = props?.activeUsersDetails?.data?.docs[0];
  console.log(
    "activeUser",
    activeUser,
    props?.activeUsersDetails,
    props?.totalActiveEventsDetails
  );
  return (
    <>
     <div className="pageContent" >
  <div className="pageContentChild">
   <div>
   <SideBar></SideBar>
   </div>
   <div className="p-3 w-100">
   <Row className="mb-4">
              <Col xs={4}>
                <DatePicker
                  style={{
                    width: "100%",
                  }}
                  cleanable={false}
                  format="dd MMM yy"
                  placeholder="Start Date..."
                  name="StartDate"
                  id="StartDate"
                  value={startDateNewValue}
                  onChange={(newValue: any) => {
                    console.log("newValue", newValue);
                    handleSubmitChangeStartDate({
                      name: "startDate",
                      value: newValue,
                    });
                  }}
                />
              </Col>
              <Col xs={4}>
                <DatePicker
                  style={{
                    width: "100%",
                  }}
                  cleanable={false}
                  format="dd MMM yy"
                  placeholder="End Date..."
                  name="endDate"
                  id="EndDate"
                  value={endDateNewValue}
                  onChange={(newValue: any) => {
                    console.log("newValue", newValue);
                    handleSubmitChangeStartDate({
                      name: "endDate",
                      value: newValue,
                    });
                  }}
                />
              </Col>
              <Col xs={3}>
                {" "}
                <button
                  onClick={() => handleReset()}
                  style={{
                    color: "black",
                    background: "Green",
                    height: "40px",
                    width: "100%",
                    padding: "10px 20px",
                  }}
                >
                  Reset
                </button>
              </Col>
            </Row>
            {loading ? (
              <>
                <div>
                  <Loader />
                </div>
              </>
            ) : (
              <>
              <h3>Dashboard</h3>
                <div className="">
                  <Row>
                    <Col xs={12} md={6} lg={4} className="m-4">
                      <Card className="activeUsersCard m-2">
                        <div>Total Active Users</div>
                        <div className="NumberDiv">
                          {activeUser?.totalActiveUsers
                            ? activeUser?.totalActiveUsers
                            : 0}
                        </div>
                      </Card>
                    </Col>
                    <Col xs={12} md={6} lg={4} className="m-4">
                      <Card className="activeUsersCard m-2">
                        <div>Total Users</div>
                        <div className="NumberDiv">
                          {props?.userDetails ? props?.userDetails : 0}
                        </div>
                      </Card>
                    </Col>
                    <Col xs={12} md={6} lg={4} className="m-4">
                      <Card className="activeUsersCard m-2">
                        <div>Total Events</div>
                        <div className="NumberDiv">
                          {props?.totalEvents ? props?.totalEvents : 0}
                        </div>
                      </Card>
                    </Col>
                    <Col xs={12} md={6} lg={4} className="m-4">
                      <Card className="activeUsersCard m-2">
                        <div>Total Active Events</div>
                        <div className="NumberDiv">
                          {props?.totalActiveEventsDetails
                            ? props?.totalActiveEventsDetails
                            : 0}
                        </div>
                      </Card>
                    </Col>
                  </Row>
                </div>
              </>
            )}
     </div>
     </div>
     </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  console.log("state", state);
  return {
    ...state,
    activeUsersDetails: state?.authLoginReducer?.activeUser,
    userDetails: state?.userReducer?.usersDetails?.data?.length,
    totalEvents: state?.tournamentReducer?.tournamentDetail?.data?.length,
    totalActiveEventsDetails:
      state?.tournamentReducer?.activeEvents?.data?.length,
  };
};
export default connect(mapStateToProps, {
  activeUsers,
  getUsers,
  fetchTournament,
  totalActiveEvents,
})(AdminDashboard);
