import React ,{useState} from "react";
import { Grid } from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  useParams,
  useLocation,
  useSearchParams,
  useNavigate,
} from "react-router-dom";
import { Store } from "react-notifications-component";
// import from store
import { sendingPasswordResetToken } from "../../../Store/auth/registration/actions";
// Creating schema
const schema = Yup.object().shape({
  password: Yup.string()
    .required("Password is a required field")
    .min(8, "Password is tooweak"),
  confirmPassword: Yup.string()
    .required("confirm password is a required field")
    .min(8, " Confirm Password is tooweak"),
});
const ResetPassword = (props: any) => {
    const [resData, setResData] = useState({success:false,message:""})
  const navigate = useNavigate();
  const params = useLocation();
  const search = useLocation().search;
  const userId = new URLSearchParams(search).get("userId");
  const passwordResetToken = new URLSearchParams(search).get(
    "passwordResetToken"
  );
  return (
    <div
      className=" text-white"
      style={{
        backgroundColor: "#080404",
      }}
    >
          <div className="">
        
            <Formik
              validationSchema={schema}
              initialValues={{
                password: "",
                confirmPassword: "",
              }}
              onSubmit={(values) => {
                props.sendingPasswordResetToken(
                  {
                    passwordResetToken: passwordResetToken,
                    userId: userId,
                    password: values?.password,
                    confirmPassword: values?.confirmPassword,
                  },
                  (res: any) => {
                      setResData({
                          success:res?.success,
                          message:res?.message
                      })
                    if (res.success) {
                      Store.addNotification({
                        title: "Success!",
                        message: res?.message,
                        type: "success",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                          duration: 5000,
                          onScreen: true,
                        },
                      });
                      navigate("/")
                      window.scrollTo(0, 0)
                    } else {
                      Store.addNotification({
                        title: "Error!",
                        message: res?.message,
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                          duration: 5000,
                          onScreen: true,
                        },
                      });
                    }
                  }
                );
              }}
            >
              {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
              }) => (
                <div className="login">
                  <div className="form">
                  <svg
                      className="cursor-pointer float-end "
                      onClick={() => props.closeResetModal()}
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13 1L1 13"
                        stroke="#413838"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M1 1L13 13"
                        stroke="#413838"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <form noValidate onSubmit={handleSubmit}>
                      <div className="formLoginCard">
                          <p className="error fontRoboto124">
                              {resData?.success === false ? resData?.message :""}
                          </p>
                        <input
                          type="password"
                          name="password"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.password}
                          placeholder="Enter password"
                          className="form-control"
                        />
                        <p className="error">
                          {errors.password &&
                            touched.password &&
                            errors.password}
                        </p>
                        <input
                          type="password"
                          name="confirmPassword"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.confirmPassword}
                          placeholder="Confirm password"
                          className="form-control"
                        />
                        <p className="error">
                          {errors.confirmPassword &&
                            touched.confirmPassword &&
                            errors.confirmPassword}
                        </p>
                        <button className="SubmitButton" type="submit">
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              )}
            </Formik>
          </div>
    </div>
  );
};
const mapStateToProps = (state: any) => {
  return {
    ...state,
  };
};
export default connect(mapStateToProps, {sendingPasswordResetToken})(ResetPassword);
