export const types = {
    GET_USERS:"GET_USERS",
    GET_USERS_SUCCESS:"GET_USERS_SUCCESS",
    USER_ACTIVE_DE_ACTIVE:" USER_ACTIVE_DE_ACTIVE",
    USER_ACTIVE_DE_ACTIVE_SUCCESS:"USER_ACTIVE_DE_ACTIVE_SUCCESS",
    UPDATE_USER:"UPDATE_USER",
    UPDATE_USER_SUCCESS:"UPDATE_USER_SUCCESS",
    USER_STATUS:'USER_STATUS',
    USER_STATUS_SUCCESS:"USER_STATUS_SUCCESS",
    SENDING_EMAIL:"SENDING_EMAIL",
    SENDING_EMAIL_SUCCESS:"SENDING_EMAIL_SUCCESS",
    STORING_MAIL_WITH_SAVE_TO_GROUP:"STORING_MAIL_WITH_SAVE_TO_GROUP",
    STORING_MAIL_WITH_SAVE_TO_GROUP_SUCCESS:"STORING_MAIL_WITH_SAVE_TO_GROUP_SUCCESS",
    GETTING_MAIL_WITH_SAVE_TO_GROUP:"GETTING_MAIL_WITH_SAVE_TO_GROUP",
    GETTING_MAIL_WITH_SAVE_TO_GROUP_SUCCESS:"GETTING_MAIL_WITH_SAVE_TO_GROUP_SUCCESS",
    SENDING_WHATS_APP_NOTIFICATIONS:"SENDING_WHATS_APP_NOTIFICATIONS",
    SENDING_WHATS_APP_NOTIFICATIONS_SUCCESS:"SENDING_WHATS_APP_NOTIFICATIONS_SUCCESS",
    UPDATE_USER_PROFILE:"UPDATE_USER_PROFILE",
    UPDATE_USER_PROFILE_SUCCESS:"UPDATE_USER_PROFILE_SUCCESS",
    GET_USER_BY_EMAILID:"GET_USER_BY_EMAILID",
    GET_USER_BY_EMAILID_SUCCESS:"GET_USER_BY_EMAILID_SUCCESS",
    GET_USER_BY_USERID:"GET_USER_BY_USERID",
    GET_USER_BY_USERID_SUCCESS:"GET_USER_BY_USERID_SUCCESS"
}