import { types } from "./actionTypes";

// creating action
export const createOrder = (payload: any, callback: any) => {
  return {
    type: types.CREATE_ORDER,
    payload,
    callback,
  };
};
export const verifyOrder = (payload: any, callback: any) => {
    return {
      type: types.VERIFY_ORDER,
      payload,
      callback,
    };
  };