import { types } from "./actionTypes";
const initialState = {
  registerUserDetails: null,
  loginUserDetails: null,
  userActivate: null,
  sendingMailDetails: null,
  passwordResetingData: null,
  updatePassword: null,
  checkSocialLoginDetail: null,
  getDiscordDetails:null,
  checkSocialRegistrationDetail:null,
  activeUser:null
};
const authLoginReducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    case types.REGISTER_USER_SUCCESS:
      state = {
        ...state,
        registerUserDetails: action.payload,
      };
      return state;
      break;
    case types.CHECK_USER_ACTIVATE_SUCCESS:
      state = {
        ...state,
        userActivate: action.payload,
      };
      return state;
      break;
    case types.CHECK_LOGIN_USER_SUCCESS:
      state = {
        ...state,
        loginUserDetails: action.payload,
      };
      return state;
      break;
    case types.SENDING_FORGOTPASSWORD_LINK_SUCCESS:
      state = {
        ...state,
        sendingMailDetails: action.payload,
      };
      return state;
      break;
    case types.PASSWORD_RESET_TOKEN_SUCCESS:
      state = {
        ...state,
        passwoReserdtingData: action.payload,
      };
      return state;
      break;
    case types.UPDATE_PASSWORD_SUCCESS:
      state = {
        ...state,
        updatePassword: action.payload,
      };
      return state;
      break;
    case types.CHECK_SOCIAL_REGISTERING_SUCCESS:
      state = {
        ...state,
        checkSocialRegistrationDetail: action.payload,
      };
      return state;
      case types.CHECK_SOCIAL_LOGIN_SUCCESS:
      state = {
        ...state,
        checkSocialLoginDetail: action.payload,
      };
      return state;
      break;
      case types.GET_DISCORD_DETAILS_SUCCESS:
        state = {
          ...state,
          getDiscordDetails: action.payload,
        };
        return state;
        break;
        case types.ACTIVE_USERS_SUCCESS:
          state = {
            ...state,
            activeUser: action.payload,
          };
          return state;
          break;
    default:
      return state;
  }
};
export default authLoginReducer;
