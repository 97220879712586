import { types } from "./actionTypes";
const initialState = {
  getScoresByLeader: null,
  getScoresByMatchLeader: null,
  getScoresByTournamntLeader: null,
  getTournamentWisePlayersAndTeams: null,
  getOverAllLeaderBoard: null,
};
const leaderBoardReducer = (state: any = initialState, action: any) => {
  // console.log("action",action.payload)
  switch (action.type) {
    case types.GET_SCORES_BY_LEADER_SUCCESS:
      state = {
        ...state,
        getScoresByLeade: action.payload,
      };
      return state;
    case types.GET_SCORES_BY_MATCH_LEADER_SUCCESS:
      state = {
        ...state,
        getScoresByMatchLeader: action.payload,
      };
      return state;
    case types.GET_SCORES_BY_TOURNAMENT_LEADER_SUCCESS:
      state = {
        ...state,
        getScoresByTournamntLeader: action.payload,
      };
      return state;
    case types.GET_TOURNAMNET_WISE_PLAYER_AND_TEAM_LEADERBOARD_SUCCESS:
      state = {
        ...state,
        getTournamentWisePlayersAndTeams: action.payload,
      };
      return state;
      case types.GET_OVERALL_LEADERBOARD_SCORES_SUCCESS:
        state = {
          ...state,
          getOverAllLeaderBoard: action.payload,
        };
        return state;
  
    default:
      return state;
  }
};

export default leaderBoardReducer;
