import React from 'react'

import { SiDiscord } from "react-icons/si";
import Grid from "@mui/material/Grid";
import { Row, Col } from "reactstrap";
import Card from "@mui/material/Card";
const FoterDiv = () => {
  return (
    <>
    <div className='footerDivParent'>
       <div className=" footerDiv">
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} className=" mt-4 mb-4">
                <Card className="CardStylesFooter">
                  <Row>
                    {/* <Col xs={1}></Col> */}
                    <Col xs={12}>
                      <div className="displayDesktopFooter">
                        <Row className="mt-4">
                          <Col
                            xs={4}
                            className="d-flex justify-content-center text-white "
                          >
                            <a
                              href="https://pro-coaching.gaminggeeks.gg/terms-and-conditions"
                              target="_blank"
                              className="fontFooterLink fontFooterLinkColor"
                              rel="noreferrer"
                            >
                              Terms & Conditions
                            </a>
                          </Col>
                          <Col
                            xs={4}
                            className="d-flex justify-content-center text-white "
                          >
                            <a
                              href="https://pro-coaching.gaminggeeks.gg/privacy-policy"
                              target="_blank"
                              className="fontFooterLink fontFooterLinkColor"
                              rel="noreferrer"
                            >
                              Privacy Policy
                            </a>
                          </Col>
                          <Col
                            xs={4}
                            className="d-flex justify-content-center text-white "
                          >
                            <a
                              href="https://pro-coaching.gaminggeeks.gg/refund-policy"
                              target="_blank"
                              className="fontFooterLink fontFooterLinkColor"
                              rel="noreferrer"
                            >
                              Refund Policy
                            </a>
                          </Col>
                        </Row>
                        <Row className="mt-3">
                          <Col xs={2}></Col>
                          <Col
                            xs={3}
                            className="d-flex justify-content-center ps-4 text-white  "
                          >
                            <span className="fontFooterLink"> FAQs</span>
                          </Col>
                          <Col xs={1}></Col>
                          <Col
                            xs={4}
                            className="d-flex justify-content-center text-white fontFollow"
                          >
                            <span className="fontFooterLink"> How to Join</span>
                          </Col>
                          <Col xs={2}></Col>
                        </Row>
                      </div>
                      <div className="displayMobileFooter">
                        <span className="d-flex justify-content-center text-white fontFooterLink">
                          Terms & conditions
                        </span>
                        <span className="d-flex justify-content-center text-white fontFooterLink">
                          Privacy Policy
                        </span>
                        <span className="d-flex justify-content-center text-white fontFooterLink">
                          Refund Policy
                        </span>
                        <span className="d-flex justify-content-center text-white fontFooterLink">
                          FAQs
                        </span>
                        <span className="d-flex justify-content-center text-white fontFooterLink">
                          How to Join
                        </span>
                      </div>
                      <div className="d-flex justify-content-center">
                        <hr className="w-50 horizentalLine"></hr>
                      </div>
                      <div className="d-flex justify-content-center">
                        <span className="textStyles mt-3 fontFollow">
                          Follow Us
                        </span>
                      </div>
                      <Row className="mt-3 fafaIconsRow">
                        <Col xs={0} md={4}></Col>
                        <Col xs={12} md={4} className="d-flex justify-content-center">
                          <Row>
                            <Col xs={3}>
                              <a
                                href="https://www.facebook.com/gg.gaminggeeks"
                                target="_blank"
                                rel="noreferrer"
                              >
                                <i className="fa faIconsStyles faIconsStyles-Facebook cursor-pointer">
                                  &#xf09a;
                                </i>
                              </a>
                            </Col>
                            <Col xs={3}>
                              <a
                                href="https://www.instagram.com/gaminggeeks.gg/"
                                target="_blank"
                                rel="noreferrer"
                              >
                                <i className="fa fa-instagram faIconsStyles faIconsStyles-instagram"></i>
                              </a>
                            </Col>
                            <Col xs={3}>
                              <a
                                href="https://www.youtube.com/channel/UCdrTr3UrzW11glIdiocaXVw"
                                target="_blank"
                                rel="noreferrer"
                              >
                                <i className="fa fa-youtube-play faIconsStyles faIconsStyles-youtube"></i>
                              </a>
                            </Col>
                            <Col xs={3}>
                              <a
                                href="https://discord.com/invite/pDUF4Kt5zW"
                                target="_blank"
                                rel="noreferrer"
                              >
                                <SiDiscord className="faIconsStyles faIconsStyles-discord" />
                              </a>
                            </Col>
                          </Row>
                        </Col>
                        <Col xs={0} md={4}></Col>
                      </Row>
                      <div className="mt-4 d-flex justify-content-center">
                        <span className="text-white fontEmail">
                          Email - contact@gaminggeeks.gg
                        </span>
                      </div>
                      <div className="mt-3 d-flex justify-content-center">
                        <span className="text-white fontEmail">
                          Address{" "}- {" "}143, 10th Cross, 1st Stage, Indiranagar, Bangalore - 560038
                        </span>
                      </div>
                    </Col>
                  </Row>
                </Card>
              </Grid>
            </Grid>
          </div>
          </div>
    </>
  )
}

export default FoterDiv