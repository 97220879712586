import React from 'react';
import { Grid } from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { useParams, useLocation, useSearchParams,useNavigate } from "react-router-dom";
import { Store } from 'react-notifications-component';

// importing from store
import {sendingForgotPasswordLink} from "../../../Store/auth/registration/actions"

// validations
const schema = Yup.object().shape({
    email: Yup.string()
      .required("Email is a required field")
      .email("Invalid email format")
  });
 const ForgotPassword = (props:any) =>{
    const navigate = useNavigate()
    const params = useLocation();
    const search = useLocation().search;
    const userId = new URLSearchParams(search).get("userId");
    const userActivationToken = new URLSearchParams(search).get(
      "userActivationToken"
    );
  return (
    <>
     <div
        className=" text-white"
        style={{
          backgroundColor: "#080404",
          height: "100vh",
        }}
      >
        <Grid container>
          <Grid item xs={1} md={2} lg={4}></Grid>
          <Grid item xs={10} md={8} lg={4}>
            <div className="">
              <Formik
                validationSchema={schema}
                initialValues={{ email: "" }}
                onSubmit={(values) => {
                  props.sendingForgotPasswordLink(values, (res: any) => {
                    if (res.success) {
                      Store.addNotification({
                        title: "Success!",
                        message: res?.message,
                        type: "success",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                          duration: 5000,
                          onScreen: true,
                        },
                      });
                    } else {
                      Store.addNotification({
                        title: "Error!",
                        message: res?.message,
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                          duration: 5000,
                          onScreen: true,
                        },
                      });
                    }
                  });
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                }) => (
                  <div className="login">
                    <div className="form">
                      <form noValidate onSubmit={handleSubmit}>
                        <div className="formLoginCard">
                          <input
                            type="email"
                            name="email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                            placeholder="Enter email"
                            className="form-control inp_text"
                            id="email"
                          />
                          <p className="error">
                            {errors.email && touched.email && errors.email}
                          </p>
                          <button className="SubmitButton" type="submit">Submit</button>
                        </div>

                      </form>
                    </div>
                  </div>
                )}
              </Formik>
            </div>
          </Grid>
          <Grid item xs={1} md={2} lg={4}></Grid>
        </Grid>
      </div>
    </>
  )
}
const mapStateToProps = (state: any) => {
    return {
      ...state,
    };
  };
export default connect(mapStateToProps,{sendingForgotPasswordLink})(ForgotPassword);