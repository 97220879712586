import {types } from "./actionTypes";

// creating action
export const createWhatsAppBulkDetails = ( payload:any,callback:any) =>{
    return {
        type:types.CREATE_WHATSAPP_BULK_DETAILS,
        payload,
        callback
    }
}
export const getWhatsAppBulkDetails = ( offset:any,limit:any,filters:any,callback:any) =>{
    return {
        type:types.GET_WHATSAPP_BULK_DETAILS,
        offset,
        limit,
        filters,
        callback
    }
}
export const updateWhatsAppBulkDetails = ( payload:any,callback:any) =>{
    return {
        type:types.UPDATE_WHATSAPP_BULK_DETAILS,
        payload,
        callback
    }
}
export const deleteWhatsAppBulkDetails = ( payload:any,callback:any) =>{
    return {
        type:types.DELETE_WHATSAPP_BULK_DETAILS,
        payload,
        callback
    }
}
export const sendWhatsAppBulkDetails = ( payload:any,callback:any) =>{
    return {
        type:types.SEND_WHATSAPP_BULK_DETAILS,
        payload,
        callback
    }
}
export const getPresignedUrlForExcel = (callback:any) =>{
    return {
        type:types.PRESIGNED_URL_UPLOAD_EXCEL_SHEET,
        callback
    }
}