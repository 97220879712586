import {types } from "./actionTypes";

// creating action
export const createWhatsAppMediaDetails = ( payload:any,callback:any) =>{
    return {
        type:types.CREATE_WHATSAPP_MEDIA_DETAILS,
        payload,
        callback
    }
}
export const getWhatsAppMediaDetails = ( offset:any,limit:any,filters:any,callback:any) =>{
    return {
        type:types.GET_WHATSAPP_MEDIA_DETAILS,
        offset,
        limit,
        filters,
        callback
    }
}
export const getPresignedUrlForMedia = (payload:any,callback:any) =>{
    return {
        type:types.GET_WHATSAPP_MEDIA_PRESIGNED_URL,
        payload,
        callback
    }
}