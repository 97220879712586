import React from "react";
import CloseIcon from "../../components/Icons/CloseIcon";
const Message = (props: any) => {
  return (
    <>
      <div className="text-white AddGamePage">
        <div className="">
          <div className="login">
            <div className="form">
              <div className="">
                <div className="d-flex justify-content-end alignItemPopUpLogin1 ">
                  <CloseIcon
                    className="float-end"
                    onClickIcon={props.closeModal}
                  />
                </div>
              </div>

              <div className="MessageCard">
                You had registered with our application. Please check the mail
                that we had sent upon registration for account activation.
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Message;
