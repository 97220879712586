import { types } from "./actionTypes";
const initialState = {
  addGame: null,
  gameDetail: null,
  updateDetails: null,
  deleteGames: null,
};
const gameReducer = (state: any = initialState, action: any) => {
  // console.log("action",action.payload)
  switch (action.type) {
    case types.ADD_GAME_SUCCESS:
      state = {
        ...state,
        addGame: action.payload,
      };
      return state;
      break;
    case types.FETCH_GAMES_SUCCESS:
      state = {
        ...state,
        gameDetail: action.payload,
      };
      return state;
      break;
    case types.EDIT_GAME_SUCCESS:
      state = {
        ...state,
        updateDetails: action.payload,
      };
      return state;
      break;
    case types.DELETE_GAME_SUCCESS:
      state = {
        ...state,
        deleteGames: action.payload,
      };
      return state;
      break;
    default:
      return state;
  }
};

export default gameReducer;
