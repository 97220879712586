import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import { types } from "./actionTypes";
import axios, { AxiosResponse } from "axios";

//medthods
import {
  postApi,
  putApi,
  getApi,
  deleteApi,
} from "../.././helpers/auth_helpers";

// workers
function* workAddGame({ payload, callback }: any) {
  try {
    const url: string = process.env.REACT_APP_SERVERURL + "/game/create";
    const response: AxiosResponse = yield call(postApi, url, payload);
    callback(response);
    // console.log("users",response)
    yield put({
      type: types.ADD_GAME_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workFetchGames({ offset, limit, filters, callback }: any) {
  let name = filters?.name ? filters?.name : "";
  try {
    const url: string =
      process.env.REACT_APP_SERVERURL +
      "/game/games?offset=" +
      offset +
      "&limit=" +
      limit +
      "&name=" +
      name;
    const response: AxiosResponse = yield call(getApi, url);
    callback(response);
    // console.log("users",response)
    yield put({
      type: types.FETCH_GAMES_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workUpdateGame({ payload, callback }: any) {
  try {
    const url: string = process.env.REACT_APP_SERVERURL + "/game/update";
    const response: AxiosResponse = yield call(putApi, url, payload);
    callback(response);
    // console.log("users",response)
    yield put({
      type: types.EDIT_GAME_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workDeleteGame({ payload, callback }: any) {
  try {
    const url: string = process.env.REACT_APP_SERVERURL + "/game/delete";
    const response: AxiosResponse = yield call(deleteApi, url, payload);
    callback(response);
    // console.log("users",response)
    yield put({
      type: types.DELETE_GAME_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
// watchers
export function* watchAddGame() {
  yield takeEvery(types.ADD_GAME, workAddGame);
}
export function* watchFetchGame() {
  yield takeEvery(types.FETCH_GAMES, workFetchGames);
}
export function* watchUpdateGame() {
  yield takeEvery(types.EDIT_GAME, workUpdateGame);
}
export function* watchDeleteGame() {
  yield takeEvery(types.DELETE_GAME, workDeleteGame);
}

function* gameSaga() {
  yield all([
    fork(watchAddGame),
    fork(watchFetchGame),
    fork(watchUpdateGame),
    fork(watchDeleteGame),
  ]);
}
export default gameSaga;
