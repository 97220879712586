import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Col,
} from "reactstrap";
import Logo from "../../assets/images/Full Logo.png";
import { useNavigate } from "react-router-dom";
import { Modal, ModalBody } from "reactstrap";
import { FiSettings } from "react-icons/fi";

// components
import ResetPassword from "../../pages/Auth/Login/ResetPassword";
import UserLogin from "../../pages/Auth/Login/UserLogin";
import UserRegistration from "../../pages/Auth/Registration/UserRegistration";

const NavBar = (props: any) => {
  const [dropDownisOpen, setDropDownisOpen] = useState(false);
  const [dropDownisOpen1, setDropDownisOpen1] = useState(false);

  const [openLogin, setOpenLogin] = useState(false);
  const [openRegistration, setOpenRegistration] = useState(false);
  const [resetPassword, setResetPassword] = useState(false);
  let openResetModelPopup = () => {
    setResetPassword(true);
  };
  let closeResetModal = () => {
    setResetPassword(false);
  };
  let openLoginPopUp = () => {
    setOpenLogin(true);
    setOpenRegistration(false);
  };
  let closeModalLogin = () => {
    setOpenLogin(false);
  };
  let openRegistrationPopUp = () => {
    setOpenRegistration(true);
    setOpenLogin(false);
  };
  let closeModal = () => {
    setOpenRegistration(false);
  };
  const navigate = useNavigate();

  let auth: any =
    localStorage.getItem("authUser") || sessionStorage.getItem("authUser");
  let authUser: any = JSON.parse(auth) || "";
  // console.log("authUser", authUser);
  let toggle = () => {
    setDropDownisOpen(!dropDownisOpen);
  };
  let toggle1 = () => {
    setDropDownisOpen1(!dropDownisOpen1);
  };
  let logOut = () => {
    sessionStorage.clear();
    localStorage.clear();
    navigate("/");
    window.scrollTo(0, 0)
  };
  let rememberMe: any = localStorage.getItem("rememberMe");
  let rememberMeAuth: any = JSON.parse(rememberMe);
  useEffect(() => {
    // if (rememberMeAuth === false  ) {
    //   console.log("Test1");
    //   window.onbeforeunload = function (e:any) {
    //     localStorage.clear();
    //   };
    //   if(rememberMeSocialAuth === false){
    //     console.log("Test1");
    //   window.onbeforeunload = function (e:any) {
    //     localStorage.clear();
    //   };
    //   }
    //   window.addEventListener('beforeunload', function (e) {
    //     e.preventDefault();
    //     e.returnValue = '';
    //             localStorage.clear();
    // });
    // }
    // console.log("Test1");
  });

  return (
    <>
      <Modal
        isOpen={resetPassword}
        toggle={closeResetModal}
        size="lg"
        className="RegistrationModal"
      >
        <ModalBody>
          <ResetPassword {...props} closeResetModal={closeResetModal} />
        </ModalBody>
      </Modal>
      <Modal
        isOpen={openRegistration}
        toggle={closeModal}
        size="lg"
        className="RegistrationModal"
      >
        <ModalBody>
          <UserRegistration
            {...props}
            closeModal={closeModal}
            openLoginPopUp={openLoginPopUp}
          />
        </ModalBody>
      </Modal>
      <Modal
        isOpen={openLogin}
        toggle={closeModalLogin}
        size="lg"
        className="RegistrationModal"
      >
        <ModalBody>
          <UserLogin
            {...props}
            closeModalLogin={closeModalLogin}
            openRegistrationPopUp={openRegistrationPopUp}
          />
        </ModalBody>
      </Modal>
      <div className="NavibarHeader">
        <nav className="navbar navbar-expand-sm navbar-dark">
          <div className="container">
            <Link to="/">
              <img src={Logo} className="logoImage" alt="Logo" />
            </Link>
            {authUser?.email ? (
              <>
                <div className="displayDesktop1">
                  <div className="d-flex justify-content-end">
                    {authUser?.role !== "Admin" ? (
                      <>
                        {" "}
                        {/* <div className="p-2 fontHeaderNav">
                          <Link to="/my-dasboard">My Dashboard</Link>
                        </div> */}
                         <div className="p-2 fontHeaderNav">
                          <Link to="/category">Courses</Link>
                        </div>
                        <div className="p-2 fontHeaderNav">
                          <Link to="/user/tournament">Tournament</Link>
                        </div>
                       
                        <div className="p-2 fontHeaderNav">
                          <Link to="/my-dasboard">Dashboard</Link>
                        </div>
                        {/* <div className="p-2 fontHeaderNav">
                          <Link to="/myPerfomance">My Performance</Link>
                        </div> */}
                        <div className="p-2 fontHeaderNav">
                          <Link to="/leaderBoard">Leaderboard</Link>
                        </div>
                      </>
                    ) : (
                      ""
                    )}

                    <div className="p-2">
                      <Dropdown isOpen={dropDownisOpen} toggle={() => toggle()}>
                        <DropdownToggle caret>
                          {" "}
                          <FiSettings />
                        </DropdownToggle>
                        <DropdownMenu>
                          <Link to="/updatePassword">
                            <DropdownItem>Settings</DropdownItem>
                          </Link>

                          <DropdownItem divider />

                          <Link to="/myProfile">
                            <DropdownItem>My profile</DropdownItem>
                          </Link>

                          <DropdownItem divider />
                          {authUser?.role !== "Admin" ? (
                            <>
                              <DropdownItem>Contact Us</DropdownItem>
                              {/* <DropdownItem divider /> */}

                              {/* <Link to="/myTournaments">
                                <DropdownItem>My tournament</DropdownItem>
                              </Link>

                              <DropdownItem divider />
                              <Link to="/myTeams">
                                <DropdownItem>My Team</DropdownItem>
                              </Link>

                              <DropdownItem divider />

                              <Link to={"/invited-players"}>
                                <DropdownItem>
                                  <span>Invitation</span>
                                </DropdownItem>
                              </Link> */}
                            </>
                          ) : (
                            ""
                          )}
                          <DropdownItem divider />
                          <DropdownItem className="" onClick={() => logOut()}>
                            Logout
                          </DropdownItem>
                          <DropdownItem divider />
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                  </div>
                </div>
                <div className="displayMobile1">
                  <div className="d-flex justify-content-end">
                    <Dropdown
                      id="1"
                      isOpen={dropDownisOpen1}
                      toggle={() => toggle1()}
                    >
                      <DropdownToggle>
                        {" "}
                        <svg
                          width="15"
                          height="10"
                          viewBox="0 0 21 15"
                          fill="#fff"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1.71582 7.09619H19.7158"
                            stroke="#f4eded"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M1.71582 1.09619H19.7158"
                            stroke="#f4eded"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M1.71582 13.0962H19.7158"
                            stroke="#f4eded"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </DropdownToggle>
                      <DropdownMenu>
                        <Link to="/category">
                          <DropdownItem>Courses</DropdownItem>
                        </Link>
                        <DropdownItem divider />
                        <Link to="/user/tournament">
                          <DropdownItem>Tournament</DropdownItem>
                        </Link>
                        <DropdownItem divider />
                        <Link to="/my-dasboard">
                          <DropdownItem>Dashboard</DropdownItem>
                        </Link>

                        <DropdownItem divider />
                        {/* <Link to="/myPerfomance">
                          <DropdownItem>My Performance</DropdownItem>
                        </Link>

                        <DropdownItem divider /> */}
                        <Link to="/leaderBoard">
                          <DropdownItem>Leaderboard</DropdownItem>
                        </Link>

                        <DropdownItem divider />
                        <Link to="/updatePassword">
                          <DropdownItem>Settings</DropdownItem>
                        </Link>

                        <DropdownItem divider />

                        <Link to="/myProfile">
                          <DropdownItem>My profile</DropdownItem>
                        </Link>

                        <DropdownItem divider />
                        {authUser?.role !== "Admin" ? (
                          <>
                            <DropdownItem>Contact Us</DropdownItem>

                          </>
                        ) : (
                          ""
                        )}
                        <DropdownItem divider />
                        <DropdownItem className="" onClick={() => logOut()}>
                          Logout
                        </DropdownItem>
                        <DropdownItem divider />
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="displayDesktop">
                  <Link to="/category">
                    <span className="navbar-text  fontBwNista20 p-2">
                      Courses
                    </span>
                  </Link>

                  <Link to="/user/tournament">
                    <span className="navbar-text  fontBwNista20 p-2">
                      Tournament
                    </span>
                  </Link>
                  <Link to="/about">
                    <span className="navbar-text  fontBwNista20 p-2">
                      About
                    </span>
                  </Link>
                  <Link to="/" onClick={() => {
                  openLoginPopUp()}
                  }>
                    <span className="navbar-text  fontBwNista20 p-2">
                      Login
                    </span>
                  </Link>
                </div>
                <div className="displayMobile">
                  {/* <span className="navbar-text text-white fontBwNista20 p-2"> */}
                  <Dropdown isOpen={dropDownisOpen} toggle={() => toggle()}>
                    <DropdownToggle>
                      {" "}
                      <svg
                        width="15"
                        height="10"
                        viewBox="0 0 21 15"
                        fill="#fff"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1.71582 7.09619H19.7158"
                          stroke="#f4eded"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M1.71582 1.09619H19.7158"
                          stroke="#f4eded"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M1.71582 13.0962H19.7158"
                          stroke="#f4eded"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </DropdownToggle>
                    <DropdownMenu>
                      <Link to="/category">
                        <DropdownItem> Courses</DropdownItem>
                      </Link>
                      <DropdownItem divider />
                      <Link to="/user/tournament">
                        <DropdownItem> Tournament</DropdownItem>
                      </Link>
                      <DropdownItem divider />
                      <Link to="/about">
                        <DropdownItem> About</DropdownItem>
                      </Link>
                      <DropdownItem divider />
                      <Link to="/" onClick={() => openLoginPopUp()}>
                        <DropdownItem>Login</DropdownItem>
                      </Link>
                      <DropdownItem divider />
                    </DropdownMenu>
                  </Dropdown>
                  {/* </span> */}
                  <div></div>
                </div>
              </>
            )}
          </div>
        </nav>
      </div>
    </>
  );
};

export default NavBar;
