import React, { useState, useEffect } from "react";
import { Card, Row, Col } from "reactstrap";
import { connect } from "react-redux";
// store
import { fetchUserTournament } from "../../Store/userTournament/actions";
import { fetchTeams } from "../../Store/tournament/actions";
import { getProfileScoreFromeMatches } from "../../Store/match/actions";
import { getUsersByUserId } from "../../Store/user/actions";

// components
import Loader from "../../components/Loader/Loader";
import FoterDiv from "../../components/common/FoterDiv";
import moment from "moment";

// images
import Logo from "../../assets/images/Favicon Logo.png";
import valorant from "../../assets/images/valorant-2020-5k-dq.jpg";
// global variables
// var offset = 0;
// var limit = 10;
// var totCnt = 0;
// var totPages = 0;
// var toVal = 0;
// var fromVal = 0;
var userData: any = [];

const MyDashboard = (props: any) => {
  const [name, setName] = useState("");
  const handleClick = (type: any) => {
    if (type?.name === "tournamentRegistration") {
      setName(type?.name);
    } else if (type?.name === "teams") {
      setName(type?.name);
    } else if (type?.name === "performance") {
      setName(type?.name);
    }
  };
  const [loading, setLoading] = useState(false);
  let auth: any =
    localStorage.getItem("authUser") || sessionStorage.getItem("authUser");
  let authUser: any = JSON.parse(auth) || "";
  console.log(authUser, "authUser");
  useEffect(() => {
    getAllUserRegisterTournaments();
    getAllData();
    getPerfomanceScores();
    getUserDetails();
  }, []);
  const getUserDetails = ()=>{
    props.getUsersByUserId(
      authUser?._id,
      ()=>{}
    )
  }
  const getPerfomanceScores = () => {
    props.getProfileScoreFromeMatches(authUser?._id,
      // "63723f7c0673114ebcdea6e2",
     () => {});
  };
  const getAllData = () => {
    console.log("level1");
    let offset = 0;
    setLoading(true);
    props.fetchTeams(
      offset,
      10000,
      { userId: authUser?._id, isInTeam: true },
      (res: any) => {
        setLoading(false);
      }
    );
  };
  const getAllUserRegisterTournaments = () => {
    setLoading(true);
    let offset = 0;
    props.fetchUserTournament(
      offset,
      1000,
      { userId: authUser?._id },
      (res: any) => {
        setLoading(false);
        setLoading(false);
      }
    );
  };
  if (props?.teamDetails !== undefined) {
    let teamDetails = props?.teamDetails?.data;

    userData = [];
    props.teamDetails?.data?.docs?.map((ele: any, i: any) => {
      userData.push({
        id: ele?._id,
        teamLogo: ele?.teamLogo,
        teamName: ele?.teamName,
        tournamentName: ele?.tournamentsDeatils?.label,
        users: ele?.users,
      });
    });
  }
  let getUserTournamentsDetails: any;
  let registerTournament: any = [];
  if (props?.getUserTournaments !== undefined) {
    getUserTournamentsDetails = props?.getUserTournaments?.data?.docs;
    getUserTournamentsDetails?.map((tournament: any, i: any) => {
      tournament?.users?.map((user: any, i: any) => {
        if (
          user?.user === authUser?._id &&
          user?.acceptanceStatus === "accepted"
        ) {
          registerTournament.push({
            ...tournament,
          });
        }
      });
    });
  }
  console.log("registerTournament", registerTournament);
  const TotalMatchScores = props?.getProfileScore?.totalMatchScores[0];
  const allMatchScoresSorted = props?.getProfileScore?.allMatchScoresSorted[0];
  const winnings = props?.getProfileScore?.winnings;
  const lossings = props?.getProfileScore?.lossings;
  let userDetails:any;
  if(props.userDetailsById !== undefined){
    userDetails= props.userDetailsById;
  }
  return (
    <>
      <div className="pageContent">
        <div>
          <img
            src={valorant}
            alt="Valorant"
            className="mt-5 ProfileHeaderImage"
          />
          <div className="d-flex parentPro">
            <Card className="profileImg">
              <img src={Logo} alt="logo" className="logoImg" />
            </Card>
            <div className="profileNameH">
              <div className="profileNameC1">{userDetails?.fullName}</div>
              <div className="profileNameC2">{userDetails?.discordId}</div>
              <div className="profileNameC3">{userDetails?.gameRank}</div>
            </div>
          </div>
        </div>
        <Row className="mt-5">
          <Col xs={0} md={1}></Col>
          <Col xs={12} md={10} className="">
            <Card className="ParentCard">
              <div className="d-flex CardHeaderParent">
                <div
                  className={
                    name === "tournamentRegistration" ||
                    (name !== "tournamentRegistration" &&
                      name !== "teams" &&
                      name !== "performance")
                      ? " CardHeaderD cardbackgroungColor"
                      : " CardHeaderD"
                  }
                  onClick={() => {
                    handleClick({
                      name: "tournamentRegistration",
                    });
                  }}
                >
                  Tournament Registered
                </div>
                <div
                  className={
                    name === "teams"
                      ? " CardHeaderD cardbackgroungColor teamManual"
                      : " CardHeaderD "
                  }
                  onClick={() => {
                    handleClick({
                      name: "teams",
                    });
                  }}
                >
                  Teams
                </div>
                <div
                  className={
                    name === "performance"
                      ? " CardHeaderD cardbackgroungColor"
                      : " CardHeaderD"
                  }
                  onClick={() => {
                    handleClick({
                      name: "performance",
                    });
                  }}
                >
                  Performance
                </div>
              </div>
              {name === "tournamentRegistration" ||
              (name !== "tournamentRegistration" &&
                name !== "teams" &&
                name !== "performance") ? (
                <>
                  {loading ? (
                    <>
                      <div>
                        <Loader />
                      </div>
                    </>
                  ) : (
                    <>
                      {props?.getUserTournaments?.data?.length > 0 ? (
                        <>
                          <div
                            className={
                              props?.getUserTournaments?.data?.length > 6
                                ? "p-4 tableHeader tableStyle"
                                : "p-4 tableHeader"
                            }
                          >
                            <Row className="TeamHeader1">
                              <Col xs={4}>Tournament</Col>
                              <Col xs={4}>Team</Col>
                              <Col xs={4}>Date</Col>
                            </Row>
                            {}
                            {registerTournament?.map((ele: any, i: any) => {
                              return (
                                <React.Fragment>
                                  <Row
                                    className={
                                      i % 2 === 0
                                        ? "teamBodyEven"
                                        : "teamBodyOdd"
                                    }
                                  >
                                    <Col xs={4}>
                                      {ele?.tournmentInfo?.label}
                                    </Col>

                                    <Col xs={4}>{ele?.teamName}</Col>
                                    <Col xs={4}>
                                      {moment(
                                        ele?.tournmentInfo?.startDate
                                      ).format("DD-MM-yyy")}
                                    </Col>
                                  </Row>
                                </React.Fragment>
                              );
                            })}
                          </div>
                        </>
                      ) : (
                        <div className="d-flex justify-content-center  text-white">
                          <span className="text-center">
                            Team Data does not exist's
                          </span>
                        </div>
                      )}
                    </>
                  )}
                </>
              ) : (
                ""
              )}

              {name === "teams" ? (
                <>
                  {loading ? (
                    <>
                      <div>
                        <Loader />
                      </div>
                    </>
                  ) : (
                    <>
                      {props?.teamDetails?.data?.length > 0 ? (
                        <>
                          <div
                            className={
                              props?.teamDetails?.data?.length > 6
                                ? "p-4 tableHeader tableStyle"
                                : "p-4 tableHeader"
                            }
                          >
                            <Row className="TeamHeader">
                              <Col xs={4}>Team Name</Col>
                              <Col xs={4}>Tournament Name</Col>
                              <Col xs={4}>Users</Col>
                            </Row>
                            {}
                            {userData?.map((ele: any, i: any) => {
                              return (
                                <React.Fragment>
                                  <Row className="TeamBody">
                                    <Col xs={4}>
                                      <span>
                                        {
                                          ele?.teamLogo ?
                                          <img
                                            src={ele?.teamLogo }
                                            width="50px"
                                            height="50px"
                                            alt="Logo"
                                          />:<img
                                          src={Logo}
                                          width="50px"
                                          height="50px"
                                          alt="Logo"
                                        />
                                        }
                                      </span>
                                      {ele?.teamName}
                                    </Col>

                                    <Col xs={4}>{ele?.tournamentName}</Col>
                                    <Col xs={4}>
                                      {ele?.users?.length} Players
                                    </Col>
                                  </Row>
                                </React.Fragment>
                              );
                            })}
                          </div>
                        </>
                      ) : (
                        <div className="d-flex justify-content-center  text-white">
                          <span className="text-center">
                            Team Data does not exist's
                          </span>
                        </div>
                      )}
                    </>
                  )}
                </>
              ) : (
                ""
              )}
              {name === "performance" ? (
                <>
                <div className="ParentPerfomance">
                  <div className="HeaderColor">
                    <div className="SubHeaderColor">
                      <div className="Pdiv">
                        <div className="text">Total Wins</div>
                        <div className="Number">{winnings?.length}</div>
                      </div>
                    </div>
                    <div className="SubHeaderColor">
                      <div className="Pdiv">
                        <div className="text">Total Kills</div>
                        <div className="Number">
                          {TotalMatchScores?.totalKills ? TotalMatchScores?.totalKills :0 }
                        </div>
                      </div>
                    </div>
                    <div className="SubHeaderColor">
                      <div className="Pdiv">
                        <div className="text">Kills/Death Ratio</div>
                        <div className="Number">
                          { TotalMatchScores?.totalKills ?(
                            TotalMatchScores?.totalKills /
                            TotalMatchScores?.totalDeaths
                          ).toFixed(2):0}
                        </div>
                      </div>
                    </div>
                    <div className="SubHeaderColor">
                      <div className="Pdiv">
                        <div className="text">Wins %</div>
                        <div className="Number">
                          {winnings?.length ? ((winnings?.length /
                            (winnings?.length + lossings?.length)) *
                            100).toFixed(2):0}
                        </div>
                      </div>
                    </div>
                  </div>
                  <Card className="perfomanceCard">
                    <div className="d-flex justify-content-center">
                      <h3 className="BestPer">Best Performance</h3>
                    </div>
                    <Row>
                      <Col xs={2}></Col>
                      <Col xs={8}>
                        <div className="d-flex  text-white subP">
                          <div className="subDiv">
                            <div className="TextS">Total Kills</div>
                            <div className="NumberS">{allMatchScoresSorted?.kills ? allMatchScoresSorted?.kills:0}</div>
                          </div>
                          <div className="subDiv">
                            <div className="TextS">Total Deaths</div>
                            <div className="NumberS">{allMatchScoresSorted?.deaths ? allMatchScoresSorted?.deaths :0}</div>
                          </div>
                          <div className="subDiv">
                            <div className="TextS">Total Assists</div>
                            <div className="NumberS">{allMatchScoresSorted?.assists ? allMatchScoresSorted?.assists :0 }</div>
                          </div>
                          <div className="subDiv">
                            <div className="TextS">K/D Ratio</div>
                            <div className="NumberS">{allMatchScoresSorted?.kills ? (allMatchScoresSorted?.kills/allMatchScoresSorted?.deaths).toFixed(2) : 0}</div>
                          </div>
                          <div className="subDiv">
                            <div className="TextS">Avg. Combat Scores</div>
                            <div className="NumberS">{allMatchScoresSorted?.combatScore ? allMatchScoresSorted?.combatScore : 0}</div>
                          </div>
                        </div>
                      </Col>
                      <Col xs={2}></Col>
                    </Row>
                  </Card>
                  </div>
                </>
              ) : (
                ""
              )}
            </Card>
          </Col>
          <Col xs={0} md={1}></Col>
        </Row>

        <div>
          <FoterDiv />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  console.log("state", state);
  return {
    ...state,
    teamDetails: state?.tournamentReducer?.fetchTeams,
    getUserTournaments: state?.userTournamentReducer?.tournamentDetail,
    getProfileScore: state?.matchReducer?.getProfileScoresFromMatch?.data,
    userDetailsById:state?.userReducer?.userDetailsByUserId?.data

  };
};
export default connect(mapStateToProps, {
  fetchTeams,
  fetchUserTournament,
  getProfileScoreFromeMatches,
  getUsersByUserId
})(MyDashboard);
