import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Card, Grid, Pagination, Stack } from "@mui/material";
import { Row, Col, Button, Input } from "reactstrap";

//components
import SideBar from "../../components/verticalMenu/SideBar";
import Loader from "../../components/Loader/Loader";
import notifications from "../../components/Notifications/Notification";
//store
import {fetchMatchPerfomance} from "../../Store/matchPerfomance/actions";
let data:any=[];
const MyPerfomance = (props:any) => {
  const [loading, setLoading] = useState(false);

  let auth: any = localStorage.getItem("authUser") || sessionStorage.getItem("authUser");
  let authUser: any = JSON.parse(auth) || "";
  useEffect(() => {
      getAllData();
    }, []);
  const getAllData = () => {
      console.log("level1");
      let offset = 0;
      setLoading(true)
      props.fetchMatchPerfomance(offset, 10000, {userId:authUser?._id},(res: any) => {
        setLoading(false)
       
      });
    };
    console.log("getMyperfomance",props.getMyperfomance)
    if (props?.getMyperfomance !== undefined) {
      let getMyperfomance = props?.getMyperfomance?.data;

      data = [];
      props.getMyperfomance?.data?.docs?.map((ele: any, i: any) => {
        data.push({
          id: ele?._id,
          logo:ele?.matchInfo?.tournamentInfo[0]?.logo,
          tournamentName: ele?.matchInfo?.tournamentInfo[0]?.label,
          winningTeam:ele?.winningTeamInfo,
          loosingTeam:ele?.lossingTeamInfo,
          combatScore: ele?.combatScore,
          kills: ele?.kills,
          deaths: ele?.deaths,
          assists: ele?.assists,
          firstBlood: ele?.firstBlood,
          plants: ele?.plants,
          userId:ele?.userId
        });
      });
      // const dataFilter = data.filter(
      //   (v: any, i: any, a: any) => a.findIndex((v2: any) => v2.id === v.id) === i
      // );
      // data = dataFilter;
    }
    console.log("data",data)
  return (
    <>
      <div className="pageContent" >
  <div className="pageContentChild">
   <div>
   <SideBar></SideBar>
   </div>
   <div className="p-3 w-100">
   {loading ? (
              <>
                <div>
                  <Loader />
                </div>
              </>
            ) : (
              <>
                <Row>
                  <Col xs="12">
                    <h4>My Perfomance</h4>
                    <Row className="mt-3">
                      {data.map(
                        (tournament: any, i: any) => {
                          console.log("tournament",tournament)
                          return (
                            <>
                              <Col xs="12" sm={6}  md={4} lg={3} className="p-4 ">
                                <Card className="MytournamentsCard ">
                                  <img src={tournament?.logo}  height="250px" alt="Logo" />
                                  <h4 className="text-capitalize pb-2">
                                    Name : {tournament?.tournamentName}
                                  </h4>
                                  <h6 className="text-capitalize pb-2">
                                    {
                                      tournament?.winningTeam?.users?.map((ele:any,i:any)=>{
                                        if(ele?.user === tournament?.userId){
                                          return(
                                            <>
                                              Team Name: {tournament?.winningTeam?.teamName}
                                            </>
                                          )
                                        }
                                       
                                      })
                                    }
                                      {
                                      tournament?.loosingTeam?.users?.map((ele:any,i:any)=>{
                                        if(ele?.user === tournament?.userId){
                                          return(
                                            <>
                                              Team Name: {tournament?.loosingTeam?.teamName}
                                            </>
                                          )
                                        }
                                       
                                      })
                                    }
                                  </h6>
                                  {/* <h6 className="text-capitalize pb-2">
                                    format: {tournament?.matchInfo?.tournamentInfo[0].format}
                                  </h6> */}
                                  <span className="text-capitalize pb-2">
                                  CombatScore : {tournament?.combatScore}
                                  </span><br/>
                                  <span className="text-capitalize pb-2">
                                  Kills : {tournament?.kills}
                                  </span><br/>
                                  <span className="text-capitalize pb-2">
                                  Deaths : {tournament?.deaths}
                                  </span><br/>
                                  <span className="text-capitalize pb-2">
                                  Assists : {tournament?.assists}
                                  </span><br/>
                                  <span className="text-capitalize pb-2">
                                  First Blood : {tournament?.firstBlood}
                                  </span><br/>
                                  <span className="text-capitalize pb-2">
                                  Plants : {tournament?.plants}
                                  </span><br/>
                                </Card>
                              </Col>
                            </>
                          );
                        }
                      )}
                    </Row>
                  </Col>
                </Row>
              </>
            )}
     </div>
     </div>
     </div>
    
    </>
  )
}

const mapStateToProps = (state: any) => {
  console.log("state", state);
  return {
    ...state,
    getMyperfomance:state?.matchPerfomanceReducer?.matchPerfomanceDetail,
  };
};
export default connect(mapStateToProps, {
  fetchMatchPerfomance,
})(MyPerfomance);