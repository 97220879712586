import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ReactNotifications } from "react-notifications-component";
import { GoogleOAuthProvider } from "@react-oauth/google";
//importing store
import store from "./Store";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
let config: any = {
  clientId: process.env.REACT_APP_GOOGLE_CLIENTID,
};
root.render(
  <GoogleOAuthProvider {...config}>
    <Provider store={store}>
      <BrowserRouter>
        <ReactNotifications isMobile={true} />
        <App />
      </BrowserRouter>
    </Provider>
  </GoogleOAuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
