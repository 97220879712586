import React, { useEffect, useState } from "react";
import { Row, Col, Input } from "reactstrap";
import { Grid, Pagination, Stack } from "@mui/material";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { connect } from "react-redux";
import axios from "axios";
import ActionVerticalButton from "../../assets/images/more-vertical.png";
//components
import ViewWhatsAppBulkMsg from "./ViewWhatsAppBulkMsg";
import AddorEditWhatsAppRecords from "./AddorEditWhatsAppRecords";
import SideBar from "../../components/verticalMenu/SideBar";
import notifications from "../../components/Notifications/Notification";
import Loader from "../../components/Loader/Loader";
import Index from "../../components/common/index";
import { HeaderData } from "./HeaderData";
//store

import {
  getWhatsAppBulkDetails,
  deleteWhatsAppBulkDetails,
  sendWhatsAppBulkDetails
} from "../../Store/actions";
//global variables
var offset: number = 0;
var limit: number = 10;
var totCnt = 0;
var totPages = 0;
var toVal = 0;
var fromVal = 0;
const WhatsAppbulk = (props: any) => {
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal]: any = useState(false);
  const [editData, setEditData]: any = useState("");
  const [openModalView, setOpenModalView]: any = useState(false);
  const [viewData, setViewData]: any = useState("");
  const openModalPopUp = () => {
    setOpenModal(true);
  };
  const closeModal = () => {
    setOpenModal(false);
    setEditData("");
  };
  const openModalPopUpView = () => {
    setOpenModalView(true);
  };
  const closeModalView = () => {
    setOpenModalView(false);
    setViewData("");
  };
  useEffect(() => {
    getAllData();
  }, []);
  const getAllData = () => {
    setLoading(true);
    let filters = {
      name: "",
    };
    props.getWhatsAppBulkDetails(offset, limit, filters, (res: any) => {
      setLoading(false);
    });
  };
 
  // delete 
  let deleteWhatsAppBulk = (ele: any) => {
    setLoading(true);
    props?.deleteWhatsAppBulkDetails({ id: ele?._id }, (res: any) => {
      setLoading(false);
      if (res.success) {
        getAllData();
        notifications({
          title: "Success!",
          message: res?.message,
          type: "success",
        });
      } else {
        notifications({
          title: "Error!",
          message: res?.message,
          type: "danger",
        });
      }
    });
  };
  // handle page
  let handleChangePage = (event: any, val?: any) => {
    console.log("val", val);
    offset = (val - 1) * 10;
    toVal = offset + 10;
    setLoading(true);
    props.getWhatsAppBulkDetails(offset, limit, { name: "" }, (res: any) => {
      setLoading(false);
    });
  };
  const downloadExcel = async (url:any) => {
    try {
      const response = await axios({
        url,
        method: 'GET',
        responseType: 'blob',
      });
      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = "BulkExcelsheet.xlsx";
      link.click();
    } catch (error) {
      console.error(error);
    }
  };
  let action = (ele: any) => {
    return (
      <>
        <div className="dropdown">
          <img
            style={{ margin: "-4px" }}
            src={ActionVerticalButton}
            alt="actions"
          />
          <div className="dropdown-content">
            <div
              className="action-button"
              onClick={() => {
                setViewData(ele);
                openModalPopUpView();
              }}
            >
              View Details
            </div>
            <div
              className="action-button"
              onClick={() => {
                setEditData(ele);
                openModalPopUp();
              }}
            >
              Edit Details
            </div>
            <div
              className="action-button"
              onClick={()=>{
                downloadExcel(ele?.excelFileURL)
              }}
            >
              Download Excelsheet
            </div>
            <div
              className="action-button"
              onClick={() => {
                setLoading(true)
                props.sendWhatsAppBulkDetails({
                  id:ele?._id
                },(res: any) => {
                  if (res.success) {
                    notifications({
                      title: "Success!",
                      message: res?.message,
                      type: "success",
                    });
                    getAllData();
                    setLoading(false)
                  } else {
                    notifications({
                      title: "Error!",
                      message: res?.message,
                      type: "danger",
                    });
                  }
                })
              }}
            >
              Send
            </div>
            <div
              className="action-button"
              onClick={() => deleteWhatsAppBulk(ele)}
            >
              Delete
            </div>
          </div>
        </div>
      </>
    );
  };
  let data: any = [];
  if (props.getWhatsAppDetails !== undefined) {
    let getWhatsAppDetails = props?.getWhatsAppDetails?.data;
    console.log("getWhatsAppDetails", getWhatsAppDetails, totPages);
    offset = getWhatsAppDetails?.offset;
    totCnt = getWhatsAppDetails?.length;
    fromVal = totCnt !== 0 ? offset + 1 : fromVal;
    toVal = getWhatsAppDetails?.offset + getWhatsAppDetails?.docs?.length;
    totPages = Math.ceil(totCnt / 10);
    getWhatsAppDetails?.docs?.map((ele: any, i: any) => {
      data.push({
        identifincationName: ele?.templateName,
        contentName: ele?.templateMessage,
        action: action(ele),
      });
    });
    console.log("data", data);
  }
  return (
    <>
     <Modal
        isOpen={openModalView}
        toggle={closeModalView}
        size="lg"
        className="WhatsAppBulkModal"
      >
        <ModalHeader>
          <div className="Heading">
            <h3>View WhatsApp Bulk Template</h3>
            <svg
              onClick={() => closeModalView()}
              width="20"
              height="20"
              viewBox="0 0 9 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8 1L1 8"
                stroke="#FFF"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M1 1L8 8"
                stroke="#FFF"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </ModalHeader>
        <ModalBody>
          <ViewWhatsAppBulkMsg
            {...props}
            closeModal={closeModalView}
            editData={viewData}
          />
        </ModalBody>
      </Modal>
      <Modal
        isOpen={openModal}
        toggle={closeModal}
        size="lg"
        className="WhatsAppBulkModal"
      >
        <ModalHeader>
          <div className="Heading">
            <h3>
              {editData?._id ? "Edit WhatsApp Bulk Record" : "Add WhatsApp Bulk Record"}
            </h3>
            <svg
              onClick={() => closeModal()}
              width="20"
              height="20"
              viewBox="0 0 9 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8 1L1 8"
                stroke="#FFF"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M1 1L8 8"
                stroke="#FFF"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </ModalHeader>
        <ModalBody>
          <AddorEditWhatsAppRecords
            {...props}
            closeModal={closeModal}
            getAllData={getAllData}
            editData={editData}

          />
        </ModalBody>
      </Modal>
      <div className="pageContent">
        <div className="pageContentChild">
          <div>
            <SideBar></SideBar>
          </div>
          <div className="p-3 w-100">
            <Row>
              <Col xs={12}>
                <button
                  className="float-end me-4 pe-4 cursor-pointer p-2"
                  onClick={() => {
                    openModalPopUp();
                  }}
                >
                  Add
                </button>
              </Col>
              <Col xs={12}>
                <Grid container spacing={2} className="me-5">
                  <Grid item xs={12} className="text-white mt-4">
                    {/* <Row className="mb-3">
                      <Col xs={12} md={6} lg={4} className="d-flex p-3">
                        <Input
                          className="form-control"
                          type="text"
                          name="eleName"
                          placeholder="Search By Game Name..."
                          aria-label="Search"
                          value={eleNameFilter}
                          onKeyDown={(event: any) => handleKeyDown(event)}
                          onChange={(e) => {
                            console.log("e", e.target.value);
                            handleChangeName({
                              name: "eleName",
                              value: e.target.value,
                            });
                          }}
                        />
                        <svg
                          onClick={() => getAllGamesData()}
                          className="SearchIcon"
                          width="40"
                          height="20"
                          viewBox="0 0 14 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6.4 11.8C9.38234 11.8 11.8 9.38234 11.8 6.4C11.8 3.41766 9.38234 1 6.4 1C3.41766 1 1 3.41766 1 6.4C1 9.38234 3.41766 11.8 6.4 11.8Z"
                            stroke="#FFF"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M12.9992 13L11.1992 11.2"
                            stroke="#FFF"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </Col>

                      <Col xs={6} md={2} className="p-3">
                        <button
                          onClick={() => handleReset()}
                          style={{
                            color: "black",
                            background: "Green",
                            height: "40px",
                            width: "100%",
                            padding: "10px 20px",
                          }}
                        >
                          Reset
                        </button>
                      </Col>
                    </Row> */}
                    {loading ? (
                      <>
                        <div>
                          <Loader />
                        </div>
                      </>
                    ) : (
                      <>
                        <h3 className=" mb-4">WhatsApp Bulk Notifications</h3>
                        <Index
                          data={data}
                          headerData={HeaderData}
                          limit={limit}
                        />
                        {totCnt ? (
                          <>
                            <div className=" mt-4">
                              <Row>
                                <Col xs={6} className="text-white">
                                  <Stack spacing={2}>
                                    <Pagination
                                      className="pagination"
                                      size="small"
                                      shape="rounded"
                                      page={offset / 10 + 1}
                                      count={totPages}
                                      color="primary"
                                      // onChange={(e)=>{handleChangePage(e,offset / 10 + 1)}}
                                      onChange={(e, val) => {
                                        handleChangePage(e, val);
                                        console.log("event", e, val);
                                      }}
                                      defaultPage={1}
                                      siblingCount={1}
                                    />
                                  </Stack>
                                </Col>
                                <Col xs={6}>
                                  <span className="text-white">
                                    Showing {fromVal} to {toVal} of {totCnt}{" "}
                                    entries
                                  </span>
                                </Col>
                              </Row>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                      </>
                    )}
                  </Grid>
                </Grid>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  console.log("state", state);
  return {
    ...state,
    getWhatsAppDetails: state?.whatsAppBulkReducer?.getWhatsAppBulkDetails,
  };
};
export default connect(mapStateToProps, {
  getWhatsAppBulkDetails,
  deleteWhatsAppBulkDetails,
  sendWhatsAppBulkDetails
})(WhatsAppbulk);
