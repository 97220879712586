import React, { useState, useEffect } from "react";
import "./TournamentTheme.scss";
import { connect } from "react-redux";
import { Modal, ModalBody, Row, Col } from "reactstrap";
import moment from "moment";
// import components
import FoterDiv from "../../components/common/FoterDiv";
import UpcomingCarousal from "./UpcomingCarousal";
import OngoingCarousal from "./OngoingCarousal";
import UserLogin from "../Auth/Login/UserLogin";
import UserRegistration from "../Auth/Registration/UserRegistration";
// images
import Asset6 from "../../assets/images/Asset6.png";

//store
import {
  totalUpCommingEvents,
  totalActiveEvents,
} from "../../Store/tournament/actions";

// Global
let upcomingEventData: any = [];
let onGoingEventsData: any = [];

function Tournament1(props: any) {
  const [dropDownisOpen, setDropDownisOpen] = useState(false);
  const [tounamentnameFilter, setTournamentNameFilter]: any = useState({
    upComing: "",
    onGoing: "",
  });
  const [loading, setLoading] = useState(false);

  const [openRegistration, setOpenRegistration] = useState(false);
  const [openLogin, setOpenLogin] = useState(false);

  useEffect(() => {
    console.log("Date test", moment().add(1, "days").format("yyyy-MM-DD"));

    getAllUpcomingTournaments();
    getAllActiveTournaments();
  }, []);
  useEffect(() => {
    if (tounamentnameFilter.upComing?.length > 2) {
      getAllUpcomingTournaments();
    } else if (tounamentnameFilter.upComing?.length === 0) {
      getAllUpcomingTournaments();
    }
    if (tounamentnameFilter?.onGoing?.length > 2) {
      getAllActiveTournaments();
    } else if (tounamentnameFilter?.onGoing?.length === 0) {
      getAllActiveTournaments();
    }
  }, [tounamentnameFilter]);
  const getAllUpcomingTournaments = () => {
    setLoading(true);
    // let date = moment().add(1, 'days').format('yyyy-MM-DD');
    props.totalUpCommingEvents(
      0,
      10000,
      { name: tounamentnameFilter?.upComing },
      () => {
        setLoading(false);
      }
    );
  };
  const getAllActiveTournaments = () => {
    setLoading(true);
    // let date = moment(new Date()).format("yyyy-MM-DD");
    props.totalActiveEvents(
      0,
      10000,
      { name: tounamentnameFilter?.onGoing },
      () => {
        setLoading(false);
      }
    );
  };
  let toggle = () => {
    setDropDownisOpen(!dropDownisOpen);
  };
  let auth: any =
    localStorage.getItem("authUser") || sessionStorage.getItem("authUser");
  let authUser: any = JSON.parse(auth) || "";
  console.log(
    "props store Data",
    props.onGoingEventsDetails,
    props.upcomingEvents
  );
  upcomingEventData = [];
  props.upcomingEvents?.data?.docs?.map((tournament: any, i: any) => {
    upcomingEventData.push({
      _id: tournament?._id,
      logo: tournament?.logo,
      label: tournament?.label,
      format: tournament?.format,
      game: {
        value: tournament?.gameInfo?._id,
        label: tournament?.gameInfo?.label,
      },
      playerInTeam: tournament?.playerInTeam,
      thumbnail: tournament?.thumbnail,
      fixture: tournament?.fixture,
      teamSize: tournament?.teamSize,
      rule: tournament?.rules,
      startDate: tournament?.startDate
        ? moment(new Date(tournament?.startDate)).format("DD/MM/YYYY HH:mm")
        : "N/A",
      endDate: tournament?.endDate
        ? moment(new Date(tournament?.endDate)).format("DD/MM/YYYY HH:mm")
        : "N/A",
      poolPrize: tournament?.poolPrize,
      prizePoolImage: tournament?.prizePoolImage,
      region: tournament?.region,
      server: tournament?.server,
      tournamentForm: tournament?.tournamentForm,
    });
  });
  onGoingEventsData = [];
  props.onGoingEventsDetails?.data?.docs?.map((tournament: any, i: any) => {
    onGoingEventsData.push({
      _id: tournament?._id,
      logo: tournament?.logo,
      label: tournament?.label,
      format: tournament?.format,
      game: {
        value: tournament?.gameInfo?._id,
        label: tournament?.gameInfo?.label,
      },
      playerInTeam: tournament?.playerInTeam,
      teamSize: tournament?.teamSize,
      rule: tournament?.rules,
      startDate: tournament?.startDate
        ? moment(new Date(tournament?.startDate)).format("YYYY-MM-DD HH:mm")
        : "N/A",
      endDate: tournament?.endDate
        ? moment(new Date(tournament?.endDate)).format("YYYY-MM-DD HH:mm")
        : "N/A",
      poolPrize: tournament?.poolPrize,
      prizePoolImage: tournament?.prizePoolImage,
      region: tournament?.region,
      server: tournament?.server,
      tournamentForm: tournament?.tournamentForm,
    });
  });
  const handleChangeOnfocus = (e: any) => {
    let x: any = document.getElementById("myInput");
    x.addEventListener("focusin", myFocusFunction);
    x.addEventListener("focusout", myBlurFunction);

    function myFocusFunction() {
      (document.getElementById("MainPage") as any).style.opacity = 0.96;
      (document.getElementById("myInput") as any).style.opacity = "unset";
    }

    function myBlurFunction() {
      (document.getElementById("MainPage") as any).style.opacity = "unset";
    }
  };
  const handleChangeOnfocus1 = (e: any) => {
    let x: any = document.getElementById("myInput1");
    x.addEventListener("focusin", myFocusFunction);
    x.addEventListener("focusout", myBlurFunction);

    function myFocusFunction() {
      (document.getElementById("MainPage") as any).style.opacity = 0.96;
      (document.getElementById("myInput") as any).style.opacity = "unset";
    }

    function myBlurFunction() {
      (document.getElementById("MainPage") as any).style.opacity = "unset";
    }
  };
  let openLoginPopUp = () => {
    setOpenLogin(true);
    setOpenRegistration(false);
  };
  let closeModalLogin = () => {
    setOpenLogin(false);
  };
  let openRegistrationPopUp = () => {
    setOpenRegistration(true);
    setOpenLogin(false);
  };
  let closeModal = () => {
    setOpenRegistration(false);
  };
  const handleChangeFilter = (type: any) => {
    console.log("type", type);
    if (type?.name === "upComing") {
      setTournamentNameFilter({
        upComing: type?.value,
      });
    } else if (type?.name === "onGoing") {
      setTournamentNameFilter({
        onGoing: type?.value,
      });
    }
  };

  return (
    <>
      {authUser ? (
        ""
      ) : (
        <>
          <Modal
            isOpen={openRegistration}
            toggle={closeModal}
            size="lg"
            className="RegistrationModal"
          >
            <ModalBody>
              <UserRegistration
                {...props}
                closeModal={closeModal}
                openLoginPopUp={openLoginPopUp}
              />
            </ModalBody>
          </Modal>
          <Modal
            isOpen={openLogin}
            toggle={closeModalLogin}
            size="lg"
            className="RegistrationModal"
          >
            <ModalBody>
              <UserLogin
                {...props}
                closeModalLogin={closeModalLogin}
                openRegistrationPopUp={openRegistrationPopUp}
              />
            </ModalBody>
          </Modal>
        </>
      )}
      <div
        id="MainPage"
        className=" text-white "
        style={{
          backgroundColor: "#000",
          overflowX: "hidden",
          overflowY: "auto",
          // height: "1100vh",
        }}
      >
        <div className=" MainTournamentPage">
          {/* Top welcome name is started */}
          <div className="container pt-5">
            {authUser ? (
              <h1 className="TopHeading">Welcome {authUser?.fullName}</h1>
            ) : (
              ""
            )}
          </div>
          {/* Top welcome name is ended */}

          {/*upcoming tournament section starts */}
          <div className="container">
            <div className="d-flex justify-content-center">
              <h2 className="UpcomingTournamentsHeading mb-3">
                Upcoming tournaments
              </h2>
              <input
                id="myInput"
                className="inputSearchField displayDesktopInput"
                type="text"
                name="upComing"
                placeholder="Search "
                aria-label="Search"
                onFocus={(e: any) => handleChangeOnfocus(e)}
                onChange={(e: any) =>
                  handleChangeFilter({
                    name: e.target.name,
                    value: e.target.value,
                  })
                }
              />
            </div>
            <div className="displayMobileInput d-flex justify-content-center ">
              <input
                id="myInput"
                className="inputSearchField "
                type="text"
                name="upComing"
                placeholder="Search"
                aria-label="Search"
                onFocus={(e: any) => handleChangeOnfocus(e)}
                onChange={(e: any) =>
                  handleChangeFilter({
                    name: e.target.name,
                    value: e.target.value,
                  })
                }
              />
              <svg
                onClick={() => getAllUpcomingTournaments()}
                className="SearchIcon cursor-pointer "
                width="40"
                height="20"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.4 11.8C9.38234 11.8 11.8 9.38234 11.8 6.4C11.8 3.41766 9.38234 1 6.4 1C3.41766 1 1 3.41766 1 6.4C1 9.38234 3.41766 11.8 6.4 11.8Z"
                  stroke="#FFF"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M12.9992 13L11.1992 11.2"
                  stroke="#FFF"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <div className="row">
              <div className="col-xl-1"></div>
              <div className="col-xl-10">
                <UpcomingCarousal
                  upcomingEventData={upcomingEventData}
                  openLoginPopUp={openLoginPopUp}
                  loading={loading}
                />
              </div>
              <div className="col-xl-1"></div>
            </div>
          </div>
          {/*upcoming tournament section ends */}

          {/*onGoing tournament section starts */}
          <div className=" OngoingTournamentsSections">
            <div className="container">
              <div className="d-flex justify-content-center">
                <h2 className="OngoingTournamentsHeading">
                  Ongoing tournaments
                </h2>
                <input
                  id="myInput1"
                  className="inputSearchField displayDesktopInput"
                  type="text"
                  name="onGoing"
                  placeholder="Search "
                  aria-label="Search"
                  onFocus={(e: any) => handleChangeOnfocus1(e)}
                  onChange={(e: any) =>
                    handleChangeFilter({
                      name: e.target.name,
                      value: e.target.value,
                    })
                  }
                />
              </div>
              <div className="displayMobileInput d-flex justify-content-center ">
                <input
                  id="myInput1"
                  className="inputSearchField "
                  type="text"
                  name="onGoing"
                  placeholder="Search "
                  aria-label="Search"
                  onFocus={(e: any) => handleChangeOnfocus1(e)}
                  onChange={(e: any) =>
                    handleChangeFilter({
                      name: e.target.name,
                      value: e.target.value,
                    })
                  }
                />
                <svg
                  onClick={() => getAllActiveTournaments()}
                  className="SearchIcon cursor-pointer "
                  width="40"
                  height="20"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.4 11.8C9.38234 11.8 11.8 9.38234 11.8 6.4C11.8 3.41766 9.38234 1 6.4 1C3.41766 1 1 3.41766 1 6.4C1 9.38234 3.41766 11.8 6.4 11.8Z"
                    stroke="#FFF"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M12.9992 13L11.1992 11.2"
                    stroke="#FFF"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <div className="row mb-10">
                <div className="col-xl-1"></div>
                <div className="col-xl-10">
                  <OngoingCarousal
                    onGoingEventsData={onGoingEventsData}
                    loading={loading}
                  />
                </div>
                <div className="col-xl-1"></div>
              </div>
            </div>
          </div>
          {/*onGoing tournament section ends */}
        </div>

        <div className=" MainTournamentPage1">
          {/* Join our community section starts */}
          <div className="separateDiv"></div>
          <Row>
            <Col xs={1} md={1} lg={1}></Col>
            <Col xs={10}>
              <div className="JoinCommunitySection">
                <div className="JoinCommunitySection_content">
                  <h3>Join our Global Community</h3>
                  <img src={Asset6} alt="Join" />
                </div>
              </div>
            </Col>
            <Col xs={1} md={1} lg={1}></Col>
          </Row>
          <div className="container text-center JoinButton">
            <button type="button" className="btn btn-success ">
              Join now
            </button>
          </div>
          {/*footer div section start  */}
          <FoterDiv />
          {/*footer div section ends */}
        </div>
      </div>
    </>
  );
}
const mapStateToProps = (state: any) => {
  console.log("state", state);
  return {
    ...state,
    onGoingEventsDetails: state?.tournamentReducer?.activeEvents,
    upcomingEvents: state?.tournamentReducer?.upcomingEvents,
  };
};
export default connect(mapStateToProps, {
  totalUpCommingEvents,
  totalActiveEvents,
})(Tournament1);
