const validate = (inputs:any) => {
    console.log("inputs",inputs)
   let errors:any = {
    tournamentId: "",
    winningTeamId: "",
    loosingTeamId: "",
   };
//    if (!inputs.tournamentName) {
//        errors.tournamentId = 'Tournament Name is required';
//    } 
    if (!inputs.winningTeamId) {
       errors.winningTeamId = 'Winning Team is Required';
   }
   if (!inputs.loosingTeamId) {
    errors.loosingTeamId = 'Loosing Team is Required';
}
   console.log("errors",errors)
   return errors;
}

export default validate;