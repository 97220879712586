import React,{Suspense, lazy} from "react";
import "./theme.scss";
import { useLocation } from "react-router-dom";
import { Routes, Route, Navigate } from "react-router-dom";
// Home page
import Login from "./pages/Auth/Login/Login";
import NavBar from "./components/Header/NavBar";
import About from "./pages/About/About";
import ActivateUser from "./pages/Auth/Login/ActivateUser";
import ForgotPassword from "./pages/Auth/Login/ForgotPassword";
import ResetPassword from "./pages/Auth/Login/ResetPassword";
import UpdatePassword from "./pages/Auth/Login/UpdatePassword";

// Admin Side
import AdminDashboard from "./pages/dashboard/AdminDashboard";
import Users from "./pages/Users/Users";
import MyProfile from "./pages/Users/MyProfile";
import Games from "./pages/Game/Games";
import ViewGame from "./pages/Game/ViewGame";
import AddVideoTag from "./pages/Tournament/AddVideoTag";

//Notifications
import GetSavedMails from "./pages/Mail/GetSavedMails";
import SendSavedMails from "./pages/Mail/SendSavedMails";
import SendingMail from "./pages/Mail/SendingMail";
import WhatsAppbulk from "./pages/WhatsAppBulk/WhatsAppbulk";

// tournament Module
import Teams from "./pages/Tournament/Teams";
import Tournament from "./pages/Tournament/Tournament";
import CreateTournament from "./pages/Tournament/CreateTournament";
import EditTournament from "./pages/Tournament/EditTournament";
import ViewTournament from "./pages/Tournament/ViewTournament";
import MatchDetails from "./pages/MatchDetails/MatchDetails";
import ViewMatchDetails from "./pages/MatchDetails/ViewMatchDetails";
import ViewLeaderBoardScoresByTournament from "./pages/LeaderBoardScores/ViewLeaderBoardScoresByTournament";
import AddPerfomanceUser from "./pages/MatchDetails/AddPerfomanceUser";
import ViewPerfomance from "./pages/MatchDetails/ViewPerfomance";
//User Side
import MyDashboard from "./pages/dashboard/MyDashboard";
import Products from "./pages/Products/Products";
import UserDashboard from "./pages/dashboard/UserDashboard";
import LeaderBoard from "./pages/LeaderBoard/LeaderBoard";
import MyPerfomance from "./pages/MatchDetails/MyPerfomance";

// User Side Tournament
import Tournament1 from "./pages/TournamentUserSiderNew/Tournament1";
import TournamentUserRegistration from "./pages/UserTournament/TournamentUserRegistration";
import ViewTournamentById from "./pages/Tournament/ViewTournamentById";
import TournamentNewRegistration from "./pages/TournamentUserSiderNew/TournamentNewRegistration";
import TournamentCompleted from "./pages/TournamentUserSiderNew/TournamentCompleted";
import TeamRegister from "./pages/TournamentUserSiderNew/TeamRegister";
import TournamentViewUserSide from "./pages/TournamentUserSiderNew/TournamentViewUserSide";
// caterogery
import ValorentNew from "./pages/Valorant/ValorentNew";
import Beginner from "./pages/CategoryPages/Beginner";
import Intermediate from "./pages/CategoryPages/Intermediate";

import InvitedPlayers from "./pages/UserTournament/InvitedPlayers";
import MyTournaments from "./pages/TournamentUserSiderNew/MyTournaments";
import MyTeams from "./pages/Teams/MyTeams";
import UserProfile from "./pages/Users/UserProfile";
import WhatsAppMedia from "./pages/WhatsAppMedia/WhatsAppMedia";
function App(props: any) {
  const search = useLocation().search;
  const userId = new URLSearchParams(search).get("userId");

  let auth: any =
    localStorage.getItem("authUser") || sessionStorage.getItem("authUser");
  let authUser: any = JSON.parse(auth) || "";

  return (
    <>
   
      {userId ? "" : <NavBar />}
      <Routes>
     
        {authUser?.role ? (
          <>
            <Route path="/users" element={<Users />} />
            <Route path="/games" element={<Games />} />
            <Route path="/game/:id" element={<ViewGame />} />
            <Route path="/tournaments" element={<Tournament />} />
            <Route path="/tournament/:id" element={<ViewTournament />} />
            <Route path="/edit-tournament/:id" element={<EditTournament />} />

            <Route path="/add-video-tag" element={<AddVideoTag />} />
            <Route path="/create-tournament" element={<CreateTournament />} />
            <Route path="/view-perfomance" element={<ViewPerfomance />} />
            <Route path="/match-details" element={<MatchDetails />} />
            <Route path="/view-match-details" element={<ViewMatchDetails />} />
            <Route path="/admin-dashboard" element={<AdminDashboard />} />
            <Route path="/sending-email" element={<SendingMail />} />
            <Route path="/sending-saved-mail" element={<SendSavedMails />} />
            <Route path="/get-saved-mails" element={<GetSavedMails />} />
            <Route path="/whatsapp-bulk" element={<WhatsAppbulk />} />
            <Route path="/whatsapp-media" element={<WhatsAppMedia />} />
            <Route path="/myTeams" element={<MyTeams />} />
            <Route
              path="/add-user-perfomance"
              element={<AddPerfomanceUser />}
            />

            <Route
              path="/view-leaderboardscores-tournament"
              element={<ViewLeaderBoardScoresByTournament />}
            />
            <Route path="/userProfile" element={<UserProfile/>} />
            <Route path="/myProfile" element={<MyProfile />} />

            {/* User Side */}
            <Route path="/my-dasboard" element={<MyDashboard />} />

            <Route path="/category" element={<ValorentNew />} />
            <Route path="/beginner" element={<Beginner />} />
            <Route path="/intermediate" element={<Intermediate />} />
            <Route path="/leaderBoard" element={<LeaderBoard />} />
            <Route
              path="/tournament-team-registration"
              element={<TournamentNewRegistration />}
            />
            <Route
              path="/tournament-registration-completed"
              element={<TournamentCompleted />}
            />
            <Route
              path="/view-tournament/:id"
              element={<TournamentViewUserSide />}
            />

            <Route path="/invited-players" element={<InvitedPlayers />} />
            <Route path="/invitation" element={<ViewTournamentById />} />
            <Route path="/teams" element={<Teams />} />
            <Route
              path="/user-tournament-registration"
              element={<TournamentUserRegistration />}
            />

            <Route path="/user-dashboard" element={<UserDashboard />} />

            <Route path="/updatePassword" element={<UpdatePassword />} />
            <Route path="/products" element={<Products />} />

            <Route path="/user/tournament" element={<Tournament1 />} />

            <Route path="/myTournaments" element={<MyTournaments />} />

            <Route path="/myPerfomance" element={<MyPerfomance />} />

            {authUser?.role === "Admin" ? (
              <Route
                path="*"
                element={<Navigate to="/admin-dashboard" replace />}
              />
            ) : (
              <Route
                path="*"
                element={<Navigate to="/my-dasboard" replace />}
              />
            )}
          </>
        ) : (
          <>
            <Route path="/user/tournament" element={<Tournament1 />} />
            <Route
              path="/view-tournament/:id"
              element={<TournamentViewUserSide />}
            />

            <Route path="/" element={<Login />} />
            {/* <Route path="/login" element={<Login />} /> */}
            <Route path="/about" element={<About />} />
            <Route path="/tournament1" element={<Tournament1 />} />
            <Route path="/category" element={<ValorentNew />} />
            <Route path="/beginner" element={<Beginner />} />
            <Route path="/intermediate" element={<Intermediate />} />
            <Route path="/activateUser" element={<ActivateUser />} />
            <Route
              path="/sendForgetPasswordLink"
              element={<ForgotPassword />}
            />
            <Route path="/resetPassword" element={<ResetPassword />} />
            <Route path="*" element={<Navigate to="/" replace />} />
            <Route
              path="/tournament-team-registration"
              element={<TournamentNewRegistration />}
            />
            <Route path="/teamm" element={<TeamRegister />} />

            <Route
              path="/tournament-registration-completed"
              element={<TournamentCompleted />}
            />
          </>
        )}
      </Routes>
    </>
  );
}

export default App;
