export const gameRankOptions: any = [
    {
      value: "Iron I",
      label: "Iron I" ,
    },
    {
      value: "Iron II",
      label: "Iron II" ,
    },
    {
      value: "Iron III",
      label: "Iron III" ,
    },
    {
      value: "Bronze I",
      label: "Bronze I",
    },
    {
      value: "Bronze II",
      label: "Bronze II",
    },
    {
      value: "Bronze III",
      label: "Bronze III",
    },
    {
      value: "Silver I",
      label: "Silver I",
    },
    {
      value: "Silver II",
      label: "Silver II",
    },
    {
      value: "Silver III",
      label: "Silver III",
    },
    {
      value: "Gold I",
      label: "Gold I",
    },
    {
      value: "Gold II",
      label: "Gold II",
    },
    {
      value: "Gold III",
      label: "Gold III",
    },
    {
      value: "Platinum I",
      label: "Platinum I",
    },
    {
      value: "Platinum II",
      label: "Platinum II",
    },
    {
      value: "Platinum III",
      label: "Platinum III",
    },
    {
      value: "Diamond I",
      label: "Diamond I",
    },
    {
      value: "Diamond II",
      label: "Diamond II",
    },
    {
      value: "Diamond III",
      label: "Diamond III",
    },
    {
      value: "Ascendant I",
      label: "Ascendant I",
    },
    {
      value: "Ascendant II",
      label: "Ascendant II",
    },
    {
      value: "Ascendant III",
      label: "Ascendant III",
    },
    {
      value: "Immortal I",
      label: "Immortal I",
    },
    {
      value: "Immortal III",
      label: "Immortal I",
    },
    {
      value: "Immortal III",
      label: "Immortal III",
    },
    {
      value: "Radiant",
      label: "Radiant",
    },
  ];