export const HeaderData = {
    columns: [
      {
        label: "Label",
        field: "label",
        width: 3,
        sort:true
      },

      {
        label: "Action",
        field: "action",
        width: 2,
      },
    ],
  };

  