import { types } from "./actionTypes";
const initialState = {
  getWhatsAppBulkDetails: null,
};
const whatsAppBulkReducer = (state: any = initialState, action: any) => {
  // console.log("action",action.payload)
  switch (action.type) {
  
    case types.GET_WHATSAPP_BULK_DETAILS_SUCCESS:
      state = {
        ...state,
        getWhatsAppBulkDetails: action.payload,
      };
      return state;
    default:
      return state;
  }
};

export default whatsAppBulkReducer;
