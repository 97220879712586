import React, { useState, useEffect, useRef, useCallback } from "react";
import { Card, Grid } from "@mui/material";
import Select from "react-select";
import { Row, Col,Button } from "reactstrap";
import { connect } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import { Modal, ModalBody } from "reactstrap";
import { useLocation, useNavigate } from "react-router-dom";

//components
import InvitedPlayerUserTournament from "./InvitedPlayerUserTournament";
import SideBar from "../../components/verticalMenu/SideBar";
import PlayersAddForm from "./PlayersAddForm";
import notifications from "../../components/Notifications/Notification";
//store
import { fetchTournament } from "../../Store/tournament/actions";
import {
  addUserTournament,
  fetchPresignedUrlTeamLogo,
  uploadImagesUserTournament,
} from "../../Store/userTournament/actions";

let touranmentOptions: any = [];
// Creating schema
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const schema = Yup.object().shape({
  tournamentId: Yup.string().required("Tournament Name is required"),
  name: Yup.string().required("Name is required"),
  teamName: Yup.string().required("Team name is required"),
  email: Yup.string()
    .required("Email is a required field")
    .email("Invalid email format"),
  phone: Yup.string()
    .required("Phone number is required")
    .matches(phoneRegExp, "Phone number is not valid"),
  gameRank: Yup.string().required("Game rank is required"),
  discordId: Yup.string().required("DiscordId is required"),
});

const TournamentUserRegistration = (props: any) => {
  const [addPlayerDetailsFlag, setPlayerDetailsFlag] = useState(false);
  const [players, setPlayers]: any = useState([]);
  const [form, setForm]: any = useState({
    tournamentId: "",
    teamName: "",
    teamLogo: "",
  });
  const [baseImageLogo, setBaseImageLogo]: any = useState("");
  const [selectedFile, setSelectedFile]: any = useState();
  const [teamLogo, setTeamLogo]: any = useState("");
  const fileInputLogo: any = useRef();
  const navigator = useNavigate();
  const location = useLocation();
  console.log("location", location, location?.state?.tournament?.label);
  useEffect(() => {
    getAllTournamentData();
    return () => {};
  }, []);
  useEffect(() => {
    console.log("players", players);
    console.log("form", form);
  }, [players, form]);
  useEffect(() => {
    if (selectedFile) {
      S3Upload();
    }
    console.log("selectedFile", selectedFile);
  }, [selectedFile]);

  let getAllTournamentData = () => {
    props.fetchTournament(0, 1000, {}, (res: any) => {});
  };
  const fileUploadTrigger = () => {
    fileInputLogo.current.click();
  };

  const S3Upload = async () => {
    props.fetchPresignedUrlTeamLogo((res: any) => {
      console.log("res", res?.data);
      if (selectedFile) {
        let softCopy = "";
        // console.log("Calling Signed",res)
        softCopy = res.data.split("?")[0];
        console.log("softCopy", softCopy);
        setForm({ ...form, teamLogo: softCopy });
        const formData = new FormData();
        formData.append(selectedFile, selectedFile.name);
        console.log("Calling");
        // console.log("--------", formData);
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", selectedFile.type);
        console.log("Calling");
        props.uploadImagesUserTournament(res.data, selectedFile, myHeaders);
        console.log("Calling");
      }
    });
  };

  const fileUpload = (event: any) => {
    console.log("EVENT console", event.target.files[0]);
    let name = event.target.name;
    uploadImage(event);
    setSelectedFile(event.target.files[0]);
    //   S3Upload();
    console.log("selectedFile", selectedFile, event.target.files[0]);
  };
  const uploadImage = async (e: any) => {
    const name = e.target.name;
    const file = e.target.files[0];
    const base64 = await convertBase64(file);
    setBaseImageLogo(base64);
  };

  const convertBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  if (props.tournamentDetails !== undefined) {
    let tournamentDetails = props?.tournamentDetails?.data;
    tournamentDetails?.docs?.map((ele: any, i: any) => {
      touranmentOptions.push({
        value: ele?._id,
        label: ele?.label,
      });
    });
    let tournamentOptionsArray = touranmentOptions.filter(
      (v: any, i: any, a: any) =>
        a.findIndex((v2: any) => v2.value === v.value) === i
    );
    touranmentOptions = tournamentOptionsArray;
  }
  const closemodal = () => {
    setPlayerDetailsFlag(false);
  };
  const playersOnsubmitForm = (values: any) => {
    console.log("values form", values);
    setPlayers([...players, { ...values }]);
  };
  let HeaderData = {
    columns: [
      {
        label: " Player Name",
        field: "name",
        width: 1,
      },
      {
        label: "Email",
        field: "email",
        width: 1,
      },
      {
        label: "Phone",
        field: "phone",
        width: 2,
      },
      {
        label: "Game Rank",
        field: "gameRank",
        width: 2,
      },
      {
        label: "Discord ID",
        field: "discordId",
        width: 2,
      },
    ],
    // rows: trips,
  };

  const handleSubmitChange = (type: any) => {
    console.log("type", type);
    setForm({ ...form, [type.name]: type.value });
  };
  console.log("tournament Option", touranmentOptions);
  return (
    <>
      <Modal
        isOpen={addPlayerDetailsFlag}
        toggle={closemodal}
        size="lg"
        className="RegistrationModal"
      >
        <ModalBody>
          <PlayersAddForm
            {...props}
            playersOnsubmitForm={playersOnsubmitForm}
            closeModal={closemodal}
          />
        </ModalBody>
      </Modal>
      <div className="pageContent" >
  <div className="pageContentChild">
   <div>
   <SideBar></SideBar>
   </div>
   <div className="p-3 w-100">
   <div className="container">
          <Card className="CardUserTournament">
            <Formik
              //   validationSchema={schema}
              initialValues={form}
              onSubmit={(values) => {
                console.log("values", values);
                if ( location?.state?.tournament?._id&&form?.teamName && players) {
                  props?.addUserTournament(
                    {
                      tournamentId: location?.state?.tournament?._id,
                      teamLogo: form?.teamLogo,
                      teamName: form?.teamName,
                      users: [...players],
                    },
                    (res: any) => {
                      if (res.success) {
                        // navigator("/teams")
                        notifications({
                          title: "Success!",
                          message: res?.message,
                          type: "success",
                        });
                        navigator("/tournaments")
                        window.scrollTo(0, 0)

                      } else {
                        notifications({
                          title: "Error!",
                          message: res?.message,
                          type: "danger",
                        });
                      }
                    }
                  );
                }
              }}
            >
              {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
              }: any) => (
                <form noValidate onSubmit={handleSubmit}>
                  <label className="mb-3 text-white m-4">
                    Tournament Name : {location?.state?.tournament?.label}
                  </label>

                  <Grid container>
                  
                    <Grid item xs={12} md={6} lg={4} className="p-4">
                      <label className="mb-3 text-white">Team Name</label>
                      <input
                        type="text"
                        name="teamName"
                        onChange={(e: any) =>
                          handleSubmitChange({
                            name: "teamName",
                            value: e.target.value,
                          })
                        }
                        onBlur={handleBlur}
                        value={values.teamName || null}
                        placeholder="Team Name"
                        className="form-control inp_text text-capitalize"
                      />
                      <p className="error">
                        {errors.teamName && touched.teamName && errors.teamName}
                      </p>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4} className="p-4">
                      <label className="mb-3 text-white">Team Logo</label>
                      <div>
                        <input
                          type="file"
                          name="Image"
                          multiple={true}
                          style={{ display: "none" }}
                          ref={fileInputLogo}
                          onChange={(e) => {
                            console.log(e.target.files);
                            fileUpload({
                              target: {
                                files: e.target.files,
                              },
                            });
                          }}
                        />
                        <span
                          style={{
                            cursor: "pointer",
                            color: "#5664D2",
                          }}
                          onClick={() => fileUploadTrigger()}
                        >
                          <svg
                            style={{
                              marginTop: "10px",
                              marginLeft: "20px",
                            }}
                            width="51"
                            height="51"
                            viewBox="0 0 51 51"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M50.7061 0H0.293948C0.131605 0 0 1.60235 0 3.57895V47.4211C0 49.3977 0.131605 51 0.293948 51H50.7061C50.8684 51 51 49.3977 51 47.4211V3.57895C51 1.60235 50.8684 0 50.7061 0Z"
                              fill="#05284E"
                              fillOpacity="0.59"
                            />
                            <path
                              d="M50.7062 0.224609H0.294131C0.141934 0.224609 0.0185547 1.72681 0.0185547 3.57987V47.422C0.0185547 49.275 0.141934 50.7772 0.294131 50.7772H50.7062C50.8584 50.7772 50.9818 49.275 50.9818 47.422V3.57987C50.9818 1.72681 50.8584 0.224609 50.7062 0.224609Z"
                              fill="#05284E"
                              fillOpacity="0.59"
                            />
                            <path
                              d="M26 21V31"
                              stroke="white"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M21 26H31"
                              stroke="white"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </span>
                        <span className="ms-3">
                          {baseImageLogo ? (
                            <img
                              src={baseImageLogo}
                              alt="logo"
                              width="100px"
                              height="100px"
                            />
                          ) : (
                            ""
                          )}
                        </span>
                      </div>
                    </Grid>
                    <Grid item xs={12} className="p-4 d-flex float-end">
                      <Button
                        onClick={() => {
                          setPlayerDetailsFlag(true);
                        }}
                      >
                        Add Player Details
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      {players?.length > 0 ? (
                        <>
                          <div className="text-white mb-5">
                            <Row className="HeaderRow">
                              {HeaderData?.columns?.map((headItem, index) => {
                                return (
                                  <Col
                                    className="IndexDatatableNeoCol"
                                    key={index}
                                    style={{
                                      fontSize: "15px",
                                      fontWeight: "700",
                                      lineHeight: "18px",
                                      fontFamily: "Roboto",
                                    }}
                                  >
                                    {headItem.label}{" "}
                                  </Col>
                                );
                              })}
                            </Row>
                            <Row className="ContentRow">
                              {players?.map((ele: any, index: any) => {
                                return (
                                  <React.Fragment key={index}>
                                    <Col
                                      xs={2}
                                      className="IndexDatatableNeoCol"
                                      style={{
                                        fontSize: "15px",
                                        fontWeight: "700",
                                        lineHeight: "18px",
                                        fontFamily: "Roboto",
                                      }}
                                    >
                                      {ele?.name}
                                    </Col>
                                    <Col
                                      xs={3}
                                      className="IndexDatatableNeoCol"
                                      style={{
                                        fontSize: "15px",
                                        fontWeight: "700",
                                        lineHeight: "18px",
                                        fontFamily: "Roboto",
                                      }}
                                    >
                                      {ele?.email}
                                    </Col>
                                    <Col
                                      xs={2}
                                      className="IndexDatatableNeoCol"
                                      style={{
                                        fontSize: "15px",
                                        fontWeight: "700",
                                        lineHeight: "18px",
                                        fontFamily: "Roboto",
                                      }}
                                    >
                                      {ele?.phoneNumber}
                                    </Col>
                                    <Col
                                      xs={2}
                                      className="IndexDatatableNeoCol"
                                      style={{
                                        fontSize: "15px",
                                        fontWeight: "700",
                                        lineHeight: "18px",
                                        fontFamily: "Roboto",
                                      }}
                                    >
                                      {ele?.gameRank}
                                    </Col>
                                    <Col
                                      xs={2}
                                      className="IndexDatatableNeoCol"
                                      style={{
                                        fontSize: "15px",
                                        fontWeight: "700",
                                        lineHeight: "18px",
                                        fontFamily: "Roboto",
                                      }}
                                    >
                                      {ele?.discordId}
                                    </Col>
                                  </React.Fragment>
                                );
                              })}
                            </Row>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </Grid>
                  </Grid>

                  <button className="SubmitButton" type="submit">
                    Submit
                  </button>
                </form>
              )}
            </Formik>
          </Card>
            <InvitedPlayerUserTournament 
            {...props}
            tournamentId={location?.state?.tournament?._id}
            />
        </div>
     </div>
     </div>
     </div>
     
    </>
  );
};

const mapStateToProps = (state: any) => {
  console.log("state", state);
  return {
    ...state,
    tournamentDetails: state?.tournamentReducer?.tournamentDetail,
  };
};
export default connect(mapStateToProps, {
  addUserTournament,
  fetchTournament,
  uploadImagesUserTournament,
  fetchPresignedUrlTeamLogo,
})(TournamentUserRegistration);
