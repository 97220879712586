export const HeaderData = {
    columns: [
      {
        label: "Tournament Logo",
        field: "tournamentLogo",
        width: 3,
      },
      {
        label: "Tournament Name",
        field: "tournamentName",
        width: 3,
      },
      {
        label: "Winning Team",
        field: "winningTeam",
        width: 3,
      },
      {
        label: "Loosing Team",
        field: "loosingTeam",
        width: 3,
      },
      {
        label: "Match Date",
        field: "matchDate",
        width: 3,
      },
      {
        label: "Action",
        field: "action",
        width: 2,
      },
    ],
  };
  