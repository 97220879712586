import React, { useState, useEffect } from "react";
import { Card, Grid } from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { Row, Col } from "reactstrap";
import { SiFacebook } from "react-icons/si";
import { ImGooglePlus3 } from "react-icons/im";
import FacebookLogin from "react-facebook-login";
import notifications from "../../../components/Notifications/Notification";
import { useGoogleLogin } from "@react-oauth/google";
import jwt from "jwt-decode";
import axios from "axios";

//importing images
import Logo from "../../../assets/images/Full Logo.png";

//components
import CloseIcon from "../../../components/Icons/CloseIcon";

// importing from store
import {
  createUser,
  checkLoginUser,
  checkSocialRegistration,
} from "../../../Store/actions";

// Creating schema
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const schema = Yup.object().shape({
  fullName: Yup.string().required("Full name is required"),
  email: Yup.string()
    .required("Email is a required field")
    .email("Invalid email format"),
  phone: Yup.string()
    .required("Phone number is required")
    .matches(phoneRegExp, "Phone number is not valid"),
  password: Yup.string()
    .required("Password is a required field")
    .min(8, "Password is tooweak")
    .matches(
      /(?=^.{8,20}$)(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[^A-Za-z0-9]).*/,
      "Password must be 8-20 characters long and contain at least 1 number, 1 uppercase letter, 1 lowercase letter, and 1 special character."
    ),
  confirmPassword: Yup.string()
    .required("Confirm password is a required field")
    .min(8, " Confirm Password is tooweak")
    .oneOf(
      [Yup.ref("password")],
      "Your confirm password do not match with password."
    ),
});

const UserRegistration = (props: any) => {
  const [login, setLogin]: any = useState(false);

  useEffect(() => {}, [login]);
  const handleOnclickDiscord = () => {
    const dPopup = window.open(
      `https://discord.com/api/oauth2/authorize?response_type=token&client_id=${process.env.REACT_APP_DISCORD_CLIENTID}&scope=email&redirect_uri=${process.env.REACT_APP_DISCORD_REDIRECT_URL}`,
      "_self"
    );
  };
  let responseFacebook = (res: any) => {
    console.log("res facebook", res);
    let payload = {
      fullName: res?.name,
      email: res?.email,
      phone: 7993149590,
      type: "facebook",
    };
    // console.log("payload Fb", payload);
    if (res) {
      let payload = {
        fullName: res?.name,
        email: res?.email,
        // phone:7993149590,
        type: "facebook",
      };
      // console.log("payload", payload);
      socialRegistration(payload);
    }
  };
  const socialRegistration = (payload: any) => {
    props?.checkSocialRegistration(payload, (res: any) => {
      if (res.success) {
        notifications({
          title: "Success!",
          message: res?.message,
          type: "success",
        });
      } else {
        notifications({
          title: "Error!",
          message: res?.message,
          type: "danger",
        });
      }
    });
    if (window.location.hash) {
      window.history.replaceState("", document.title, window.location.pathname);
    }
  };
  const onSuccessGoogle = (res: any) => {
    console.log("success:", res);
    if (res) {
      let payload = {
        fullName: res?.profileObj?.name,
        email: res?.profileObj?.email,
        phone: 7993149590,
        type: "google",
      };
      // console.log("payload", payload);
      socialRegistration(payload);
    }
  };
  const onFailureGoogle = (err: any) => {
    console.log("failed:", err);
    // notifications({
    //   title: "Error!",
    //   message: err?.error,
    //   type: "danger",
    // });
  };
  const loginWithGoogle = useGoogleLogin({
    onSuccess: async (respose) => {
      console.log("respose", respose);
      try {
        const res = await axios.get(
          "https://www.googleapis.com/oauth2/v3/userinfo",
          {
            headers: {
              Authorization: `Bearer ${respose.access_token}`,
            },
          }
        );

        console.log(res.data);
        if (res?.data) {
          let payload = {
            fullName: res?.data?.given_name,
            email: res?.data?.email,
            phone: 0,
            type: "google",
          };
          // console.log("payload", payload);
          socialRegistration(payload);
        }
      } catch (err) {
        console.log(err);
      }
    },
  });
  return (
    <div className=" text-white RegistrationPage">
      <div className="">
        <Formik
          validationSchema={schema}
          initialValues={{
            fullName: "",
            email: "",
            phone: "",
            password: "",
            confirmPassword: "",
          }}
          onSubmit={(values) => {
            console.log("values", values);
            props?.createUser(
              {
                fullName: values?.fullName,
                email: values?.email,
                phone: Number(values?.phone),
                password: values?.password,
                confirmPassword: values?.confirmPassword,
              },
              (res: any) => {
                if (res.success) {
                  notifications({
                    title: "Success!",
                    message: res?.message,
                    type: "success",
                  });
                  setLogin(true);
                } else {
                  notifications({
                    title: "Error!",
                    message: res?.message,
                    type: "danger",
                  });
                }
              }
            );
          }}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
          }) => (
            <div className="login">
              <div className="form">
                <form noValidate onSubmit={handleSubmit}>
                  <div className="">
                    <div className="d-flex justify-content-center">
                      <div className="d-flex justify-content-start">
                        <img
                          className="logoImage imageLogoPos"
                          src={Logo}
                          alt="logo"
                        />
                      </div>
                      <div className="d-flex justify-content-end alignItemPopUp ">
                        <p
                          onClick={() => {
                            props?.openLoginPopUp();
                            props.closeModal();
                          }}
                          style={{
                            lineHeight: "20px",
                          }}
                          className="ps-2 pe-2 pt-1 cursor-pointer fontFuturaMedium207 textStylesLogin"
                        >
                          Login?
                          {/* </Link> */}
                        </p>
                        <CloseIcon onClickIcon={props.closeModal} />
                      </div>
                    </div>
                  </div>
                  {login ? (
                    <div className="fontRoboto147 verfiyEmailDiv">
                      You have registered successfully!. Please verify your
                      registered email for account activation
                    </div>
                  ) : (
                    <>
                      <div className="formLoginCard">
                        <span className="spanHeading">
                          SIGN UP TO GAMINGGEEKS
                        </span>
                        <div className="InputDiv">
                          <input
                            type="text"
                            name="fullName"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.fullName}
                            placeholder="Full Name"
                            className="form-control inp_text text-capitalize"
                          />
                          <p className="error">
                            {errors.fullName &&
                              touched.fullName &&
                              errors.fullName}
                          </p>
                          <input
                            type="email"
                            name="email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                            placeholder="Enter email"
                            className="form-control inp_text"
                            id="email"
                          />
                          <p className="error">
                            {errors.email && touched.email && errors.email}
                          </p>
                          <input
                            {...props}
                            type="text"
                            name="phone"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.phone}
                            placeholder="Phone"
                            className="form-control inp_text"
                            id="phone"
                            maxLength="10"
                            pattern="\d{10}"
                          />
                          <p className="error">
                            {errors.phone && touched.phone && errors.phone}
                          </p>
                          <input
                            type="password"
                            name="password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.password}
                            placeholder="Enter password"
                            className="form-control"
                          />
                          <p className="error">
                            {errors.password &&
                              touched.password &&
                              errors.password}
                          </p>
                          <input
                            type="password"
                            name="confirmPassword"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.confirmPassword}
                            placeholder="Confirm password"
                            className="form-control"
                          />
                          <p className="error">
                            {errors.confirmPassword &&
                              touched.confirmPassword &&
                              errors.confirmPassword}
                          </p>
                          <button className="signUPButton" type="submit">
                            Submit
                          </button>
                        </div>
                      </div>
                      {/* <span className="d-flex justify-content-center marginTopOr">
                        <hr className="w-25"></hr>
                        <span className="pl5 pr5 pt5">Or</span>
                        <hr className="w-25"></hr>
                      </span> */}
                      {/* <Card className="iconCard ">
                        <Row className="RowPadding">
                          <Col xs={2}></Col>
                          <Col xs={4} className="ps-2">
                            <FacebookLogin
                              {...props}
                              disableMobileRedirect={true}
                              autoLoad={false}
                              appId={process.env.REACT_APP_FB_ID}
                              returnScopes={false}
                              fields="name,email,picture"
                              scope="public_profile, email"
                              callback={responseFacebook}
                              icon={
                                <SiFacebook className="faceBookIcon cursor-pointer" />
                              }
                              reAuthenticate={false}

                            />
                          </Col>
                        
                          <Col xs={4} className="GoogleIconButton">
                            <ImGooglePlus3
                              className="googleIcon"
                              onClick={() => loginWithGoogle()}
                            />
                          </Col>
                          <Col xs={2}></Col>
                        </Row>
                      </Card> */}
                    </>
                  )}
                </form>
              </div>
            </div>
          )}
        </Formik>
      </div>
    </div>
  );
};
const mapStateToProps = (state: any) => {
  // console.log("state", state);
  return {
    ...state,
  };
};
export default connect(mapStateToProps, {
  createUser,
  checkLoginUser,
  checkSocialRegistration,
})(UserRegistration);
