import React, { useState, useEffect } from "react";
import { Card, Row, Col } from "reactstrap";
import { Grid } from "@mui/material";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { FiArrowLeft } from "react-icons/fi";
// store
import { getUsers } from "../.././Store/user/actions";
import { fetchTournament } from "../../Store/tournament/actions";
import { fetchTeams } from "../../Store/tournament/actions";
import {
  sendingEmail,
  storingEmailsWithSaveToGroup,
} from "../../Store/user/actions";

// components
import Loader from "../../components/Loader/Loader";
import SideBar from "../../components/verticalMenu/SideBar";
import notifications from "../../components/Notifications/Notification";
import CloseIcon from "../../components/Icons/CloseIcon";

//global variables
let usersOptionsData: any = [];
let usersOptionsDataEmails: any = [];
let teamsOptions: any = [];
let tournamentOptionsData: any = [];
let teamUsersOptions: any = [];

const SendingMail = (props: any) => {
  const navigator = useNavigate();
  const location = useLocation();
  const groupMails = location?.state?.groupMails;
  console.log("location", location);
  const [loading, setLoading] = useState(false);

  const [saveToGroup, setSaveToGroup]: any = useState("no");
  const [saveToGroupValue, setSaveToGroupValue]: any = useState("");

  const [form, setForm]: any = useState({
    emails: [...groupMails?.emails],
    subject: groupMails?.subject,
    body: groupMails?.body,
  });
  const [emails, setEmails]: any = useState([]);
  const [emails1, setEmails1]: any = useState([]);
  // calling api's
  useEffect(() => {
    console.log("form", form);
  }, [form]);
  const handleChange = (type: any) => {
    if (type.name === "subject") {
      setForm({ ...form, [type.name]: type?.value });
    } else if (type.name === "body") {
      setForm({ ...form, [type.name]: type?.value });
    }
  };
  const onSubmit = () => {
    if (form?.subject && form?.body && (emails || emails1)) {
      let payload = {
        emails: form?.emails,
        subject: form?.subject,
        body: form?.body,
      };
      console.log("payload", payload);
      props.sendingEmail(payload, (res: any) => {
        if (res.success) {
          notifications({
            title: "Success!",
            message: res?.message,
            type: "success",
          });
        } else {
          notifications({
            title: "Error!",
            message: res?.message,
            type: "danger",
          });
        }
      });
    }
  };

  return (
    <>
      <div className="pageContent">
        <div className="pageContentChild">
          <div>
            <SideBar></SideBar>
          </div>
          <div className="p-3 w-100">
            <div>
              <Link to="/get-saved-mails">
                <button className="m-4">
                  <FiArrowLeft className="cursor-pointer editIcon " />
                </button>
              </Link>
            </div>
            <h3 className=" mb-4">Sending Mails</h3>
            <div></div>

            <Card className="CardMail">
              <div>
                <Row>
                  <Col xs={12} className="m-3">
                    <h5>To:</h5>
                    <div className="selectArea">
                    <span className="emailBox">
                        {form?.emails.map((email: any, i: any) => {
                          return (
                            <>
                              <span className="emailSpan">{email}</span>
                            </>
                          );
                        })}
                        {/* </span>
                    <span> */}
                        {emails1.map((email: any, i: any) => {
                          return (
                            <>
                              <span className="emailSpan">{email}</span>
                            </>
                          );
                        })}
                      </span>
                    </div>
                  </Col>
                  <Col xs={12} className="m-3">
                    <div></div>
                    <h5>Subject:
                    <span
                        style={{
                          color: "red",
                        }}
                      >
                        *
                      </span>
                    </h5>

                    <div className="subjectInput">
                      <input
                        className=""
                        type="text"
                        placeholder="Subject..."
                        value={form?.subject}
                        onChange={(e: any) => {
                          handleChange({
                            name: "subject",
                            value: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </Col>
                  <Col xs={12} className="m-3">
                    <h5 className="mb-2">
                      Body:{" "}
                      <span
                        style={{
                          color: "red",
                        }}
                      >
                        *
                      </span>
                    </h5>
                    <div className="bodyInput">
                      <textarea
                        {...props}
                        className=""
                        placeholder="Body..."
                        value={form?.body}
                        onChange={(e: any) => {
                          handleChange({
                            name: "body",
                            value: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </Col>
                  <Col xs={12} className="m-3">
                    <button
                      className="sendButton"
                      onClick={() => {
                        onSubmit();
                      }}
                    >
                      send
                    </button>
                  </Col>
                </Row>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  console.log("state", state);
  return {
    ...state,
    userDetails: state?.userReducer?.usersDetails,
    getTournamentDetails: state?.tournamentReducer?.tournamentDetail,
    teamDetails: state?.tournamentReducer?.fetchTeams,
  };
};
export default connect(mapStateToProps, {
  getUsers,
  fetchTournament,
  fetchTeams,
  sendingEmail,
  storingEmailsWithSaveToGroup,
})(SendingMail);
