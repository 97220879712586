import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import { Row, Col, Input } from "reactstrap";
import { connect } from "react-redux";
import { Grid, Pagination, Stack } from "@mui/material";
import { DatePicker } from "rsuite";
import { Modal, ModalBody, ModalHeader, ModalFooter, Button } from "reactstrap";
//images
import ActionVerticalButton from "../../assets/images/more-vertical.png";

//store
import {
  fetchTournament,
  deleteTournament,
} from "../../Store/tournament/actions";
import { fetchGame } from "../../Store/game/actions";

//components
import { HeaderData } from "./HeaderData";
import CloseIcon from "../../components/Icons/CloseIcon";
import Index from "../../components/common/index";
import Loader from "../../components/Loader/Loader";
import notifications from "../../components/Notifications/Notification";
import SideBar from "../../components/verticalMenu/SideBar";
import moment from "moment";

var offset: number = 0;
var limit: number = 10;
var totCnt = 0;
var totPages = 0;
var toVal = 0;
var fromVal = 0;
let gameOptionsArry: any = [];

const Tournament = (props: any) => {
  const [tournamentNameFilter, setTournamentNameFilter]: any = useState("");
  const [gameIdFilter, setGameIdFilter]: any = useState("");
  const [startDateValue, setStartDate]: any = useState(null);
  const [startDateNewValue, setStartDateNewValue]: any = useState(null);
  const [endDateValue, setEndDate]: any = useState(null);
  const [endDateNewValue, setEndDateNewValue]: any = useState(null);
  const [resetFlag, setResetFlag]: any = useState(false);
  const [loading, setLoading] = useState(false);
  const [warningModal, setWarningModal]: any = useState(false);
  const [deleteContent, setDeleteContent]: any = useState("");
  const navigator = useNavigate();
  useEffect(() => {
    getAllTournamentData();
    fetchGames();
  }, []);
  useEffect(() => {
    if (
      gameIdFilter ||
      startDateValue ||
      // tournamentNameFilter ||
      startDateNewValue ||
      endDateNewValue ||
      endDateValue ||
      (!gameIdFilter &&
        !startDateValue &&
        // !tournamentNameFilter &&
        resetFlag &&
        !endDateValue &&
        !endDateNewValue &&
        !startDateNewValue)
    ) {
      getAllTournamentData();
    }
  }, [
    gameIdFilter,
    startDateValue,
    resetFlag,
    startDateNewValue,
    endDateNewValue,
    endDateValue,
  ]);

  const handleReset = () => {
    setTournamentNameFilter("");
    setGameIdFilter("");
    setStartDate("");
    setStartDateNewValue("");
    setEndDate("");
    setEndDateNewValue("");
    setResetFlag(true);
    localStorage.removeItem("TournamentGameFilter");
    localStorage.removeItem("TournamentNameFilter");
    localStorage.removeItem("TournamentStartDateFilter");
    localStorage.removeItem("TournamentEndDateFilter");
    localStorage.removeItem("TournamentOffset");
  };

  const fetchGames = () => {
    props.fetchGame(offset, 10, {}, (res: any) => {});
  };

  let getAllTournamentData = () => {
    setLoading(true);
    let offset = 0;
    let localTournamentStartDateFilter: any = localStorage.getItem(
      "TournamentStartDateFilter"
    );
    let locTournamentStartDateFilter: any = JSON.parse(
      localTournamentStartDateFilter
    );
    let localTournamentEndDateFilter: any = localStorage.getItem(
      "TournamentEndDateFilter"
    );
    let locTournamentEndDateFilter: any = JSON.parse(
      localTournamentEndDateFilter
    );
    let localTournamentNameFilter: any = localStorage.getItem(
      "TournamentNameFilter"
    );
    let locTournamentNameFilter: any = JSON.parse(localTournamentNameFilter);
    let localTournamentgameFilter: any = localStorage.getItem(
      "TournamentGameFilter"
    );
    let localGameFilter: any = JSON.parse(localTournamentgameFilter);
    console.log(localGameFilter);
    let filters = {
      name: locTournamentNameFilter
        ? locTournamentNameFilter
        : tournamentNameFilter,
      gameId: localGameFilter ? localGameFilter?.value : gameIdFilter?.value,
      startDate: locTournamentStartDateFilter
        ? locTournamentStartDateFilter
        : startDateValue,
      endDate: locTournamentEndDateFilter
        ? locTournamentEndDateFilter
        : endDateValue,
    };
    let localTourOffset: any = localStorage.getItem("TournamentOffset");
    let localTourOffsetF: any = JSON.parse(localTourOffset);
    props.fetchTournament(
      localTourOffsetF ? localTourOffsetF : offset,
      limit,
      filters,
      (res: any) => {
        setLoading(false);
      }
    );
  };
  let deleteTournament = (tournament: any) => {
    setLoading(true);
    props?.deleteTournament({ tournamentId: tournament?._id }, (res: any) => {
      setLoading(false);
      if (res.success) {
        getAllTournamentData();
        notifications({
          title: "Success!",
          message: res?.message,
          type: "success",
        });
      } else {
        notifications({
          title: "Error!",
          message: res?.message,
          type: "danger",
        });
      }
    });
  };
  let handleChangePage = (event: any, val?: any) => {
    console.log("val", val);
    offset = (val - 1) * 10;
    localStorage.setItem("TournamentOffset", JSON.stringify(offset));
    toVal = offset + 10;
    setLoading(true);
    props.fetchTournament(
      offset,
      limit,
      {
        name: tournamentNameFilter,
        gameId: gameIdFilter?.value,
        startDate: startDateValue,
        endDate: endDateValue,
      },
      (res: any) => {
        setLoading(false);
      }
    );
  };
  let auth: any =
    localStorage.getItem("authUser") || sessionStorage.getItem("authUser");
  let authUser: any = JSON.parse(auth) || "";
  let action = (tournament: any) => {
    let currentDate = new Date();
    let tommorowsDate = new Date(currentDate);

    let tommrrow = new Date(tommorowsDate.setDate(currentDate.getDate() + 1));
    let tomorrowDate = moment(tommrrow).format("yyyy-MM-DD");
    let tournamentStartDate = moment(new Date(tournament?.startDate)).format(
      "yyyy-MM-DD"
    );
    let currentDateForm = moment(new Date()).format("yyyy-MM-DD");

    console.log(
      "tomorrow's date",
      tomorrowDate,
      tournamentStartDate,
      currentDateForm,
      tournamentStartDate === tomorrowDate
    );
    return (
      <>
        <div className="dropdown">
          <img
            style={{ margin: "-4px" }}
            src={ActionVerticalButton}
            alt="actions"
          />
          <div className="dropdown-content">
            {(tournamentStartDate === tomorrowDate ||
            tournamentStartDate < currentDateForm) ? (
              <div className="action-button">
                <span>
                  <button
                    onClick={() => {
                      navigator("/add-video-tag", {
                        state: {
                          tournament: tournament,
                        },
                      });
                      window.scrollTo(0, 0)

                    }}
                  >
                    Add Videos
                  </button>
                </span>
              </div>
            ) : (
              ""
            )}

            <div className="action-button">
              <span>
                <button
                  onClick={() => {
                    navigator("/teams", {
                      state: {
                        tournament: tournament,
                      },
                    });
                    window.scrollTo(0, 0)

                  }}
                >
                  View Teams
                </button>
              </span>
            </div>
            <div
              onClick={() => {
                navigator(`/tournament/${tournament?._id}`, {
                  state: {
                    tournamentDetails: tournament,
                  },
                });
                window.scrollTo(0, 0)

              }}
              className="action-button"
            >
              View Details
            </div>
            <div
              className="action-button"
              onClick={() => {
                navigator(`/edit-tournament/${tournament?._id}`, {
                  state: {
                    tournament: tournament,
                    mode: "edit",
                  },
                });
                window.scrollTo(0, 0)

              }}
            >
              <span className="">Edit Details</span>
            </div>
            <div
              onClick={() => {
                setWarningModal(true);
                setDeleteContent(tournament);
              }}
              className="action-button"
            >
              <span>Delete</span>
            </div>
          </div>
        </div>
      </>
    );
  };
  const handleChangeName = (type: any) => {
    console.log("type", type);
    if ((type.name = "tournamentName")) {
      setTournamentNameFilter(type?.value);
      localStorage.setItem("TournamentNameFilter", JSON.stringify(type?.value));
    }
  };
  const handleKeyDown = (event: any) => {
    console.log("event", event.target);
    if (event.key === "Enter") {
      getAllTournamentData();
    }
  };
  const handleSubmitChange = (type: any) => {
    setGameIdFilter(type?.value);
    localStorage.setItem("TournamentGameFilter", JSON.stringify(type?.value));
  };
  const handleOnInputChangeGame = (e: any) => {
    if (e.length > 2) {
      props.fetchGame(0, 10, { name: e }, (res: any) => {});
    }
  };
  const handleSubmitChangeStartDate = (type: any) => {
    if (type?.name === "startDate") {
      setStartDateNewValue(type?.value);
      setStartDate(moment(type?.value).format("yyyy-MM-DD"));
      localStorage.setItem(
        "TournamentStartDateFilter",
        JSON.stringify(moment(type?.value).format("yyyy-MM-DD"))
      );
    } else if (type?.name === "endDate") {
      setEndDateNewValue(type?.value);
      setEndDate(moment(type?.value).format("yyyy-MM-DD"));
      localStorage.setItem(
        "TournamentEndDateFilter",
        JSON.stringify(moment(type?.value).format("yyyy-MM-DD"))
      );
    }
  };
 
  let data: any = [];

  if (props?.getTournamentDetails !== undefined) {
    let getTournamentsDetails = props?.getTournamentDetails?.data;
    offset = getTournamentsDetails?.offset;
    totCnt = getTournamentsDetails?.length;
    fromVal = totCnt !== 0 ? offset + 1 : fromVal;
    toVal = getTournamentsDetails?.offset + getTournamentsDetails?.docs?.length;
    totPages = Math.ceil(totCnt / 10);
    data = [];
    props?.getTournamentDetails?.data?.docs?.map((tournament: any, i: any) => {
      data.push({
        // id:tournament?._id,
        logo: (
          <a href={tournament?.logo} target="_blank" rel="noopener noreferrer">
            <img
              className="mb-2"
              src={tournament?.logo}
              width="30px"
              height="30px"
              alt="logo"
            />
            <div>View Logo</div>
          </a>
        ),
        label: tournament?.label,
        format: tournament?.format,
        game: tournament?.gameInfo?.label,
        playerInTeam: tournament?.playerInTeam,
        teamSize: tournament?.teamSize,
        rule: tournament?.rules ? (
          <a href={tournament?.rules} target="_blank" rel="noopener noreferrer">
            View Rules
          </a>
        ) : (
          "N/A"
        ),
        startDate: tournament?.startDate
          ? moment(new Date(tournament?.startDate)).format("YYYY-MM-DD")
          : "N/A",
        endDate: tournament?.endDate
          ? moment(new Date(tournament?.endDate)).format("YYYY-MM-DD")
          : "N/A",
        action: action(tournament),
      });
    });
    console.log("data", data);
  }
  gameOptionsArry = [];
  if (props.getGamesDetails !== undefined) {
    let getGamesDetails = props?.getGamesDetails?.data;
    getGamesDetails?.docs?.map((ele: any, i: any) => {
      gameOptionsArry.push({
        value: ele?._id,
        label: ele?.label,
      });
    });

    console.log("test", gameOptionsArry);
  }
  const warningToggle = () => {
    setWarningModal(false);
  };
  return (
    <>
      <Modal
        isOpen={warningModal}
        toggle={warningToggle}
        size="lg"
        className=""
      >
        <ModalHeader>
          <div className="d-flex">
            <h4 style={{ color: "red" }}> WARNING</h4>
            <span className="closeIconTou">
              <CloseIcon onClickIcon={() => warningToggle()} />
            </span>
          </div>
        </ModalHeader>
        <ModalBody>
          <div>Are you sure want to delete Tournament?</div>
          {/* <button color="warning" onClick={() => warningToggle()}>
            Close
          </button> */}
        </ModalBody>
        <ModalFooter>
          <Button color="warning" onClick={() => warningToggle()}>
            Close
          </Button>
          <Button
            color="danger"
            onClick={() => {
              if (warningModal && deleteContent) {
                deleteTournament(deleteContent);
                getAllTournamentData();
                warningToggle();
              }
            }}
          >
            Continue
          </Button>
        </ModalFooter>
      </Modal>
      <div className="pageContent">
        <div className="pageContentChild">
          <div>
            <SideBar></SideBar>
          </div>
          <div className="p-3 w-100">
            <Row>
              <Col xs={12}>
                {authUser?.role === "Admin" ? (
                  <Link
                    to="/create-tournament"
                    className="cursor-pointer float-end me-5"
                  >
                    <Button>Add Tournament</Button>
                  </Link>
                ) : (
                  ""
                )}
              </Col>
              <Col xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} className="text-white mt-4">
                    {/* <div> Tournament Name</div> */}
                    <Row className="mb-5">
                      <Col xs={12} md={3} lg={3} className="p-3">
                        <Select
                          className="Game_selector"
                          classNamePrefix="react-select"
                          placeholder="Select Game..."
                          autoFocus={true}
                          // onBlur={handleBlur}
                          isSearchable
                          name="gameName"
                          id="gameName"
                          value={gameIdFilter}
                          options={gameOptionsArry}
                          onChange={(e: any) => {
                            // console.log("e test", e);
                            handleSubmitChange({
                              name: "gameId",
                              value: e,
                            });
                          }}
                          onInputChange={(e) => handleOnInputChangeGame(e)}
                        />
                      </Col>
                      <Col xs={12} md={2} lg={3} className="d-flex p-3">
                        <Input
                          className="form-control"
                          type="text"
                          name="tournamentName"
                          placeholder="Search By Tournament Name..."
                          aria-label="Search"
                          value={tournamentNameFilter}
                          onKeyDown={(event: any) => handleKeyDown(event)}
                          onChange={(e) => {
                            console.log("e", e.target.value);
                            handleChangeName({
                              name: "tournamentName",
                              value: e.target.value,
                            });
                          }}
                        />
                        <svg
                          onClick={() => getAllTournamentData()}
                          className="SearchIcon cursor-pointer"
                          width="40"
                          height="20"
                          viewBox="0 0 14 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6.4 11.8C9.38234 11.8 11.8 9.38234 11.8 6.4C11.8 3.41766 9.38234 1 6.4 1C3.41766 1 1 3.41766 1 6.4C1 9.38234 3.41766 11.8 6.4 11.8Z"
                            stroke="#FFF"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M12.9992 13L11.1992 11.2"
                            stroke="#FFF"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </Col>
                      <Col xs={12} md={3} lg={2} className="p-3">
                        <DatePicker
                          style={{
                            width: "100%",
                          }}
                          format="dd MMM yy"
                          placeholder="Start Date..."
                          name="StartDate"
                          id="StartDate"
                          cleanable={false}
                          value={startDateNewValue}
                          onChange={(newValue: any) => {
                            console.log("newValue", newValue);
                            handleSubmitChangeStartDate({
                              name: "startDate",
                              value: newValue,
                            });
                          }}
                        />
                      </Col>
                      <Col xs={12} md={3} lg={2} className="p-3">
                        <DatePicker
                          style={{
                            width: "100%",
                          }}
                          format="dd MMM yy"
                          placeholder="End Date..."
                          name="endDate"
                          id="EndDate"
                          cleanable={false}
                          value={endDateNewValue}
                          onChange={(newValue: any) => {
                            console.log("newValue", newValue);
                            handleSubmitChangeStartDate({
                              name: "endDate",
                              value: newValue,
                            });
                          }}
                        />
                      </Col>
                      <Col xs={12} md={1} lg={2} className="p-3">
                        {" "}
                        <button
                          onClick={() => handleReset()}
                          style={{
                            color: "black",
                            background: "Green",
                            height: "40px",
                            width: "100%",
                            padding: "10px 20px",
                          }}
                        >
                          Reset
                        </button>
                      </Col>
                    </Row>
                    {loading ? (
                      <>
                        <div>
                          <Loader />
                        </div>
                      </>
                    ) : (
                      <>
                        <h3 className="mt-4 mb-4">Tournament Details</h3>
                        <Index
                          data={data}
                          headerData={HeaderData}
                          limit={limit}
                          rowHeight={"60px"}
                        />
                        {totCnt ? (
                          <>
                            <div className="mt-4 mb-5">
                              <Row>
                                <Col xs={6} className="text-white">
                                  <Stack spacing={2}>
                                    <Pagination
                                      className="pagination"
                                      size="small"
                                      shape="rounded"
                                      page={offset / 10 + 1}
                                      count={totPages}
                                      color="primary"
                                      // onChange={(e)=>{handleChangePage(e,offset / 10 + 1)}}
                                      onChange={(e, val) => {
                                        handleChangePage(e, val);
                                        console.log("event", e, val);
                                      }}
                                      defaultPage={1}
                                      siblingCount={1}
                                    />
                                  </Stack>
                                </Col>
                                <Col xs={6}>
                                  <span className="text-white">
                                    Showing {fromVal} to {toVal} of {totCnt}{" "}
                                    entries
                                  </span>
                                </Col>
                              </Row>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                      </>
                    )}
                  </Grid>
                </Grid>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state: any) => {
  console.log("state", state);
  return {
    ...state,
    getGamesDetails: state?.gameReducer?.gameDetail,
    getTournamentDetails: state?.tournamentReducer?.tournamentDetail,
  };
};
export default connect(mapStateToProps, {
  fetchGame,
  fetchTournament,
  deleteTournament,
})(Tournament);
