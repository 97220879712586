import React, { useEffect, useState } from "react";
import { Row, Col, Input } from "reactstrap";
import { connect } from "react-redux";
import { Grid, Pagination, Stack } from "@mui/material";
import { Modal, ModalBody ,ModalHeader} from "reactstrap";
import { useNavigate } from "react-router-dom";
import ActionVerticalButton from "../../assets/images/more-vertical.png";

//components
import { HeaderData } from "./HeaderData";
import SideBar from "../../components/verticalMenu/SideBar";
import notifications from "../../components/Notifications/Notification";
import AddGameOrEdit from "./AddOrEditGame";
import Loader from "../../components/Loader/Loader";
import Index from "../../components/common/index";
// store
import {
  fetchGame,
  deleteGame,
  addGame,
  updateGame,
} from "../../Store/game/actions";

//global variables
var offset: number = 0;
var limit: number = 10;
var totCnt = 0;
var totPages = 0;
var toVal = 0;
var fromVal = 0;

const Games = (props: any) => {
  const [OpenAddGame, setAddGame] = useState(false);
  const [gameDetails, setGameDetails]: any = useState({});
  const [gameNameFilter, setGameNameFilter]: any = useState("");
  const [loading, setLoading] = useState(false);

  const navigator = useNavigate();
  // calling api
  useEffect(() => {
    getAllGamesData();
  }, []);
  let getAllGamesData = () => {
    setLoading(true);
    let offset = 0;
    let filters = {
      name: gameNameFilter,
    };
    props.fetchGame(offset, limit, filters, (res: any) => {
      setLoading(false);
    });
  };

  // reset
  const handleReset = () => {
    setGameNameFilter("");
    setLoading(true);
    props.fetchGame(offset, limit, {}, (res: any) => {
      setLoading(false);
    });
  };
  //filters
  const handleChangeName = (type: any) => {
    console.log("type", type);
    if ((type.name = "name")) {
      setGameNameFilter(type?.value);
    }
  };
  const handleKeyDown = (event: any) => {
    console.log("event", event.target);
    if (event.key == "Enter") {
      getAllGamesData();
    }
  };

  // updateGame
  const handleSubmitUpdateGame = (values: any) => {
    setLoading(true);
    props?.updateGame(values, (res: any) => {
      setLoading(false);
      if (res.success) {
        notifications({
          title: "Success!",
          message: res?.message,
          type: "success",
        });
        closeModal();
        getAllGamesData();
        setGameDetails("");
      } else {
        notifications({
          title: "Error!",
          message: res?.message,
          type: "danger",
        });
      }
    });
  };
  // create game
  const handleSubmitCreateGame = (values: any) => {
    setLoading(true);
    props?.addGame(values, (res: any) => {
      setLoading(false);
      if (res.success) {
        notifications({
          title: "Success!",
          message: res?.message,
          type: "success",
        });
        closeModal();
        getAllGamesData();
      } else {
        notifications({
          title: "Error!",
          message: res?.message,
          type: "danger",
        });
      }
    });
  };
  const openAddGameModal = () => {
    setAddGame(true);
  };

  const closeModal = () => {
    setAddGame(false);
    setGameDetails("")
  };
  // delete game
  let deleteGame = (game: any) => {
    setLoading(true);
    props?.deleteGame({ id: game?._id }, (res: any) => {
      setLoading(false);
      if (res.success) {
        getAllGamesData();
        notifications({
          title: "Success!",
          message: res?.message,
          type: "success",
        });
      } else {
        notifications({
          title: "Error!",
          message: res?.message,
          type: "danger",
        });
      }
    });
  };
  let action = (game: any) => {
    return (
      <>
        <div className="dropdown">
          <img
            style={{ margin: "-4px" }}
            src={ActionVerticalButton}
            alt="actions"
          />
          <div className="dropdown-content">
            <div
              className="action-button"
              onClick={() => {
                navigator(`/game/${game?._id}`, {
                  state: {
                    gameDetails: game,
                  },
                });
                window.scrollTo(0, 0)
              }}
            >
              View Details
            </div>
            <div
              className="action-button"
              onClick={() => {
                openAddGameModal();
                setGameDetails(game);
              }}
            >
              Edit Details
            </div>
            <div className="action-button" onClick={() => deleteGame(game)}>
              Delete
            </div>
          </div>
        </div>
      </>
    );
  };
  // handle page
  let handleChangePage = (event: any, val?: any) => {
    console.log("val", val);
    offset = (val - 1) * 10;
    toVal = offset + 10;
    setLoading(true);
    props.fetchGame(offset, limit, { name: gameNameFilter }, (res: any) => {
      setLoading(false);
    });
  };
  let data: any = [];

  if (props.getGamesDetails !== undefined) {
    let getGamesDetails = props?.getGamesDetails?.data;
    console.log("getGamesDetails", getGamesDetails, totPages);
    offset = getGamesDetails?.offset;
    totCnt = getGamesDetails?.length;
    fromVal = totCnt !== 0 ? offset + 1 : fromVal;
    toVal = getGamesDetails?.offset + getGamesDetails?.docs?.length;
    totPages = Math.ceil(totCnt / 10);
    getGamesDetails?.docs?.map((game: any, i: any) => {
      data.push({
        id: game?._id,
        label: game?.label,
        // active: game?.isActive ? "True" :"False",
        // delete:game?.isDeleted ? "True" :"False",
        action: action(game),
      });
    });
    console.log("data", data);
  }
  return (
    <>
      <Modal
       isOpen={OpenAddGame}
       toggle={openAddGameModal}
        size="lg"
        className="WhatsAppBulkModal"
      >
        <ModalHeader>
          <div className="Heading">
         
            <h3> {gameDetails?.label ? "Edit Game" : " ADD GAME"}</h3>
            <svg
              onClick={() => closeModal()}
              width="20"
              height="20"
              viewBox="0 0 9 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8 1L1 8"
                stroke="#C59C6C"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M1 1L8 8"
                stroke="#C59C6C"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </ModalHeader>
        <ModalBody>
        <AddGameOrEdit
            {...props}
            closeModal={closeModal}
            getAllGamesData={getAllGamesData}
            gameDetails={gameDetails}
            handleSubmitUpdateGame={handleSubmitUpdateGame}
            handleSubmitCreateGame={handleSubmitCreateGame}
          />
        </ModalBody>
      </Modal>
      
      <div className="pageContent">
        <div className="pageContentChild">
          <div>
            <SideBar></SideBar>
          </div>
          <div className="p-3 w-100">
            <Row>
              <Col xs={12}>
                <button
                  className="float-end me-4 pe-4 cursor-pointer p-2"
                  onClick={() => {
                    openAddGameModal();
                  }}
                >
                  Add Game
                </button>
              </Col>
              <Col xs={12}>
                <Grid container spacing={2} className="me-5">
                  <Grid item xs={12} className="text-white mt-4">
                    <Row className="mb-3">
                      <Col xs={12} md={6} lg={4} className="d-flex p-3">
                        <Input
                          className="form-control"
                          type="text"
                          name="gameName"
                          placeholder="Search By Game Name..."
                          aria-label="Search"
                          value={gameNameFilter}
                          onKeyDown={(event: any) => handleKeyDown(event)}
                          onChange={(e) => {
                            console.log("e", e.target.value);
                            handleChangeName({
                              name: "gameName",
                              value: e.target.value,
                            });
                          }}
                        />
                        <svg
                          onClick={() => getAllGamesData()}
                          className="SearchIcon"
                          width="40"
                          height="20"
                          viewBox="0 0 14 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6.4 11.8C9.38234 11.8 11.8 9.38234 11.8 6.4C11.8 3.41766 9.38234 1 6.4 1C3.41766 1 1 3.41766 1 6.4C1 9.38234 3.41766 11.8 6.4 11.8Z"
                            stroke="#FFF"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M12.9992 13L11.1992 11.2"
                            stroke="#FFF"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </Col>

                      <Col xs={6} md={2} className="p-3">
                        <button
                          onClick={() => handleReset()}
                          style={{
                            color: "black",
                            background: "Green",
                            height: "40px",
                            width: "100%",
                            padding: "10px 20px",
                          }}
                        >
                          Reset
                        </button>
                      </Col>
                    </Row>
                    {loading ? (
                      <>
                        <div>
                          <Loader />
                        </div>
                      </>
                    ) : (
                      <>
                        <h3 className=" mb-4">Games</h3>
                        <Index
                          data={data}
                          headerData={HeaderData}
                          limit={limit}
                        />
                        {totCnt ? (
                          <>
                            <div className=" mt-4">
                              <Row>
                                <Col xs={6} className="text-white">
                                  <Stack spacing={2}>
                                    <Pagination
                                      className="pagination"
                                      size="small"
                                      shape="rounded"
                                      page={offset / 10 + 1}
                                      count={totPages}
                                      color="primary"
                                      // onChange={(e)=>{handleChangePage(e,offset / 10 + 1)}}
                                      onChange={(e, val) => {
                                        handleChangePage(e, val);
                                        console.log("event", e, val);
                                      }}
                                      defaultPage={1}
                                      siblingCount={1}
                                    />
                                  </Stack>
                                </Col>
                                <Col xs={6}>
                                  <span className="text-white">
                                    Showing {fromVal} to {toVal} of {totCnt}{" "}
                                    entries
                                  </span>
                                </Col>
                              </Row>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                      </>
                    )}
                  </Grid>
                </Grid>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  console.log("state", state);
  return {
    ...state,
    getGamesDetails: state?.gameReducer?.gameDetail,
  };
};
export default connect(mapStateToProps, {
  fetchGame,
  deleteGame,
  addGame,
  updateGame,
})(Games);
