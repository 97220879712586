export const HeaderData = {
  columns: [
    {
      label: "Identification Name",
      field: "identifincationName",
      width: 3,
      sort: true,
    },

    {
      label: "Action",
      field: "action",
      width: 2,
    },
  ],
};
