import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import { types } from "./actionTypes";
import  { AxiosResponse } from "axios";

//medthods
import {
  postApi,
  putApi,
  getApi,
  deleteApi,
} from "../.././helpers/auth_helpers";

// workers
function* workCreateWhatsAppBulk({ payload, callback }: any) {
  try {
    const url: string = process.env.REACT_APP_SERVERURL + "/whatsappBulk/create";
    const response: AxiosResponse = yield call(postApi, url, payload);
    callback(response);
    // console.log("users",response)
    yield put({
      type: types.CREATE_WHATSAPP_BULK_DETAILS_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workGetWhatsAppBulk({ offset, limit, filters, callback }: any) {
  let name = filters?.name ? filters?.name : "";
  try {
    const url: string =
      process.env.REACT_APP_SERVERURL +
      "/whatsappBulk/whatsAppBulk?offset=" +
      offset +
      "&limit=" +
      limit +
      "&sort=-1"+
      "&templateName=" +
      name;
    const response: AxiosResponse = yield call(getApi, url);
    callback(response);
    // console.log("users",response)
    yield put({
      type: types.GET_WHATSAPP_BULK_DETAILS_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workUpdateWhatsAppBulk({ payload, callback }: any) {
  try {
    const url: string = process.env.REACT_APP_SERVERURL + "/whatsappBulk/update";
    const response: AxiosResponse = yield call(putApi, url, payload);
    callback(response);
    // console.log("users",response)
    yield put({
      type: types.UPDATE_WHATSAPP_BULK_DETAILS_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workDeleteWhatsAppBulk({ payload, callback }: any) {
  try {
    const url: string = process.env.REACT_APP_SERVERURL + "/whatsappBulk/delete";
    const response: AxiosResponse = yield call(deleteApi, url, payload);
    callback(response);
    // console.log("users",response)
    yield put({
      type: types.DELETE_WHATSAPP_BULK_DETAILS_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workSendWhatsAppBulk({ payload, callback }: any) {
  try {
    const url: string = process.env.REACT_APP_SERVERURL + "/whatsappBulk/sendBulkNotification";
    const response: AxiosResponse = yield call(postApi, url, payload);
    callback(response);
    // console.log("users",response)
    yield put({
      type: types.SEND_WHATSAPP_BULK_DETAILS_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workFetchPresignedUrlExcel({ callback }: any) {
  try {
    const url: string =
      process.env.REACT_APP_SERVERURL + "/upload/whatsapp/excelURL";
    const response: AxiosResponse = yield call(getApi, url);
    callback(response);
    yield put({
      type: types.PRESIGNED_URL_UPLOAD_EXCEL_SHEET_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
// watchers
export function* watchCreateWhatsAppBulk() {
  yield takeEvery(types.CREATE_WHATSAPP_BULK_DETAILS, workCreateWhatsAppBulk);
}
export function* watchGetWhatsAppBulk() {
  yield takeEvery(types.GET_WHATSAPP_BULK_DETAILS, workGetWhatsAppBulk);
}
export function* watchUpdateWhatsAppBulk() {
  yield takeEvery(types.UPDATE_WHATSAPP_BULK_DETAILS, workUpdateWhatsAppBulk);
}
export function* watchDeleteWhatsAppBulk() {
  yield takeEvery(types.DELETE_WHATSAPP_BULK_DETAILS, workDeleteWhatsAppBulk);
}
export function* watchSendWhatsAppBulk() {
  yield takeEvery(types.SEND_WHATSAPP_BULK_DETAILS, workSendWhatsAppBulk);
}
export function* watchFetchPresignedUrlExcel() {
  yield takeEvery(types.PRESIGNED_URL_UPLOAD_EXCEL_SHEET, workFetchPresignedUrlExcel);
}

function* whatsAppBulkSaga() {
  yield all([
    fork(watchCreateWhatsAppBulk),
    fork(watchGetWhatsAppBulk),
    fork(watchUpdateWhatsAppBulk),
    fork(watchDeleteWhatsAppBulk),
    fork(watchFetchPresignedUrlExcel),
    fork(watchSendWhatsAppBulk)
    
  ]);
}
export default whatsAppBulkSaga;
