import React, { useState } from "react";
import { Grid } from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Store } from "react-notifications-component";
//components
import SideBar from "../../../components/verticalMenu/SideBar";
// import from store
import { updatePassword } from "../../../Store/auth/registration/actions";
// Creating schema
const schema = Yup.object().shape({
  password: Yup.string()
    .required("Password is a required field")
    .min(8, "Password is tooweak"),
  confirmPassword: Yup.string()
    .required("Confirm password is a required field")
    .min(8, " Confirm Password is tooweak"),
});
const UpdatePassword = (props: any) => {
  const [resData, setResData] = useState({ success: false, message: "" });
  const navigate = useNavigate();
  return (
    <>
      <div className="pageContent1">
        <div className="pageContentChild">
          <div className="p-3 w-100">
            <Grid container spacing={2}>
              <Grid item xs={1} md={2} lg={4}></Grid>
              <Grid item xs={10} md={8} lg={4}>
                {/* <Card> */}
                <div className="setting">
                  <Formik
                    validationSchema={schema}
                    initialValues={{
                      password: "",
                      confirmPassword: "",
                    }}
                    onSubmit={(values) => {
                      let authUser = JSON.parse(
                        localStorage.getItem("authUser") || sessionStorage.getItem("authUser") || ""
                      );
                      props.updatePassword(
                        {
                          userId: authUser?._id,
                          password: values?.password,
                          confirmPassword: values?.confirmPassword,
                        },
                        (res: any) => {
                          setResData({
                            success: res?.success,
                            message: res?.message,
                          });
                          if (res.success) {
                            navigate("/users");
                            window.scrollTo(0, 0)
                            Store.addNotification({
                              title: "Success!",
                              message: res?.message,
                              type: "success",
                              insert: "top",
                              container: "top-right",
                              animationIn: [
                                "animate__animated",
                                "animate__fadeIn",
                              ],
                              animationOut: [
                                "animate__animated",
                                "animate__fadeOut",
                              ],
                              dismiss: {
                                duration: 5000,
                                onScreen: true,
                              },
                            });
                          } else {
                            Store.addNotification({
                              title: "Error!",
                              message: res?.message,
                              type: "danger",
                              insert: "top",
                              container: "top-right",
                              animationIn: [
                                "animate__animated",
                                "animate__fadeIn",
                              ],
                              animationOut: [
                                "animate__animated",
                                "animate__fadeOut",
                              ],
                              dismiss: {
                                duration: 5000,
                                onScreen: true,
                              },
                            });
                          }
                        }
                      );
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                    }) => (
                      <div className="login">
                        <div className="form">
                          <h4>Update Password</h4>
                          {/* Passing handleSubmit parameter tohtml form onSubmit property */}
                          <form noValidate onSubmit={handleSubmit}>
                            <div className="formLoginCard">
                              <p className="error fontRoboto124">
                                {resData?.success === false
                                  ? resData?.message
                                  : ""}
                              </p>
                              {/* Our input html with passing formik parameters like handleChange, values, handleBlur to input properties */}
                              <input
                                type="password"
                                name="password"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.password}
                                placeholder="Enter password"
                                className="form-control"
                              />
                              {/* If validation is not passed show errors */}
                              <p className="error">
                                {errors.password &&
                                  touched.password &&
                                  errors.password}
                              </p>
                              <input
                                type="password"
                                name="confirmPassword"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.confirmPassword}
                                placeholder="Confirm password"
                                className="form-control"
                              />
                              {/* If validation is not passed show errors */}
                              <p className="error">
                                {errors.confirmPassword &&
                                  touched.confirmPassword &&
                                  errors.confirmPassword}
                              </p>
                              {/* Click on submit button to submit the form */}
                              <button className="SubmitButton" type="submit">
                                Submit
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    )}
                  </Formik>
                </div>
                {/* </Card> */}
              </Grid>
              <Grid item xs={1} md={2} lg={4}></Grid>
            </Grid>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state: any) => {
  return {
    ...state,
  };
};
export default connect(mapStateToProps, { updatePassword })(UpdatePassword);
