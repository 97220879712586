import React, { useState, useEffect } from "react";
import { Card, Row, Col, Button } from "reactstrap";
import { Modal, ModalBody } from "reactstrap";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import Logo from "../../assets/images/Favicon Logo.png";

// components
import UserLogin from "../Auth/Login/UserLogin";
import UserRegistration from "../Auth/Registration/UserRegistration";
import FoterDiv from "../../components/common/FoterDiv";
import TeamRegister from "./TeamRegister";
import FrequentlyAsked from "../../components/common/FrequentlyAsked";
import Loader from "../../components/Loader/Loader";
import notifications from "../../components/Notifications/Notification";
import Message from "./Message";
// import InvitedPlayerUserTournament from "../UserTournament/InvitedPlayerUserTournament";
// import from store
import { getUsersByEmailId } from "../../Store/user/actions";
import {
  fetchTournamentById,
  getTeamsOrPlayersByTournamentIdAndUserId,
  getTeamsByTournamentBeforeLogin,
  UserStatus,
} from "../../Store/tournament/actions";

var frequentlyAskedQuestions = [
  {
    question: "What is GamingGeeks ?",
    answer:
      "GamingGeeks is an esports learning platform made for gamers at every rank. We get professional gamers and accomplished international coaches to provide a world class learning experience for all gamers.",
  },
  {
    question: "How to join a team ?",
    answer:
      "You will be sent an invite on email by your team leader. If you wish to join a particular team, you can reach out to the team leader on their Discord ID provided in their user profile.",
  },
  {
    question: "I don’t have a team, can I play ?",
    answer:
      "Join our Discord. There are a lot of players like you who don’t have a team but are keen on playing. Come over to our general chat channel and you shall find a teammate there.",
  },
  {
    question: "What are the benefits of playing GG Tournaments ?",
    answer:
      "GG runs multiple rouste programs. When you play GG Touraments you are basically giving a try-outs to become a part of a pro gaming team.",
  },
  {
    question: "How do I know if my registration is complete ?",
    answer:
      "Once you have properly registered for a tournament, the tournament details along with your team details should display in your GG Dashboard. However, it might take sometime to reflect. In any case of confusion, reach out to one of the Geeks.",
  },
  {
    question: "How to cancel my registration ? ",
    answer:
      "You have to reach out to us to cancel your registration. Drop us a message on Instagram, Discord, FB Messenger, or WhatsApp with your team name and team leader details.",
  },
];
var totCntBeforeLogin: any;
const TournamentNewRegistration = (props: any) => {
  let auth: any =
    localStorage.getItem("authUser") || sessionStorage.getItem("authUser");
  let authUser: any = JSON.parse(auth) || "";
  const location = useLocation();
  const search = useLocation().search;
  const emailIdParams = new URLSearchParams(search).get("emailId");
  const tournamentIdParms = new URLSearchParams(search).get("tournamentId");
  console.log("params", emailIdParams, tournamentIdParms);
  const tournamentId = location?.state?.tournamentId;
  console.log("Location", location);
  const [name, setName]: any = useState("");
  const [openTeamRegistration, setTeamRegistration]: any = useState(false);

  const [openRegistration, setOpenRegistration] = useState(false);
  const [openLogin, setOpenLogin] = useState(false);
  const [message, setMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userStatus,setUserStatus]:any=useState()
  useEffect(() => {
    if (tournamentId) {
      props.fetchTournamentById(tournamentId, (res: any) => {});
      // getAllTeamsBeforeLogin();
      getAllTeamsANdPlayersData();

      localStorage.removeItem("AcceptBeforeLogin");
    }
    if (tournamentIdParms) {
      props.fetchTournamentById(tournamentIdParms, (res: any) => {});
      getAllTeamsBeforeLogin({
        tournamentId: tournamentIdParms,
        emailId: emailIdParams,
      });
    }
    if (emailIdParams) {
      getAllUserDataByEmailId();
    }
  }, []);
  const getUserStatus = (payload: any) => {
    props?.UserStatus(payload, (res: any) => {
      if (res.success) {
        notifications({
          title: "Success!",
          message: res?.message,
          type: "success",
        });
        getAllTeamsANdPlayersData();
      } else {
        notifications({
          title: "Error!",
          message: res?.message,
          type: "danger",
        });
      }
    });
  };
  let getAllUserDataByEmailId = () => {
    setLoading(true);
    props.getUsersByEmailId(emailIdParams, (res: any) => {
      setLoading(false);
      setUserStatus(res?.data)
    });
  };
  const getAllTeamsANdPlayersData = () => {
    props.getTeamsOrPlayersByTournamentIdAndUserId(
      {
        tournamentId,
        // userId: authUser?._id,
        email:authUser?.email
      },
      () => {}
    );
  };
  const getAllTeamsBeforeLogin = (payload: any) => {
    setLoading(true);
    props.getTeamsByTournamentBeforeLogin(payload, (res: any) => {
      setLoading(false);
    });
  };
  const tournamentDetails = props?.tournamentByIdDetails;
  let rulesType = tournamentDetails?.rules.substring(
    tournamentDetails?.rules.lastIndexOf(".") + 1,
    tournamentDetails?.rules.length
  );

  console.log("tournamentDetails", tournamentDetails, rulesType);
  const handleClick = (type: any) => {
    if (type?.name === "details") {
      setName(type?.name);
    } else if (type?.name === "rules") {
      setName(type?.name);
    } else if (type?.name === "fixtures") {
      setName(type?.name);
    } else if (type?.name === "teams") {
      setName(type?.name);
    } else if (type?.name === "prizePool") {
      setName(type?.name);
    }
  };
  const closeTeamRegistration = () => {
    setTeamRegistration(false);
  };
  const openTeamReg = () => {
    setTeamRegistration(true);
  };
  let openLoginPopUp = () => {
    setOpenLogin(true);
    setOpenRegistration(false);
  };
  let closeModalLogin = () => {
    setOpenLogin(false);
  };
  let openRegistrationPopUp = () => {
    setOpenRegistration(true);
    setOpenLogin(false);
  };
  let closeModal = () => {
    setOpenRegistration(false);
  };
  let openMessagePopUp = () => {
    setMessage(true);
  };
  let closeMessageModal = () => {
    setMessage(false);
  };

  const teamDataBeforeLogin = props?.beforeLoginTeamData?.teams;
  totCntBeforeLogin = props?.beforeLoginTeamData?.teams?.length;
  const teamsDataAfterLogin = props.afterLoginTeamData;
  return (
    <>
      <Modal
        isOpen={message}
        toggle={closeMessageModal}
        size="lg"
        className="RegistrationModal"
      >
        <ModalBody>
          <Message closeModal={closeMessageModal} />
        </ModalBody>
      </Modal>
      <Modal
        isOpen={openRegistration}
        toggle={closeModal}
        size="lg"
        className="RegistrationModal"
      >
        <ModalBody>
          <UserRegistration
            {...props}
            closeModal={closeModal}
            openLoginPopUp={openLoginPopUp}
          />
        </ModalBody>
      </Modal>
      <Modal
        isOpen={openLogin}
        toggle={closeModalLogin}
        size="lg"
        className="RegistrationModal"
      >
        <ModalBody>
          <UserLogin
            {...props}
            closeModalLogin={closeModalLogin}
            openRegistrationPopUp={openRegistrationPopUp}
          />
        </ModalBody>
      </Modal>
      <Modal
        isOpen={openTeamRegistration}
        toggle={closeTeamRegistration}
        size="lg"
        className="RegistrationModal TeamRegModal"
      >
        <ModalBody>
          <TeamRegister
            {...props}
            closePopUp={closeTeamRegistration}
            teamRegistrationFlag={true}
            tournamentId={tournamentId}
            tournamentDetails={tournamentDetails}
          />
        </ModalBody>
      </Modal>
      <div className="pageContent">
        {/* <div> */}
        <div className="d-flex justify-content-center">
          <img
            src={tournamentDetails?.thumbnail}
            alt="asset"
            className="thumbnailImage"
          />
        </div>
        <div className="d-grid justify-content-center text-center imageText">
          <div className="d-flex justify-content-center text-capitalize">
            <Row>
              <Col xs={12} md={12}>
                <h1 className="tournamentName">{tournamentDetails?.label}</h1>
              </Col>
            </Row>
          </div>
          <div className="d-flex justify-content-center">
            {/* <h1 className="price">
              ₹{Intl.NumberFormat("en-IN").format(tournamentDetails?.poolPrize)}
            </h1> */}
                <span className="ps-4 pb-4 DateDiv">
                  {moment(new Date(tournamentDetails?.startDate)).format(
                    "DD/MM/YYYY"
                  )}{" "}
                  -
                  {moment(new Date(tournamentDetails?.endDate)).format(
                    "DD/MM/YYYY"
                  )}{" "}
                </span>
          </div>
          <div className="d-flex justify-content-center">
            <Button
              className="imageButton"
              onClick={() => {
                if (authUser) {
                  openTeamReg();
                } else {
                  openLoginPopUp();
                }
              }}
            >
              Register Now
            </Button>
          </div>
        </div>
        {/* </div> */}
        <div className="d-flex justify-content-center fontText">
          <h1>Tournament Details</h1>
        </div>
        <Row className="mt-5">
          <Col xs={0} md={1}></Col>
          <Col xs={12} md={10} className="">
            <Card className="tournamentCard">
              <div className="d-flex CardHeaderParent">
                <div
                  className={
                    (name !== "details" &&
                      name !== "rules" &&
                      name !== "fixtures" &&
                      name !== "teams" &&
                      name !== "prizePool") ||
                    name === "details"
                      ? " cardHeader cardbackgroungColor"
                      : " cardHeader"
                  }
                  onClick={() => {
                    handleClick({
                      name: "details",
                    });
                  }}
                >
                  <svg
                    version="1.0"
                    xmlns="http://www.w3.org/2000/svg"
                    width="50"
                    height="50"
                    viewBox="0 0 512.000000 512.000000"
                    preserveAspectRatio="xMidYMid meet"
                  >
                    <g
                      transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                      fill="#FFF"
                      stroke="none"
                    >
                      <path
                        d="M4305 4831 c-16 -10 -362 -351 -767 -758 l-738 -738 0 -278 c0 -229
3 -283 15 -307 30 -57 47 -60 352 -60 l278 0 752 752 c532 532 754 760 758
780 4 15 2 43 -4 60 -13 41 -512 542 -554 558 -40 14 -58 13 -92 -9z m207
-428 c82 -81 148 -152 148 -158 0 -5 -294 -304 -652 -662 l-653 -653 -157 0
-158 0 0 157 0 158 652 652 c359 359 657 653 663 653 5 0 76 -66 157 -147z"
                      />
                      <path
                        d="M635 4354 c-176 -38 -336 -163 -413 -324 -22 -47 -46 -112 -52 -145
-8 -43 -10 -397 -8 -1230 l3 -1170 22 -66 c59 -172 210 -323 382 -382 65 -21
76 -22 668 -25 l603 -3 0 -315 c0 -346 2 -359 60 -389 51 -26 91 -19 156 27
32 23 250 185 483 360 l424 318 616 0 c684 0 682 0 814 66 99 50 209 162 259
264 70 142 69 131 66 1112 -3 874 -3 877 -24 905 -25 34 -84 58 -119 49 -14
-4 -40 -19 -58 -35 l-32 -29 -5 -909 -5 -908 -27 -57 c-35 -76 -107 -151 -177
-185 l-56 -28 -671 -5 -671 -5 -396 -298 -397 -297 0 245 c0 269 -5 298 -56
332 -26 17 -71 18 -694 23 l-665 5 -57 27 c-73 34 -142 103 -176 176 l-27 57
0 1175 0 1175 28 56 c34 70 109 142 185 177 l57 27 1147 5 1147 5 30 29 c40
39 49 84 25 133 -39 78 48 73 -1204 72 -944 -1 -1126 -3 -1185 -15z"
                      />
                      <path
                        d="M944 3631 c-63 -38 -81 -99 -49 -161 32 -62 12 -60 709 -60 l635 0
35 31 c45 39 55 84 30 136 -37 76 -12 73 -709 73 -593 0 -621 -1 -651 -19z"
                      />
                      <path
                        d="M944 2911 c-63 -38 -81 -99 -49 -161 32 -62 12 -60 709 -60 l635 0
35 31 c45 39 55 84 30 136 -37 76 -12 73 -709 73 -593 0 -621 -1 -651 -19z"
                      />
                      <path
                        d="M944 2191 c-63 -38 -81 -99 -49 -161 33 -64 -72 -60 1549 -60 l1475
0 35 31 c45 39 55 84 30 136 -39 79 90 73 -1549 73 -1420 0 -1461 -1 -1491
-19z"
                      />
                    </g>
                  </svg>
                  <span className="ps-2 mobileDisplayDetails">Details</span>
                </div>
                <div
                  className={
                    name === "rules"
                      ? " cardHeader cardbackgroungColor"
                      : " cardHeader"
                  }
                  onClick={() => {
                    handleClick({
                      name: "rules",
                    });
                  }}
                >
                  <svg
                    className="ps-2"
                    version="1.0"
                    xmlns="http://www.w3.org/2000/svg"
                    width="60"
                    height="60"
                    viewBox="0 0 512.000000 512.000000"
                    preserveAspectRatio="xMidYMid meet"
                  >
                    <g
                      transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                      fill="#FFF"
                      stroke="none"
                    >
                      <path
                        d="M816 4278 c-23 -20 -44 -90 -166 -556 -77 -294 -140 -540 -140 -548
0 -7 12 -27 28 -43 23 -25 130 -62 777 -276 413 -136 754 -250 758 -255 5 -4
-1 -36 -12 -71 -43 -129 -56 -217 -55 -379 1 -120 6 -178 23 -258 114 -536
502 -932 1024 -1044 131 -28 359 -30 487 -5 264 52 501 177 685 362 335 335
462 825 330 1280 -14 50 -52 144 -84 210 -96 196 -222 349 -394 477 -141 104
-256 153 -738 312 -379 125 -448 146 -471 137 -15 -5 -46 -34 -68 -63 -23 -29
-45 -54 -49 -56 -3 -1 -58 15 -121 36 l-115 39 -5 80 c-3 46 -11 87 -19 97
-13 16 -1597 546 -1632 546 -10 0 -29 -10 -43 -22z m211 -209 c91 -30 91 -30
83 -57 -5 -15 -52 -184 -105 -377 -53 -192 -103 -373 -111 -402 l-15 -51 -90
30 c-54 18 -89 35 -86 42 2 6 53 199 113 428 60 230 111 418 114 418 3 0 46
-14 97 -31z m1317 -508 c3 -41 13 -85 21 -97 11 -18 61 -38 216 -88 233 -75
235 -75 299 10 22 30 44 54 48 54 29 0 824 -275 887 -308 313 -159 526 -440
601 -794 23 -108 23 -323 0 -428 -79 -362 -287 -636 -601 -793 -170 -85 -317
-120 -505 -120 -447 -1 -822 240 -1020 656 -140 293 -155 611 -40 896 40 101
43 136 11 165 -9 9 -285 104 -612 212 -326 108 -597 199 -600 202 -4 5 156
605 220 824 l11 36 529 -176 528 -176 7 -75z"
                      />
                      <path
                        d="M3140 2936 c-89 -20 -130 -36 -225 -84 -73 -38 -108 -64 -186 -142
-100 -99 -147 -168 -193 -284 -45 -113 -58 -196 -53 -336 4 -107 9 -139 37
-223 73 -219 221 -389 423 -487 410 -198 903 -30 1103 375 61 125 85 224 86
360 1 369 -220 678 -572 796 -85 29 -109 32 -230 35 -85 2 -155 -2 -190 -10z
m380 -192 c194 -68 341 -215 412 -414 20 -57 23 -83 23 -210 -1 -131 -3 -151
-27 -212 -75 -198 -228 -348 -418 -410 -91 -30 -270 -36 -360 -13 -416 107
-621 565 -423 948 77 149 244 281 415 327 110 30 264 23 378 -16z"
                      />
                    </g>
                  </svg>
                  <span className="ps-2 mobileDisplayDetails">Rules</span>
                </div>
                <div
                  className={
                    name === "fixtures"
                      ? " cardHeader cardbackgroungColor"
                      : " cardHeader"
                  }
                  onClick={() => {
                    handleClick({
                      name: "fixtures",
                    });
                  }}
                >
                  <svg
                    className="ps-2"
                    version="1.0"
                    xmlns="http://www.w3.org/2000/svg"
                    width="50"
                    height="50"
                    viewBox="0 0 512.000000 512.000000"
                    preserveAspectRatio="xMidYMid meet"
                  >
                    <g
                      transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                      fill="#FFF"
                      stroke="none"
                    >
                      <path
                        d="M2080 5040 l0 -80 -160 0 -160 0 0 -124 c0 -144 11 -212 45 -283 47
-96 153 -182 250 -204 50 -11 54 -14 76 -62 32 -69 88 -138 143 -178 30 -21
46 -40 46 -53 0 -13 -31 -52 -80 -101 l-80 -80 0 -92 0 -93 400 0 400 0 0 93
0 92 -80 80 c-92 91 -98 112 -45 145 52 32 120 113 150 179 25 53 32 60 64 66
101 19 216 110 264 208 34 71 47 149 47 289 l0 118 -160 0 -160 0 0 80 0 80
-480 0 -480 0 0 -80z m240 -310 l0 -240 97 0 c90 0 99 2 123 25 l26 25 24 -25
c22 -23 30 -25 117 -25 l93 0 0 240 0 240 45 0 45 0 0 -232 c0 -128 -4 -259
-10 -292 -19 -116 -86 -207 -188 -255 l-52 -24 0 -91 0 -91 72 -72 72 -73
-218 0 -218 0 61 65 61 66 0 99 0 100 -50 25 c-60 30 -122 90 -148 142 -35 68
-42 136 -42 388 l0 245 45 0 45 0 0 -240z m320 93 l0 -147 -40 39 -40 39 -38
-37 c-21 -20 -41 -37 -45 -37 -4 0 -7 65 -7 145 l0 145 85 0 85 0 0 -147z
m-560 -168 c0 -85 -4 -155 -8 -155 -17 0 -90 59 -112 90 -30 41 -50 112 -50
172 l0 48 85 0 85 0 0 -155z m1127 86 c-7 -82 -27 -135 -70 -179 -28 -30 -76
-62 -92 -62 -3 0 -5 70 -5 155 l0 155 86 0 87 0 -6 -69z"
                      />
                      <path
                        d="M2487 3523 c-4 -3 -7 -60 -7 -125 l0 -118 -640 0 -640 0 0 -195 0
-195 75 0 74 0 3 118 3 117 1205 0 1205 0 3 -117 3 -118 75 0 74 0 -2 193 -3
192 -642 3 -643 2 0 125 0 125 -68 0 c-38 0 -72 -3 -75 -7z"
                      />
                      <path
                        d="M807 2713 c-4 -3 -7 -201 -7 -440 l0 -433 475 0 475 0 0 440 0 440
-468 0 c-258 0 -472 -3 -475 -7z m793 -438 l0 -285 -325 0 -325 0 0 285 0 285
325 0 325 0 0 -285z"
                      />
                      <path d="M1042 2398 l3 -73 78 -3 77 -3 0 76 0 75 -80 0 -81 0 3 -72z" />
                      <path
                        d="M3370 2280 l0 -440 475 0 475 0 -2 438 -3 437 -472 3 -473 2 0 -440z
m798 -2 l-3 -283 -322 -3 -323 -2 0 285 0 285 325 0 325 0 -2 -282z"
                      />
                      <path d="M3610 2395 l0 -76 78 3 77 3 3 73 3 72 -81 0 -80 0 0 -75z" />
                      <path
                        d="M1200 1555 l0 -125 -320 0 -320 0 0 -195 0 -195 75 0 75 0 0 120 0
120 565 0 565 0 0 -120 0 -120 75 0 75 0 0 195 0 195 -320 0 -320 0 0 125 0
125 -75 0 -75 0 0 -125z"
                      />
                      <path
                        d="M3770 1555 l0 -125 -320 0 -320 0 0 -195 0 -196 73 3 72 3 3 118 3
117 564 0 565 0 0 -120 0 -120 75 0 75 0 0 195 0 195 -320 0 -320 0 0 125 0
125 -75 0 -75 0 0 -125z"
                      />
                      <path
                        d="M160 435 l0 -435 475 0 475 0 0 435 0 435 -475 0 -475 0 0 -435z
m800 0 l0 -285 -325 0 -325 0 0 285 0 285 325 0 325 0 0 -285z"
                      />
                      <path d="M400 555 l0 -75 80 0 80 0 0 75 0 75 -80 0 -80 0 0 -75z" />
                      <path
                        d="M1440 435 l0 -435 475 0 475 0 0 435 0 435 -475 0 -475 0 0 -435z
m800 0 l0 -285 -325 0 -325 0 0 285 0 285 325 0 325 0 0 -285z"
                      />
                      <path d="M1680 555 l0 -75 80 0 80 0 0 75 0 75 -80 0 -80 0 0 -75z" />
                      <path
                        d="M2720 435 l0 -435 480 0 480 0 0 435 0 435 -480 0 -480 0 0 -435z
m810 0 l0 -285 -325 0 -325 0 0 285 0 285 325 0 325 0 0 -285z"
                      />
                      <path d="M2970 555 l0 -75 80 0 80 0 0 75 0 75 -80 0 -80 0 0 -75z" />
                      <path
                        d="M4010 435 l0 -435 475 0 475 0 0 435 0 435 -475 0 -475 0 0 -435z
m800 0 l0 -285 -325 0 -325 0 0 285 0 285 325 0 325 0 0 -285z"
                      />
                      <path d="M4250 555 l0 -75 80 0 80 0 0 75 0 75 -80 0 -80 0 0 -75z" />
                    </g>
                  </svg>
                  <span className="ps-2 mobileDisplayDetails">Fixtures</span>
                </div>
                <div
                  className={
                    name === "teams"
                      ? " cardHeader cardbackgroungColor"
                      : " cardHeader"
                  }
                  onClick={() => {
                    handleClick({
                      name: "teams",
                    });
                  }}
                >
                  <svg
                    className="ps-2"
                    version="1.0"
                    xmlns="http://www.w3.org/2000/svg"
                    width="50"
                    height="50"
                    viewBox="0 0 512.000000 512.000000"
                    preserveAspectRatio="xMidYMid meet"
                  >
                    <g
                      transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                      fill="#FFF"
                      stroke="none"
                    >
                      <path
                        d="M2445 5108 c-27 -5 -86 -27 -131 -48 -152 -73 -274 -232 -303 -394
-17 -92 -14 -478 4 -554 29 -121 111 -249 205 -319 l50 -37 0 -81 0 -81 -263
-87 c-180 -60 -279 -98 -313 -121 -49 -33 -115 -99 -145 -146 l-17 -25 -64 61
c-173 166 -424 202 -642 92 -123 -62 -237 -204 -280 -347 -23 -77 -23 -529 -1
-616 31 -116 118 -243 215 -310 l41 -28 -3 -80 -3 -80 -263 -88 c-284 -95
-333 -119 -408 -202 -87 -97 -124 -203 -124 -358 0 -82 3 -100 21 -123 26 -33
77 -36 105 -5 15 17 20 43 25 138 4 68 13 130 22 151 26 59 76 120 124 150 39
25 478 180 509 180 6 0 16 -11 22 -24 6 -14 28 -44 49 -67 109 -121 307 -121
416 0 21 23 43 53 49 67 6 13 16 24 22 24 32 0 470 -155 510 -180 25 -16 61
-50 79 -76 56 -80 58 -76 -56 -114 -134 -43 -209 -81 -268 -137 -97 -90 -141
-190 -154 -346 l-7 -87 -449 0 -449 0 0 109 c0 106 -1 110 -26 130 -31 24 -43
26 -78 10 -34 -16 -46 -57 -46 -160 l0 -89 -135 0 -135 0 0 24 c0 64 -91 97
-131 47 -28 -35 -27 -158 2 -195 l20 -26 715 0 714 0 0 -289 c0 -293 4 -332
38 -357 25 -20 2079 -20 2104 0 51 38 51 184 0 222 -44 34 -112 -1 -112 -56
l0 -30 -140 0 -140 0 0 235 c0 222 -1 236 -20 255 -30 30 -83 27 -109 -6 -20
-25 -21 -39 -21 -255 l0 -229 -510 0 -510 0 0 229 c0 216 -1 230 -21 255 -26
33 -79 36 -109 6 -19 -19 -20 -33 -20 -255 l0 -235 -141 0 -140 0 4 403 c4
434 5 442 60 523 56 84 94 105 349 189 128 43 238 80 245 82 6 3 21 -13 31
-34 26 -48 74 -94 132 -124 35 -18 61 -23 120 -23 59 0 85 5 120 23 58 30 106
76 132 124 10 21 25 37 31 34 7 -2 117 -39 245 -82 167 -55 245 -86 275 -109
48 -36 101 -110 118 -164 7 -21 14 -136 16 -270 l5 -232 25 -20 c45 -35 92
-20 113 34 5 15 10 56 10 92 l0 64 714 0 715 0 20 26 c29 37 30 160 2 195 -40
50 -131 17 -131 -47 l0 -24 -135 0 -135 0 0 89 c0 103 -12 144 -46 160 -35 16
-47 14 -78 -10 -25 -20 -26 -24 -26 -130 l0 -109 -449 0 -449 0 -7 88 c-13
155 -57 255 -154 345 -59 56 -134 94 -268 137 -114 38 -112 34 -56 114 18 26
54 60 79 76 40 25 478 180 510 180 6 0 16 -11 22 -24 20 -45 86 -109 137 -132
69 -33 171 -33 240 0 51 23 117 87 137 132 6 13 16 24 21 24 31 0 471 -155
510 -180 48 -30 98 -91 124 -150 9 -21 18 -83 22 -151 5 -95 10 -121 25 -138
28 -31 79 -28 105 5 18 23 21 41 21 123 0 155 -37 261 -124 358 -75 83 -124
107 -408 202 l-263 88 -3 80 -3 80 41 28 c97 67 184 194 215 310 21 83 22 525
0 608 -37 142 -155 291 -281 355 -218 110 -469 74 -642 -92 l-64 -61 -17 25
c-30 47 -96 113 -145 146 -33 22 -135 62 -312 121 l-264 88 0 80 0 81 50 37
c96 72 177 199 206 324 13 55 15 115 12 313 -4 233 -5 248 -29 311 -37 96 -71
149 -140 218 -127 128 -318 184 -504 149z m219 -153 c143 -37 262 -160 295
-305 17 -74 14 -445 -5 -515 -31 -120 -110 -215 -222 -268 -62 -30 -75 -32
-172 -32 -97 0 -110 2 -172 32 -74 35 -141 96 -181 164 -47 80 -57 144 -57
369 0 275 16 335 114 438 108 114 252 156 400 117z m34 -1365 c-4 -103 -16
-134 -66 -166 -35 -23 -109 -23 -144 0 -50 32 -62 63 -66 166 l-4 95 142 0
142 0 -4 -95z m-376 -205 c65 -97 182 -148 292 -125 88 18 150 66 211 164 9
14 37 7 240 -60 127 -41 249 -86 271 -99 49 -29 95 -79 126 -138 21 -38 23
-52 18 -142 -3 -55 -5 -189 -5 -298 l0 -197 -176 0 -177 0 -6 48 c-19 132 -82
249 -184 344 -329 303 -865 104 -928 -344 l-6 -48 -178 0 -177 0 5 145 c3 80
-1 208 -8 284 -12 140 -12 140 13 193 29 64 75 119 125 150 33 21 488 178 504
175 3 -1 21 -24 40 -52z m-1070 -139 c139 -66 220 -181 239 -341 17 -138 6
-415 -19 -487 -41 -115 -127 -205 -239 -249 -81 -32 -215 -32 -293 -1 -115 46
-209 145 -244 256 -15 46 -17 90 -14 308 3 249 3 254 29 309 47 99 137 181
242 220 80 29 223 22 299 -15z m2911 15 c108 -37 196 -117 246 -221 l26 -55 0
-275 0 -275 -30 -60 c-34 -70 -113 -153 -173 -184 -229 -117 -499 -12 -584
226 -16 47 -21 91 -25 228 -10 336 30 461 185 567 100 69 241 88 355 49z
m-1521 -392 c125 -26 233 -114 291 -237 l32 -67 3 -227 c4 -255 -5 -313 -61
-407 -41 -68 -95 -115 -177 -155 -60 -29 -73 -31 -170 -31 -97 0 -110 2 -170
31 -82 40 -136 87 -177 155 -56 94 -65 152 -61 407 l3 227 32 67 c85 181 268
276 455 237z m-642 -656 c0 -147 16 -228 61 -317 40 -78 89 -139 156 -190 l53
-41 0 -82 0 -81 -62 -21 c-34 -11 -62 -20 -64 -18 -1 1 -13 25 -27 52 -33 66
-104 145 -166 184 -30 20 -151 66 -313 120 l-263 88 -3 80 -3 80 41 28 c59 41
132 124 166 189 l29 56 198 0 197 0 0 -127z m1544 71 c34 -65 107 -148 166
-189 l41 -28 -3 -80 -3 -80 -263 -88 c-162 -54 -283 -100 -313 -120 -62 -39
-133 -118 -166 -184 -14 -27 -26 -51 -27 -52 -2 -2 -30 7 -64 18 l-62 21 0 81
0 82 53 41 c67 51 116 112 156 190 45 89 61 170 61 317 l0 127 198 0 197 0 29
-56z m-2459 -294 c33 0 77 4 98 9 l37 9 0 -97 c0 -108 -16 -148 -69 -176 -64
-32 -145 -11 -181 48 -16 26 -20 50 -20 128 l0 97 38 -9 c20 -5 64 -9 97 -9z
m2950 0 c33 0 77 4 98 9 l37 9 0 -97 c0 -108 -16 -148 -69 -176 -64 -32 -145
-11 -181 48 -16 26 -20 50 -20 128 l0 97 38 -9 c20 -5 64 -9 97 -9z m-1337
-491 c-3 -69 -8 -103 -21 -122 -54 -83 -172 -85 -231 -4 -16 22 -21 47 -24
125 l-4 97 142 0 142 0 -4 -96z"
                      />
                    </g>
                  </svg>
                  <span className="ps-2 mobileDisplayDetails">Teams</span>
                </div>
                <div
                  className={
                    name === "prizePool"
                      ? " cardHeader cardbackgroungColor"
                      : " cardHeader"
                  }
                  onClick={() => {
                    handleClick({
                      name: "prizePool",
                    });
                  }}
                >
                  <svg
                    className="ps-2"
                    version="1.0"
                    xmlns="http://www.w3.org/2000/svg"
                    width="50"
                    height="50"
                    viewBox="0 0 512.000000 512.000000"
                    preserveAspectRatio="xMidYMid meet"
                  >
                    <g
                      transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                      fill="#FFF"
                      stroke="none"
                    >
                      <path
                        d="M2505 5095 c-25 -24 -25 -26 -25 -215 0 -189 0 -191 25 -215 15 -16
36 -25 55 -25 19 0 40 9 55 25 25 24 25 26 25 215 0 189 0 191 -25 215 -15 16
-36 25 -55 25 -19 0 -40 -9 -55 -25z"
                      />
                      <path
                        d="M1670 4730 c-13 -13 -20 -33 -20 -57 0 -33 10 -47 111 -149 61 -62
123 -115 137 -119 57 -14 111 40 97 97 -4 14 -57 76 -119 137 -102 101 -116
111 -149 111 -24 0 -44 -7 -57 -20z"
                      />
                      <path
                        d="M3238 4632 c-102 -102 -118 -122 -118 -150 0 -44 38 -82 82 -82 28 0
48 16 150 118 102 102 118 122 118 150 0 22 -9 41 -25 57 -16 16 -35 25 -57
25 -28 0 -48 -16 -150 -118z"
                      />
                      <path
                        d="M345 4135 l-25 -24 0 -418 c0 -303 4 -447 15 -525 65 -490 336 -922
745 -1191 96 -63 272 -149 375 -183 65 -21 86 -34 117 -69 127 -144 392 -321
600 -399 l63 -24 -3 -49 c-8 -134 -86 -330 -180 -453 -69 -91 -148 -161 -279
-247 l-112 -73 -87 0 c-118 0 -159 -14 -219 -75 -64 -63 -75 -100 -75 -244 0
-105 1 -113 25 -136 l24 -25 1231 0 1231 0 24 25 c24 23 25 31 25 136 0 144
-11 181 -75 244 -60 61 -101 75 -217 75 l-85 0 -109 69 c-189 120 -296 235
-379 406 -46 95 -82 217 -87 298 l-3 48 72 29 c212 82 453 242 585 388 36 40
57 53 123 75 122 42 329 149 435 224 304 217 543 564 641 929 51 194 59 285
59 747 l0 418 -25 24 -24 25 -2191 0 -2191 0 -24 -25z m775 -215 l0 -80 -215
0 -216 0 -26 -26 -25 -25 5 -297 c6 -378 24 -473 137 -707 84 -175 234 -359
389 -476 58 -44 67 -57 105 -142 24 -52 50 -104 59 -118 36 -51 20 -47 -101
22 -306 176 -559 486 -672 828 -67 201 -72 245 -77 694 l-5 407 321 0 321 0 0
-80z m2718 -572 c-5 -731 -6 -741 -78 -959 -76 -231 -184 -405 -359 -579 -169
-169 -355 -285 -569 -355 -50 -17 -88 -36 -97 -48 -27 -39 -13 -203 31 -356
54 -184 154 -343 308 -488 l87 -83 -316 0 -316 0 -24 -25 c-33 -32 -33 -78 0
-110 l24 -25 551 0 551 0 24 -25 c21 -20 25 -34 25 -80 l0 -55 -1120 0 -1120
0 0 55 c0 46 4 60 25 80 l24 25 391 0 391 0 24 25 c33 32 33 78 0 110 -24 24
-28 25 -180 25 l-156 0 86 82 c217 205 331 441 351 725 9 121 2 132 -108 168
-215 71 -401 187 -568 354 -178 179 -284 349 -360 580 -72 218 -73 228 -78
959 l-4 652 1282 0 1282 0 -4 -652z m799 245 c-5 -449 -10 -494 -76 -692 -104
-310 -312 -587 -576 -767 -93 -63 -206 -126 -213 -119 -2 2 6 20 18 40 12 20
39 74 60 120 33 76 44 90 101 134 160 121 334 343 415 529 45 104 49 155 15
183 -28 23 -69 24 -98 4 -11 -8 -35 -51 -53 -96 -18 -44 -54 -116 -80 -159
-47 -79 -169 -234 -177 -226 -3 2 1 35 7 73 8 44 14 239 17 566 l5 497 160 0
161 0 -5 -257 -5 -257 23 -23 c30 -29 66 -29 100 1 37 31 42 79 43 394 l1 253
-25 24 c-24 25 -24 25 -240 25 l-215 0 0 80 0 80 321 0 321 0 -5 -407z m-3517
-320 c1 -372 8 -571 25 -693 l6 -45 -41 45 c-134 146 -234 337 -282 540 -16
68 -21 133 -25 323 l-5 237 161 0 161 0 0 -407z"
                      />
                      <path
                        d="M345 2455 l-25 -24 0 -831 0 -831 25 -24 c13 -14 34 -25 47 -25 13 0
109 35 215 77 l193 77 193 -77 c106 -42 202 -77 215 -77 13 0 34 11 47 25 l25
24 0 471 0 471 -25 24 c-32 33 -78 33 -110 0 l-25 -24 0 -396 c0 -217 -1 -395
-3 -395 -2 0 -70 27 -152 60 -82 33 -156 60 -165 60 -9 0 -83 -27 -165 -60
-82 -33 -150 -60 -152 -60 -2 0 -3 340 -3 755 l0 756 -25 24 c-15 16 -36 25
-55 25 -19 0 -40 -9 -55 -25z"
                      />
                      <path
                        d="M4665 2455 l-25 -24 0 -756 c0 -415 -1 -755 -3 -755 -2 0 -70 27
-152 60 -82 33 -156 60 -165 60 -9 0 -83 -27 -165 -60 -82 -33 -150 -60 -152
-60 -2 0 -3 178 -3 395 l0 396 -25 24 c-32 33 -78 33 -110 0 l-25 -24 0 -471
0 -471 25 -24 c13 -14 34 -25 47 -25 13 0 109 35 215 77 l193 77 193 -77 c106
-42 202 -77 215 -77 13 0 34 11 48 25 l24 24 -2 837 -3 836 -24 19 c-32 26
-77 24 -106 -6z"
                      />
                    </g>
                  </svg>
                  <span className="ps-2 mobileDisplayDetails">Prize Pool</span>
                </div>
              </div>
              {name !== "details" &&
              name !== "rules" &&
              name !== "fixtures" &&
              name !== "teams" &&
              name !== "prizePool" ? (
                <>
                  <div className="ParentDetailsCard">
                    <div className="parentDivDeatils">
                      <span className="Heading">Server:</span>
                      <span className="content">
                        {tournamentDetails?.server}
                      </span>
                    </div>
                    <div className="parentDivDeatils">
                      <span className="Heading">Prize Pool:</span>
                      <span className="content">
                        ₹
                        {Intl.NumberFormat("en-IN").format(
                          tournamentDetails?.poolPrize
                        )}
                      </span>
                    </div>
                    <div className="parentDivDeatils">
                      <span className="Heading">Number of teams:</span>
                      <span className="content">
                        {tournamentDetails?.teamSize}
                      </span>
                    </div>
                    <div className="parentDivDeatils">
                      <span className="Heading">Tournament Region:</span>
                      <span className="content">
                        {tournamentDetails?.region}
                      </span>
                    </div>
                    <div className="parentDivDeatils">
                      <span className="Heading">Tournament Form:</span>
                      <span className="content">
                        {tournamentDetails?.tournamentForm}
                      </span>
                    </div>
                    <div className="parentDivDeatils">
                      <span className="Heading">Tournament Start Date:</span>
                      <span className="content">
                        {moment(new Date(tournamentDetails?.startDate)).format(
                          "DD/MM/YYYY"
                        )}{" "}
                      </span>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {name === "details" ||
                  (name !== "details" &&
                    name !== "rules" &&
                    name !== "fixtures" &&
                    name !== "teams" &&
                    name !== "prizePool") ? (
                    <>
                      <div className="ParentDetailsCard">
                        <div className="parentDivDeatils">
                          <span className="Heading">Server:</span>
                          <span className="content">
                            {tournamentDetails?.server}
                          </span>
                        </div>
                        <div className="parentDivDeatils">
                          <span className="Heading">Prize Pool:</span>
                          <span className="content">
                            ₹
                            {Intl.NumberFormat("en-IN").format(
                              tournamentDetails?.poolPrize
                            )}
                          </span>
                        </div>
                        <div className="parentDivDeatils">
                          <span className="Heading">Number of teams:</span>
                          <span className="content">
                            {tournamentDetails?.teamSiz}
                          </span>
                        </div>
                        <div className="parentDivDeatils">
                          <span className="Heading">Tournament Region:</span>
                          <span className="content">
                            {tournamentDetails?.region}
                          </span>
                        </div>
                        <div className="parentDivDeatils">
                          <span className="Heading">Tournament Format:</span>
                          <span className="content">
                            {tournamentDetails?.format}
                          </span>
                        </div>
                        <div className="parentDivDeatils">
                          <span className="Heading">
                            Tournament Start Date:
                          </span>
                          <span className="content">
                            {tournamentDetails?.startDate}
                          </span>
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  {name === "rules" ? (
                    <>
                      {rulesType === "pdf" ? (
                        <iframe
                          title="RulesPdf"
                          width="100%"
                          height="100%"
                          frameBorder="0"
                          src={tournamentDetails?.rules}
                        ></iframe>
                      ) : (
                        <>
                          <iframe
                            title="RulesDocx"
                            src={`https://docs.google.com/viewer?url=${tournamentDetails?.rules}&embedded=true`}
                            width="100%"
                            height="100%"
                            frameBorder="0"
                          ></iframe>
                        </>
                      )}
                      <button
                        onClick={() => {
                          fetch(` ${tournamentDetails?.rules}`).then(
                            (response) => {
                              response.blob().then((blob) => {
                                const fileURL =
                                  window.URL.createObjectURL(blob);

                                let alink = document.createElement("a");
                                alink.href = fileURL;

                                alink.download = `RULES.${rulesType}`;
                                alink.click();
                              });
                            }
                          );
                        }}
                      >
                        Dowload PDF
                      </button>
                    </>
                  ) : (
                    ""
                  )}
                  {name === "fixtures" ? (
                    <>
                      <img
                        className="p-3 fixtureImage"
                        src={tournamentDetails?.fixture}
                        alt={"Fixtures"}
                      />
                    </>
                  ) : (
                    ""
                  )}
                  {name === "teams" ? (
                    <>
                      {loading ? (
                        <>
                          <div>
                            <Loader />
                          </div>
                        </>
                      ) : (
                        <>
                          {!authUser ? (
                            <>
                              {totCntBeforeLogin && !authUser ? (
                                <>
                                  <div
                                    className={
                                      totCntBeforeLogin > 5
                                        ? "p-4 tableHeader tableStyle"
                                        : "p-4 tableHeader"
                                    }
                                  >
                                    <Row className="TeamHeader">
                                      <Col xs={8}>Team Name</Col>
                                      <Col xs={4}>Status</Col>
                                    </Row>
                                    {teamDataBeforeLogin?.map(
                                      (ele: any, i: any) => {
                                        return (
                                          <React.Fragment key={i}>
                                            <Row className="TeamBody ">
                                              <Col xs={8}>
                                                <span >
                                                  {
                                                    <img
                                                      src={ele?.teamLogo}
                                                      width="50px"
                                                      height="50px"
                                                      alt="Logo"
                                                    />
                                                  }
                                                </span>
                                                {ele?.teamName}
                                              </Col>
                                              <Col xs={4} className="d-flex">
                                                <Button
                                                  className="me-2"
                                                  style={{
                                                    backgroundColor: "#fff",
                                                    color: "#000",
                                                  }}
                                                  onClick={() => {
                                                    if (!authUser) {
                                                      if (
                                                        userStatus?.isActive === true
                                                      ) {
                                                        openLoginPopUp();
                                                      } else if (
                                                        userStatus?.isActive === false
                                                      ) {
                                                        openMessagePopUp();
                                                      } else {
                                                        openRegistrationPopUp();
                                                      }
                                                      localStorage.setItem(
                                                        "AcceptBeforeLogin",
                                                        JSON.stringify({
                                                          path: "/tournament-team-registration",
                                                          tournamentId:
                                                            tournamentId,
                                                        })
                                                      );
                                                    }
                                                  }}
                                                >
                                                  Accept
                                                </Button>
                                                <Button
                                                  style={{
                                                    backgroundColor: "#fff",
                                                    color: "#000",
                                                  }}
                                                  onClick={() => {
                                                  
                                                    if (!authUser) {
                                                      if (
                                                        props
                                                          ?.userDetailsByEmailId
                                                          ?.isActive === true
                                                      ) {
                                                        openLoginPopUp();
                                                        // openMessagePopUp()
                                                      } else if (
                                                        props
                                                          ?.userDetailsByEmailId
                                                          ?.isActive === false
                                                      ) {
                                                        openMessagePopUp();
                                                      } else {
                                                        openRegistrationPopUp();
                                                      }
                                                      localStorage.setItem(
                                                        "AcceptBeforeLogin",
                                                        JSON.stringify({
                                                          path: "/tournament-team-registration",
                                                          tournamentId:
                                                            tournamentId,
                                                        })
                                                      );
                                                    }
                                                  }}
                                                >
                                                  Reject
                                                </Button>
                                              </Col>
                                            </Row>
                                          </React.Fragment>
                                        );
                                      }
                                    )}
                                  </div>
                                </>
                              ) : (
                                <div className="d-flex justify-content-center  text-white">
                                  <span className="text-center NoDataDiv1">
                                    Team Data does not exist's
                                  </span>
                                </div>
                              )}
                            </>
                          ) : (
                            ""
                          )}
                          {authUser && (
                            <>
                              {teamsDataAfterLogin?.isAccepted === false ? (
                                <>
                                  {teamsDataAfterLogin?.teams?.length ? (
                                    <>
                                      <div
                                        className={
                                          teamsDataAfterLogin?.teams?.length > 5
                                            ? "p-4 tableHeader tableStyle"
                                            : "p-4 tableHeader"
                                        }
                                      >
                                        <Row className="TeamHeader">
                                          <Col xs={8}>Team Name</Col>
                                          <Col xs={4}>Status</Col>
                                        </Row>
                                        {teamsDataAfterLogin?.teams?.map(
                                          (ele: any, i: any) => {
                                            return (
                                              <React.Fragment key={i}>
                                                <Row className="TeamBody">
                                                  <Col xs={8}>
                                                    <span className="TeamImag">
                                                      {
                                                        <img
                                                          src={ele?.teamLogo ? ele?.teamLogo : Logo}
                                                          width="50px"
                                                          height="50px"
                                                          alt="Logo"
                                                        />
                                                      }
                                                    </span>
                                                    {ele?.teamName}
                                                  </Col>
                                                  <Col
                                                    xs={4}
                                                    className="d-flex justify-content-center"
                                                  >
                                                    <Button
                                                      className="me-2"
                                                      style={{
                                                        backgroundColor: "#fff",
                                                        color: "#000",
                                                      }}
                                                      onClick={() => {
                                                        getUserStatus({
                                                          tournamentId:
                                                            tournamentId,
                                                          teamId: ele?._id,
                                                          userId: authUser?._id,
                                                          email:
                                                            authUser?.email,
                                                          status: "accepted",
                                                        });
                                                      }}
                                                    >
                                                      Accept
                                                    </Button>
                                                    <Button
                                                      style={{
                                                        backgroundColor: "#fff",
                                                        color: "#000",
                                                      }}
                                                      onClick={() => {
                                                        getUserStatus({
                                                          tournamentId:
                                                            tournamentId,
                                                          teamId: ele?._id,
                                                          userId: authUser?._id,
                                                          email:
                                                            authUser?.email,
                                                          status: "rejected",
                                                        });
                                                      }}
                                                    >
                                                      Reject
                                                    </Button>
                                                  </Col>
                                                </Row>
                                              </React.Fragment>
                                            );
                                          }
                                        )}
                                      </div>
                                    </>
                                  ) : (
                                    <div className="d-flex justify-content-center  text-white">
                                      <span className="text-center NoDataDiv1">
                                        Team Data does not exist's
                                      </span>
                                    </div>
                                  )}
                                </>
                              ) : (
                                ""
                              )}
                              {teamsDataAfterLogin?.isAccepted === true ? (
                                <>
                                  {teamsDataAfterLogin?.users?.teamUsers
                                    ?.length ? (
                                    <>
                                      <div
                                        className={
                                          teamsDataAfterLogin?.users?.teamUsers
                                            ?.length > 5
                                            ? "p-4 tableHeader tableStyle"
                                            : "p-4 tableHeader"
                                        }
                                      >
                                        <div className="d-block text-white TeamLabel">
                                          <div>
                                            <label className="">
                                              Team Logo:
                                            </label>
                                            <img
                                              className="m-2"
                                              src={
                                                teamsDataAfterLogin?.users
                                                  ?.teamLogo
                                              }
                                              style={{
                                                borderRadius: "50%",
                                              }}
                                              width="50px"
                                              height={"50px"}
                                              alt="logo"
                                            />
                                          </div>
                                          <div>
                                            <label>Team Name:</label>
                                            <span>
                                              {
                                                teamsDataAfterLogin?.users
                                                  ?.teamName
                                              }
                                            </span>
                                          </div>
                                        </div>
                                        <Row className="TeamHeader">
                                          <Col xs={3}>Player Name</Col>
                                          <Col xs={3}>IGN</Col>
                                          <Col xs={3}>DiscordId</Col>
                                          <Col xs={3}>Game Rank</Col>
                                        </Row>
                                        {teamsDataAfterLogin?.users?.teamUsers?.map(
                                          (ele: any, i: any) => {
                                            return (
                                              <React.Fragment key={i}>
                                                <Row className="TeamBody">
                                                  <Col xs={3}>{ele?.name}</Col>
                                                  <Col xs={3}>{ele?.IGN}</Col>
                                                  <Col xs={3}>
                                                    {ele?.discordId}
                                                  </Col>
                                                  <Col xs={3}>
                                                    {ele?.gameRank}
                                                  </Col>
                                                </Row>
                                              </React.Fragment>
                                            );
                                          }
                                        )}
                                      </div>
                                    </>
                                  ) : (
                                    <div className="d-flex justify-content-center  text-white">
                                      <span className="text-center NoDataDiv1">
                                        Team Data does not exist's
                                      </span>
                                    </div>
                                  )}
                                </>
                              ) : (
                                ""
                              )}
                            </>
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {name === "prizePool" ? (
                    <>
                      <img
                        className="PoolPriceImg"
                        src={tournamentDetails?.prizePoolImage}
                        width="100%"
                        height={"100%"}
                        alt={"Prize Pool"}
                      />
                    </>
                  ) : (
                    ""
                  )}
                </>
              )}
            </Card>
          </Col>
          <Col xs={0} md={1}></Col>
        </Row>
        <div className="sepDiv"></div>

        <div>
          <div className="d-flex justify-content-center text-white fontText">
            <h1>Frequently Asked Questions!</h1>
          </div>
          <div className="mt-5">
            <FrequentlyAsked
              {...props}
              frequentlyAskedQuestions={frequentlyAskedQuestions}
            />
          </div>
        </div>
        <div>
          <FoterDiv />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  console.log("state", state);
  return {
    ...state,
    userDetailsByEmailId: state?.userReducer?.getUserByEmailId?.data,
    tournamentByIdDetails:
      state?.tournamentReducer?.tournamentById?.data?.docs[0],
    beforeLoginTeamData:
      state?.tournamentReducer?.getTeamsByTournamentBeforeLogin,
    afterLoginTeamData:
      state?.tournamentReducer?.getTeamsOrPlayersByTournamentIdAndUserId,
  };
};
export default connect(mapStateToProps, {
  fetchTournamentById,
  getTeamsByTournamentBeforeLogin,
  getTeamsOrPlayersByTournamentIdAndUserId,
  getUsersByEmailId,
  UserStatus,
})(TournamentNewRegistration);
