import React, { useEffect, useState } from "react";
import { Row, Col, Card } from "reactstrap";
import { Pagination } from "@mui/material";

import Select from "react-select";
import { Grid } from "@mui/material";
import { Link } from "react-router-dom";
import { useNavigate, useLocation } from "react-router";
import { DatePicker, Stack } from "rsuite";
import { connect } from "react-redux";
//components
import SideBar from "../../components/verticalMenu/SideBar";
import notifications from "../../components/Notifications/Notification";
import Teams from "./Teams";
//store
import {
  fetchTournamentById,
  fetchTeams,
} from "../../Store/tournament/actions";

var offset = 0;
var limit = 10;
var totCnt = 0;
var totPages = 0;
var toVal = 0;
var fromVal = 0;
var data: any = [];
const ViewTournamentById = (props: any) => {
  const [teamsDisplayFlag, setTeamsDisplayFlag] = useState(false);

  const tournamentSearch = useLocation().search;
  const tournamentId = new URLSearchParams(tournamentSearch).get(
    "tournamentId"
  );
  console.log("location", tournamentId);
  useEffect(() => {
    if (tournamentId) {
      props.fetchTournamentById(tournamentId, (res: any) => {
        if (res.success) {
          notifications({
            title: "Success!",
            message: res?.message,
            type: "success",
          });
        } else {
          notifications({
            title: "Error!",
            message: res?.message,
            type: "danger",
          });
        }
      });
    }
  }, []);

  const tournamentDetails = props?.tournamentByIdDetails;
  console.log("props", props?.tournamentByIdDetails);
  let HeaderData = {
    columns: [
      {
        label: " Player Name",
        field: "name",
        width: 1,
      },
      {
        label: "Email",
        field: "email",
        width: 1,
      },
      {
        label: "Phone",
        field: "phone",
        width: 2,
      },
      {
        label: "Game Rank",
        field: "gameRank",
        width: 2,
      },
      {
        label: "Discord ID",
        field: "discordId",
        width: 2,
      },
    ],
    // rows: trips,
  };
  let handleChangePage = (event: any, val?: any) => {
    console.log("val", val);
    offset = (val - 1) * 10;
    toVal = offset + 10;
    // getAllData();
  };
  if (props?.teamDetails !== undefined) {
    let teamDetails = props?.teamDetails?.data;
    offset = teamDetails?.offset;
    totCnt = teamDetails?.length;
    fromVal = totCnt !== 0 ? offset + 1 : fromVal;
    toVal = teamDetails?.offset + teamDetails?.docs?.length;
    totPages = Math.ceil(totCnt / 10);
    data = [];
    props.teamDetails?.data?.docs?.map((ele: any, i: any) => {
      data.push({
        id: ele?._id,
        teamLogo: ele?.teamLogo,
        teamName: ele?.teamName,
        tournamentName: ele?.tournamentsDeatils?.label,
        users: ele?.users,
        // action: action(ele),
      });
    });
    // const dataFilter = data.filter(
    //   (v: any, i: any, a: any) => a.findIndex((v2: any) => v2.id === v.id) === i
    // );
    // data = dataFilter;
  }
  return (
    <>
      <div className="pageContent">
        <div className="pageContentChild">
          <div>
            <SideBar></SideBar>
          </div>
          <div className="p-3 w-100">
            <Grid container className="viewTournament">
              <Grid item xs={12} md={6} lg={4} className="p-4">
                <label className="mb-3 text-white">Game</label>
                <input
                  className="text-capitalize"
                  type="string"
                  name="game"
                  value={tournamentDetails?.gameLabel}
                  readOnly
                />
              </Grid>

              <Grid item xs={12} md={6} lg={4} className="p-4">
                <label className="mb-3 text-white">Tournament Name</label>
                <input
                  className="text-capitalize"
                  type="text"
                  id="tournamentName"
                  name="label"
                  value={tournamentDetails?.label}
                  readOnly
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4} className="p-4">
                <label className="mb-3 text-white">Tournament Format</label>
                <input
                  type="string"
                  name="formatt"
                  value={tournamentDetails?.format}
                  readOnly
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4} className="p-4">
                <label className="mb-3 text-white">Max. number of teams</label>
                <input
                  type="number"
                  name="teamSize"
                  value={tournamentDetails?.teamSize}
                  readOnly
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4} className="p-4">
                <label className="mb-3 text-white">
                  Max. number of players in a team{" "}
                </label>
                <input
                  type="number"
                  name="playerInTeam"
                  value={tournamentDetails?.playerInTeam}
                  className=" inp_text text-capitalize"
                  readOnly
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4} className="p-4">
                <label className="mb-3 text-white">Start Date</label>
                <div>
                  <DatePicker
                    style={{
                      width: "100%",
                    }}
                    cleanable={false}
                    format="yyyy-MM-dd HH:mm"
                    name="startDate"
                    id="startDate"
                    value={
                      tournamentDetails?.startDate
                        ? new Date(tournamentDetails?.startDate)
                        : null
                    }
                    readOnly
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={6} lg={4} className="p-4">
                <label className="mb-3 text-white">End Date</label>
                <div>
                  <DatePicker
                    style={{
                      width: "100%",
                    }}
                    format="yyyy-MM-dd HH:mm"
                    name="endDate"
                    id="endDate"
                    cleanable={false}
                    value={
                      tournamentDetails?.endDate
                        ? new Date(tournamentDetails?.endDate)
                        : null
                    }
                    readOnly
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={6} lg={4} className="p-4">
                <label className="mb-3 text-white">Tournament Logo</label>
                <div>
                  <span className="ms-3">
                    <img
                      src={tournamentDetails?.logo || ""}
                      alt="TournamentLogo"
                      width="100px"
                      height="100px"
                    />
                  </span>
                </div>
              </Grid>
              <Grid item xs={12} md={6} lg={4} className="p-4">
                <label className="mb-3 text-white">Tournament Rule</label>
                <div>
                  {/* <a href={"tournamentDetails?.rules"} target="_blank">
                    Rules
                   </a> */}
                  <embed
                    src={tournamentDetails?.rules}
                    width="50px"
                    height="50px"
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={6} lg={4} className="p-4">
                <div>
                  <button
                    className="m-4  seeMoreButton"
                    onClick={() => {
                      props.fetchTeams(
                        offset,
                        1000,
                        { tournament: tournamentDetails?.label },
                        () => {}
                      );
                    }}
                  >
                    See More...
                  </button>
                </div>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={4}>
                  <Grid item xs={12} md={12} className="mt-2">
                    {totCnt ? (
                      <>
                        <Row className="ms-4 me-4">
                          {data?.map((ele: any, i: any) => {
                            console.log("ele", ele);
                            return (
                              <>
                                <Row className="m-3">
                                  <Col xs="3">
                                    Team Logo:
                                    <img
                                      src={ele?.teamLogo}
                                      width="50"
                                      height={50}
                                      alt="TeamLogo"
                                    />
                                  </Col>
                                  <Col xs="3">Team Name: {ele?.teamName}</Col>
                                  <Col xs="3">
                                    Tournament Name: {ele?.tournamentName}
                                  </Col>
                                  {/* <Col xs="3">Action: {ele?.action}</Col> */}
                                  <Col xs="12">
                                    <Row className="HeaderRow">
                                      {HeaderData?.columns?.map(
                                        (headItem, index) => {
                                          return (
                                            <React.Fragment key={index}>
                                              <Col
                                                className="IndexDatatableNeoCol"
                                                key={index}
                                                style={{
                                                  fontSize: "15px",
                                                  fontWeight: "700",
                                                  lineHeight: "18px",
                                                  fontFamily: "Roboto",
                                                }}
                                              >
                                                {headItem.label}{" "}
                                              </Col>
                                            </React.Fragment>
                                          );
                                        }
                                      )}
                                    </Row>
                                    <Row className="ContentRow">
                                      {ele?.users?.map(
                                        (ele: any, index: any) => {
                                          return (
                                            <React.Fragment key={index}>
                                              <Col
                                                xs={2}
                                                className="IndexDatatableNeoCol"
                                                style={{
                                                  fontSize: "15px",
                                                  fontWeight: "700",
                                                  lineHeight: "18px",
                                                  fontFamily: "Roboto",
                                                }}
                                              >
                                                {ele?.name}
                                              </Col>
                                              <Col
                                                xs={3}
                                                className="IndexDatatableNeoCol"
                                                style={{
                                                  fontSize: "15px",
                                                  fontWeight: "700",
                                                  lineHeight: "18px",
                                                  fontFamily: "Roboto",
                                                }}
                                              >
                                                {ele?.email}
                                              </Col>
                                              <Col
                                                xs={2}
                                                className="IndexDatatableNeoCol"
                                                style={{
                                                  fontSize: "15px",
                                                  fontWeight: "700",
                                                  lineHeight: "18px",
                                                  fontFamily: "Roboto",
                                                }}
                                              >
                                                {ele?.phoneNumber}
                                              </Col>
                                              <Col
                                                xs={2}
                                                className="IndexDatatableNeoCol"
                                                style={{
                                                  fontSize: "15px",
                                                  fontWeight: "700",
                                                  lineHeight: "18px",
                                                  fontFamily: "Roboto",
                                                }}
                                              >
                                                {ele?.gameRank}
                                              </Col>
                                              <Col
                                                xs={2}
                                                className="IndexDatatableNeoCol"
                                                style={{
                                                  fontSize: "15px",
                                                  fontWeight: "700",
                                                  lineHeight: "18px",
                                                  fontFamily: "Roboto",
                                                }}
                                              >
                                                {ele?.discordId}
                                              </Col>
                                            </React.Fragment>
                                          );
                                        }
                                      )}
                                    </Row>
                                  </Col>
                                </Row>
                              </>
                            );
                          })}
                        </Row>
                        <div className=" m-2">
                          <Row className="m-4">
                            <Col xs={6} className="text-white">
                              <Stack spacing={2}>
                                <Pagination
                                  className="pagination"
                                  size="small"
                                  shape="rounded"
                                  page={offset / 10 + 1}
                                  count={totPages}
                                  color="primary"
                                  // onChange={(e)=>{handleChangePage(e,offset / 10 + 1)}}
                                  onChange={(e, val) => {
                                    handleChangePage(e, val);
                                    console.log("event", e, val);
                                  }}
                                  defaultPage={1}
                                  siblingCount={1}
                                />
                              </Stack>
                            </Col>
                            <Col xs={6}>
                              <span className="text-white">
                                Showing {fromVal} to {toVal} of {totCnt} entries
                              </span>
                            </Col>
                          </Row>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  // console.log("state", state,state?.tournamentReducer?.tournamentDetail?.data?.docs);
  return {
    ...state,
    teamDetails: state?.tournamentReducer?.fetchTeams,
    tournamentByIdDetails:
      state?.tournamentReducer?.tournamentById?.data?.docs[0],
  };
};
export default connect(mapStateToProps, {
  fetchTournamentById,
  fetchTeams,
})(ViewTournamentById);
