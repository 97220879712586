import { combineReducers } from "redux";
// importing all reducers
import authLoginReducer from "./auth/registration/reducer";
import userReducer from "./user/reducer";
import gameReducer from "./game/reducer"
import tournamentReducer from "./tournament/reducer";
import userTournamentReducer from "./userTournament/reducer";
import matchReducer from "./match/reducer"
import matchPerfomanceReducer from "./matchPerfomance/reducer";
import productsReducer from "./products/reducer";
import leaderBoardReducer from "./leaderBoard/reducer";
import razarPayReducer from "./razorPay/reducer";
import whatsAppBulkReducer from "./whatsAppBulk/reducer";
import whatsAppMediaReducer from "./whatsAppMedia/reducer";
const rootReducer= combineReducers({
    authLoginReducer,
    userReducer,
    gameReducer,
    tournamentReducer,
    userTournamentReducer,
    matchReducer,
    matchPerfomanceReducer,
    leaderBoardReducer,
    productsReducer,
    razarPayReducer,
    whatsAppBulkReducer,
    whatsAppMediaReducer
})
export default rootReducer;