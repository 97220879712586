export const types = {
    ADD_TOURNAMENT:"ADD_TOURNAMENT",
    ADD_TOURNAMENT_SUCCESS:"ADD_TOURNAMENT_SUCCESS",
    FETCH_TOURNAMENTS:"FETCH_TOURNAMENTS",
    FETCH_TOURNAMENTS_SUCCESS:"FETCH_TOURNAMENTS_SUCCESS",
    EDIT_TOURNAMENT:"EDIT_TOURNAMENT",
    EDIT_TOURNAMENT_SUCCESS:"EDIT_TOURNAMENT_SUCCESS",
    DELETE_TOURNAMENT:"DELETE_TOURNAMENT",
    DELETE_TOURNAMENT_SUCCESS:"DELETE_TOURNAMENT_SUCCESS",
    PRESIGNED_URL_LOGO:"PRESIGNED_URL_LOGO",
    PRESIGNED_URL_LOGO_SUCCESS:"PRESIGNED_URL_LOGO_SUCCESS",
    PRESIGNED_URL_RULE:"PRESIGNED_URL_RULE",
    PRESIGNED_URL_RULE_SUCCESS:"PRESIGNED_URL_RULE_SUCCESS",
    PRESIGNED_URL_RULE_DOCX:"PRESIGNED_URL_RULE_DOCX",
    PRESIGNED_URL_RULE_DOCX_SUCCESS:"PRESIGNED_URL_RULE_DOCX_SUCCESS",
    UPLOAD_IMAGES:"UPLOAD_IMAGES",
    UPLOAD_IMAGES_SUCCESS:"UPLOAD_IMAGES_SUCCESS",
    TOURNAMENT_BY_ID:" TOURNAMENT_BY_ID",
    TOURNAMENT_BY_ID_SUCCESS:" TOURNAMENT_BY_ID_SUCCESS",
    FETCH_TEAMS:"FETCH_TEAMS",
    FETCH_TEAMS_SUCCESS:"FETCH_TEAMS_SUCCESS",
    DELETE_TEAM_BY_ID:"DELETE_TEAM_BY_ID",
    DELETE_TEAM_BY_ID_SUCCESS:"DELETE_TEAM_BY_ID_SUCCESS",
    TOTAL_ACTIVE_EVENTS:"TOTAL_ACTIVE_EVENTS",
    TOTAL_ACTIVE_EVENTS_SUCCESS:"TOTAL_ACTIVE_EVENTS_SUCCESS",
    UPCOMING_EVENTS:"UPCOMING_EVENTS",
    UPCOMING_EVENTS_SUCCESS:"UPCOMING_EVENTS_SUCCESS",
    ADD_VIDEO_TAGE:"ADD_VIDEO_TAGE",
    ADD_VIDEO_TAGE_SUCCESS:"ADD_VIDEO_TAGE_SUCCESS",
    GET_ADD_VIDEO_TAGE:'GET_ADD_VIDEO_TAGE',
    GET_ADD_VIDEO_TAGE_SUCCESS:"GET_ADD_VIDEO_TAGE_SUCCESS",
    UPDATE_VIDEO_TAGE:"UPDATE_VIDEO_TAGE",
    UPDATE_VIDEO_TAGE_SUCCESS:"UPDATE_VIDEO_TAGE_SUCCESS",
    DELETE_VIDEO_TAGE:"DELETE_VIDEO_TAGE",
    DELETE_VIDEO_TAGE_SUCCESS:"DELETE_VIDEO_TAGE_SUCCESS",
    DELETE_VIDEO_TAGE_BY_ID:"DELETE_VIDEO_TAGE_BY_ID",
    DELETE_VIDEO_TAGE_BY_ID_SUCCESS:"DELETE_VIDEO_TAGE_BY_ID_SUCCESS",
    ADD_TOURAMENT_TUMBNAIL:"ADD_TOURAMENT_TUMBNAIL",
    ADD_TOURAMENT_TUMBNAIL_SUCCESS:"ADD_TOURAMENT_TUMBNAIL_SUCCESS",
    ADD_TOURAMENT_FIXTURE:"ADD_TOURAMENT_FIXTURE",
    ADD_TOURAMENT_FIXTURE_SUCCESS:"ADD_TOURAMENT_FIXTURE_SUCCESS",
    ADD_POOL_PRICE:"ADD_POOL_PRICE",
    ADD_POOL_PRICE_SUCCESS:"ADD_POOL_PRICE_SUCCESS",
    TOURNAMENT_USER_STATUS:"TOURNAMENT_USER_STATUS",
    TOURNAMENT_USER_STATUS_SUCCESS:"TOURNAMENT_USER_STATUS_SUCCESS",
    GET_TEAMS_OR_PLAYERS_BY_TOURNAMNTBYID_AND_USERID:"GET_TEAMS_OR_PLAYERS_BY_TOURNAMNTBYID_AND_USERID",
    GET_TEAMS_OR_PLAYERS_BY_TOURNAMNTBYID_AND_USERID_SUCCESS:"GET_TEAMS_OR_PLAYERS_BY_TOURNAMNTBYID_AND_USERID_SUCCESS",
    GET_TEAMS_BY_TOURNAMENT_BEFORE_LOGIN:"GET_TEAMS_BY_TOURNAMENT_BEFORE_LOGIN",
    GET_TEAMS_BY_TOURNAMENT_BEFORE_LOGIN_SUCCESS:"GET_TEAMS_BY_TOURNAMENT_BEFORE_LOGIN_SUCCESS"
}