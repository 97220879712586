import { AnyAaaaRecord } from "dns";
import { types } from "./actionTypes";

// creating action
export const addTournament = (payload: any, callback: any) => {
  return {
    type: types.ADD_TOURNAMENT,
    payload,
    callback,
  };
};
export const fetchTournament = (
  offset: any,
  limit: any,
  filters: any,
  callback: any
) => {
  return {
    type: types.FETCH_TOURNAMENTS,
    offset,
    limit,
    filters,
    callback,
  };
};
export const fetchTournamentById = (tournamentId: any, callback: any) => {
  return {
    type: types.TOURNAMENT_BY_ID,
    tournamentId,
    callback,
  };
};

export const updateTournament = (payload: any, callback: any) => {
  return {
    type: types.EDIT_TOURNAMENT,
    payload,
    callback,
  };
};
export const deleteTournament = (payload: any, callback: any) => {
  return {
    type: types.DELETE_TOURNAMENT,
    payload,
    callback,
  };
};

export const fetchPresignedUrlRulesForDocx = (callback: any) => {
  return {
    type: types.PRESIGNED_URL_RULE_DOCX,
    callback,
  };
};
export const uploadImages = (uri: any, data: any, headers: any) => ({
  type: types.UPLOAD_IMAGES,
  uri,
  data,
  headers,
});
export const fetchTeams = (
  offset: any,
  limit: any,
  filters: any,
  callback: any
) => {
  return {
    type: types.FETCH_TEAMS,
    offset,
    limit,
    filters,
    callback,
  };
};
export const deleteTeamById = (payload: any, callback: any) => {
  return {
    type: types.DELETE_TEAM_BY_ID,
    payload,
    callback,
  };
};
export const totalActiveEvents = (
  offset: any,
  limit: any,
  filters:any,
  callback: any
) => {
  return {
    type: types.TOTAL_ACTIVE_EVENTS,
    offset,
    limit,
    filters,
    callback,
  };
};
export const totalUpCommingEvents = (
  offset: any,
  limit: any,
  filters:any,
  callback: any
) => {
  return {
    type: types.UPCOMING_EVENTS,
    offset,
    limit,
    filters,
    callback,
  };
};
export const addTaggedVideo = (payload: any, callback: any) => {
  return {
    type: types.ADD_VIDEO_TAGE,
    payload,
    callback,
  };
};
export const getTaggedVideo = ( filters:any,callback: any) => {
  return {
    type: types.GET_ADD_VIDEO_TAGE,
    filters,
    callback,
  };
};
export const updateTaggedVideo = (payload: any, callback: any) => {
  return {
    type: types.UPDATE_VIDEO_TAGE,
    payload,
    callback,
  };
}
export const deleteTaggedVideo = (payload: any, callback: any) => {
  return {
    type: types.DELETE_VIDEO_TAGE,
    payload,
    callback,
  };
}
export const deleteTaggedVideoById = (payload: any, callback: any) => {
  return {
    type: types.DELETE_VIDEO_TAGE_BY_ID,
    payload,
    callback,
  };
}
export const fetchPresignedUrlLogo = (callback: any) => {
  return {
    type: types.PRESIGNED_URL_LOGO,
    callback,
  };
};
export const fetchPresignedUrlRules = (callback: any) => {
  return {
    type: types.PRESIGNED_URL_RULE,
    callback,
  };
};
export const fetchPresignedUrlTumbnail = (callback: any) => {
  return {
    type: types.ADD_TOURAMENT_TUMBNAIL,
    callback,
  };
};
export const fetchPresignedUrlFixtures = (payload:any,callback: any) => {
  return {
    type: types.ADD_TOURAMENT_FIXTURE,
    payload,
    callback,
  };
};
export const fetchPresignedUrlPoolPrice = (callback: any) => {
  return {
    type: types.ADD_POOL_PRICE,
    callback,
  };
};
export const UserStatus= (payload: any, callback: any) => {
  return {
    type: types.TOURNAMENT_USER_STATUS,
    payload,
    callback,
  };
}
export const getTeamsOrPlayersByTournamentIdAndUserId= (filters: any, callback: any) => {
  return {
    type: types.GET_TEAMS_OR_PLAYERS_BY_TOURNAMNTBYID_AND_USERID,
    filters,
    callback,
  };
}
export const getTeamsByTournamentBeforeLogin= (filters: any, callback: any) => {
  return {
    type: types.GET_TEAMS_BY_TOURNAMENT_BEFORE_LOGIN,
    filters,
    callback,
  };
}