export const types = {
  GET_SCORES_BY_LEADER:"GET_SCORES_BY_LEADER",
  GET_SCORES_BY_LEADER_SUCCESS:"GET_SCORES_BY_LEADER_SUCCESS",
  GET_SCORES_BY_MATCH_LEADER:"GET_SCORES_BY_MATCH_LEADER",
  GET_SCORES_BY_MATCH_LEADER_SUCCESS:"GET_SCORES_BY_MATCH_LEADER_SUCCESS",
  GET_SCORES_BY_TOURNAMENT_LEADER:"ET_SCORES_BY_TOURNAMENT_LEADER",
  GET_SCORES_BY_TOURNAMENT_LEADER_SUCCESS:"GET_SCORES_BY_TOURNAMENT_LEADER_SUCCESS",
  GET_TOURNAMNET_WISE_PLAYER_AND_TEAM_LEADERBOARD:"GET_TOURNAMNET_WISE_PLAYER_AND_TEAM_LEADERBOARD",
  GET_TOURNAMNET_WISE_PLAYER_AND_TEAM_LEADERBOARD_SUCCESS:"GET_TOURNAMNET_WISE_PLAYER_AND_TEAM_LEADERBOARD_SUCCESS",
  GET_OVERALL_LEADERBOARD_SCORES:"GET_OVERALL_LEADERBOARD_SCORES",
  GET_OVERALL_LEADERBOARD_SCORES_SUCCESS:"GET_OVERALL_LEADERBOARD_SCORES_SUCCESS"
}