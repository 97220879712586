import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import { types } from "./actionTypes";
import axios, { AxiosResponse } from "axios";

//medthods
import { getApi, postApi, putApi } from "../.././helpers/auth_helpers";
import { SocialIcon } from "react-social-icons";

// workers
function* workerGetUsers({ offset, limit, filters, callback }: any) {
  let name = filters?.name ? filters?.name : "";
  let email = filters?.email ? filters?.email : "";
  let social = filters?.social ? filters?.social : "";
  console.log("filters", name, email, social, filters);
  try {
    const url: string =
      process.env.REACT_APP_SERVERURL +
      "/user/users?offset=" +
      offset +
      "&limit=" +
      limit +
      "&name=" +
      name +
      "&email=" +
      email +
      "&socialUser=" +
      social;
    const response: AxiosResponse = yield call(postApi, url);
    callback(response);
    // console.log("users",response)
    yield put({
      type: types.GET_USERS_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workerUsersActiveDeActive({ payload, callback }: any) {
  try {
    const url: string =
      process.env.REACT_APP_SERVERURL + "/user/activateOrDeactivate";
    const response: AxiosResponse = yield call(postApi, url, payload);
    callback(response);
    // console.log("users",response)
    yield put({
      type: types.USER_ACTIVE_DE_ACTIVE_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workerUpdateUser({ payload, callback }: any) {
  try {
    const url: string = process.env.REACT_APP_SERVERURL + "/user/updateRole";
    const response: AxiosResponse = yield call(putApi, url, payload);
    callback(response);
    console.log("users",response)
    yield put({
      type: types.UPDATE_USER_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workerUserStatus({ payload, callback }: any) {
  try {
    const url: string = process.env.REACT_APP_SERVERURL + "/tournament/userStatus";
    const response: AxiosResponse = yield call(putApi, url, payload);
    callback(response);
    console.log("users",response)
    yield put({
      type: types.USER_STATUS_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workerSendinEmail({payload, callback }: any) {

  try {
    const url: string =
      process.env.REACT_APP_SERVERURL +
      "/notification/sendMails"
    const response: AxiosResponse = yield call(postApi, url,payload);
    callback(response);
    // console.log("users",response)
    yield put({
      type: types.SENDING_EMAIL_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workerSavingEmailsWithSaveToGroup({ payload, callback }: any) {
  try {
    const url: string =
      process.env.REACT_APP_SERVERURL + "/notification/createGroup";
    const response: AxiosResponse = yield call(postApi, url, payload);
    callback(response);
    // console.log("users",response)
    yield put({
      type: types.STORING_MAIL_WITH_SAVE_TO_GROUP_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workerGettingEmailsWithSaveToGroup({offset,limit, callback }: any) {
  try {
    const url: string =
      process.env.REACT_APP_SERVERURL + "/notification/groups?offset"+offset+"&limit"+limit;
    const response: AxiosResponse = yield call(getApi, url);
    callback(response);
    // console.log("users",response)
    yield put({
      type: types.GETTING_MAIL_WITH_SAVE_TO_GROUP_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workerSendinWhatsAppNotifiation({payload, callback }: any) {

  try {
    const url: string =
      process.env.REACT_APP_SERVERURL +
      "/notification/whatsAppMessage"
    const response: AxiosResponse = yield call(postApi, url,payload);
    callback(response);
    // console.log("users",response)
    yield put({
      type: types.SENDING_WHATS_APP_NOTIFICATIONS_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workerUpdateUserProfile({ payload, callback }: any) {
  console.log("payload1",payload)
  try {
    const url: string = process.env.REACT_APP_SERVERURL + "/user/update";
    const response: AxiosResponse = yield call(putApi, url, payload);
    callback(response);
    console.log("users",response)
    yield put({
      type: types.UPDATE_USER_PROFILE_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workerGetUserByEmailId({ emailId, callback }: any) {
  ;
  try {
    const url: string =
      process.env.REACT_APP_SERVERURL +
      "/user/userByEmail?&emailId=" +
      emailId;
    const response: AxiosResponse = yield call(getApi, url);
    callback(response);
    // console.log("users",response)
    yield put({
      type: types.GET_USER_BY_EMAILID_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workerGetUserByUserId({ userId, callback }: any) {
  ;
  try {
    const url: string =
      process.env.REACT_APP_SERVERURL +
      "/user/userByuserId?&userId=" +
      userId;
    const response: AxiosResponse = yield call(getApi, url);
    callback(response);
    // console.log("users",response)
    yield put({
      type: types.GET_USER_BY_USERID_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
// watchers
export function* watchGetUsers() {
  yield takeEvery(types.GET_USERS, workerGetUsers);
}
export function* watchUsersActiveDeActive() {
  yield takeEvery(types.USER_ACTIVE_DE_ACTIVE, workerUsersActiveDeActive);
}
export function* watchUpdateUser() {
  yield takeEvery(types.UPDATE_USER, workerUpdateUser);
}
export function* watchUserStatus() {
  yield takeEvery(types.USER_STATUS, workerUserStatus);
}
export function* watchSendingEmail() {
  yield takeEvery(types.SENDING_EMAIL, workerSendinEmail);
}
export function* watchSavingEmailsWithSaveToGroup() {
  yield takeEvery(types.STORING_MAIL_WITH_SAVE_TO_GROUP, workerSavingEmailsWithSaveToGroup);
}
export function* watchGettingEmailsWithSaveToGroup() {
  yield takeEvery(types.GETTING_MAIL_WITH_SAVE_TO_GROUP, workerGettingEmailsWithSaveToGroup);
}
export function* watchSendingWhatsAppNotification() {
  yield takeEvery(types.SENDING_WHATS_APP_NOTIFICATIONS, workerSendinWhatsAppNotifiation);
}
export function* watchUpdateUserProfile() {
  yield takeEvery(types.UPDATE_USER_PROFILE, workerUpdateUserProfile);
}
export function* watchGetUserByEmailId() {
  yield takeEvery(types.GET_USER_BY_EMAILID, workerGetUserByEmailId);
}
export function* watchGetUserByUserId() {
  yield takeEvery(types.GET_USER_BY_USERID, workerGetUserByUserId);
}
function* usersSaga() {
  yield all([
    fork(watchGetUsers),
    fork(watchUsersActiveDeActive),
    fork(watchUpdateUser),
    fork(watchUserStatus),
    fork(watchSendingEmail),
    fork(watchSavingEmailsWithSaveToGroup),
    fork(watchGettingEmailsWithSaveToGroup),
    fork(watchSendingWhatsAppNotification),
    fork(watchUpdateUserProfile),
    fork(watchGetUserByEmailId),
    fork(watchGetUserByUserId)
  ]);
}
export default usersSaga;
