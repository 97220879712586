import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { FiArrowLeft } from "react-icons/fi";
import { Card, Grid } from "@mui/material";
//components
import SideBar from "../../components/verticalMenu/SideBar";
const ViewGame = (props: any) => {
  const location = useLocation();
  const gameDetail = location?.state?.gameDetails;
  console.log("gameDetails", props, location, gameDetail?.label);
  console.log("props", props);
  return (
    <>
      <div className="pageContent1">
        <div className="pageContentChild">
          <div>
            <SideBar></SideBar>
          </div>
          <div className="p-3 w-100">
            <div>
              <Link to="/games">
                <button className="m-4">
                  <FiArrowLeft className="cursor-pointer editIcon " />
                </button>
              </Link>
            </div>
            <h3 className="p-4">Game Details</h3>
            <h5 className="spanHeading m-4">Game</h5>
            <div className="">
              <input
                type="text"
                name="label"
                value={gameDetail?.label}
                readOnly
                className="form-control inp_text text-capitalize w-50 m-4"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewGame;
