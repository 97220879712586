import { getHeaders } from "./global_headers";
import jwt from "jwt-decode";
// Gets the logged in user data from local session
const getLoggedInUser: any = () => {
  const user = localStorage.getItem("authUser") || sessionStorage.getItem("authUser");
  if (user) return { user: JSON.parse(user) };
  return null;
};
const getUserToken = () => {
  let token = localStorage.getItem("accessToken") || sessionStorage.getItem("accessToken");
  token = token || null;
  return token;
};
//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser().user !== null;
};
const postApi = (url: any, values?: any) => {
  let headers = getHeaders();

  const parameters = {
    method: "POST",
    headers: headers,
    body: JSON.stringify(values),
  };

  return fetch(url, parameters)
    .then((response) => {
      const result = tokenValidator(response);
      return result;
      // return response.json();
    })
    .then((json) => {
      return json;
    });
};

const patchApi = (url: any, values?: any) => {
  let headers = getHeaders();

  const parameters: any = {
    method: "PATCH",
    headers: headers,
    //  mode: "cors", // or without this line
    redirect: "follow",
    body: JSON.stringify(values),
  };
  return fetch(url, parameters)
    .then((response) => {
      const result = tokenValidator(response);
      // return response.json();
      return result;
    })
    .then((json) => {
      return json;
    });
};
const putApi = (url: any, values?: any) => {
  let headers = getHeaders();

  const parameters: any = {
    method: "PUT",
    headers: headers,
    //  mode: "cors", // or without this line
    redirect: "follow",
    body: JSON.stringify(values),
  };

  return fetch(url, parameters)
    .then((response) => {
      const result = tokenValidator(response);
      return result;
      // return response.json();
    })
    .then((json) => {
      return json;
    });
};
const getApi = (url: any) => {
  let headers = getHeaders();
  return fetch(url, { headers })
    .then((response) => {
      const result = tokenValidator(response);
      return result;
      // return response.json();
    })
    .then((json) => {
      return json;
    });
};
const deleteApi = (url: any, values?: any) => {
  let headers = getHeaders();

  const parameters = {
    method: "DELETE",
    headers: headers,
    body: JSON.stringify(values),
  };
  return fetch(url, parameters)
    .then((response) => {
      const result = tokenValidator(response);
      // // if (result) return response.json();
      return result;
      // return response.json()
    })
    .then((json) => {
      return json;
    });
};
const putUploadImagesApi = (url: any, values: any, headers: any) => {
  console.log("headers", headers, values);
  const parameters = {
    method: "PUT",
    headers: headers,
    //  mode: "cors", // or without this line
    // redirect: "follow",
    body: values,
  };

  return fetch(url, parameters)
    .then((response) => {
      const result = tokenValidator(response);
      return result;
      // return response;
    })
    .then((json) => {
      return json;
    });
};
const tokenValidator = async (response: any) => {
  let responseJson = await response.json();
  if (
    responseJson.statusCode === 404 &&
    responseJson.message === "Unauthorized"
  ) {
    let newTokenResponse = await getNewToken();
    if (newTokenResponse.success === true) {
      let authUser: any = jwt(newTokenResponse.data.token);

      let rememberMe: any = localStorage.getItem("rememberMe");
      if (rememberMe === true) {
        localStorage.setItem("authUser", JSON.stringify(authUser));
        localStorage.setItem("accessToken", newTokenResponse.data.accessToken);
        localStorage.setItem(
          "refreshToken",
          newTokenResponse.data.refreshToken
        );
      } else {
        sessionStorage.setItem("authUser", JSON.stringify(authUser));
        sessionStorage.setItem("accessToken", newTokenResponse.data.accessToken);
        sessionStorage.setItem(
          "refreshToken",
          newTokenResponse.data.refreshToken
        );
      }
    }
  } else if (response.status === 403 && response.statusText === "Forbidden") {
    window.location.href = "/pages-401";
  } else return responseJson;
};

const getNewToken = async () => {
  let refreshToken = localStorage.getItem("authUser") || sessionStorage.getItem("authUser");
  let uri =
    process.env.REACT_APP_SERVERURL + `/auth/getAccessTokenByRefreshToken`;
  let refreshTokenPayload = {
    refreshToken: refreshToken,
  };
  let response = await postApi(uri, refreshTokenPayload);
  return response;
};

export {
  postApi,
  patchApi,
  deleteApi,
  getApi,
  putApi,
  putUploadImagesApi,
  getUserToken,
  isUserAuthenticated,
};
