import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import { types } from "./actionTypes";
import  { AxiosResponse } from "axios";

//medthods
import {
  postApi,
} from "../../helpers/auth_helpers";

// workers
function* workGetProducts({ callback }: any) {
  try {
    const url: string =process.env.REACT_APP_SERVERURL+"/lms/allProducts";
    const response: AxiosResponse = yield call(postApi, url);
    callback(response);
    console.log("users",response)
    yield put({
      type: types.GET_PRODUCTS_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
// watchers
export function* watchGetProducts() {
  yield takeEvery(types.GET_PRODUCTS, workGetProducts);
}


function* productsSaga() {
  yield all([
    fork(watchGetProducts),
 
  ]);
}
export default productsSaga;
