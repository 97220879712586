import { all } from "redux-saga/effects";
// importing all sagas
import authLoginSaga from "./auth/registration/saga";
import usersSaga from "./user/saga";
import gameSaga from "./game/saga";
import tournamentSaga from "./tournament/saga";
import userTournamentSaga from "./userTournament/saga";
import matchSaga from "./match/saga";
import matchPerfomanceSaga from "./matchPerfomance/saga";
import productsSaga from "./products/saga"
import leaderBoardSaga from "./leaderBoard/saga"
import razorPaySaga from "./razorPay/saga";
import whatsAppBulkSaga from "./whatsAppBulk/saga";
import whatsAppMediaSaga from "./whatsAppMedia/saga";
export default function* rootSaga(){
 yield all ([
     authLoginSaga(),
     usersSaga(),
     gameSaga(),
     tournamentSaga(),
     userTournamentSaga(),
     matchSaga(),
     matchPerfomanceSaga(),
     productsSaga(),
     leaderBoardSaga(),
     razorPaySaga(),
     whatsAppBulkSaga(),
     whatsAppMediaSaga()
 ])
}