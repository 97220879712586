import { AnyAaaaRecord } from "dns";
import {types } from "./actionTypes";

// creating action
export const addUserTournament = ( payload:any,callback:any) =>{
    return {
        type:types.ADD_USER_TOURNAMENT,
        payload,
        callback
    }
}
export const fetchUserTournament = ( offset:any,limit:any,filters:any,callback:any) =>{
    return {
        type:types.FETCH_USER_TOURNAMENTS,
        offset,
        limit,
        filters,
        callback
    }
}
export const fetchInvitedPlayers = (offset:any,limit:any,filters:any, callback:any) =>{
    return {
        type:types.FETCH_INVITED_PLAYER,
        offset,
        limit,
        filters,
        callback
    }
}
export const updateUserTournament = ( payload:any,callback:any) =>{
    return {
        type:types.EDIT_USER_TOURNAMENT,
        payload,
        callback
    }
}
export const deleteUserTournament = ( payload:any,callback:any) =>{
    return {
        type:types.DELETE_USER_TOURNAMENT,
        payload,
        callback
    }
}
export const fetchPresignedUrlTeamLogo = ( callback:any) =>{
    return {
        type:types.PRESIGNED_URL_TEAM_LOGO,
        callback
    }
}

export const uploadImagesUserTournament = (uri:any, data:any, headers:any) => ({
    type: types.UPLOAD_IMAGES_TEAM_LOGO,
    uri,
    data,
    headers,
  });
  