import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { Pagination, Stack } from "@mui/material";
import { Row, Col } from "reactstrap";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";
//components
import Loader from "../../components/Loader/Loader";
import SideBar from "../../components/verticalMenu/SideBar";

// store
import { getingMailWithSavedToGroup } from "../../Store/user/actions";

//global variable
let HeaderData = {
  columns: [
    {
      label: "Group Name",
      field: "label",
      width: 2,
    },
    {
      label: "CreatedAt",
      field: "createdAt",
      width: 2,
    },
    {
      label: "Action",
      field: "action",
      width: 2,
    },
  ],
  // rows: trips,
};
var offset = 0;
var limit = 10;
var totCnt = 0;
var totPages = 0;
var toVal = 0;
var fromVal = 0;
var data: any = [];
const GetSavedMails = (props: any) => {
  const [loading, setLoading] = useState(false);

  const navigator = useNavigate();
  useEffect(() => {
    getAllSavedMails();
  }, []);
  const getAllSavedMails = () => {
    setLoading(true);
    props.getingMailWithSavedToGroup(
      offset,
      limit,

      (res: any) => {
        setLoading(false);
      }
    );
  };
  let handleChangePage = (event: any, val?: any) => {
    console.log("val", val);
    offset = (val - 1) * 10;
    toVal = offset + 10;
    setLoading(true);
    props.getingMailWithSavedToGroup(
      offset,
      limit,

      (res: any) => {
        setLoading(false);
      }
    );
  };
  let action = (ele: any) => {
    return (
      <>
        <div className="UserButtons">
          <button
            className="p-2"
            onClick={() => {
              navigator("/sending-saved-mail", {
                state: {
                  groupMails: ele,
                },
              });
              window.scrollTo(0, 0)
            }}
          >
            Send Mail
          </button>
        </div>
      </>
    );
  };
  if (props.getSavedMails !== undefined) {
    let getSavedMails = props?.getSavedMails?.data;
    offset = getSavedMails?.offset;
    totCnt = getSavedMails?.length;
    fromVal = totCnt !== 0 ? offset + 1 : fromVal;
    toVal = getSavedMails?.offset + getSavedMails?.docs?.length;
    totPages = Math.ceil(totCnt / 10);
    data = [];
    props.getSavedMails?.data?.docs?.map((ele: any, i: any) => {
      data.push({
        _id: ele?._id,
        label: ele?.label,
        createdAt: ele?.createdAt
          ? moment(new Date(ele?.createdAt)).format("YYYY-MM-DD HH:mm")
          : "N/A",
        action: action(ele),
      });
    });
  }
  return (
    <>
      <div className="pageContent">
        <div className="pageContentChild">
          <div>
            <SideBar></SideBar>
          </div>
          <div className="p-3 w-100">
            <h3 className="mb-3">Saved Group Mails</h3>
            {loading ? (
              <>
                <div>
                  <Loader />
                </div>
              </>
            ) : (
              <>
                <Row className="HeaderRow">
                  {HeaderData?.columns?.map((headItem, index) => {
                    return (
                      <Col
                        className="IndexDatatableNeoCol"
                        key={index}
                        style={{
                          fontSize: "15px",
                          fontWeight: "700",
                          lineHeight: "18px",
                          fontFamily: "Roboto",
                        }}
                      >
                        {headItem.label}{" "}
                      </Col>
                    );
                  })}
                </Row>
                {totCnt ? (
                  <>
                    {data?.map((ele: any, index: any) => {
                      return (
                        <React.Fragment key={index}>
                          <Row className=" ContentRow">
                            <Col
                              xs={4}
                              className="IndexDatatableNeoCol"
                              style={{
                                fontSize: "15px",
                                fontWeight: "700",
                                lineHeight: "18px",
                                fontFamily: "Roboto",
                              }}
                            >
                              {ele?.label}
                            </Col>
                            <Col
                              xs={4}
                              className="IndexDatatableNeoCol"
                              style={{
                                fontSize: "15px",
                                fontWeight: "700",
                                lineHeight: "18px",
                                fontFamily: "Roboto",
                              }}
                            >
                              {ele?.createdAt}
                            </Col>
                            <Col
                              xs={4}
                              className="IndexDatatableNeoCol"
                              style={{
                                fontSize: "15px",
                                fontWeight: "700",
                                lineHeight: "18px",
                                fontFamily: "Roboto",
                              }}
                            >
                              {ele?.action}
                            </Col>
                          </Row>
                        </React.Fragment>
                      );
                    })}
                    <div className=" mt-4">
                      <Row>
                        <Col xs={6} className="text-white">
                          <Stack spacing={2}>
                            <Pagination
                              className="pagination"
                              size="small"
                              shape="rounded"
                              page={offset / 10 + 1}
                              count={totPages}
                              color="primary"
                              // onChange={(e)=>{handleChangePage(e,offset / 10 + 1)}}
                              onChange={(e, val) => {
                                handleChangePage(e, val);
                                console.log("event", e, val);
                              }}
                              defaultPage={1}
                              siblingCount={1}
                            />
                          </Stack>
                        </Col>
                        <Col xs={6}>
                          <span className="text-white">
                            Showing {fromVal} to {toVal} of {totCnt} entries
                          </span>
                        </Col>
                      </Row>
                    </div>
                  </>
                ) : (
                  <div className="NoDataDiv">
                    <span>No data exists</span>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  console.log("state", state);
  return {
    ...state,
    getSavedMails: state?.userReducer?.gettingEmailsWithSavingGroup,
  };
};
export default connect(mapStateToProps, {
  getingMailWithSavedToGroup,
})(GetSavedMails);
