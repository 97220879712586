import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import { useLocation } from "react-router-dom";
import Select from "react-select";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import SideBar from "../../components/verticalMenu/SideBar";
import notifications from "../../components/Notifications/Notification";
import { addMatchPerfomance } from "../../Store/matchPerfomance/actions";
import { FiArrowLeft } from "react-icons/fi";
import { Link } from "react-router-dom";
let HeaderData = {
  columns: [
    {
      label: "User Name",
      field: "userName",
      width: 3,
    },
    {
      label: "CombatScore",
      field: "combatScore",
      width: 3,
    },
    {
      label: "Kills",
      field: "kills",
      width: 3,
    },
    {
      label: "Deaths",
      field: "deaths",
      width: 3,
    },
    {
      label: "Assists",
      field: "assists",
      width: 2,
    },
    {
      label: "FirstBlood",
      field: "firstBlood",
      width: 2,
    },
    {
      label: "Plants",
      field: "plants",
      width: 2,
    },
  ],
  // rows: trips,
};
const AddPerfomanceUser = (props: any) => {
  const location = useLocation();
  const navigator = useNavigate();
  let winningArray: any = [];
  if (location?.state?.matchDetails !== undefined) {
    winningArray = location?.state?.matchDetails?.lossingTeamInfo?.users;
    let loosingArray = location?.state?.matchDetails?.winningTeamInfo?.users;
    winningArray = [...winningArray, ...loosingArray];
  }
  let winningArr: any = [];
  let TeamArray: any = [];
  if (winningArray) {
    winningArray.map((user: any, i: any) => {
      if (user?.user !== null) {
        TeamArray.push({
          value: user?.user,
          label: user?.name,
        });
        winningArr = [
          ...winningArr,
          {
            userId: user?.user,
            combatScore: 0,
            kills: 0,
            deaths: 0,
            assists: 0,
            firstBlood: 0,
            plants: 0,
          },
        ];
      }
    });
    console.log("winningArray", winningArr);
  }
  const [userId, setUserId]: any = useState([]);
  const [form, setForm]: any = useState({
    userId: "",
    combatScore: 0,
    kills: 0,
    deaths: 0,
    assists: 0,
    firstBlood: 0,
    plants: 0,
  });
  const [userPerfomance, setUsersPerfomance]: any = useState([...winningArr]);
  const [userPerfomanceDisplaying, setUsersPerfomanceDisplay]: any = useState(
    []
  );
  console.log("location", location);
  let matchDetails = location?.state?.matchDetails;
  console.log(
    location?.state?.matchDetails?.lossingTeamInfo?.users?.length +
      location?.state?.matchDetails?.winningTeamInfo?.users?.length
  );
  useEffect(() => {
    console.log(
      "userPerfomance",
      userId,
      form,
      userPerfomance,
      userPerfomanceDisplaying
    );
    console.log(
      TeamArray?.filter(
        (user: any) =>
          !userId?.some((user1: any) => user1?.value == user?.value)
      )
    );
  }, [userId, userPerfomance, form, userPerfomanceDisplaying]);

  console.log("winningArray", winningArray);
  const playersOnsubmitForm = (data: any) => {
    // setUsersPerfomance({...userPerfomance,data})
  };
  const handleOnSubmit = () => {
    let payload = {
      tournamentId: location?.state?.matchDetails.tournamentId,
      matchId: location?.state?.matchDetails?._id,
      playersScore: userPerfomance,
    };
    console.log("payload", payload);
    props.addMatchPerfomance(payload, (res: any) => {
      if (res.success) {
        notifications({
          title: "Success!",
          message: res?.message,
          type: "success",
        });
        navigator("/match-details");
        window.scrollTo(0, 0)
      } else {
        notifications({
          title: "Error!",
          message: res?.message,
          type: "danger",
        });
      }
    });
  };
  const onClickAddUserPerfomance = () => {
    console.log("form", form);
    setUserId([...userId, form?.userId]);
    setUsersPerfomanceDisplay([...userPerfomanceDisplaying, { ...form }]);
    let index = userPerfomance.findIndex(
      (user: any) => user?.userId === form?.userId?.value
    );

    userPerfomance[index] = {
      userId: form?.userId?.value,
      combatScore: Number(form?.combatScore),
      kills: Number(form?.kills),
      deaths: Number(form?.deaths),
      assists: Number(form?.assists),
      firstBlood: Number(form?.firstBlood),
      plants: Number(form?.plants),
    };

    // userPerfomance[index].userId = form?.userId?.value;
    console.log("result", index);
    setForm({
      userId: "",
      combatScore: 0,
      kills: 0,
      deaths: 0,
      assists: 0,
      firstBlood: 0,
      plants: 0,
    });
  };
  const onChangeSubmit = (type: any) => {
    console.log("type", type);

    setForm({ ...form, [type?.name]: type?.value });
  };
  return (
    <React.Fragment>
      <div className="pageContent">
        <div className="pageContentChild">
          <div>
            <SideBar></SideBar>
          </div>
          <div className="p-3 w-100">
            <div>
              <Link to="/match-details">
                <button className="m-4">
                  <FiArrowLeft className="cursor-pointer editIcon " />
                </button>
              </Link>
            </div>
            <div className="mt-3">
              {/* <h4>User Name :{user?.name}</h4> */}
              <Row>
                <Col xs={6} md={3}>
                  <label className="mb-3 text-white">User</label>

                  <Select
                    {...props}
                    className="Game_selector"
                    classNamePrefix="react-select"
                    placeholder="Select name..."
                    autoFocus={true}
                    isSearchable
                    name="userId"
                    value={form?.userId}
                    options={TeamArray?.filter(
                      (user: any) =>
                        !userId?.some(
                          (user1: any) => user1?.value == user?.value
                        )
                    )}
                    onChange={(e: any) => {
                      console.log("e value", e);
                      onChangeSubmit({
                        name: "userId",
                        value: e,
                      });
                    }}
                  />
                </Col>
                <Col xs={6} md={3}>
                  <label className="mb-3 text-white">CombatScore</label>

                  <input
                    type="number"
                    name="CombatScore"
                    placeholder="CombatScore"
                    value={form?.combatScore}
                    className="form-control inp_text text-capitalize"
                    onChange={(e: any) =>
                      onChangeSubmit({
                        name: "combatScore",
                        value: e.target.value,
                      })
                    }
                  />
                </Col>
                <Col xs={6} md={3}>
                  <label className="mb-3 text-white">Kills</label>

                  <input
                    type="number"
                    name="Kills"
                    placeholder="Kills"
                    value={form?.kills}
                    onChange={(e: any) =>
                      onChangeSubmit({
                        name: "kills",
                        value: e.target.value,
                      })
                    }
                    className="form-control inp_text text-capitalize"
                  />
                </Col>
                <Col xs={6} md={3}>
                  <label className="mb-3 text-white">Deaths</label>

                  <input
                    type="number"
                    name="Deaths"
                    placeholder="Deaths"
                    value={form?.deaths}
                    onChange={(e: any) =>
                      onChangeSubmit({
                        name: "deaths",
                        value: e.target.value,
                      })
                    }
                    className="form-control inp_text text-capitalize"
                  />
                </Col>
                <Col xs={6} md={3}>
                  <label className="mb-3 text-white">Assists</label>

                  <input
                    type="number"
                    name="Assists"
                    placeholder="Assists"
                    value={form?.assists}
                    onChange={(e: any) =>
                      onChangeSubmit({
                        name: "assists",
                        value: e.target.value,
                      })
                    }
                    className="form-control inp_text text-capitalize"
                  />
                </Col>
                <Col xs={6} md={3}>
                  <label className="mb-3 text-white">FirstBlood</label>

                  <input
                    type="number"
                    name="FirstBlood"
                    placeholder="First Blood"
                    value={form?.firstBlood}
                    onChange={(e: any) =>
                      onChangeSubmit({
                        name: "firstBlood",
                        value: e.target.value,
                      })
                    }
                    className="form-control inp_text text-capitalize"
                  />
                </Col>
                <Col xs={6} md={3}>
                  <label className="mb-3 text-white">Plants</label>
                  <input
                    type="number"
                    name="Plants"
                    placeholder="Plants"
                    value={form?.plants}
                    onChange={(e: any) =>
                      onChangeSubmit({
                        name: "plants",
                        value: e.target.value,
                      })
                    }
                    className="form-control inp_text text-capitalize"
                  />
                </Col>
                <Col xs={6} md={3}>
                  <div className="addButton ">
                    <button
                      onClick={() => {
                        onClickAddUserPerfomance();
                      }}
                    >
                      Add
                    </button>
                  </div>
                </Col>
              </Row>
            </div>
            {userPerfomanceDisplaying?.length ? (
              <>
                <Row className="HeaderRow mt-5">
                  {HeaderData?.columns?.map((headItem, index) => {
                    return (
                      <Col
                        className="IndexDatatableNeoCol"
                        key={index}
                        style={{
                          fontSize: "15px",
                          fontWeight: "700",
                          lineHeight: "18px",
                          fontFamily: "Roboto",
                        }}
                      >
                        {headItem.label}{" "}
                      </Col>
                    );
                  })}
                </Row>
                {userPerfomanceDisplaying?.map((user: any, i: any) => {
                  return (
                    <>
                      <Row>
                        <Col xs={2}>
                          <span>{user?.userId?.label}</span>
                        </Col>
                        <Col xs={2}>
                          <span>{user?.combatScore}</span>
                        </Col>
                        <Col xs={1}>
                          <span>{user?.kills}</span>
                        </Col>
                        <Col xs={1}>
                          <span>{user?.deaths}</span>
                        </Col>
                        <Col xs={2}>
                          <span>{user?.assists}</span>
                        </Col>
                        <Col xs={2}>
                          <span>{user?.firstBlood}</span>
                        </Col>
                        <Col xs={2}>
                          <span>{user?.plants}</span>
                        </Col>
                      </Row>
                    </>
                  );
                })}
                <button
                  className="mt-5 submitButton"
                  onClick={() => {
                    handleOnSubmit();
                  }}
                >
                  Submit
                </button>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state: any) => {
  console.log("state", state);
  return {
    ...state,
  };
};
export default connect(mapStateToProps, {
  addMatchPerfomance,
})(AddPerfomanceUser);
